import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import toast from "react-hot-toast";
import API from "../API";

const ContactForm = () => {
  const location = useLocation();
  const currentPage = location.state?.currentPage || 1;
  const search = location.state?.search || "";
  const search2 = location.state?.search2 || "";
  const search4 = location.state?.search4 || "";
  const searchDate = location.state?.searchDate || "";
  const search8 = location.state?.search8 || "";
  const search7 = location.state?.search7 || "";
  const search9 = location.state?.search9 || "";
  const search10 = location.state?.search10 || "";
  const searchCountry = location.state?.searchCountry || "";
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [name, setName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [hubspotEmail, setHubspotEmail] = useState("");
  const [hubspotNumber, setHubspotNumber] = useState("");
  const [hubspotEmailCopy, setHubspotEmailCopy] = useState("");
  const [hubspotNumberCopy, setHubspotNumberCopy] = useState("");
  const [hubspotId, setHubspotId] = useState("");
  const [hubspotName, setHubspotName] = useState("");
  const [nameMatch, setNameMatch] = useState(true);
  const [contactphone, setContactPhone] = useState("");
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [initialCommunication, setInitialCommunication] = useState(data.initialCommunication);
  
  async function fetchData() {
    try{
      const response = await API.get(
        `/ivisa/edit/${id}`
      );
      setData(response.data);
      await fetchHubspotData(response.data.hubspot_id);
    } catch(error){
      if(error.response && error.response.status===401){
        navigate('/login');
      }
    }
  };

  async function fetchHubspotData(id) {
    try {
      setIsSubmitDisabled(true);
      const response = await API.get(
        `/flights/user/hubspot/${id}`
      );
      setHubspotId(response.data.properties.hs_object_id)
      setHubspotEmail(response.data.properties.email);
      setHubspotEmailCopy(response.data.properties.email);
      setHubspotNumber(response.data.properties.phone);
      setHubspotNumberCopy(response.data.properties.phone);
      setHubspotName(response.data.properties.firstname + ' ' + (response.data.properties.lastname ? response.data.properties.lastname : ''));
      setIsSubmitDisabled(false);
    } catch (err) {
      setHubspotEmail("");
      setHubspotNumber("");
      setHubspotName("");
      console.error(err);
    }
  };
  async function fetchHubspotDataFromEmail(hubspot_email) {
    try {
      setIsSubmitDisabled(true);
      const response = await API.get(
        `/flights/user/hubspotemail/${hubspot_email}`
      );
      setHubspotNumber(response.data.properties.phone);
      setHubspotName(response.data.properties.firstname + ' ' + (response.data.properties.lastname ? response.data.properties.lastname : ''));
      setHubspotId(response.data.properties.hs_object_id)
      setHubspotEmail(response.data.properties.email)
      setIsSubmitDisabled(false);
    } catch (err) {
      setHubspotEmail("");
      setHubspotNumber("");
      setHubspotName("");
      console.error(err);
    }
  };
  const handleEmailBlur = () => {
    fetchHubspotDataFromEmail(hubspotEmail);
  };
  const convertToID = (link) => {
    if (link && String(link).includes("/")) {
      let id = link.split("/").pop();
      return id;
    } else {
      return link;
    }
  };
  const isEmailValid = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitDisabled(true);
    if (!isEmailValid(contactEmail) && contactEmail !== "") {
      setEmailError("Please enter a valid email address");
      setIsSubmitDisabled(false);
      return;
    }
    if (hubspotEmail === contactEmail || hubspotNumber === contactphone){
      window.alert("Please make sure the hubspot contact details are different from the alternate contact details.")
      setIsSubmitDisabled(false);      
      return;
    }
    // Check if the HubSpot or contact email has changed
    let emailChanges = [];

    if (hubspotEmail !== hubspotEmailCopy) {
      emailChanges.push(`HubSpot Email: ${hubspotEmail}`);
    }
    if (contactEmail !== data.contact_email) {
      emailChanges.push(`Contact Email: ${contactEmail}`);
    }

    // If there are changes, confirm with the user
    if (emailChanges.length > 0) {
      const confirmMessage = `The following email address(es) will be updated:\n${emailChanges.join('\n')}\nDo you want to proceed?`;
      if (!window.confirm(confirmMessage)) {
        setIsSubmitDisabled(false);
        return; // Stop the submission if the user cancels
      }
    }
    try {
      let updatedData = {
        contact_email: contactEmail,
        hubspot_id: convertToID(hubspotId)?hubspotId:'',
        group_urn: data.group_URN_reference_number?data.group_URN_reference_number:'',
        reference_number: data.reference_number?data.reference_number:false,
        phone: contactphone?contactphone:'',
        applicant_name: data.name?data.name:'',
        name_match:nameMatch?nameMatch:false,
        initialCommunication:initialCommunication,
        hubspot_email: hubspotEmail,
      };
      const result = await API.patch(
        `/ivisa/edit/contact/${id}`,
        updatedData
      );
      setData(result.data);
      toast.success("Contact Details Updated Successfully.");
      // window.location.reload();
    } catch (err) {
      console.error(err);
    }
    setIsSubmitDisabled(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (data) {
      setName(data.name || "");
      setContactPhone(data.phone_number || "");
      setContactEmail(data.contact_email || "");
      setHubspotId(data.hubspot_id || "");
      setInitialCommunication(data.initialCommunication);      
    }
  }, [data]);
  return (
    <>
      <Button
        variant="contained"
        sx={{ margin: "5px" }}
        onClick={() =>
          navigate("/visa/applications", {
            state: {  currentPage: currentPage,
              search: search,
              search2: search2,
              search4: search4,
              searchDate: searchDate,
              search8: search8,
              search7: search7,
              search9: search9,
              search10: search10,
              searchCountry: searchCountry,
            },
          })
        }
      >
        <ArrowBackIcon /> &nbsp;Go Back
      </Button>
      <Button
        variant="contained"
        sx={{ margin: "5px" }}
        onClick={() =>
          navigate(`/visa/edit/${data._id}`, {
            state: {  currentPage: currentPage,
              search: search,
              search2: search2,
              search4: search4,
              searchDate: searchDate,
              search8: search8,
              search7: search7,
              search9: search9,
              search10: search10,
              searchCountry: searchCountry,
            },
          })
        }
      >
        <EditIcon /> &nbsp;Go To Application Edit
      </Button>
      <Box
        component="section"
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          height: "80vh", // Set the height to 100% of the viewport height
          p: 2,
          border: "1px dashed grey",
          marginTop: "10px",
          width: "500px",
          margin: "auto",

        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} >
            <Typography variant="h6" gutterBottom component="div">
              Passenger Contact Details
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <label for="hubspot_email">Hubspot Primary Email</label>
            <input
              id="hubspot_email"
              type="email"
              placeholder="Hubspot Email"
              value={hubspotEmail}
              onChange={(e) => setHubspotEmail(e.target.value)}
              onBlur={handleEmailBlur}
            />
          </Grid>
          <Grid item xs={12} sm={6} >
          </Grid>
          <Grid item xs={12} sm={6}>
            <label for="contact_phone">Contact Email Address</label>
            <input
              id="contact_email"
              placeholder="Email Address"
              value={contactEmail}
              onChange={(e) => {
                setContactEmail(e.target.value);
              }
            }
            />
            {emailError && <div style={{ color: "red" }}>{emailError}</div>}
          </Grid>           
          <Grid item xs={12} sm={6}>
            <label for="contact_phone">Contact Phone Number</label>
            <input
              id="contact_phone"
              placeholder="9715x"
              value={contactphone}
              onChange={(e) => {
                setContactPhone(e.target.value);
              }
            }
            />
          </Grid>          
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  name="copyPNR_NAME"
                  value="yes"
                  checked
                />
              }
              label="Copy Hubspot ID to all applicants with the same group URN Reference"
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  name="copyPNR_NAME"
                  value={nameMatch}
                  checked={nameMatch}
                  onChange={(e) => {
                    setNameMatch(e.target.checked);
                  }}
                />
              }
              label="Copy to all entries of this applicant with exact Name Match"
            />
          </Grid>
          <Grid item xs={12}>
            <div>
              <span style={{ fontStyle: 'italic', fontSize: '12px' }}>Hubspot URL:</span>
              &nbsp;
              <a href={`https://app.hubspot.com/contacts/20340520/contact/${hubspotId}`}>
                <span>{hubspotName}</span>
              </a>
              <br />
              <span style={{ fontStyle: 'italic', fontSize: '12px' }}>Customer Communication Methods</span>
              <br />
              <span >{hubspotEmail}</span>
              <br />
              <span >{hubspotNumber}</span>
              <br />
              <span >{contactEmail}</span>
              <br />
              <span >{contactphone}</span>
            </div>
          </Grid>          

        </Grid>
        <style>
          {`
          input{
            border: 1px solid #393f81;
            border-radius: 5px;
            padding: 5px;
          }
          input::placeholder{
            opacity: 0.5;
            }
          `}
        </style>
        <div className="fixed-bottom">
          <button
            type="submit"
            className={`btn btn-success col-2`}
            disabled={isSubmitDisabled}
            onClick={handleSubmit}
          >
            Save
          </button>
        </div>
      </Box>
    </>
  );
};

export default ContactForm;
