import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { useDispatch } from "react-redux";
import { addFilter,removeFilter } from "../filterSlice";
const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};

const filter = [
  "To Departure Date",
  "Departure Code",
  "Arrival Code",
  "Airline Name",
  "Status",
  "Hubspot",
  "Phone Number",
];

export default function MultipleSelectCheckmarks() {
  const [personName, setPersonName] = React.useState([]);
  const dispatch = useDispatch();
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    const newFilters = typeof value === "string" ? value.split(",") : value;

    // Determine filters to add and remove
    const filtersToAdd = newFilters.filter((f) => !personName.includes(f));
    const filtersToRemove = personName.filter((f) => !newFilters.includes(f));

    // Dispatch add and remove actions
    filtersToAdd.forEach((filter) => dispatch(addFilter(filter)));
    filtersToRemove.forEach((filter) => dispatch(removeFilter(filter)));

    // Update state
    setPersonName(newFilters);
  };

  return (
    <div>
      <FormControl sx={{ m: 1, width: 200 }}>
        <InputLabel id="demo-multiple-checkbox-label">Filter Search</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={personName}
          onChange={handleChange}
          input={<OutlinedInput label="Filter Search" />}
          renderValue={(selected) => selected.join(", ")}
          MenuProps={MenuProps}
        >
          {filter.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={personName.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
