import React, { useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import PhoneIcon from "@mui/icons-material/Phone";
import GoogleIcon from '@mui/icons-material/Google';
import { useDispatch } from "react-redux";
import {
  getDataAscending,
  getDataDescending,
  sortByDepartureDateAscending,
  sortByDepartureDateDescending,
} from "../dataSlice";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import EmailIcon from '@mui/icons-material/Email';
import CircleIcon from "@mui/icons-material/Circle";
import ToolTip from "@mui/material/Tooltip";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import API from '../../Visa/API'
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { toast } from "react-hot-toast";
import Modal from "@mui/material/Modal";
import { TextField } from "@mui/material";

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
};

export default function UserTable1({
  data,
  onDelete,
  onRefund,
  onCancel,
  onNameClick,
  onPNRClick,
  onConfirm,
  submitSearch,
  currentPage,
  search,
  search1,
  search8,
  search4,
  search5,
  search6,
  search7,
  searchTicketType,
  searchDate
}) {
  const [selectedRows, setSelectedRows] = useState([]);
  const [latestTimeOfSelected, setLatestTimeOfSelected] = useState(null);
  const [mainCheck, setMainCheck] = useState(false);
  const navigate = useNavigate();  
  const onMainCheck = () => {
    setMainCheck((prevMainCheck) => {
      const newCheck = !prevMainCheck;
      if (newCheck) {
        // If main checkbox is checked, select all rows
        const allIds = data.map((row) => row._id);
        setSelectedRows(allIds);
      } else {
        // If main checkbox is unchecked, clear selection
        setSelectedRows([]);
      }

      return newCheck;
    });
  };
  const handleConfirm = () => {
    onConfirm(selectedRows);
    setSelectedRows([]);
  };

  const handleDel = () => {
    onDelete(selectedRows);
    setSelectedRows([]);
  };
  const handleRefund = () => {
    onRefund(selectedRows);
    setSelectedRows([]);
  };
  const handleCancel = () => {
    onCancel(selectedRows, latestTimeOfSelected);
    setSelectedRows([]);
  };
  const getAirportName = async (code) => {
    const options = {
      method: "GET",
      url: "https://airport-info.p.rapidapi.com/airport",
      params: { iata: code },
      headers: {
        "X-RapidAPI-Key": "adb92967f9mshc7a132f0c140613p1bd516jsn45c226219870",
        "X-RapidAPI-Host": "airport-info.p.rapidapi.com",
      },
    };

    try {
      const response = await axios.request(options);
      return response.data.location + " - " + response.data.name;
    } catch (error) {
      console.error(error);
    }
  };
  const getHubspotData = async (id) => {
    let response = await API.get(
      `/flights/user/hubspot/${id}`
    );
    return response.data;
  };

  const convertDateToString = (date2, withTime=true) => {
    const newdate = date2.split("T");
    const date = newdate[0];
    const time = newdate[1].split(":");
    const splitted_date = date.split("-");
    const year = splitted_date[0].slice(2, 4);
    const month = giveStringMonth(splitted_date[1]);
    const day = splitted_date[2];
    const time1 = `${time[0]}:${time[1]}`;
    let date1;
    if (withTime){
      date1 = `${day}-${month}-${year} ${time1}`;
    } else {
      date1 = `${day}-${month}-${year}`;
    }
    return date1;
  };
  const giveStringMonth = (month) => {
    // i get the month in digit and i return the first three letter of the month in string
    let monthString = "";
    switch (month) {
      case "01":
        monthString = "Jan";
        break;
      case "02":
        monthString = "Feb";
        break;
      case "03":
        monthString = "Mar";
        break;
      case "04":
        monthString = "Apr";
        break;
      case "05":
        monthString = "May";
        break;
      case "06":
        monthString = "Jun";
        break;
      case "07":
        monthString = "Jul";
        break;
      case "08":
        monthString = "Aug";
        break;
      case "09":
        monthString = "Sep";
        break;
      case "10":
        monthString = "Oct";
        break;
      case "11":
        monthString = "Nov";
        break;
      case "12":
        monthString = "Dec";
        break;
      default:
        monthString = "Jan";
        break;
    }
    return monthString;
  };

  const Row = ({ row }) => {
    const [open, setOpen] = useState(false);

    const handleCheckboxChange = (_id, departure_date) => {
      const newSelectedRows = selectedRows.includes(_id)
        ? selectedRows.filter((id) => id !== _id)
        : [...selectedRows, _id];
      setSelectedRows(newSelectedRows);
      setLatestTimeOfSelected(departure_date);
    };
    
    let tickets;
    try {
        tickets = row.ticket_url ? JSON.parse(row.ticket_url) : [];
    } catch (error) {
        tickets = row.ticket_url; // Fallback to string if parsing fails
    }

    const [departureAirport, setDepartureAirport] = useState("");
    const [arrivalAirport, setArrivalAirport] = useState("");
    const [hubspotName, setHubspotName] = useState("");
    const [hubspotEmail, setHubspotEmail] = useState("");
    const [hubspotNumber, setHubspotNumber] = useState("");
    const [openGReviewModal, setOpenGReviewModal] = useState(false);
    const [gReview, setGReview] = useState({});
    const [activeGVisaForReview, setActiveGVisaForReview] = useState({});
    const [openCreateEditReviewModal, setOpenCreateEditReviewModal] = useState(false);

    useEffect(() => {
      if (open) {
        getAirportName(row.departure_code)
          .then((res) => {
            setDepartureAirport(res);
          })
          .catch((error) => {
            console.error("Error getting departure airport", error);
          });

        getAirportName(row.arrival_code)
          .then((res) => {
            setArrivalAirport(res);
          })
          .catch((error) => {
            console.error("Error getting arrival airport", error);
          });
        if (row.hubspot_id) {
          getHubspotData(row.hubspot_id)
            .then((res) => {
              setHubspotName(res.properties.firstname);
              setHubspotEmail(res.properties.email);
              setHubspotNumber(res.properties.phone);
            })
            .catch((error) => {
              console.error("Error getting hubspot data", error);
            });
        }
      }
    }, [open, row.departure_code, row.arrival_code]);
    const getStatusColor = (status) => {
      switch (status) {
        case "Active":
          return "green"; // Green color for "Active"
        case "Refunded":
          return "red"; // Yellow color for "Refunded"
        case "Cancelled":
          return "yellow";
        case "Changed":
          return "blue";
        default:
          return "black"; // Default color for other statuses
      }
    };
    const color = getStatusColor(row.status);

    const onViewGoogleReviewClick = async (row) => {
      const googleReview = await API.get(`/flights/review/${row.g_review?.review_id}`)
      setGReview(googleReview.data);
      setActiveGVisaForReview(row);
      setOpenGReviewModal(true);
    };

    const removeGoogleReview = async () => {
      try {
        const response = await API.post(`/flights/edit_review/${activeGVisaForReview._id}`, {
          matched: null,
          review_id: null,
          score: null,
        });
        if (response.status === 200) {
          toast.success("Google Review Removed Successfully");
          setOpenGReviewModal(false);
          setOpenCreateEditReviewModal(true);
          setTimeout(() => {
            submitSearch();
          }, 500);      
        }
      } catch (error) {
        console.error("Error removing google review:", error);
      }
    }

    const onCreateGoogleReviewClick = async (row) => {
      setActiveGVisaForReview(row);
      setGReview({"full_name": row.passenger_name, "matched": "Manual", "review_text": ""});
      setOpenCreateEditReviewModal(true);
      setOpenGReviewModal(false);
    };

    const saveGoogleReview = async () => {
      let newGReview = {};
      try{
        newGReview = await API.post(`/flights/add_new_review`, {
          full_name: gReview.full_name,
          review_text: gReview.review_text,
        });
      } catch (error) {
        console.error("Error saving google review:", error);
        return;
      }
      console.log("Google Review Saved Successfully", newGReview);
      try {
        const response = await API.post(`/flights/edit_review/${activeGVisaForReview._id}`, {
          matched: "Manual",
          review_id: newGReview.data._id,
          score: 100,
        });
        if (response.status === 200) {
          toast.success("Google Review Added Successfully");
          setOpenGReviewModal(false);
          setOpenCreateEditReviewModal(false);
          setTimeout(() => {
            submitSearch();
          }, 500);          
        }
      } catch (error) {
        console.error("Error removing google review:", error);
      }
    }
    
    return (
      <>
        <Modal open={openCreateEditReviewModal}>
          <Box sx={modalStyle}>
            <p className="m-2">Google Review</p>
            <h3 className="m-1">{gReview.full_name}</h3>
            <br />
            <TextField 
              label="Review Text"
              multiline
              rows={4}
              variant="outlined"
              value={gReview.review_text}
              onChange={(e) => setGReview({...gReview, review_text: e.target.value})}
            />
            <br />
            <Button variant="contained" color="success" onClick={() => saveGoogleReview()}>
              Save Review
            </Button>
            <br />
            <Button variant="contained" color="primary" onClick={() => setOpenCreateEditReviewModal(false)}>
              Close
            </Button>
          </Box>
        </Modal>
        <Modal open={openGReviewModal}>
          <Box sx={modalStyle}>
            <p className="m-2">Google Review</p>
            <h3 className="m-1">{gReview.full_name}</h3>
            <p className="mb-3">{gReview.review_text}</p>
            <br />
            <Button variant="contained" color="secondary" onClick={() => removeGoogleReview()}>
              Remove Review
            </Button>            
            <br />
            <Button variant="contained" color="primary" onClick={() => setOpenGReviewModal(false)}>
              Close
            </Button>
          </Box>
        </Modal>      
        <TableRow key={row._id} sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell>
            <Checkbox
              checked={selectedRows.includes(row._id)}
              onChange={() => handleCheckboxChange(row._id, row.departure_date)}
            />
          </TableCell>
          <TableCell>
            <ToolTip title={row.status === "Cancelled" ? "Use By "+convertDateToString(row.use_by_date, false) : row.status}>
              <CircleIcon sx={{ color: color }} />
            </ToolTip>
            {(row.g_review && (row.g_review.matched === "Auto" || row.g_review.matched === "Manual")) && 
                <ToolTip title={<div style={{ whiteSpace: 'pre-line' }}>{`${row.g_review.matched} matched with score ${row.g_review.score}. \nClick for more details`}</div>}>
                  <GoogleIcon 
                    style={{ color: 'green' }}
                    onClick={() => onViewGoogleReviewClick(row)}
                  /> 
                </ToolTip>
              }
              {(!row.g_review || (row.g_review.matched === "None" || row.g_review.matched === null)) && 
                <ToolTip title={`Click to add Review`}>
                  <GoogleIcon 
                    style={{ color: 'red' }}
                    onClick={() => onCreateGoogleReviewClick(row)}  
                  />
                </ToolTip>
              }            
          </TableCell>
          <TableCell
            component="th"
            scope="row"
            onClick={() => onNameClick(row.passenger_name)}
            sx={{ cursor: "pointer" }}
          >
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={(event) => {
                event.stopPropagation(); // Prevent click event from bubbling up
                setOpen(!open);
              }}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
            {row.passenger_name || "-"}
          </TableCell>
          <TableCell>{row.type || "-"}</TableCell>

          <TableCell
            onClick={() =>
              onPNRClick(
                row.type === "SABRE" ? row.sabre_reference : row.pnr || "-"
              )
            }
            sx={{ cursor: "pointer",fontStyle: row.rebooked ? 'italic' : 'normal' }}
          >
            {row.type === "SABRE" ? row.sabre_reference : row.pnr || "-"}
          </TableCell>
          <TableCell>{row.flight_number || "-"}</TableCell>
          <TableCell>
            <b>{row.departure_code + "-" + row.arrival_code || "-"}</b>
          </TableCell>
          <TableCell>
            <b>{convertDateToString(row.departure_date)}</b>
          </TableCell>

          <TableCell>
            <b>{convertDateToString(row.arrival_date)}</b>
          </TableCell>
          <TableCell>
            {Array.isArray(tickets) ? (
              tickets.length > 0 ? (
                tickets.map((ticket, index) => {
                  const parts = ticket.split('#');
                  const isEmailAttachment = parts[0] === 'emailattachment';
                  const url = isEmailAttachment ? parts[1] : ticket;
                  const IconComponent = isEmailAttachment ? (
                    <EmailIcon key={index} style={{ color: 'green' }} />
                  ) : (
                    <PictureAsPdfIcon key={index} />
                  );

                  return (
                    <a
                      key={index}
                      href={url}
                      target="_blank"
                      rel="noreferrer"
                      style={{ textDecoration: 'none' }}
                    >
                      {IconComponent}
                    </a>
                  );
                })
              ) : (
                <span>No tickets available</span>
              )
            ) : (
              (() => {
                const ticket = tickets; // `tickets` is a string
                const parts = ticket.split('#');
                const isEmailAttachment = parts[0] === 'emailattachment';
                const url = isEmailAttachment ? parts[1] : ticket;
                const IconComponent = isEmailAttachment ? (
                  <EmailIcon style={{ color: 'green' }} />
                ) : (
                  <PictureAsPdfIcon />
                );

                return (
                  <a
                    href={url}
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: 'none' }}
                  >
                    {IconComponent}
                  </a>
                );
              })()
            )}
          </TableCell>
          <TableCell>
            <ToolTip title="Contact">
              
              <Button 
                onClick={() => navigate(`/flights/edit/contact/${row._id}`,
                  {state: { currentPage: currentPage,
                            search: search,
                            search1: search1,
                            search8: search8,
                            search4: search4,
                            search5: search5,
                            search6: search6,
                            search7: search7,
                            searchTicketType: searchTicketType,
                            searchDate: searchDate
                  }}
                )} 
                style={{ textDecoration: "none", padding: 0, minWidth: 'auto', border: 'none', display: 'inline-flex'}}>
                <PhoneIcon />
              </Button> 
            </ToolTip>
            <ToolTip title="Edit">
              <Button 
                onClick={() => navigate(`/flights/edit/${row._id}`, 
                  {state: { currentPage: currentPage,
                    search: search,
                    search1: search1,
                    search8: search8,
                    search4: search4,
                    search5: search5,
                    search6: search6,
                    search7: search7,
                    searchTicketType: searchTicketType,
                    searchDate: searchDate
                  }}
                )}
                style={{ textDecoration: "none", padding: 0, minWidth: 'auto', border: 'none', display: 'inline-flex'}}>
                <EditIcon />
              </Button>              
            </ToolTip>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={11}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1, border: "2px solid black", p: 2 }}>
                <Typography variant="h6" gutterBottom component="div">
                  Additional Information
                </Typography>
                <Table size="small" aria-label="tickets">
                  <TableHead>
                    <TableCell>Ticket Date</TableCell>
                    <TableCell>Ticket Number</TableCell>
                    <TableCell>Airline Name</TableCell>
                    <TableCell>Departure Airport</TableCell>
                    <TableCell>Arrival Airport</TableCell>
                  </TableHead>
                  <TableBody>
                    <TableCell>
                      <b>
                        {row.email_date
                          ? convertDateToString(row.email_date)
                          : "-"}
                      </b>
                    </TableCell>
                    <TableCell>{row.ticket_number}</TableCell>
                    <TableCell>{row.airline_name}</TableCell>
                    <TableCell>
                      {row.departure_terminal
                        ? departureAirport + " " + row.departure_terminal
                        : departureAirport}
                    </TableCell>
                    <TableCell>
                      {row.arrival_terminal
                        ? arrivalAirport + " " + row.arrival_terminal
                        : arrivalAirport}
                    </TableCell>
                  </TableBody>
                </Table>
                <Table>
                  <TableHead>
                    <TableCell>Baggage</TableCell>
                    <TableCell>Airline Reference</TableCell>
                    <TableCell>Departure Date (DXB)</TableCell>
                    <TableCell>Arrival Date (DXB)</TableCell>
                    <TableCell>HubSpot Name</TableCell>
                    <TableCell>HubSpot Phone Number</TableCell>
                    <TableCell>HubSpot Email</TableCell>
                    <TableCell>Contact Email</TableCell>
                    <TableCell>Contact Number</TableCell>
                    <TableCell>Service Staff</TableCell>
                    <TableCell>Ticketing Staff</TableCell>
                  </TableHead>
                  <TableBody>
                    <TableCell>{row.baggage}</TableCell>
                    <TableCell>
                      {row.type === "SABRE" ? row.pnr : "-"}
                    </TableCell>

                    <TableCell>
                      <b>
                        {row.departure_time_dxb
                          ? convertDateToString(row.departure_time_dxb)
                          : "-"}
                      </b>
                    </TableCell>
                    <TableCell>
                      <b>
                        {row.arrival_time_dxb
                          ? convertDateToString(row.arrival_time_dxb)
                          : "-"}
                      </b>
                    </TableCell>

                    {row.hubspot_id ? (
                      <a
                        href={`https://app.hubspot.com/contacts/20340520/record/0-1/${row.hubspot_id}`}
                        target="_blank"
                      >
                        <TableCell>{hubspotName}</TableCell>
                      </a>
                    ) : (
                      "-"
                    )}
                    <TableCell>{hubspotNumber}</TableCell>
                    <TableCell>{hubspotEmail}</TableCell>
                    <TableCell>{row.contact_email}</TableCell>
                    <TableCell>{row.contact_number}</TableCell>
                    <TableCell>{row.service_staff}</TableCell>
                    <TableCell>{row.ticketing_staff}</TableCell>
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  };
  const [sort, setSort] = useState(false);
  const [sortdepDate, setSortdepDate] = useState(false);
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);
  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 600 }}>
        <Table stickyHeader aria-label="collapsible table">
          <TableHead className="fixed">
            <TableRow>
              <TableCell>
                <Checkbox checked={mainCheck} onChange={onMainCheck} />
              </TableCell>
              <TableCell>Status</TableCell>
              <TableCell>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => {
                    setSort(!sort);
                    sort
                      ? dispatch(getDataAscending())
                      : dispatch(getDataDescending());
                  }}
                >
                  {sort ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                </IconButton>
                Passenger Name
              </TableCell>
              <TableCell>Type</TableCell>
              <TableCell>PNR</TableCell>
              <TableCell>Flight Number</TableCell>
              <TableCell>Sector</TableCell>
              <TableCell>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => {
                    setSortdepDate(!sortdepDate);
                    sortdepDate
                      ? dispatch(sortByDepartureDateAscending())
                      : dispatch(sortByDepartureDateDescending());
                  }}
                >
                  {sortdepDate ? (
                    <KeyboardArrowDownIcon />
                  ) : (
                    <KeyboardArrowUpIcon />
                  )}
                </IconButton>
                Departure Date
              </TableCell>
              <TableCell>Arrival Date</TableCell>
              <TableCell>Ticket Links</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 ? (
              data.map((row) => <Row key={row._id} row={row} />)
            ) : null}
          </TableBody>
        </Table>
        {data.length == 0 ? (
          <div
            style={{
              width: "max-width",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          > 
            No Future Flight Segments for Search Criteria. Please Enter Dates to Search History.
          </div> 
        ) : null}
        <div className="fixed-bottom">
          <button
            className="btn btn-warning col-2 col-md-3 col-sm-4 mr-2 button"
            onClick={handleCancel}
          >
            Cancel
          </button>
          <button
            className="btn btn-danger col-2 col-md-3 col-sm-4 mr-2 button"
            onClick={handleRefund}
          >
            Refund
          </button>
          <button
            className="btn btn-dark col-2 col-md-3 col-sm-4 button"
            onClick={handleDel}
          >
            Delete
          </button>

          <style>
            {` @media (max-width: 576px) { /* Adjust for small screens */
              .button {
                font-size: 14px; /* Reduce the font size */
                padding: .375rem .75rem; /* Adjust padding if needed */
                }
              }`}
          </style>
        </div>
      </TableContainer>
    </Paper>
  );
}