import { useState, useEffect, useRef } from "react";
import axios from "axios";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import { Link, Tooltip } from "@mui/material";
import Modal from "@mui/material/Modal";
import Box from '@mui/material/Box';
import TextField from "@mui/material/TextField";
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import API from "../API";
import { useNavigate } from "react-router-dom";
import DOMPurify from 'dompurify';
import RichTextEditor from "../../UtilComponents/RichTextEditor";
export default function EVisa() {
    const navigate = useNavigate();
    const quillRef = useRef(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [requirements, setRequirements] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [openModal, setOpenModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openDeleteModal, setopenDeleteModal] = useState(false);
    const [countryInput, setCountryInput] = useState("");
    const [linkInput, setLinkInput] = useState("");
    const [noteInput, setNoteInput] = useState("");
    const [editNote, setEditNote] = useState("");
    const [editCountry, setEditCountry] = useState("");
    const [editLink, setEditLink] = useState("");
    const [deleteId, setDeleteId] = useState("");
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        display: "flex",
        flexDirection: "column",
    };

      useEffect(() => {
        fetchRequirements();
      }, []);  

    const [editId, setEditId] = useState("");
    const fetchRequirements = async () => {
        try {
            // const response = await axios.get("http://165.232.95.138/v1/visa/requirements");
            const response = await API.get("/ivisa/evisa");
            setRequirements(response.data);
        } catch (error) {
           if(error.response && error.response.status===401){
             navigate('/login');
           }
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleSearchInputChange = (event) => {
        setSearchTerm(event.target.value);
    };
    const filteredRequirements = requirements.filter((requirement) =>
        requirement.country.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setCountryInput("");
        setLinkInput("");
        setNoteInput("");
    };
    const handleLinkInputChange = (event) => {
        setLinkInput(event.target.value);
    };

    const handleAddNewCountry = () => {
        // Add your logic to handle adding a new country here
        const response = API.post("/ivisa/evisa/new", {
            country: countryInput,
            link: linkInput,
            notes: noteInput
        });
        window.location.reload();
        
    };

    const handleDeleteEvisa = () => {
        // Add your logic to handle adding a new country here
        const response = API.post(`/ivisa/evisa/delete/${deleteId}`);
         window.location.reload();
    };    

    const handleOpenEditModal = (country, link,_id,note) => {
        setOpenEditModal(true);
        setEditCountry(country);
        setEditLink(link);
        setEditNote(note)
        setEditId(_id);    
    };

    const handleCloseEditModal = () => {
        setOpenEditModal(false);
        setEditCountry("");
        setEditLink("");
    };

    const handleOpenDeleteModal = (_id) => {
        setopenDeleteModal(true);
        setDeleteId(_id);
    };

    const handleCloseDeleteModal = (_id) => {
        setDeleteId("");
        setopenDeleteModal(false);
    };    

    const handleCountryInputChange = (event) => {
        setCountryInput(event.target.value);
    };
    const handleEditCountryInputChange = (event) => {
        setEditCountry(event.target.value);
    };

    const handleEditLinkInputChange = (event) => {
        setEditLink(event.target.value);
    };

    const handleEditCountry = (_id) => {
        // Add your logic to handle editing a country here
        const response = API.patch(`/ivisa/evisa/edit/${_id}`, {
            country: editCountry,
            link: editLink,
            notes: editNote
        });
        window.location.reload();
    };

    const truncatedValue = (value, totalLength) => {
        if (value && value.length > totalLength) {
            return value.substring(0, totalLength) + '...';
        }
        return value;
    }

    const SafeHtml = ({ htmlContent }) => {
        // Sanitize the HTML content
        const sanitizedHtml = DOMPurify.sanitize(htmlContent);
      
        return (
          <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
        );
      };    

    return (
        <Paper>
            <Box sx={{ padding: 2,display:"flex",alignItems:"center",justifyContent:"center",width:"300px" }}>
                <TextField
                    fullWidth
                    label="Search by Country"
                    variant="outlined"
                    value={searchTerm}
                    onChange={handleSearchInputChange}
                />
            </Box>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Country</TableCell>
                            <TableCell>URL</TableCell>
                            <TableCell>Notes</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredRequirements
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((requirement) => (
                                <TableRow key={requirement._id}>
                                    <TableCell>{requirement.country}</TableCell>
                                    <TableCell>
                                        <Link target="_blank" href={requirement.link}>
                                            {truncatedValue(requirement.link,50)}
                                        </Link>
                                    </TableCell>
                                    <TableCell>
                                        <Tooltip title={requirement.notes} placement="top" arrow>
                                            <SafeHtml htmlContent= {truncatedValue(requirement.notes,100)} /> 
                                        </Tooltip>
                                        {/* <Link target="_blank" href={`/visa/e-visa/notes/${requirement._id}`}><TextSnippetIcon/></Link> */}
                                    </TableCell>
                                    <TableCell>
                                        <Button variant="contained" color="secondary" onClick={() => handleOpenEditModal(requirement.country, requirement.link,requirement._id,requirement.notes)}>
                                            Edit
                                        </Button>
                                        <Button className="m-2" variant="contained" color="secondary" onClick={() => handleOpenDeleteModal(requirement._id)}>
                                            Delete
                                        </Button>                                        
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10]}
                component="div"
                count={requirements.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <div style={{ position: "fixed", bottom: "10px", left: "10px" }}>
                <Button variant="contained" color="primary" onClick={handleOpenModal}>
                    Add New Country
                </Button>
            </div>
            <Modal open={openModal} onClose={handleCloseModal}>
                <Box sx={style}>
                    <TextField
                        label="Country"
                        value={countryInput}
                        onChange={handleCountryInputChange}
                        style={{ marginBottom: "10px" }}
                    />
                    <TextField
                        label="Link"
                        value={linkInput}
                        onChange={handleLinkInputChange}
                        style={{ marginBottom: "10px" }}
                    />                    
                    <RichTextEditor
                        htmlContent={noteInput}
                        setHTMLContent={setNoteInput}
                        style={{ marginBottom: "10px" }}
                        placeholder={"Enter notes here"}
                    />
                    <Button variant="contained" color="primary" onClick={handleAddNewCountry}>
                        Add
                    </Button>
                </Box>
            </Modal>
            <Modal open={openEditModal} onClose={handleCloseEditModal}>
                <Box sx={style}>
                    <TextField
                        label="Country"
                        value={editCountry}
                        onChange={handleEditCountryInputChange}
                        style={{ marginBottom: "10px" }}
                    />
                    <TextField
                        label="Link"
                        value={editLink}
                        onChange={handleEditLinkInputChange}
                        style={{ marginBottom: "10px" }}
                    />
                    <RichTextEditor 
                        htmlContent={editNote} 
                        setHTMLContent={setEditNote} 
                        style={{marginBottom: "10px"}} 
                    />
                    <Button variant="contained" color="primary" onClick={() => handleEditCountry(editId)}>
                        Save
                    </Button>
                </Box>
            </Modal>
            <Modal open={openDeleteModal} onClose={handleCloseDeleteModal}>
                <Box sx={style}>
                    <h3 className="m-4">Please confirm to delete </h3>
                    <Button variant="contained" color="primary" onClick={() => handleDeleteEvisa(deleteId)}>
                        Delete
                    </Button>
                </Box>
            </Modal>            
        </Paper>
    );
}
