import { DateRange } from 'react-date-range';

export default function DateTimePicker({ isDatePickerVisible, setDatePickerVisible, searchDate, setSearchDate }) {
  
  const DatePickerStyle = {
    datePickerWrapper: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    datePickerPopup: {
      position: 'absolute',
      zIndex: 1000, // High z-index to ensure it overlays other elements
      top: '100%', // Position it below the button
      left: 0, // Align it with the left edge of the button
      backgroundColor: '#fff', // Background color for visibility
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Optional: Adds shadow for better separation from other content
      width: 'auto', // Adjust width as necessary
    }
  };

  const formatDate = (date) => {
    const options = { month: 'short', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  };  

  return (
    <div className="datePicker" style={DatePickerStyle.datePickerWrapper}>
      <button onClick={() => setDatePickerVisible(!isDatePickerVisible)} className={`form-control rounded mr-2 ${
        searchDate[0].startDate ? "border-success border-4" : ""}`}>
        {searchDate[0].startDate || searchDate[0].endDate || isDatePickerVisible ? (
          <span>
            {searchDate[0].startDate ? formatDate(searchDate[0].startDate) : ''}
            {searchDate[0].endDate ? ` - ${formatDate(searchDate[0].endDate)}` : ''}
            {isDatePickerVisible ? <span style={{whiteSpace: 'pre'}}>{'\u0009\u2715'}</span> : ''}                    
          </span>
        ) : 'Select Date *'}
      </button>          
      {isDatePickerVisible && (
        <div className="datePicker" style={DatePickerStyle.datePickerPopup}>
          <DateRange
            editableDateInputs={false}
            onChange={item => setSearchDate([item.selection])}
            moveRangeOnFirstSelection={true}
            ranges={searchDate}
          />
        </div>
      )}          
    </div>
  );
}
