import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function DiplomaticMissionSelect({ diplomaticMissionVal, setDiplomaticMissionVal }) {

  const handleDiplomaticMissionChange = (event, value) => {
    setDiplomaticMissionVal(value);
  }

  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={diplomaticMissions}
      sx={{ width: 300}}
      renderInput={(params) => <TextField {...params} label="Diplomatic Mission *" />}
      value={diplomaticMissionVal}
      onChange={handleDiplomaticMissionChange} 
      getOptionLabel={(option) => option}
    />
  );
}

const diplomaticMissions = ["Embassy", "Consulate"];