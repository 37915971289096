import React, { useEffect, useState } from "react";
import VisaUserTable from "../components/VisaUserTable";
import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, IconButton, Input, InputLabel, ListItemText, MenuItem, OutlinedInput, TextField } from "@mui/material";
import API from "../../Visa/API";
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import Select from '@mui/material/Select';
import toast from "react-hot-toast";
import { useNavigate, useLocation } from "react-router-dom";
import queryString from 'query-string';


const VisaHomePage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [datestate, setDateState] = useState([null, null]);
  const [datesearch, setDateSearch] = useState("");
  const [namesearch, setNameSearch] = useState("");
  const [passportsearch, setPassportSearch] = useState("");
  const [data, setData] = useState([]);
  const [showUnverifiedOnly, setShowUnverifiedOnly] = useState(false);
  const [searchUnverified, setSearchUnverified] = useState(false);
  const [status, setStatus] = useState([]);

  const handleChange = (event) => {
    setDateSearch(event.target.value);
  };

  const getAllVisaRecords = async () => {
    try {
      const response = await API.get('/uaevisa/getvisarecords');
      setData(response.data);
      setSearchUnverified(false);
      setShowUnverifiedOnly(false);
    } catch (error) {
      console.error('Error fetching visa records:', error);
    }
  };

  useEffect(() => {
    const params = queryString.parse(location.search);
    const { name, passport_number, start_date, end_date, search_criteria, searchUnverified, statuses } = params;

    if (name) setNameSearch(name);
    if (passport_number) setPassportSearch(passport_number);
    if (start_date && end_date) setDateState([new Date(start_date), new Date(end_date)]);
    if (search_criteria) setDateSearch(search_criteria);
    if (searchUnverified === 'true') setSearchUnverified(true);
    if (statuses) setStatus(Array.isArray(statuses) ? statuses : statuses.split(','));

    if (name || passport_number || start_date || end_date || search_criteria || searchUnverified || statuses) {
      handleSearch(params);
      return;
    }

    if (showUnverifiedOnly) {
      showUnverified();
    } else {
      getAllVisaRecords();
    }
  }, [location.search]);

  const handleSearch = async (searchParams) => {
    const params = searchParams || {
      name: namesearch,
      passport_number: passportsearch,
      start_date: datestate ? datestate[0] : null,
      end_date: datestate ? datestate[1] : null,
      search_criteria: datesearch,
      searchUnverified: searchUnverified,
      statuses: status,
    };

    if (!params.name && !params.passport_number && !params.start_date && !params.end_date && !params.statuses) {
      toast.error('Please fill in search fields');
      return;
    }
    if (!params.search_criteria && params.start_date && params.end_date) {
      toast.error('Please select search criteria');
      return;
    } else if (params.search_criteria && (!params.start_date || !params.end_date)) {
      toast.error('Please select start and end date');
      return;
    }

    try {
      const response = await API.get(`/uaevisa/searchRecords`, { params });
      setData(response.data);
      navigate({
        pathname: location.pathname,
        search: queryString.stringify(params),
      });
    } catch (error) {
      console.error('Error fetching visa records:', error);
    }
  };

  const statusNames = [
    'Used',
    'Closed',
    'Expired',
    'Active'
  ];

  const handleStatusChange = (event) => {
    const {
      target: { value },
    } = event;
    setStatus(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleRefresh = () => {
    setDateState([null, null]);
    setDateSearch("");
    setNameSearch("");
    setStatus([]);
    setShowUnverifiedOnly(false);
    setSearchUnverified(false);
    setPassportSearch("");
    getAllVisaRecords();
    navigate(location.pathname);
  };

  const showUnverified = async () => {
      setShowUnverifiedOnly(true);

      try {
        const response = await API.get("/uaevisa/getUnverifiedRecords");
        setData(response.data);
      } catch (error) {
        console.error('Error fetching unverified visa records:', error);
      } 
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return (
    <>
      <style>{`
        .react-daterange-picker__calendar {
          z-index: 50;
        }
      `}</style>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "5px"
        }}
      >
        <div>
          <TextField
            variant="standard"
            label="Search by Passport Number"
            value={passportsearch}
            onChange={(e) => setPassportSearch(e.target.value)}
            style={{ marginLeft: "10px" }}
          />
          <TextField
            variant="standard"
            label="Search by Name"
            value={namesearch}
            onChange={(e) => setNameSearch(e.target.value)}
            style={{ marginLeft: "20px" }}
          />
          <FormControlLabel sx={{ ml: 2 }} control={<Checkbox checked={showUnverifiedOnly} onChange={(e) => { if(e.target.checked) {showUnverified()} else {handleRefresh()}}} />} label="Unverified in the Past" />
        </div>
        <div>
          <FormControlLabel sx={{ ml: 2 }} control={<Checkbox checked={searchUnverified} onChange={(e) => { setSearchUnverified(e.target.checked) }} />} label="Search Unverified" />
          <FormControl sx={{ m: 1, width: 200 }}>
            <InputLabel id="demo-multiple-checkbox-label">Status</InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={status}
              onChange={handleStatusChange}
              input={<OutlinedInput label="Status" />}
              renderValue={(selected) => Array.isArray(selected) ? selected.join(', ') : ''}
              MenuProps={MenuProps}
            >
              {statusNames.map((name) => (
                <MenuItem key={name} value={name}>
                  <Checkbox checked={status.indexOf(name) > -1} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 200 }}>
            <InputLabel id="demo-simple-select-label">Search Date</InputLabel>
            <Select
              value={datesearch}
              onChange={handleChange}
              defaultValue="Entry Date"
              label="Search Date"
            >
              <MenuItem value={null}>--</MenuItem>
              <MenuItem value="Entry Date">Entry Date</MenuItem>
              <MenuItem value="Exit Date">Exit Date</MenuItem>
              <MenuItem value="Issue Date">Issue Date</MenuItem>
              <MenuItem value="Expiry Date">Expiry Date</MenuItem>
              <MenuItem value="Last Date to Exit">Last Date to Exit</MenuItem>
            </Select>
          </FormControl>
          <DateRangePicker onChange={setDateState} value={datestate} />
          <IconButton onClick={async () => await handleSearch()} style={{ marginLeft: "10px" }}>
            <SearchIcon />
          </IconButton>
          <IconButton onClick={handleRefresh} style={{ marginLeft: "10px" }}>
            <RefreshIcon />
          </IconButton>
        </div>
      </div>
      <VisaUserTable data={data} handleSearch={handleSearch} handleRefresh={handleRefresh} />
      <style>
        {`
        .react-daterange-picker__wrapper {
          height: 50px;
          margin-top: 10px;
        }
        `}
      </style>
    </>
  );
};

export default VisaHomePage;
