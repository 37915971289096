import { useState, useEffect } from "react";
import API from "../API";
import { Button, Paper, Grid, TextField, Tooltip } from '@mui/material';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from "@mui/icons-material/Save";
import RichTextEditor from "../../UtilComponents/RichTextEditor";
import toast from 'react-hot-toast';
import DOMPurify from 'dompurify';
import { Modal } from "react-bootstrap";

export default function EmailTemplates() {
    const [templates, setTemplates] = useState([]);
    const [editId, setEditId] = useState("");
    const [editValues, setEditValues] = useState({
        emailTitle: "",
        emailSubject: "",
        emailBody: ""
    });
    const [newTemlpate, setNewTemplate] = useState({
        emailTitle: "",
        emailSubject: "",
        emailBody: ""
    });    
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [deleteId, setDeleteId] = useState("");
    const [openCreateModal, setOpenCreateModal] = useState(false);

    const fetchAllTemplates = async () => {
        try {
            const response = await API.get("/ivisa/templates");
            setTemplates(response.data.templates);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchAllTemplates();
    }, []);

    const handleEditEmail = (template) => {
        setEditId(template._id);
        setEditValues({
            emailTitle: template.emailTitle,
            emailSubject: template.emailSubject,
            emailBody: template.emailBody
        });
    };

    const handleEditTemplate = async (id) => {
        try {
            const response = await API.post(`/ivisa/edittemplate/${id}`, editValues)
            toast.success(response.data.message);
            setTemplates((prevTemplates) => 
                prevTemplates.map((template) =>
                    template._id === id ? { ...template, ...editValues } : template
                )
            );
            setEditId("");
        } catch (error) {
            console.error(error);
        }
    };

    const handleChange = (name, value) => {
        setEditValues((prevValues) => ({
            ...prevValues,
            [name]: value
        }));
    };

    const SafeHtml = ({ htmlContent }) => {
        // Sanitize the HTML content
        const sanitizedHtml = DOMPurify.sanitize(htmlContent);
      
        return (
          <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
        );
      };

    const handleOpenDeleteTemplateModal = (id) => {
        setDeleteId(id);
        setOpenDeleteModal(true);
    };

    const handleDeleteTemplate = async () => {
        try {
            const response = await API.delete(`/ivisa/deletetemplate/${deleteId}`);
            toast.success(response.data.message);
            setTemplates((prevTemplates) => prevTemplates.filter((template) => template._id !== deleteId));
            setOpenDeleteModal(false);
        } catch (error) {
            console.error(error);
        }
    };

    const handleCreateTemplate = async () => {
        console.log(newTemlpate);
        try {
            const response = await API.post("/ivisa/createtemplate", newTemlpate);
            toast.success(response.data.message);
            setTemplates((prevTemplates) => [...prevTemplates, response.data.template]);
            setOpenCreateModal(false);
        } catch (error) {
            console.error(error);
        }
    };

    const handleOpenCreateTemplate = () => {
        setOpenCreateModal(true);
        setNewTemplate({
            emailTitle: "",
            emailSubject: "",
            emailBody: ""
        })
    }

    return (
        <>
        <div style={{ marginBottom: "200px" }}>
            {templates.map((template) => (
                <Paper key={template._id} elevation={5} sx={{ padding: 2, margin: 2 }}>
                    <Grid container spacing={2}>
                        {editId === template._id ? (
                            <>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="emailTitle"
                                        label="Email Title"
                                        value={editValues.emailTitle}
                                        onChange={(e) => handleChange(e.target.name, e.target.value)}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} container justifyContent="flex-end" alignItems="flex-start">
                                    <small>Last Updated By: <em>{template.updatedBy}</em></small>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        name="emailSubject"
                                        label="Email Subject"
                                        value={editValues.emailSubject}
                                        onChange={(e) => handleChange(e.target.name, e.target.value)}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <RichTextEditor
                                        htmlContent={editValues.emailBody}
                                        setHTMLContent={(content) => handleChange("emailBody", content)}
                                        style={{ marginBottom: "10px" }}
                                        placeholder={"Email Body here"}
                                    />
                                </Grid>
                                <Grid item xs={12} container justifyContent="flex-start">
                                    <Tooltip
                                        title="Save"
                                        sx={{ color: "blue", cursor: "pointer" }}
                                        onClick={() => handleEditTemplate(template._id)}
                                    >
                                        <SaveIcon style={{ color: "blue" }} />
                                    </Tooltip>
                                </Grid>
                            </>
                        ) : (
                            <>
                                <Grid item xs={12} sm={6}>
                                    <h2>{template.emailTitle}</h2>
                                </Grid>
                                <Grid item xs={12} sm={6} container justifyContent="flex-end" alignItems="flex-start">
                                    <small>Last Updated By: <em>{template.updatedBy}</em></small>
                                </Grid>
                                <Grid item xs={12}>
                                    <h4>{template.emailSubject}</h4>
                                </Grid>
                                <Grid item xs={12}>
                                    <SafeHtml htmlContent= {template.emailBody} /> 
                                </Grid>
                                <Grid item xs={12} container justifyContent="flex-start">
                                    <Tooltip
                                        title="Edit"
                                        sx={{ color: "blue", cursor: "pointer" }}
                                        onClick={() => handleEditEmail(template)}
                                    >
                                        <EditIcon style={{ color: "blue" }} />
                                    </Tooltip>
                                    <Tooltip 
                                            title="Delete"
                                            sx={{color:"red",cursor:"pointer"}} 
                                            onClick={() => handleOpenDeleteTemplateModal(template._id)}
                                        >
                                            <DeleteIcon style={{ color: "red" }} />
                                        </Tooltip>                                    
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Paper>
            ))}
        </div>
        <div style={{ position: "fixed", bottom: "10px", left: "10px" }}>
            <Button variant="contained" color="primary" onClick={() => handleOpenCreateTemplate()}>
                Create Template
            </Button>
        </div>          
        <Modal show={openDeleteModal} onHide={() => setOpenDeleteModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Delete Template</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure you want to delete this template?</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setOpenDeleteModal(false)}>Close</Button>
                <Button variant="danger" onClick={handleDeleteTemplate}>Delete</Button>
            </Modal.Footer>
        </Modal>
        <Modal show={openCreateModal} onHide={() => setOpenCreateModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Create New Template</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <TextField
                    name="emailTitle"
                    label="Email Title"
                    value={newTemlpate.emailTitle}
                    onChange={(e) => setNewTemplate({ ...newTemlpate, emailTitle: e.target.value })}
                    fullWidth
                    style={{ marginBottom: "10px" }}
                />
                <TextField
                    name="emailSubject"
                    label="Email Subject"
                    value={newTemlpate.emailSubject}
                    onChange={(e) => setNewTemplate({ ...newTemlpate, emailSubject: e.target.value })}
                    fullWidth
                    style={{ marginBottom: "10px" }}
                />
                <RichTextEditor
                    htmlContent={newTemlpate.emailBody}
                    setHTMLContent={(content) => setNewTemplate({ ...newTemlpate, emailBody: content })}
                    style={{ marginBottom: "10px" }}
                    placeholder={"Email Body here"}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setOpenCreateModal(false)}>Close</Button>
                <Button variant="success" onClick={() => handleCreateTemplate()}>Create</Button>
            </Modal.Footer>
        </Modal>
        </>
    );
}
