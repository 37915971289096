import React, { useState, useEffect } from "react";

import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import toast from "react-hot-toast";
import API from "../../Visa/API";
import { useParams } from "react-router-dom";
import countryCode from "../../UaeVisa/countryCode.js";

const EVisaUpload = () => {
  const { id } = useParams();
  const [nationality, setNationality] = useState("");
  const [edit, setEdit] = useState(false);
  const [name, setName] = useState("");
  const [uid, setUid] = useState("");
  const [dob, setDob] = useState("");
  const [issue_date, setIssueDate] = useState("");
  const [filesToUpload, setFilesToUpload] = useState({
    visa: [],
    // insurance: [],
  });
  const [pdfPreviewbase64, setPdfPreviewbase64] = useState(null);
  const [hubspotemail, setHubspotemail] = useState("");
  const [validation, setValidation] = useState(false);
  const [hubspot_id, setHubspot_id] = useState(null);
  const [visaFileName, setVisaFileName] = useState("");
  const [approvalDate, setApprovalDate] = useState(new Date().toISOString().split("T")[0]);
  const [ticketingStaff, setTicketingStaff] = useState("");
    const [closingStaff, setClosingStaff] = useState("");
//   const [insuranceFileName, setInsuranceFileName] = useState("");
  const [visatype, setVisaType] = useState("");
  const [onedrive_url, setOnedriveUrl] = useState("");
  const [passport_number, setPassportNumber] = useState("");
    const [visaCountry, setVisaCountry] = useState("");
  const createFileObjects = async () => {
    const visaFiles = filesToUpload?.visa || [];
    // const insuranceFiles = filesToUpload?.insurance || [];

    const visaFileObjects =
      visaFiles.length > 0
        ? await Promise.all(
            visaFiles.map(async (file) => {
              const fileContent = await file.arrayBuffer();
              const fileContentBase64 = btoa(
                new Uint8Array(fileContent).reduce(
                  (data, byte) => data + String.fromCharCode(byte),
                  ""
                )
              );
              return {
                fileName: file.name,
                fileContent: fileContentBase64,
                fileType: file.type,
              };
            })
          )
        : [];

    // const insuranceFileObjects =
    //   insuranceFiles.length > 0
    //     ? await Promise.all(
    //         insuranceFiles.map(async (file) => {
    //           const fileContent = await file.arrayBuffer();
    //           const fileContentBase64 = btoa(
    //             new Uint8Array(fileContent).reduce(
    //               (data, byte) => data + String.fromCharCode(byte),
    //               ""
    //             )
    //           );
    //           return {
    //             fileName: file.name,
    //             fileContent: fileContentBase64,
    //             fileType: file.type,
    //           };
    //         })
    //       )
    //     : [];

    return [...visaFileObjects];
  };
  const handleUpload = async () => {
    if (!filesToUpload.visa.length > 0) {
      toast.error("Please select a file to upload");
    } else {
      const fileContentArray = await createFileObjects();

      toast.loading("Uploading file...");

      try {
        const response = await API.post(
          `/ivisa/evisaupload`,
          { fileContentArray,id },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        toast.dismiss(); // Dismiss the loading toast

        if(response.data.message === "success"){
            setPdfPreviewbase64(fileContentArray[0].fileContent);
            setName(response?.data?.result?.name);
            setUid(response?.data?.result?.visa_application_number);
            setNationality(response?.data?.result?.nationality);
            setPassportNumber(response?.data?.result?.passport_number);
            setTicketingStaff(response?.data?.result?.staff_name);
            setClosingStaff(response?.data?.result?.staff_name);
            setVisaFileName(fileContentArray[0].fileName);
            setVisaCountry(response?.data?.result?.visa_country);
        }

        
      } catch (error) {
        toast.dismiss(); // Dismiss the loading toast
        toast.error("Error uploading file");
        console.error(error.response ? error.response.data : error.message);
      }
    }
  };

  const handleSubmit = async () => {
    try{
        if(!name || !uid || !issue_date || !approvalDate || !passport_number || !approvalDate || !nationality || !ticketingStaff || !closingStaff){
            toast.error("Please fill all the fields");
            return;
        } else{
            const response = await API.post("/ivisa/newentry",{fullName:name,referenceNumber:uid,groupURN:uid,visaType:"E-Visa",passportnumber:passport_number,visacountry:visaCountry,hubspot:hubspot_id,visa_status:"Approved",onedrive_url:onedrive_url,appointment_file_url:`https://marcopoloholidays151.sharepoint.com/sites/MarcopoloITDept/Shared%20Documents/EVISA/${visaFileName}`,service_staff:ticketingStaff,closing_staff:closingStaff,approvalDate:approvalDate,issue_date:issue_date});
            if(response.status === 200){
              toast.success("Form submitted successfully");
          }
        }
        
    } catch (error) {
        toast.error("Error submitting form");
    }
    
  };
  const handleVisaFileChange = (event) => {
    setFilesToUpload((prevState) => ({
      ...prevState,
      visa: [event.target.files[0]],
    }));
  };

//   const handleInsuranceFileChange = (event) => {
//     setFilesToUpload((prevState) => ({
//       ...prevState,
//       insurance: [event.target.files[0]],
//     }));
//   };
  const [open, setOpen] = useState(false);
  const handleHubspotEmail = async () => {
    try {
      if (!hubspotemail) {
        setValidation(false);
      }
      const response = await API.get(
        `/flights/user/hubspotemail/${hubspotemail}`
      );
      if (response.data) {
        toast.success("Hubspot Contact Found");
        setValidation(true);
        setHubspot_id(response.data.id);
      }
    } catch (error) {
      setOpen(true);
      
    }
  };
  const [staffnames, setStaffnames] = useState([]);
  const getStaffByDepartment = async () => {
    const staff_by_department = await API.get("/flights/staffnames?type=ivisa");
    console.log(staff_by_department.data);
    setStaffnames(staff_by_department.data.staff);
  }
  useEffect(() => {
    getStaffByDepartment();
  },[])
  const [staff, setStaff] = useState("");
  return (
    <>
      <div
        className="container"
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          justifyContent: "center",
          height: "80vh",
          marginTop: "40px",
          gap: "40px",
        }}
      >
        <div style={{ flex: 1 ,width:"100%"}}>
          {!edit && (
            <>
              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px" }}>
                  <InputLabel focused>Upload Visa</InputLabel>
                  <Input type="file" onChange={handleVisaFileChange} />
                </div>
                <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="contained"
                  endIcon={<SendIcon />}
                  onClick={handleUpload}
                  sx={{ m: 2 }}
                >
                  Upload
                </Button>
              </div>
              </div>
              
            </>
          )}

          <Grid
            container
            spacing={2}
            style={{ width: "100%", marginTop: "5px" }}
          >
            <Grid item xs={12} sm={6}>
              <InputLabel focused>Visa Holder Name</InputLabel>
              <Input
                fullWidth
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel focused>Visa Reference Number</InputLabel>
              <Input
                fullWidth
                value={uid}
                onChange={(e) => {
                  setUid(e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <InputLabel focused>E Visa Application Date</InputLabel>
              <Input
                type="date"
                fullWidth
                value={issue_date}
                onChange={(e) => setIssueDate(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel focused>Visa Approval Date</InputLabel>
              <Input
                type="date"
                fullWidth
                value={approvalDate}
                onChange={(e) => setApprovalDate(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel focused>Passport Number</InputLabel>
              <Input
                fullWidth
                value={passport_number}
                onChange={(e) => setPassportNumber(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} >
              <Autocomplete
                disablePortal
                options={countryCode}
                sx={{ width: 250 }}
                value={nationality ? { label: nationality } : null}
                onChange={(event, value) =>
                  setNationality(value ? value.label : "")
                }
                renderInput={(params) => (
                  <TextField {...params} label="Nationality" />
                )}
              />
            </Grid>
            
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth sx={{mt:4}}>
              <InputLabel focused>Service Staff</InputLabel>
              <Select label="Ticketing Staff" value={ticketingStaff} onChange={(e) => setTicketingStaff(e.target.value)}>
                {staffnames.length > 0 && staffnames?.map((staff) => (
                    <MenuItem key={staff._id} value={staff?.staff_firstname + " " + staff?.staff_lastname}>{staff?.staff_firstname + " " + staff?.staff_lastname}</MenuItem>
                ))}
              </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth sx={{mt:4}}>
              <InputLabel focused>Closing Staff</InputLabel>
              <Select label="Closing Staff" value={closingStaff} onChange={(e) => setClosingStaff(e.target.value)}>
                {staffnames.length > 0 && staffnames?.map((staff) => (
                    <MenuItem key={staff._id} value={staff?.staff_firstname + " " + staff?.staff_lastname}>{staff?.staff_firstname + " " + staff?.staff_lastname}</MenuItem>
                ))}
              </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel focused>Visitor Email</InputLabel>
              <TextField
                fullWidth
                type="email"
                color="success"
                value={hubspotemail}
                onChange={(e) => {
                  setHubspotemail(e.target.value);
                }}
                onBlur={(e) => {
                  handleHubspotEmail();
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel focused>One Drive URL</InputLabel>
              <TextField
                fullWidth
                type="text"
                color="success"
                value={onedrive_url}
                onChange={(e) => {
                  setOnedriveUrl(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12}>

                <Button
                  variant="contained"
                  endIcon={<SendIcon />}
                  onClick={handleSubmit}
                  sx={{ mt: 1, mb: 2 }}
                  fullWidth
                  disabled={!validation}
                >
                  Submit
                </Button>
            </Grid>
           
          </Grid>
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              height: "700px",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {!pdfPreviewbase64 && edit && <div className="loader"></div>}
            {pdfPreviewbase64 && (
              <object
                data={`data:application/pdf;base64,${pdfPreviewbase64}`}
                type="application/pdf"
                width="100%"
                height="100%"
              >
                <embed
                  width="100%"
                  height="100%"
                  src={`data:application/pdf;base64,${pdfPreviewbase64}`}
                  type="application/pdf"
                />
              </object>
            )}
          </div>
          <ModalAddContact open={open} handleClose={() => setOpen(false)} setHubspot_id={setHubspot_id} hubspotemail={hubspotemail} date_of_birth={dob} contact_name={name} national={nationality} setValidation={setValidation}/>
        </div>
        <style>
          {`
        @media (max-width: 600px) {
          .container {
            flex-direction: column;
            height: auto;
            width: 90vw;
            padding: 20px;
            gap: 20px;
            }
            .container > div {
              flex-direction: column;
              align-items: stretch;
              }
              .container input, .container button, .container select {
                font-size: 16px;
                }
                .dropzone {
                  padding: 20px;
                  font-size: 14px;
                  }
                  button, select {
                    min-height: 44px;
                    font-size: 16px;
                    }
                    input {
                      margin-left: 10px;
                      }
                      }
.loader {
  width: 80px;
  aspect-ratio: 2;
  --c:linear-gradient(#FFD412 25%,#ABD406 0 50%,#FF821C 0 75%,#FFD412 0);
  background: var(--c), var(--c), var(--c), var(--c);
  background-size: 26% 400%;
  background-position: calc(0*100%/3) 100%,calc(1*100%/3) 100%,calc(2*100%/3) 100%,calc(3*100%/3) 100%;
  background-repeat: no-repeat;
  animation: l10 2s infinite; 
}
@keyframes l10 {
  0%     {background-position: calc(0*100%/3) calc(3*100%/3),calc(1*100%/3) calc(3*100%/3),calc(2*100%/3) calc(3*100%/3),calc(3*100%/3) calc(3*100%/3)}
  8.33%  {background-position: calc(0*100%/3) calc(2*100%/3),calc(1*100%/3) calc(3*100%/3),calc(2*100%/3) calc(3*100%/3),calc(3*100%/3) calc(3*100%/3)}
  16.67% {background-position: calc(0*100%/3) calc(2*100%/3),calc(1*100%/3) calc(2*100%/3),calc(2*100%/3) calc(3*100%/3),calc(3*100%/3) calc(3*100%/3)}
  25%    {background-position: calc(0*100%/3) calc(2*100%/3),calc(1*100%/3) calc(2*100%/3),calc(2*100%/3) calc(2*100%/3),calc(3*100%/3) calc(3*100%/3)}
  30%,
  33.33% {background-position: calc(0*100%/3) calc(2*100%/3),calc(1*100%/3) calc(2*100%/3),calc(2*100%/3) calc(2*100%/3),calc(3*100%/3) calc(2*100%/3)}
  41.67% {background-position: calc(0*100%/3) calc(1*100%/3),calc(1*100%/3) calc(2*100%/3),calc(2*100%/3) calc(2*100%/3),calc(3*100%/3) calc(2*100%/3)}
  50%    {background-position: calc(0*100%/3) calc(1*100%/3),calc(1*100%/3) calc(1*100%/3),calc(2*100%/3) calc(2*100%/3),calc(3*100%/3) calc(2*100%/3)}
  58.33% {background-position: calc(0*100%/3) calc(1*100%/3),calc(1*100%/3) calc(1*100%/3),calc(2*100%/3) calc(1*100%/3),calc(3*100%/3) calc(2*100%/3)}
  63%,
  66.67% {background-position: calc(0*100%/3) calc(1*100%/3),calc(1*100%/3) calc(1*100%/3),calc(2*100%/3) calc(1*100%/3),calc(3*100%/3) calc(1*100%/3)}
  75%    {background-position: calc(0*100%/3) calc(0*100%/3),calc(1*100%/3) calc(1*100%/3),calc(2*100%/3) calc(1*100%/3),calc(3*100%/3) calc(1*100%/3)}
  83.33% {background-position: calc(0*100%/3) calc(0*100%/3),calc(1*100%/3) calc(0*100%/3),calc(2*100%/3) calc(1*100%/3),calc(3*100%/3) calc(1*100%/3)}
  91.67% {background-position: calc(0*100%/3) calc(0*100%/3),calc(1*100%/3) calc(0*100%/3),calc(2*100%/3) calc(0*100%/3),calc(3*100%/3) calc(1*100%/3)}
  97%,
  100%   {background-position: calc(0*100%/3) calc(0*100%/3),calc(1*100%/3) calc(0*100%/3),calc(2*100%/3) calc(0*100%/3),calc(3*100%/3) calc(0*100%/3)}
}`}
        </style>
      </div>
    </>
  );
};
const ModalAddContact = ({ open, handleClose, setHubspot_id, hubspotemail, date_of_birth, contact_name, national,setValidation }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [dob, setDob] = useState("");
  const [phone, setPhone] = useState("");
  const [nationality, setNationality] = useState("");

  useEffect(() => {
    if (open) {
      setEmail(hubspotemail);
      setName(contact_name);
      setDob(date_of_birth);
      setNationality(national);
    }
  }, [open, hubspotemail, contact_name, date_of_birth, national]);

  const handleSubmit = async () => {
    try {
      if (!email && !name && !phone && !dob && !nationality) {
        toast.error("Please fill all the fields");
        return;
      }
      const response = await API.post("/uaevisa/createContact", {
        email: email,
        firstname: name,
        phone: phone,
        date_of_birth: dob,
        nationality: nationality,
      });
      if (response.status === 200) {
        toast.success("Contact created successfully");
        setHubspot_id(response.data.id);
        setValidation(true);
        handleClose();
      }
    } catch (error) {
      toast.error("Error creating contact");
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={{ ...style, width: 400 }}>
        <h2 id="parent-modal-title" style={{ margin: "10px" }}>
          Create a Contact
        </h2>
        <InputLabel focused>Email</InputLabel>
        <Input
          fullWidth
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <InputLabel focused>Name</InputLabel>
        <Input
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <InputLabel focused>Phone</InputLabel>
        <Input
          fullWidth
          placeholder="9715xxxxxxx"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
        <InputLabel focused>Date of Birth</InputLabel>
        <Input
          type="date"
          fullWidth
          value={dob}
          onChange={(e) => setDob(e.target.value)}
          style={{ marginBottom: "10px" }}
        />
        <Autocomplete
          disablePortal
          options={countryCode}
          sx={{ width: 250 }}
          value={nationality ? { label: nationality } : null}
          onChange={(event, value) =>
            setNationality(value ? value.label : "")
          }
          renderInput={(params) => (
            <TextField {...params} label="Nationality" />
          )}
        />

        <Button sx={{ mt: 2 }} variant="contained" fullWidth color="primary" onClick={handleSubmit}>
          Save
        </Button>
        <Button
          sx={{ mt: 2 }}
          variant="contained"
          fullWidth
          color="secondary"
          onClick={handleClose}
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
};

export default EVisaUpload;
