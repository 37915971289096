import React from "react";
import UserTable1 from "../components/FlightUserTable1";
import { useEffect } from "react";
import axios from "axios";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import API from '../../Visa/API'

export default function FlightLatest() {
  const [data, setData] = React.useState("");
  const fetchData = async () => {
    const result = await API.get(
      `/flights/latest?token=${localStorage.getItem(
        "authToken"
      )}`
    );
    setData(result.data);
  };
  useEffect(() => {
    fetchData();
  }, []);
  const rows = [
    data &&
      data.map((row) => ({
        name: row.passenger_name,
        pnr: row.pnr,
        dep_code: row.departure_code,
        arr_code: row.arrival_code,
        dep_date: row.departure_date,
        arr_date: row.arrival_date,
      })),
  ];
  const columns = [
    { id: "name", label: "Passenger Name", minWidth: 170 },
    { id: "pnr", label: "PNR", minWidth: 100 },
    {
      id: "departure_code",
      label: "Departure Code",
      minWidth: 170,
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "arrival_code",
      label: "Arrival Code",
      minWidth: 170,
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "departure_date",
      label: "Departure Date",
      minWidth: 170,
      format: (value) => value.toFixed(2),
      },
      {
        id: "arrival_date",
        label: "Arrival Date",
        minWidth: 170,
        format: (value) => value.toFixed(2),
    }
  ];
  console.log(rows);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ minHeight:"50vh" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows[0].length > 0 &&
              rows[0]
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                          <TableCell>{row.name}</TableCell>
                          <TableCell>{row.pnr}</TableCell>
                          <TableCell>{row.dep_code}</TableCell>
                          <TableCell>{row.arr_code}</TableCell>
                          <TableCell>{row.dep_date}</TableCell>
                          <TableCell>{row.arr_date}</TableCell>
                          
                    </TableRow>
                  );
                })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
