import { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PhoneIcon from "@mui/icons-material/Phone";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";
import API from "../API";
import CountrySelect from "../components/CountrySelect";


export default function EditForm() {

  dayjs.extend(dayjsPluginUTC);
  const location = useLocation();
  const currentPage = location.state?.currentPage || 1;
  const search = location.state?.search || "";
  const search2 = location.state?.search2 || "";
  const search4 = location.state?.search4 || "";
  const searchDate = location.state?.searchDate || "";
  const search8 = location.state?.search8 || "";
  const search7 = location.state?.search7 || "";
  const search9 = location.state?.search9 || "";
  const search10 = location.state?.search10 || "";
  const searchCountry = location.state?.searchCountry || "";
  
  const navigate = useNavigate();
  
  const { id } = useParams();
  
  const [data, setData] = useState({
    "visa_country":"",
    "reference_number":"",
    "group_URN_reference_number":"",
    "name":"",
    "passport_number":"",
    "visa_category":"",
    "application_center_details":"",
    "appointment_file_url":"",
    "onedrive_url":"",
    "emirate":"",
    "closing_staff":"",
    "service_staff":"",    
  });
  const [initGroupURN, setInitGroupURN] = useState("");
  const [staff, setStaff] = useState([]);
  const [samestaff, setSameStaff] = useState(true);
  const [copyOneDrive, setCopyOneDrive] = useState(true);
  const [appointment_date, setAppointmentDate] = useState(
    dayjs(data.visa_appointment_date)
  );
  
  async function fetchData() {
    try{
      const response = await API.get(`/ivisa/edit/${id}`);
      setData(response.data);
      setInitGroupURN(response.data.group_URN_reference_number);
      if (response.data.closing_staff === response.data.service_staff) {
        setSameStaff(true);
      } else {
        setSameStaff(false);
      }
    } catch (err) {
      if(err.response.data && err.response.status===401){
        navigate("/login")
      }
    }
  }

  async function fetchStaff() {
    const response = await API.get(`/flights/staffnames?type=ivisa`);
    setStaff(response.data.staff);
  }
  
  useEffect(() => {
    fetchData();
    fetchStaff();
  }, []);
  
  useEffect(() => {
    if (data) {
      setAppointmentDate(dayjs(data.visa_appointment_date) || "");
    }
  }, [data]);

  const validateGroupURN  = async () => {
    try {
      if (!data.group_URN_reference_number || data.group_URN_reference_number === initGroupURN) {
        toast('Group URN is empty or unchanged');
        return;
      }
      const response = await API.get(`/ivisa/validategroupurn/${data.group_URN_reference_number}`, {
        validateStatus: function (status) {
          return status < 500; // Reject only if the status code is greater than or equal to 500
        }
      });
  
      if (response.status === 200) {
        toast.success(response.data.message || 'Group URN is valid');
      } else if (response.status === 409) {
        toast.error(response.data.message || 'Group URN already exists');
        setData({...data, group_URN_reference_number: ''});
      } else {
        toast.error('Unexpected response from the server');
        setData({...data, group_URN_reference_number: ''});
      }
    } catch (error) {
      console.error('Error validating group URN:', error);
      toast.error('An error occurred while validating the Group URN.');
    }
  };  
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let updateData = {
        name: data.name,
        reference_number: data.reference_number,
        group_URN_reference_number: data.group_URN_reference_number,
        visa_country: data.visa_country,
        passport_number: data.passport_number,
        visa_appointment_date: appointment_date.utc().format(),
        visa_category: data.visa_category,
        application_center_details: data.application_center_details,
        appointment_file_url: data.appointment_file_url,
        onedrive: copyOneDrive,
        onedrive_url: data.onedrive_url,
        emirate: data.emirate,
        closing_staff: data.closing_staff,
        service_staff: data.service_staff,      
      };
      const response = await API.patch(
        `/ivisa/edit/visa/${id}`,
        updateData
      );
      setData(response.data.updatedRecord);
      toast.success(response.data.message);
    } catch (err) {
      console.error(err);
    }
  };
  
  return (
    <>
      <Button
        variant="contained"
        sx={{ margin: "5px" }}
        onClick={() =>
          navigate("/visa/applications", {
            state: {  
              currentPage: currentPage,
              search: search,
              search2: search2,
              search4: search4,
              searchDate: searchDate,
              search8: search8,
              search7: search7,
              search9: search9,
              search10: search10,
              searchCountry: searchCountry,
            },
          })
        }
      >
        <ArrowBackIcon /> &nbsp;Go Back
      </Button>

      <Button
        variant="contained"
        sx={{ margin: "5px" }}
        onClick={() =>
          navigate(`/visa/edit/contact/${data._id}`, {
            state: {  currentPage: currentPage,
                      search: search,
                      search2: search2,
                      search4: search4,
                      searchDate: searchDate,
                      search8: search8,
                      search7: search7,
                      search9: search9,
                      search10: search10,
                      searchCountry: searchCountry,
            },
          })
        }
      >
        <PhoneIcon /> &nbsp;Go To Contact Edit
      </Button>
      <Box
        component="section"
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          height: "100%", // Set the height to 100% of the viewport height
          p: 2,
          border: "1px dashed grey",
          width: "500px",
          margin: "auto",
          marginBottom: "100px",
          
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h6">Applicant Visa Details</Typography>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: 2 }}>
            <CountrySelect 
              countrySelectVal={data.visa_country} 
              setCountrySelectVal={(newCountry) => setData({...data, visa_country: newCountry})} 
              fieldLabel={"Application Country"} 
              isSchengen={false} 
            />
          </Grid>
          <Grid item xs={12} sx={{ marginTop: 2 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                sx={{ width: '300px' }}
                label="Visa Appointment Date"
                value={appointment_date}
                onChange={(new_date) => setAppointmentDate(new_date)}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6} sm={6} sx={{ marginTop: 2 }}>
            <label for="reference_number">Reference Number</label>
            <input
              required
              id="reference_number"
              placeholder="Reference Number"
              value={data.reference_number}
              onChange={(e) => setData({...data, reference_number: e.target.value})}
            />
          </Grid>
          <Grid item xs={6} sm={6} sx={{ marginTop: 2 }}>
            <label for="group_urn_reference_number">Group URN Reference Number</label>
            <input
              required
              id="group_urn_reference_number"
              placeholder="Group URN Reference Number"
              value={data.group_URN_reference_number}
              onChange={(e) => setData({...data, group_URN_reference_number: e.target.value.trim()})}
              inputProps={{ pattern: "[A-Za-z0-9-_/\ ]+" }}
              onBlur={validateGroupURN}
            />
          </Grid>
          <Grid item xs={6} sm={6} sx={{ marginTop: 2 }}>
            <label for="applicant_name">Applicant Name</label>
            <input
              id="applicant_name"
              placeholder="Applicant Name"
              value={data.name}
              onChange={(e) => setData({...data, name: e.target.value})}
            />
          </Grid>
          <Grid item xs={6} sm={6} sx={{ marginTop: 2 }}>
            <label for="passport_number">Passport Number</label>
            <input
              id="passport_number"
              placeholder="Passport Number"
              value={data.passport_number}
              onChange={(e) => setData({...data, passport_number: e.target.value})}
            />
          </Grid>
          <Grid item xs={6} sx={{ marginTop: 2 }}>
            <label for="appointment_file_url">Appointment File URL</label>
            <input
              id="appointment_file_url"
              placeholder="Appointment File URL"
              value={data.appointment_file_url}
              onChange={(e) => setData({...data, appointment_file_url: e.target.value})}
            />
          </Grid>
          <Grid item xs={6} sx={{ marginTop: 2 }}>
            <label for="onedrive_url">OneDrive URL</label>
            <input
              id="onedrive_url"
              placeholder="OneDrive URL"
              value={data.onedrive_url}
              onChange={(e) => setData({...data, onedrive_url: e.target.value})}
            />
          </Grid>
          <Grid item xs={6} sx={{ marginTop: 2 }}>
            <label for="application_center_details">Application Center Address</label>
            <input
              id="application_center_details"
              placeholder="Application Center Details"
              value={data.application_center_details}
              onChange={(e) => setData({...data, application_center_details: e.target.value})}
            />
          </Grid>            
          <Grid item xs={6} sx={{ marginTop: 2 }}>
            <label for="visa_category">Visa Category</label>
            <select
              className="form-select"
              id=" visa_category"
              value={data.visa_category}
              onChange={(e) => setData({...data,  visa_category: e.target.value})
              }
            >
              <option value="E-Visa">E-Visa</option>
              <option value="Stamp">Stamp</option>
            </select> 
          </Grid>          
          <Grid item xs={6} sx={{ marginTop: 2 }}>
            <label for="emirate">Emirate</label>
            <select
              className="form-select"
              id="emirate"
              value={data.emirate}
              onChange={(e) => setData({...data, emirate: e.target.value})
              }
            >
              <option value="Dubai">Dubai </option>
              <option value="Abu Dhabi">Abu Dhabi</option>
              <option value="Sharjah">Sharjah</option>
              <option value="VAYD">VAYD</option>
            </select>
          </Grid>           
          <Grid item xs={6} sm={6} sx={{ marginTop: 2 }}>
            <label for="closing_staff">Sales Staff</label>
            <select
              className="form-select"
              id="closing_staff"
              value={data.closing_staff}
              onChange={(e) => 
                samestaff ? (
                  setData({...data, closing_staff: e.target.value, service_staff: e.target.value})
                ) : (
                  setData({...data, closing_staff: e.target.value})
                )
              }
            >
              <option selected value={data.closing_staff}>
                {data.closing_staff}
              </option>
              {staff.map((staff) => (
                <option value={staff.staff_firstname +' '+ staff.staff_lastname}>{staff.staff_firstname +' '+ staff.staff_lastname}</option>
              ))}
            </select>
          </Grid>
          <Grid item xs={6} sm={6} sx={{ marginTop: 2 }}>
            <label for="service_staff">Service Staff</label>
            <select
              {...(samestaff ? {disabled: true} : {}) }
              className="form-select"
              id="service_staff"
              value={data.service_staff}
              onChange={(e) => 
                samestaff ? (
                  setData({...data, closing_staff: e.target.value, service_staff: e.target.value})
                ) : (
                  setData({...data, service_staff: e.target.value})
                )
              }
            >
              <option selected value={data.service_staff}>
                {data.service_staff}
              </option>
              {staff.map((staff) => (
                <option value={staff.staff_firstname +' '+ staff.staff_lastname}>{staff.staff_firstname +' '+ staff.staff_lastname}</option>
              ))}
            </select>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  name="copyPNR_NAME"
                  value={samestaff}
                  checked = {samestaff}
                   onChange={(e) => {
                    setSameStaff(e.target.checked);
                    
                   }}                
                    />
              }
              label="Sales Staff is the same as the Service Staff"
              
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  name="copyPNR_NAME"
                  value={copyOneDrive}
                  checked={copyOneDrive}
                  onChange={(e) => {
                    setCopyOneDrive(e.target.checked);
                  }}
                />
              }
              label="Copy the One Drive Folder Link to all Applicant with the same Group URN"
            />
          </Grid>
        </Grid>
        <style>
          {`
          input{
            border: 1px solid #393f81;
            border-radius: 5px;
            padding: 5px;
          }
          input::placeholder{
            opacity: 0.5;
            }
          `}
        </style>
        <div className="fixed-bottom">
          <button
            type="submit"
            className={`btn btn-success col-2`}
            onClick={handleSubmit}
          >
            Save
          </button>
        </div>
      </Box>
    </>
  );
}