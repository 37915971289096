import React, { useState, useEffect } from 'react';
import { Paper, Table, TableContainer, TableHead, TextField, TableRow, TableBody, TableCell, Tooltip, TablePagination, Box, IconButton, InputAdornment, Typography } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import API from "../../Visa/API"; // Replace with your actual API import

const cleanPhoneNumber = (phoneNumber) => {
  if (typeof phoneNumber !== 'string') {
    return '';
  }
  return phoneNumber.replace(/\D/g, '');
};

const formattedPhoneNumber = (code, number) => {
  return `+${cleanPhoneNumber(code)}${cleanPhoneNumber(number)}`;
};

const CompanyProfileDirectory = () => {
  const [profiles, setProfiles] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const fetchCompanyProfiles = async () => {
    try {
      const response = await API.get('/DMC/companyProfiles'); // Replace with your actual endpoint
      setProfiles(response.data);
      console.log(response.data); // Log the fetched data
    } catch (error) {
      console.error('Error fetching company profiles:', error);
    }
  };

  useEffect(() => {
    fetchCompanyProfiles();
  }, []);

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
    setPage(0);
  };

  const handleSearchInputClear = () => {
    setSearchInput('');
    setPage(0);
  };

  const filteredProfiles = profiles.filter((profile) => {
    const searchRegex = new RegExp(searchInput, 'i');

    const concatDetails = (detailsArray) => {
      return detailsArray.map(details => 
        `${details.country} ${formattedPhoneNumber(details.code1, details.phoneNumber1)} ${formattedPhoneNumber(details.code2, details.phoneNumber2)}`
      ).join(' ');
    };

    const concatQuotes = (quotesArray) => {
      return quotesArray.map(quote => 
        `${quote.country} ${formattedPhoneNumber(quote.code, quote.phoneNumber)} ${quote.email}`
      ).join(' ');
    };

    const rowText = `
      ${profile.basicCompanyInfo.data.companyName}
      ${profile.contactDetails.data.officeSetupDetailsDuringOfficeHours ? concatDetails(profile.contactDetails.data.officeSetupDetailsDuringOfficeHours) : ''}
      ${profile.contactDetails.data.officeSetupDetailsAfterOfficeHours ? concatDetails(profile.contactDetails.data.officeSetupDetailsAfterOfficeHours) : ''}
      ${profile.contactDetails.data.contactDetailsQuotes ? concatQuotes(profile.contactDetails.data.contactDetailsQuotes) : ''}
      ${profile.contactDetails.data.escalationPointsOfContact ? concatQuotes(profile.contactDetails.data.escalationPointsOfContact) : ''}
    `;

    return searchRegex.test(rowText);
  });

  return (
    <Paper elevation={3} sx={{ margin: '20px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '20px' }}>
        <TextField
          fullWidth
          label="Search Contacts"
          variant="outlined"
          value={searchInput}
          onChange={handleSearchInputChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="clear text"
                  onClick={handleSearchInputClear}
                  edge="end"
                >
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Company Name</TableCell>
              <TableCell>During Office Hours</TableCell>
              <TableCell>After Office Hours</TableCell>
              <TableCell>Contact Details Quotes</TableCell>
              <TableCell>Escalation Points of Contact</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredProfiles
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((profile) => (
                <TableRow key={profile._id}>
                  <TableCell>
                    <Typography style={{ fontSize: 22 }}>
                      <strong>{profile.basicCompanyInfo.data.companyName}</strong>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {profile.contactDetails.data.officeSetupDetailsDuringOfficeHours.map((details, index) => {
                      const formattedPhone1 = `+${details.code1.replace(/[^\d]/g, '')}${details.phoneNumber1}`;
                      const formattedPhone2 = `+${details.code2.replace(/[^\d]/g, '')}${details.phoneNumber2}`;
                      return (
                        <div key={index} style={{ marginBottom: '10px' }}>
                          <div><strong>{details.country}</strong></div>
                          <div>
                            <a href={`tel:${formattedPhone1}`} style={{ color: 'blue', textDecoration: 'underline' }}>
                              <strong>{formattedPhone1}</strong>
                            </a>
                          </div>
                          <div>
                            <a href={`https://wa.me/${formattedPhone2}`} target="_blank" rel="noopener noreferrer">
                              <img src={`${process.env.PUBLIC_URL}/whatsapp.svg`} alt="WhatsApp" style={{ width: '20px', height: '20px', cursor: 'pointer' }} />
                              <strong> {formattedPhone2}</strong>
                            </a>
                          </div>
                        </div>
                      );
                    })}
                  </TableCell>
                  <TableCell>
                    {profile.contactDetails.data.officeSetupDetailsAfterOfficeHours.map((details, index) => {
                      const formattedPhone1 = `+${details.code1.replace(/[^\d]/g, '')}${details.phoneNumber1}`;
                      const formattedPhone2 = `+${details.code2.replace(/[^\d]/g, '')}${details.phoneNumber2}`;
                      return (
                        <div key={index} style={{ marginBottom: '10px' }}>
                          <div><strong>{details.country}</strong></div>
                          <div>
                            <a href={`tel:${formattedPhone1}`} style={{ color: 'blue', textDecoration: 'underline' }}>
                              <strong>{formattedPhone1}</strong>
                            </a>
                          </div>
                          <div>
                            <a href={`https://wa.me/${formattedPhone2}`} target="_blank" rel="noopener noreferrer">
                              <img src={`${process.env.PUBLIC_URL}/whatsapp.svg`} alt="WhatsApp" style={{ width: '20px', height: '20px', cursor: 'pointer' }} />
                              <strong> {formattedPhone2}</strong>
                            </a>
                          </div>
                        </div>
                      );
                    })}
                  </TableCell>
                  <TableCell>
                    {profile.contactDetails.data.contactDetailsQuotes.map((contact, index) => {
                      const formattedPhone = `+${contact.code.replace(/[^\d]/g, '')}${contact.phoneNumber}`;
                      return (
                        <div key={index} style={{ marginBottom: '10px' }}>
                        <div><strong>{contact.country ? contact.country : profile.companyProfileStage.data.countriesOwnOffices.map(office => office.label).join(', ')}</strong></div>

                          <div>{contact.name}</div>
                          <div>
                            <a href={`tel:${formattedPhone}`} style={{ color: 'blue', textDecoration: 'underline' }}>
                              <strong>{formattedPhone}</strong>
                            </a>
                          </div>
                          <div>
                            <a href={`mailto:${contact.email}`} style={{ color: 'blue', textDecoration: 'underline' }}>
                              {contact.email}
                            </a>
                          </div>
                        </div>
                      );
                    })}
                  </TableCell>
                  <TableCell>
                    {profile.contactDetails.data.escalationPointsOfContact.map((contact, index) => {
                      const formattedPhone = `+${contact.code.replace(/[^\d]/g, '')}${contact.phoneNumber}`;
                      return (
                        <div key={index} style={{ marginBottom: '10px' }}>
                          <div><strong>{contact.country ? contact.country : profile.companyProfileStage.data.countriesOwnOffices.map(office => office.label).join(', ')}</strong></div>

                          <div>{contact.name}</div>
                          <div>
                            <a href={`tel:${formattedPhone}`} style={{ color: 'blue', textDecoration: 'underline' }}>
                              <strong>{formattedPhone}</strong>
                            </a>
                          </div>
                          <div>
                            <a href={`mailto:${contact.email}`} style={{ color: 'blue', textDecoration: 'underline' }}>
                              {contact.email}
                            </a>
                          </div>
                        </div>
                      );
                    })}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10]}
        component="div"
        count={filteredProfiles.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(event, newPage) => setPage(newPage)}
        onRowsPerPageChange={(event) => {
          setRowsPerPage(parseInt(event.target.value, 10));
          setPage(0);
        }}
      />
    </Paper>
  );
};

export default CompanyProfileDirectory;
