import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function NOCCategorySelect({ NOCCategoryVal, setNOCCategoryVal }) {

  const handleNOCCategoryChange = (event, value) => {
    setNOCCategoryVal(value);
  }

  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={NOCCategoryOptions}
      sx={{ width: 300}}
      renderInput={(params) => <TextField {...params} label="NOC Categories" />}
      value={NOCCategoryVal}
      onChange={handleNOCCategoryChange} 
      getOptionLabel={(NOCCategoryOptions) => NOCCategoryOptions}
    />
  );
}

const NOCCategoryOptions = ["Investor", "Partner", "Employee (Inc. GOLDEN VISA)", "Family Sponsored (Inc. GOLDEN VISA Dependent)"];