import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function TitleSelect({ titleVal, setTitleVal, setApplicantGender, fieldLabel }) {

  const handleTitleChange = (event, value) => {
    setTitleVal(value?value.label:'');
    setApplicantGender(value?value.gender:'')
  }

  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={titleOptions}
      sx={{ width: 140}}
      renderInput={(params) => <TextField {...params} label={fieldLabel} />}
      value={titleOptions.find(title => title.label === titleVal) || null}
      onChange={handleTitleChange} 
      getOptionLabel={(titleOptions) => titleOptions.label}
    />
  );
}

const titleOptions = [
  {label:"Mr", gender:'male'},
  {label:"Master", gender:'male'},
  {label:"Ms", gender:'female'},
  {label:"Mrs", gender:'female'},
  {label:"Mr/Ms", gender:'unknown'},
];