import React from 'react'
import { useState } from 'react'
import toast from 'react-hot-toast'
import API from './Visa/API'
import { useNavigate } from 'react-router-dom'
const Login = () => {
    const navigate = useNavigate()
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    
    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
          // Wait for the API.post to resolve and then proceed
          const response = await API.post('/auth/login', {
              username: email,
              password: password,
          });
  
          // Use the response with toast.promise
          toast.promise(
              Promise.resolve(response), // wrap the response in a resolved promise
              {
                  loading: 'Logging in...',
                  success: (data) => {
                      return 'Login successful!';
                  },
                  error: (err) => {
                      return `Login failed! ${err.message}`;
                  },
              }
          );
  
          // Check the status after the API call has completed
          if (response.status === 200) {
              navigate('/');
          }
  
      } catch (error) {
          console.error(error);
  
          // Handle API call errors
          toast.error(`Error: ${error.message}`);
      }
  }
  
return (
    <>
        <section style={{ height: '100vh', backgroundColor: '#f8f9fa' }}>
            <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '2rem' }}>
                <div style={{ textAlign: 'center' }}>
                    <form onSubmit={handleSubmit} style={{ maxWidth: '400px', margin: 'auto' }}>
                        <img src="http://marcopolo-holidays.com/wp-content/uploads/2021/08/Marcopolo-Blue-transparent-logo-1-2.png"
                            style={{ marginBottom: '1rem', maxWidth: '100%', height: 'auto' }} alt="Logo"/>
                        <div style={{ marginBottom: '1rem' }}>
                            <label for="form1Example13" style={{ marginTop: '0.5rem', fontSize: '1rem', color: '#6c757d' }}>Email address</label>
                            <input type="email" id="form1Example13" style={{ width: '100%', padding: '0.75rem', fontSize: '1rem', border: '1px solid #ced4da', borderRadius: '0.25rem' }} value={email} onChange={(e) => setEmail(e.target.value)} />
                        </div>

                        <div style={{ marginBottom: '1rem' }}>
                            <label for="form1Example23" style={{ marginTop: '0.5rem', fontSize: '1rem', color: '#6c757d' }}>Password</label>
                            <input type="password" id="form1Example23" style={{ width: '100%', padding: '0.75rem', fontSize: '1rem', border: '1px solid #ced4da', borderRadius: '0.25rem' }} value={password} onChange={(e) => setPassword(e.target.value)} />
                        </div>

{/*                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
                            <a href="#" disabled style={{ color: '#0d6efd', textDecoration: 'none' }}>Forgot password?</a>
                        </div>*/}

                        <button type="submit" style={{ width: '100%', padding: '0.75rem', fontSize: '1rem', color: 'white', backgroundColor: '#0d6efd', border: 'none', borderRadius: '0.25rem' }}>Sign in</button>
                    </form>
                </div>
            </div>
        </section>
    </>
)

}

export default Login