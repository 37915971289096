import React from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
const DMCNavbar = () => {
  return (
    <Navbar bg="dark" expand="lg" variant="dark">
      <Container>
        <Navbar.Brand href="/">
          <img
            src="	https://marcopolo-holidays.com/wp-content/uploads/2021/08/Marcopolo-Grey-transparent-logo-200-px.png"
            width="120px"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auhref">
          <a href="/DMC/main" style={{ margin: "10px" }}>
              Home
            </a> 
          <a href="/DMC/invite" style={{ margin: "10px" }}>
              Invite a DMC
            </a> 
            <a href="/DMC/emergency-contacts" style={{ margin: "10px" }}>
              Address Book
            </a> 
            <a href="/DMC/review-forms" style={{ margin: "10px" }}>
              Review DMC Forms
            </a> 
            <a href="/staff" style={{ margin: "10px" }}>
              Staff
            </a>
            <a href = "/DMC/admin-settings" style = {{margin:"10px"}}>Admin Settings</a>
            <a href="/logout" style={{margin:"10px"}}>Logout</a>
            
            {/* ... other as */}
          </Nav>
        </Navbar.Collapse>
      </Container>
      <style>
        {
          `
          .me-auhref a {
            color: white;
            text-decoration: none;
          }
          .me-auhref a:hover {
            color: grey;
          }`
        }
      </style>
    </Navbar>
  );
};

export default DMCNavbar;
