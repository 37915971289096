import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function UAEEmirateSelect({ UAEEmirateVal, setUAEEmirateVal }) {

  const handleUAEEmirateChange = (event, value) => {
    setUAEEmirateVal(value);
  }

  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={UAEEmirates}
      sx={{ width: 300}}
      renderInput={(params) => <TextField {...params} label="Emirate *" />}
      value={UAEEmirateVal}
      onChange={handleUAEEmirateChange} 
      getOptionLabel={(UAEEmirates) => UAEEmirates}
    />
  );
}

const UAEEmirates = ["Abu Dhabi", "Dubai"];