import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import toast from "react-hot-toast";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PhoneIcon from "@mui/icons-material/Phone";
import { useParams, useNavigate } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import API from "../../Visa/API";
import { useLocation } from 'react-router-dom';

export default function FlightEditForm() {
  const location = useLocation();
  const currentPage = location.state?.currentPage || 1;
  const search = location.state?.search || "";
  const search1 = location.state?.search1 || "";
  const search8 = location.state?.search8 || "";
  const search4 = location.state?.search4 || "";
  const search5 = location.state?.search5 || "";
  const search6 = location.state?.search6 || "";
  const search7 = location.state?.search7 || "";  
  const searchTicketType = location.state?.searchTicketType || "";
  const searchDate = location.state?.searchDate || "";
  dayjs.extend(dayjsPluginUTC);
  const { id } = useParams();
  // console.log(id);
  const [data, setData] = useState([]);
  const [staff, setStaff] = useState([]);
  async function fetchData() {
      const response = await API.get(
        `/flights/edit/${id}?token=${localStorage.getItem(
          "authToken"
        )}`
      );   
    setData(response.data);
  }
  async function fetchStaff() {
    const response = await API.get(`/flights/staffnames?type=flights`);
    setStaff(response.data.staff);
  }
  useEffect(() => {
    fetchData();
    fetchStaff();
  }, []);
  useEffect(() => {
    if (data) {
      setPassengerName(data.passenger_name || "");
      setTicketType(data.type || "");
      setDepcode(data.departure_code || "");
      setArrcode(data.arrival_code || "");
      setPnr(data.pnr || "");
      setAirlineName(data.airline_name || "");
      setDepartureDate(dayjs(data.departure_date) || "");
      setArrivalDate(dayjs(data.arrival_date) || "");
      setTicketingStaff(data.ticketing_staff || "");
      setServiceStaff(data.service_staff || "");
      setAirlineReference(data.sabre_reference || "");
      setTicketNumber(data.ticket_number || "");
    }
  }, [data]);
  //console.log(data);
  const [passenger_name, setPassengerName] = useState(data.passenger_name);
  const navigate = useNavigate();
  const [tickettype, setTicketType] = useState(data.type);
  const [samestaff, setSameStaff] = useState(true);
  const [depcode, setDepcode] = useState(data.departure_code);
  const [arrcode, setArrcode] = useState(data.arrival_code);
  const [pnr, setPnr] = useState(data.pnr);
  const [airline_name, setAirlineName] = useState(data.airline_name);
  const [ticketing_staff, setTicketingStaff] = useState(data.ticketing_staff);
  const [service_staff, setServiceStaff] = useState(data.service_staff);
  const [airlineReference, setAirlineReference] = useState(data.sabre_reference)
  const [departure_date, setDepartureDate] = useState(
    dayjs(data.departure_date)
  );
  const [ticketNumber, setTicketNumber] = useState(data.ticket_number)
  const [arrival_date, setArrivalDate] = useState(dayjs(data.arrival_date));
  // console.log(passenger_name, tickettype, depcode, arrcode, pnr, airline_name);
  const convertToDubaiTime = async (dateTime, fromTimeZone) => {
    const payload = {
      fromTimeZone,
      dateTime,
      toTimeZone: "Asia/Dubai",
      dstAmbiguity: "",
    };

    try {
      const response = await API.post(
        "/flights/convertTimeZone",
        payload
      );
      return response.data.conversionResult?.dateTime;
    } catch (error) {
      console.error("Error converting time zone:", error);
      return null;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      let departure_time_dxb = data.departure_time_dxb;
      let arrival_time_dxb = data.arrival_time_dxb;
      const excludeCodes = ["DXB", "DWC", "AUH", "SHJ", "RKT"];
      if (!excludeCodes.includes(depcode)) {
        const convertedDeparture = await convertToDubaiTime(
          departure_date,
          data.departure_code
        );
        // console.log(convertedDeparture);
        departure_time_dxb = convertedDeparture
          ? dayjs(convertedDeparture).utc().format()
          : null;
      }

      if (!excludeCodes.includes(arrcode)) {
        const convertedArrival = await convertToDubaiTime(
          arrival_date,
          data.arrival_code
        );
        // console.log(convertedArrival);
        arrival_time_dxb = convertedArrival
          ? dayjs(convertedArrival).utc().format()
          : null;
      }
      let updateData = {
        passenger_name: passenger_name,
        type: tickettype,
        departure_code: depcode,
        arrival_code: arrcode,
        pnr: pnr,
        airline_name: airline_name,
        departure_date: departure_date.utc().format(),
        arrival_date: arrival_date.utc().format(),
        departure_time_dxb: departure_time_dxb,
        arrival_time_dxb: arrival_time_dxb,
        ticketing_staff: ticketing_staff,
        service_staff: service_staff,
      };

      await API.patch(
        `/flights/edit/passenger/${id}?token=${localStorage.getItem(
          "authToken"
        )}`,
        updateData
      );
      toast.success("Changes saved successfully");
    } catch (err) {
      toast.error("Error saving changes");
      console.log(err);
    }
  };
  return (
    <>
      <Button
        variant="contained"
        sx={{ margin: "5px" }}
        onClick={() =>
          navigate("/flights/tickets", {
            state: {  
              currentPage: currentPage,
              search: search,
              search1: search1,
              search8: search8,
              search4: search4,
              search5: search5,
              search6: search6,
              search7: search7,
              searchTicketType: searchTicketType,
              searchDate: searchDate
            },
          })
        }
      >
        <ArrowBackIcon /> &nbsp;Go Back
      </Button>
      <Button
        variant="contained"
        sx={{ margin: "5px" }}
        onClick={() =>
          navigate(`/flights/edit/contact/${data._id}`, {
            state: {  
              currentPage: currentPage,
              search: search,
              search1: search1,
              search8: search8,
              search4: search4,
              search5: search5,
              search6: search6,
              search7: search7,
              searchTicketType: searchTicketType,
              searchDate: searchDate
          }})
        }
      >
        <PhoneIcon /> &nbsp;Go To Contact Edit
      </Button>
      <Box
        component="section"
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          height: "80vh", // Set the height to 100% of the viewport height
          p: 2,
          border: "1px dashed grey",
          width: "500px",
          margin: "auto",
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h6">Passenger Ticket Details</Typography>
          </Grid>
          <Grid item xs={6} sm={6}>
            <label for="passenger_name">Passenger Name</label>
            <input
              id="passenger_name"
              placeholder="Passenger Name"
              value={passenger_name}
              onChange={(e) => {
                setPassengerName(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <label for="ticket_type">Ticket Type</label>
            <input
              id="ticket_type"
              placeholder="Type"
              value={tickettype}
              onChange={(e) => {
                setTicketType(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <label for="depcode">Departure Code</label>
            <input
              id="depcode"
              placeholder="Departure Code"
              value={depcode}
              onChange={(e) => {
                setDepcode(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <label for="arrcode">Arrival Code</label>
            <input
              id="arrcode"
              placeholder="Arrival Code"
              value={arrcode}
              onChange={(e) => {
                setArrcode(e.target.value);
              }}
            />
          </Grid>
          {(tickettype === 'SABRE') &&(
            <>
            <Grid item xs={6}>
              <label for="pnr">Airline Reference</label>
              <input
                id="pnr"
                placeholder="Airline Reference"
                value={pnr}
                onChange={(e) => {
                  setPnr(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <label for="airline_reference">Sabre Reference</label>
              <input
                id="airline_reference"
                placeholder="Sabre Reference"
                value={airlineReference}
                onChange={(e) => {
                  setAirlineReference(e.target.value);
                }}
              />
            </Grid>
            </>
          )}
          {(tickettype !== 'SABRE') &&(
            <>
            <Grid item xs={6}>
              <label for="pnr">PNR&nbsp;&nbsp;</label>
              <input
                id="pnr"
                placeholder="PNR"
                value={pnr}
                onChange={(e) => {
                  setPnr(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <label for="airline_reference">Airline Reference</label>
              <input
                id="airline_reference"
                placeholder="Airline Reference"
                value={airlineReference}
                onChange={(e) => {
                  setAirlineReference(e.target.value);
                }}
              />
            </Grid>
            </>
          )}          

          <Grid item xs={6} sm={6}>
            <label for="ticket_number">Ticket Number</label>
            <input
              id="ticket_number"
              placeholder="Ticket Number"
              value={ticketNumber}
              onChange={(e) => {
                setTicketNumber(e.target.value);
              }}
            />
          </Grid>          
          <Grid item xs={6} sm={6}>
            <label for="airline_name">Airline Name</label>
            <input
              id="airline_name"
              placeholder="Airline"
              value={airline_name}
              onChange={(e) => {
                setAirlineName(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6} sx={{ marginTop: 2 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                label="From Departure Date"
                value={departure_date}
                onChange={(new_date) => setDepartureDate(new_date)}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6} sm={6} sx={{ marginTop: 2 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                label="To Departure Date"
                value={arrival_date}
                onChange={(new_date) => setArrivalDate(new_date)}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6} sm={6} sx={{ marginTop: 2 }}>
            <label for="ticketing_staff">Ticketing Staff</label>
            <select
              className="form-select"
              id="ticketing_staff"
              value={ticketing_staff}
              onChange={(e) => 
                samestaff ? (
                  setTicketingStaff(e.target.value),
                  setServiceStaff(e.target.value)
                ) : (
                  setTicketingStaff(e.target.value)
                )
          
              }
            >
              <option selected value={data.ticketing_staff}>
                {data.ticketing_staff}
              </option>
              {staff.map((staff) => (
                <option value={staff.staff_firstname +' '+staff.staff_lastname}>{staff.staff_firstname +' '+staff.staff_lastname}</option>
              ))}
            </select>
          </Grid>
          <Grid item xs={6} sm={6} sx={{ marginTop: 2 }}>
            <label for="service_staff">Service Staff</label>
            <select
              className="form-select"
              id="service_staff"
              value={service_staff}
              onChange={(e) => setServiceStaff(e.target.value)}
            >
              <option selected value={service_staff}>
                {service_staff}
              </option>
              {staff.map((staff) => (
                <option value={staff.staff_firstname +' '+staff.staff_lastname}>{staff.staff_firstname +' '+staff.staff_lastname}</option>
              ))}
            </select>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  name="copyPNR_NAME"
                  value={samestaff}
                  checked = {samestaff}
                   onChange={(e) => {
                    setSameStaff(e.target.checked);
                    
                   }}                
                    />
              }
              label="Ticketing Staff is the same as the Service Staff"
              
            />
          </Grid>
        </Grid>
        <style>
          {`
          input{
            border: 1px solid #393f81;
            border-radius: 5px;
            padding: 5px;
          }
          input::placeholder{
            opacity: 0.5;
            }
          `}
        </style>
        <div className="fixed-bottom">
          <button
            type="submit"
            className={`btn btn-success col-2`}
            onClick={handleSubmit}
          >
            Save
          </button>
        </div>
      </Box>
    </>
  );
}
