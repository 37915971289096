import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container, Grid, TextField, MenuItem, Card, CardContent, Typography, Box, FormControl, Select, Button, IconButton, InputLabel, OutlinedInput, Chip
} from '@mui/material';
import { Add, Close } from '@mui/icons-material';
import API from "../../Visa/API";
 
function getStringBeforeT(str) {
  if (typeof str !== 'string') {
    return '';
  }
  const indexOfT = str.indexOf('T');
  if (indexOfT !== -1) {
    return str.substring(0, indexOfT);
  }
  return str;
}
 
function formatDate(dateStr) {
  if (!dateStr) return '';
  const date = new Date(dateStr);
  const options = { day: 'numeric', month: 'short', year: 'numeric' };
  return date.toLocaleDateString('en-US', options);
}
 
const HomePage = () => {
  const [data, setData] = useState([]);
  const [filteredData,  setFilteredData] = useState([]);
  const [countries, setCountries] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [offices, setOffices] = useState([]);
  const [selectedOffices, setSelectedOffices] = useState([]);
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [tagSelections, setTagSelections] = useState({});
  const [searchQuery, setSearchQuery] = useState('');
 
  const navigate = useNavigate();
 
  const handleRemoveFilters = () => {
    setSelectedCountries([]);
    setSelectedOffices([]);
    setSelectedTags([]);
    setSearchQuery('');
    setFilteredData(data);
  };
 
  const handleTagChange = async (id, tagIndex, event) => {
    const value = event.target.value;
    setTagSelections(prevState => {
      const currentTags = prevState[id] || [];
      const updatedTags = currentTags.map((tag, index) => index === tagIndex ? value : tag);
      saveTagsToDatabase(id, updatedTags); // Save tags to the database
      return {
        ...prevState,
        [id]: updatedTags,
      };
    });
  };
 
  const handleAddTag = async (id) => {
    setTagSelections(prevState => {
      const newTags = [...(prevState[id] || []), ''];
      saveTagsToDatabase(id, newTags); // Save tags to the database
      return {
        ...prevState,
        [id]: newTags,
      };
    });
  };
 
  const handleRemoveTag = async (id, tagIndex) => {
    setTagSelections(prevState => {
      const newTags = prevState[id].filter((_, index) => index !== tagIndex);
      saveTagsToDatabase(id, newTags); // Save tags to the database
      return {
        ...prevState,
        [id]: newTags,
      };
    });
  };
 
  const saveTagsToDatabase = async (id, tags) => {
    try {
      console.log('tags is ', tags);
      await API.post('/DMC/update-tags', { id, tags });
      console.log('Tags updated successfully');
    } catch (error) {
      console.error('Error updating tags:', error);
    }
  };
 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [dmcResponse, tagsResponse] = await Promise.all([
          API.get('/DMC/dmc-forms-final', { withCredentials: true }),
          API.get('/DMC/tags')
        ]);
        const dmcData = dmcResponse.data;
        const tagsData = tagsResponse.data || [];
 
        setData(dmcResponse.data);
        setFilteredData(dmcResponse.data);
        setTags(tagsData);
        console.log('tagsData is ', tagsData);
        console.log('dmcData is ', dmcData);
        console.log('filteredData is ', filteredData);
        const initialTagSelections = dmcData.reduce((acc, item) => {
          acc[item._id] = item.tags || [];
          return acc;
        }, {});
        setTagSelections(initialTagSelections);
 
        const uniqueCountries = [
          ...new Set(
            dmcData.flatMap(item =>
              item.companyProfileStage.data.countriesProvideTour.map(country => country.label)
            )
          ),
        ];
        setCountries(uniqueCountries);
 
        const uniqueOffices = [
          ...new Set(
            dmcData.flatMap(item =>
              item.companyProfileStage.data.countriesOwnOffices.map(country => country.label)
            )
          ),
        ];
        setOffices(uniqueOffices);

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
 
    fetchData();
  }, []);
 
  useEffect(() => {
    let filtered = data;
 
    if (selectedCountries.length > 0) {
      filtered = filtered.filter(item =>
        selectedCountries.every(country => item.companyProfileStage.data.countriesProvideTour.some(c => c.label === country))
      );
    }
 
    if (selectedOffices.length > 0) {
      filtered = filtered.filter(item =>
        selectedOffices.every(office => item.companyProfileStage.data.countriesOwnOffices.some(c => c.label === office))
      );
    }
 
    if (selectedTags.length > 0) {
      filtered = filtered.filter(item =>
        selectedTags.some(tag => item.tags.includes(tag))
      );
    }
 
    if (searchQuery) {
      const searchRegex = new RegExp(searchQuery, 'i');
      filtered = filtered.filter(item => {
        const basicCompanyInfo = item.basicCompanyInfo.data || {};
        const companyProfile = item.companyProfileStage.data || {};
        const servicesAndOperations = item.servicesAndOperations.data || {};
        const contactDetails = item.contactDetails.data || {};
       
        return (
          (basicCompanyInfo.companyName && basicCompanyInfo.companyName.match(searchRegex)) ||
          (basicCompanyInfo.email && basicCompanyInfo.email.match(searchRegex)) ||
          (basicCompanyInfo.companyRepresentativeName && basicCompanyInfo.companyRepresentativeName.match(searchRegex)) ||
          (basicCompanyInfo.yearOfIncorporation && basicCompanyInfo.yearOfIncorporation.toString().match(searchRegex)) ||
          (basicCompanyInfo.companyOwners && basicCompanyInfo.companyOwners.some(owner => owner.email.match(searchRegex) || (owner.phoneNumber && owner.phoneNumber.toString().match(searchRegex)) || (owner.email && owner.email.includes(`@${searchQuery.split('@')[1]}`)))) ||
          (companyProfile.countriesProvideTour && companyProfile.countriesProvideTour.some(country => country.label.match(searchRegex))) ||
          (companyProfile.countriesOwnOffices && companyProfile.countriesOwnOffices.some(country => country.label.match(searchRegex))) ||
          (servicesAndOperations.tourGuideLanguages && servicesAndOperations.tourGuideLanguages.some(language => language.label.match(searchRegex))) ||
          (servicesAndOperations.acceptsFIT && 'FIT'.match(searchRegex)) ||
          (servicesAndOperations.providesSIC && 'SIC'.match(searchRegex)) ||
          (contactDetails.contactDetailsQuotes && contactDetails.contactDetailsQuotes.some(contact => contact.email.match(searchRegex) || (contact.phoneNumber && contact.phoneNumber.toString().match(searchRegex)) || (contact.email && contact.email.includes(`@${searchQuery.split('@')[1]}`)))) ||
          (contactDetails.escalationPointsOfContact && contactDetails.escalationPointsOfContact.some(contact => contact.email.match(searchRegex) || (contact.phoneNumber && contact.phoneNumber.toString().match(searchRegex)) || (contact.email && contact.email.includes(`@${searchQuery.split('@')[1]}`)))) ||
          (contactDetails.financeOrAccountingDetails && contactDetails.financeOrAccountingDetails.some(contact => contact.email.match(searchRegex) || (contact.phoneNumber && contact.phoneNumber.toString().match(searchRegex)) || (contact.email && contact.email.includes(`@${searchQuery.split('@')[1]}`)))) ||
          (servicesAndOperations.segmentsCaterTo && servicesAndOperations.segmentsCaterTo.some(segment => segment.match(searchRegex))) ||
          (basicCompanyInfo.email && basicCompanyInfo.email.includes(`@${searchQuery.split('@')[1]}`))
        );
       
      });
    }
 
    setFilteredData(filtered);
  }, [selectedCountries, selectedOffices, selectedTags, searchQuery, data]);
 
  const handleCardClick = (id) => {
    navigate(`/DMC/main-forms/${id}`);
  };
 
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 200,
      },
    },
  };
 
  return (
    <Container>
      <Typography variant="h5" gutterBottom align="center" style={{ fontSize: '40px', marginTop: '10px' }}>
        DMC FORMS
      </Typography>
 
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <FormControl fullWidth>
            <InputLabel>Select Destinations</InputLabel>
            <Select
              multiple
              value={selectedCountries}
              onChange={(e) => setSelectedCountries(e.target.value)}
              input={<OutlinedInput label="Select Destinations" />}
              renderValue={(selected) => (
                <Box display="flex" flexWrap="wrap" gap={0.5}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} style={{ maxWidth: '100px' }} />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {countries.map((country) => (
                <MenuItem key={country} value={country}>
                  {country}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth>
            <InputLabel>Select Offices</InputLabel>
            <Select
              multiple
              value={selectedOffices}
              onChange={(e) => setSelectedOffices(e.target.value)}
              input={<OutlinedInput label="Select Offices" />}
              renderValue={(selected) => (
                <Box display="flex" flexWrap="wrap" gap={0.5}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} style={{ maxWidth: '100px' }} />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {offices.map((office) => (
                <MenuItem key={office} value={office}>
                  {office}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth>
            <InputLabel>Select Tags</InputLabel>
            <Select
              multiple
              value={selectedTags}
              onChange={(e) => setSelectedTags(e.target.value)}
              input={<OutlinedInput label="Select Tags" />}
              renderValue={(selected) => (
                <Box display="flex" flexWrap="wrap" gap={0.5}>
                  {selected.map((value) => (
                    <Chip key={value} label={tags.find(tag => tag._id === value)?.name || value} style={{ maxWidth: '100px' }} />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {tags.map((tag) => (
                <MenuItem key={tag._id} value={tag._id}>
                  {tag.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <TextField
            label="Search"
            variant="outlined"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            fullWidth
          />
        </Grid>
      </Grid>
 
      <Grid container spacing={3} marginTop={3}>
        {filteredData.map(item => (
          item.approved && (
            <Grid item xs={12} md={6} lg={4} key={item._id}>
              <Card>
                <CardContent>
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Box display="flex" alignItems="center" flexWrap="wrap" position="relative">
                      {(tagSelections[item._id] || []).map((tag, index) => (
                        <FormControl key={index} margin="dense" size="small" style={{ margin: 5 }}>
                          <Select
                            IconComponent={() => <div style={{ display: 'none' }} />}
                            value={tag}
                            onChange={(e) => handleTagChange(item._id, index, e)}
                            displayEmpty
                            style={{
                              backgroundColor: tag
                                ? tags.find(t => t._id === tag)?.color
                                : '#ccc',
                              color: 'white',
                              borderRadius: '50px',
                              padding: '0 10px',
                              fontSize: '0.75rem',
                              minWidth: '50px'
                            }}
                            renderValue={(selected) =>
                              selected ? tags.find(tag => tag._id === selected)?.name : 'Tag'
                            }
                            MenuProps={MenuProps}
                            onClick={(e) => e.stopPropagation()}
                          >
                            {tags.map(tag => (
                              <MenuItem key={tag._id} value={tag._id}>
                                {tag.name}
                              </MenuItem>
                            ))}
                          </Select>
 
                          {tag && (
                            <IconButton
                              size="small"
                              style={{ marginLeft: 4, position: 'absolute'}}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleRemoveTag(item._id, index);
                              }}
                            >
                              <Close fontSize="small" />
                            </IconButton>
                          )}
                        </FormControl>
                      ))}
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleAddTag(item._id);
                        }}
                        style={{ marginLeft: 5 }}
                      >
                        <Add />
                      </IconButton>
                    </Box>
                  </Box>
                  <Typography variant="h6" align="center" style={{ marginTop: '8px', fontSize: 32 }}>
                    {item.basicCompanyInfo.data.companyName}
                  </Typography>
                  <Typography>Founded:<strong>{item.basicCompanyInfo.data.yearOfIncorporation}</strong> </Typography>
                  <Typography>Destinations:<strong>{item.companyProfileStage.data.countriesProvideTour.map(country => country.label).join(', ')}</strong> </Typography>
                  <Typography>Offices:<strong>{item.companyProfileStage.data.countriesOwnOffices.map(country => country.label).join(', ')}</strong> </Typography>
                  <Typography>Segments:<strong>{item.servicesAndOperations.data.segmentsCaterTo.join(', ')}</strong> </Typography>
                  <Typography fontSize={13}>Create Date:<strong>{formatDate(item.basicCompanyInfo.metadata.createdDate) || 'N/A'}</strong> </Typography>
                  <Typography fontSize={13}>Last Updated:
                    <strong> {formatDate(new Date(Math.max(
                      new Date(item.basicCompanyInfo.metadata.updatedDate)?.getTime(),
                      new Date(item.companyProfileStage.metadata.updatedDate)?.getTime(),
                      new Date(item.servicesAndOperations.metadata.updatedDate)?.getTime(),
                      new Date(item.contactDetails.metadata.updatedDate)?.getTime(),
                      new Date(item.packagesAndTariff.metadata.updatedDate)?.getTime(),
                    )).toISOString()) || 'N/A'}</strong>
                  </Typography>
                  <Box display="flex" justifyContent="center" alignItems="center" flexWrap="wrap" gap={1} marginTop={2}>
                    {item.servicesAndOperations.data.acceptsFIT && (
                      <Box
                        component="span"
                        sx={{
                          display: 'inline-block',
                          bgcolor: '#F5F5F5',
                          color: '#000',
                          border: '1px solid #ccc',
                          borderRadius: '50px',
                          padding: '0 10px',
                          fontSize: '1rem'
                        }}
                      >
                        FIT
                      </Box>
                    )}
                    {item.servicesAndOperations.data.providesSIC && (
                      <Box
                        component="span"
                        sx={{
                          display: 'inline-block',
                          bgcolor: '#F5F5F5',
                          color: '#000',
                          border: '1px solid #ccc',
                          borderRadius: '50px',
                          padding: '0 10px',
                          fontSize: '1rem'
                        }}
                      >
                        SIC
                      </Box>
                    )}
                  </Box>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleCardClick(item._id);
                    }}
                    style={{ marginTop: '10px', float: 'right' }}
                  >
                    View
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          )
        ))}
      </Grid>
    </Container>
  );
};
 
export default HomePage;
 
