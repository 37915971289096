
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

import { Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import FlightIcon from '@mui/icons-material/Flight';
import PublicIcon from '@mui/icons-material/Public';

import API from './Visa/API';
function HomePage() {
    const navigate = useNavigate();
    const sectionStyle = {
        display: 'flex',
        height: '100vh', // full height of the viewport
    };

    const halfSectionStyle = {
        flex: 1, // equally divide the space
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
        textAlign: 'center',
        padding: '20px',
    };

    const flightsStyle = {
        ...halfSectionStyle,
        backgroundImage:   'url("https://i.pinimg.com/originals/ec/05/db/ec05dbb5deb17a06d30ee83f7b98d3f5.jpg")',
        backgroundSize: 'cover'
    };

    const visaStyle = {
        ...halfSectionStyle,
        backgroundImage: 'url("https://p7.hiclipart.com/preview/75/375/665/travel-visa-passport-konsolosluk-diplomatic-mission-visa.jpg")',
        backgroundSize: 'cover',
        backgroundColor: '#7ed321', // example color, replace with your own or a background image
    };

    return (
        <div className="container mt-5">
            <div className="row d-flex align-items-center justify-content-center">
                <div className="mt-3 col-lg-4 d-flex align-items-center justify-content-center" style={{ minHeight: '200px'}}>
                    <Card sx={{ maxWidth: 345, height: '220px' }} variant="outlined"> {/* Updated height here */}
                        <Link to="/flights/tickets" style={{ textDecoration: 'none', color: 'inherit' }}>
                          <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                              <FlightIcon />
                              &nbsp; Flights
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              Streamlined flight management app for tracking ticket details, passenger information, and travel itineraries.
                            </Typography>
                          </CardContent>
                          <CardActions>
                                <Button size="large">Open Flights</Button>
                          </CardActions>
                        </Link>
                    </Card> 
                </div> 
                <div className="mt-3 col-lg-4 d-flex align-items-center justify-content-center">
                    <Card sx={{ maxWidth: 345, height: '220px' }} variant="outlined"> {/* Updated height here */}
                        <Link to="/visa/applications" style={{ textDecoration: 'none', color: 'inherit' }}>
                          <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                              <PublicIcon/>
                              &nbsp; Global Visa
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              Visa application management system with status tracking, applicant details, document attachments, and communication for efficient processing and updates.
                            </Typography>
                          </CardContent>
                          <CardActions>
                                <Button size="large">Open Global Visa</Button>
                            
                          </CardActions>
                        </Link>
                    </Card> 
                </div>
                <div className="mt-3 col-lg-4 d-flex align-items-center justify-content-center">
                    <Card sx={{ maxWidth: 345, height: '220px' }} variant="outlined"> {/* Updated height here */}
                        <Link to="/DMC/main" style={{ textDecoration: 'none', color: 'inherit' }}>
                          <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                              <PublicIcon/>
                              &nbsp; DMC 
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean et libero augue. Praesent viverra tortor in dui luctus, non convallis ligula laoreet. 
                            </Typography>
                          </CardContent>
                          <CardActions>
                                <Button size="large">Open DMC</Button>
                            
                          </CardActions>
                        </Link>
                    </Card> 
                </div>  
                <div className="mt-3 col-lg-4 d-flex align-items-center justify-content-center">
                    <Card sx={{ maxWidth: 345, height: '220px' }} variant="outlined"> {/* Updated height here */}
                        <Link to="/uaevisa/home" style={{ textDecoration: 'none', color: 'inherit' }}>
                          <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                              <PublicIcon/>
                              &nbsp; UAE VISA
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean et libero augue. Praesent viverra tortor in dui luctus, non convallis ligula laoreet. 
                            </Typography>
                          </CardContent>
                          <CardActions>
                                <Button size="large">Open UAE Visa</Button>
                            
                          </CardActions>
                        </Link>
                    </Card> 
                </div>  
                <div className="mt-3 col-lg-4 d-flex align-items-center justify-content-center">
                    <Card sx={{ maxWidth: 345, height: '220px' }} variant="outlined"> {/* Updated height here */}
                        <Link to="/internal/home" style={{ textDecoration: 'none', color: 'inherit' }}>
                          <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                              <PublicIcon/>
                              &nbsp; INTERNAL
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean et libero augue. Praesent viverra tortor in dui luctus, non convallis ligula laoreet. 
                            </Typography>
                          </CardContent>
                          <CardActions>
                                <Button size="large">Open Internal</Button>                            
                          </CardActions>
                        </Link>
                    </Card> 
                </div>
                <div className="mt-3 col-lg-4 d-flex align-items-center justify-content-center">
                    <Card sx={{ maxWidth: 345, height: '220px' }} variant="outlined"> {/* Updated height here */}
                        <Link to="/groups" style={{ textDecoration: 'none', color: 'inherit' }}>
                          <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                              <PublicIcon/>
                              &nbsp; Group Tours
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean et libero augue. Praesent viverra tortor in dui luctus, non convallis ligula laoreet. 
                            </Typography>
                          </CardContent>
                          <CardActions>
                                <Button size="large">Open Groups</Button>                            
                          </CardActions>
                        </Link>
                    </Card> 
                </div>                                  
            </div>
        </div>    
    );
}

export default HomePage;
