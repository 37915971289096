import React from 'react';
import { Document, Packer, Paragraph, TextRun } from 'docx';
import { saveAs } from 'file-saver';
import { Grid, Button } from '@mui/material';
import API from "../../Visa/API";

function FamilyNOCDocGenerator({applicationDate,
                                diplomaticMissionVal,
                                UAEEmirateVal,
                                nocGranterName,
                                nocGranterPassportNumber,
                                granterNationality,
                                applicantRelationVal,
                                titleVal,
                                nocApplicantName,
                                applicantNationality,
                                nocApplicantPassportNumber,
                                schengenEntries,
                                isSchengen,
                                countrySelectVal,
                                nonSchengenSearchDate,
                                applicantGender,
                                nocGranterPhoneNumber,
                                freeTextField,
                                shareToEmail,
                                sepFinSponsor,
                                finSponsorName,
                                finSponsorNationality,
                                finSponsorPhoneNumber,
                                finSponsorPassportNumber,
                                shouldDisplayFamilyNOCDocGenerator,                             
                              }) {

  const shareByEmail = true;
    
  const formatDate = (dateString, isCountryList) => {
    if (!dateString) return 'Date not set';
    const date = new Date(dateString);
    const monthNames = [
      "Jan", "Feb", "Mar", "Apr", "May", "June",
      "July", "Aug", "Sept", "Oct", "Nov", "Dec"
    ];
    if (isCountryList){
      return [
        date.getDate().toString().padStart(2, '0'),
        monthNames[date.getMonth()], // Use the month name from the array
        (date.getFullYear() % 100).toString()
      ].join('-');
    } else {
      return [
        date.getDate().toString().padStart(2, '0'),
        monthNames[date.getMonth()], // Use the month name from the array
        date.getFullYear()
      ].join('-');
    }
  }; 

  const freeText = () => {
    if (freeTextField.trim()) {
      return  [ new Paragraph({}),
                new Paragraph({
                  children: [
                    new TextRun(`${freeTextField.trim()}`),
                  ]
                }),
              ]
    } else {
      return ``
    }
  } 

  const countries_travelling_to = () => {
    if (isSchengen){
      return schengenEntries.map((entry, index, array) => {
        const isLast = index === array.length - 1;
        const prefix = isLast && array.length > 1 ? 'and ' : '';
        const suffix = !isLast ? ', ' : '';
        return (
          `${prefix}${entry.countryName} from ` +
          `${formatDate(entry.searchDate[0].startDate, true)} to ` +
          `${formatDate(entry.searchDate[0].endDate, true)}${suffix}`
        );
      }).join('').trim();
    } else {
      return `${countrySelectVal} from ` +
      `${formatDate(nonSchengenSearchDate[0].startDate, true)} to ` +
      `${formatDate(nonSchengenSearchDate[0].endDate, true)}`
    }
  };

  const closing_line = () => {
    if (isSchengen){
      return `Your assistance in granting the Schengen visa will be much appreciated.`
    } else {
      return `Your assistance in granting the necessary visa to enter ${countrySelectVal} will be much appreciated.`
    }
  }  



  const removeFinSponsorContent = () => {
    if (sepFinSponsor){
      return ``
    } else {
      return [
        new Paragraph({}),
        new Paragraph({
          children: [
            new TextRun(`Please treat my personal bank statements, from where ${applicantGender==='male'?'he':(applicantGender==='female'?'she':'he/she')} will utilize funds, as `+
              `supporting documents to ${applicantGender==='male'?'his':(applicantGender==='female'?'her':'his/her')} ${isSchengen?'Schengen':countrySelectVal} Visa Application.\n\n`)]
        }),
      ]
    }
  }

  const sepFinSponsorContent = () => {
    if (sepFinSponsor) {
      // Wrap the return value in parentheses to return an object or array correctly
      return [
        {
          // Assuming this is intended to be an object within an array
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: `${formatDate(applicationDate, false)}`,
                  bold: true
                })
              ]
            }), 
            new Paragraph({}),
            new Paragraph({
              children: [
                new TextRun({
                  text: "To: The Visa Section\n",
                  bold: true 
                })
              ]
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: `${diplomaticMissionVal} of ${countrySelectVal}\n`,
                  bold: true 
                })
              ]
            }), 
            new Paragraph({
              children: [
                new TextRun({
                  text: `${UAEEmirateVal} – United Arab Emirates\n\n`,
                  bold: true 
                })
              ]
            }),
            new Paragraph({}),
            new Paragraph({
              children: [
                new TextRun({
                  text: "Subject: Financial Support Letter\n\n",
                  bold: true,
                  underline: true
                })
              ]
            }),
            new Paragraph({}),
            new Paragraph({
              children: [
                new TextRun("Dear Sir/Madam,\n\n")]
            }),
            new Paragraph({}),
            new Paragraph({
              children: [
                new TextRun({
                  text: "This is to confirm that I, ",
                }),
                new TextRun({
                  text: finSponsorName,
                  bold: true,
                }),
                new TextRun({
                  text: `, holder of ${finSponsorNationality} passport number ${finSponsorPassportNumber}, undertake to sponsor all expenses for `,
                }),
                new TextRun({
                  text: `${titleVal} ${nocApplicantName}`,
                  bold: true,
                }),
                new TextRun({
                  text: `, holder of ${applicantNationality} passport number ${nocApplicantPassportNumber}, travelling to `+
                        `${countries_travelling_to()}.\n\n`,
                }),
              ],
            }),
            new Paragraph({}),
            new Paragraph({
              children: [
                new TextRun(`Please treat my personal bank statements, from where ${applicantGender==='male'?'he':(applicantGender==='female'?'she':'he/she')} will utilize funds, as `+
                  `supporting documents to ${applicantGender==='male'?'his':(applicantGender==='female'?'her':'his/her')} ${isSchengen?'Schengen':countrySelectVal} Visa Application.\n\n`)]
            }),
            new Paragraph({}),
            new Paragraph({
              children: [
                new TextRun(`${closing_line()}\n\n`)]
            }),
            new Paragraph({}),
            new Paragraph({}),
            new Paragraph({
              children: [
                new TextRun("Kind Regards,\n\n\n")]
            }),
            new Paragraph({}),
            new Paragraph({}),
            new Paragraph({}),
            new Paragraph({
              children: [
                new TextRun(`Name: ${finSponsorName}\n`)]
            }),
            new Paragraph({
              children: [
                new TextRun(`Nationality: ${finSponsorNationality}\n`)]
            }),
            new Paragraph({
              children: [
                new TextRun(`Passport Number: ${finSponsorPassportNumber}\n`)]
            }),
            new Paragraph({
              children: [
                new TextRun(`Cell: ${finSponsorPhoneNumber}`)]
            }), 
            new Paragraph({}),
            new Paragraph({
              text: 'Enclosed:',
            }),
            new Paragraph({
              text: 'My Passport copy',
               numbering: {
                reference: 'my-numbering2', // Reference your numbering definition here
                level: 0, // The level of the item (0 in this case)
              },
            }),
            new Paragraph({
              text: 'My UAE Residence Visa copy',
              numbering: {
                reference: 'my-numbering2', // Reference your numbering definition here
                level: 0, // The level of the item (0 in this case)
              },
            }),
            new Paragraph({
              text: 'My Emirates ID copy',
              numbering: {
                reference: 'my-numbering2', // Reference your numbering definition here
                level: 0, // The level of the item (0 in this case)
              },
            }),
            new Paragraph({
              text: 'Personal Bank Statement',
              numbering: {
                reference: 'my-numbering2', // Reference your numbering definition here
                level: 0, // The level of the item (0 in this case)
              },
            }),                                              
          ]
        }
      ];
    } else {
      return ``;
    }
  }

  const handleGenerateNOC = () => {
    const numbering = {
      config: [
        {
          reference: 'my-numbering', // A unique identifier for this numbering configuration
          levels: [
            {
              level: 0,
              format: 'decimal', // This will create a numbered list
              text: '%1.', // The %1 indicates where the level number will appear
              alignment: 'left', // How the text should be aligned
              style: {
                paragraph: {
                  indent: { left: 720, hanging: 260 }, // How much indentation you want
                },
              },
            },
          ],
        },
        {
          reference: 'my-numbering2', // A unique identifier for this numbering configuration
          levels: [
            {
              level: 0,
              format: 'decimal', // This will create a numbered list
              text: '%1.', // The %1 indicates where the level number will appear
              alignment: 'left', // How the text should be aligned
              style: {
                paragraph: {
                  indent: { left: 720, hanging: 260 }, // How much indentation you want
                },
              },
            },
          ],          
        },
      ],
    };
    const doc = new Document({
      styles: {
        default: {
          document: {
            run: {
              font: "Calibri",
              size: 24, 
            },
          },
        },
      },
      numbering: numbering,
      sections: [
      {
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text:`${formatDate(applicationDate, false)}\n`,
                bold: true
              })
            ]
          }),
          new Paragraph({}),
          new Paragraph({
            children: [
              new TextRun({
                text: "To: The Visa Section\n",
                bold: true 
              })
            ]
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: `${diplomaticMissionVal} of ${countrySelectVal}\n`,
                bold: true 
              })
            ]
          }), 
          new Paragraph({
            children: [
              new TextRun({
                text: `${UAEEmirateVal} – United Arab Emirates\n\n`,
                bold: true 
              })
            ]
          }),
          new Paragraph({}),
          new Paragraph({
            children: [
              new TextRun({
                text: "Subject: No Objection Certificate for Travel\n\n",
                bold: true,
                underline: true
              })
            ]
          }),
          new Paragraph({}),
          new Paragraph({
            children: [
              new TextRun("Dear Sir/Madam,\n\n")]
          }),
          new Paragraph({}),
          new Paragraph({
            children: [
              new TextRun({
                text: "This is to confirm that I, ",
              }),
              new TextRun({
                text: nocGranterName,
                bold: true,
              }),
              new TextRun({
                text: `, holder of ${granterNationality} passport number ${nocGranterPassportNumber}, have no objection in my ${applicantRelationVal}, `,
              }),
              new TextRun({
                text: `${titleVal} ${nocApplicantName}`,
                bold: true,
              }),
              new TextRun({
                text: `, holder of ${applicantNationality} passport number ${nocApplicantPassportNumber}, travelling to `+
                      `${countries_travelling_to()}.\n\n`,
              }),
            ],
          }),
          ...freeText(),
          ...removeFinSponsorContent(),
          new Paragraph({}),
          new Paragraph({
            children: [
              new TextRun(`${closing_line()}\n\n`)]
          }),
          new Paragraph({}),
          new Paragraph({}),
          new Paragraph({
            children: [
              new TextRun("Kind Regards,\n\n\n")]
          }),
          new Paragraph({}),
          new Paragraph({}),
          new Paragraph({}),
          new Paragraph({
            children: [
              new TextRun(`Name: ${nocGranterName}\n`)]
          }),
          new Paragraph({
            children: [
              new TextRun(`Nationality: ${granterNationality}\n`)]
          }),
          new Paragraph({
            children: [
              new TextRun(`Passport Number: ${nocGranterPassportNumber}\n`)]
          }),
          new Paragraph({
            children: [
              new TextRun(`Cell: ${nocGranterPhoneNumber}`)]
          }), 
          new Paragraph({}),
          new Paragraph({
            text: 'Enclosed:',
          }),
          new Paragraph({
            text: 'My Passport copy',
             numbering: {
              reference: 'my-numbering', // Reference your numbering definition here
              level: 0, // The level of the item (0 in this case)
            },
          }),
          new Paragraph({
            text: 'My UAE Residence Visa copy',
            numbering: {
              reference: 'my-numbering', // Reference your numbering definition here
              level: 0, // The level of the item (0 in this case)
            },
          }),
          new Paragraph({
            text: 'My Emirates ID copy',
            numbering: {
              reference: 'my-numbering', // Reference your numbering definition here
              level: 0, // The level of the item (0 in this case)
            },
          }),                  
          ]        
      },
      ...sepFinSponsorContent(),
      ],
    });

    Packer.toBlob(doc).then(blob => {
      saveAs(blob, `NOC_${nocApplicantName}_${countrySelectVal}.docx`);
      if (shareByEmail){
        const formData = new FormData();
        formData.append('file', blob, `NOC_${nocApplicantName}_${countrySelectVal}.docx`);
        formData.append('to_email_address', shareToEmail);
        formData.append('applicant_name', `${nocApplicantName}`);
        formData.append('country_name', `${countrySelectVal}`);
        formData.append('isEmployee', false);
        formData.append('isInvestor', false);
        formData.append('isPartner', false);
        formData.append('isFamily', true);
        try {
          // Send the document to the backend
          const result = API.post('/ivisa/senddoc', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
          
          console.log('Document sent successfully.');
        } catch (error) {
          console.error('Error sending document:', error);
        }        
      }
    });
  };

  return (
    <Grid item>
      <Button type="submit" variant="contained" disabled={!shouldDisplayFamilyNOCDocGenerator} onClick={handleGenerateNOC}>{shareByEmail?'Generate & Share NOC':'Generate NOC'}</Button>
    </Grid>
  );
}

export default FamilyNOCDocGenerator;
