import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import API from "../../Visa/API";
import { useNavigate } from 'react-router-dom';
import RichTextEditor from '../../UtilComponents/RichTextEditor';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { Form } from 'react-bootstrap';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const PieChart = ({ profile }) => {
  const percentageIsFIT = profile.servicesAndOperations.data.percentageIsFIT || 0;
  const percentageIsGroups = 100 - percentageIsFIT;

  const data = {
    labels: ['FIT', 'Groups'],
    datasets: [
      {
        data: [percentageIsFIT, percentageIsGroups],
        backgroundColor: ['#FF6384', '#36A2EB'],
        hoverBackgroundColor: ['#FF6384', '#36A2EB'],
      },
    ],
  };

  return (
    <div className="fit-groups-percentage">
      <Row className="my-3 text-left">
        <Col md={3}>Distribution of FIT and Groups:</Col>
        <Col md={9}>
          <table className='table table-custom'>
            <thead>
              <tr>
                <th>#</th>
                <th>Type</th>
                <th>Percentage</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>FIT</td>
                <td>{percentageIsFIT}%</td>
              </tr>
              <tr>
                <td>2</td>
                <td>Groups</td>
                <td>{percentageIsGroups}%</td>
              </tr>
            </tbody>
          </table>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md={{ span: 8, offset: 2 }} style={{ textAlign: 'center' }}>
          <div style={{ width: '300px', height: '300px', margin: '0 auto' }}>
            <Pie data={data} />
          </div>
        </Col>
      </Row>
    </div>
  );
};

const ensureProtocol = (url) => {
  if (!/^https?:\/\//i.test(url)) {
    return `http://${url}`;
  }
  return url;
};

const SourceMarketsWithPieChart = ({ profile }) => {
  const sourceMarkets = profile.servicesAndOperations.data.sourceMarketsPercentages || [];
  const rows = sourceMarkets.map(market => ({
    country: market.country,
    percentage: market.percentage || 0
  }));

  const data = {
    labels: rows.map(row => row.country),
    datasets: [
      {
        label: 'Country Percentages',
        data: rows.map(row => row.percentage),
        backgroundColor: [
          '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40',
          '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'
        ]
      }
    ]
  };

  return (
    <div className="source-markets">
      <Row className="my-3 text-left">
        <Col md={3}>Source Markets for Incoming:</Col>
        <Col md={9}>
          <table className='table table-custom'>
            <thead>
              <tr>
                <th>#</th>
                <th>Country</th>
                <th>Percentage</th>
              </tr>
            </thead>
            <tbody>
              {rows.map((row, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{row.country}</td>
                  <td>{row.percentage}%</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md={{ span: 8, offset: 2 }} style={{ textAlign: 'center' }}>
          <div style={{ width: '300px', height: '300px', margin: '0 auto' }}>
            <Pie data={data} />
          </div>
        </Col>
      </Row>
    </div>
  );
};

const ViewForm = () => {
  const [htmlContent, setHTMLContent] = useState('');
  const { id } = useParams();
  const [profile, setProfile] = useState(null);
  const [notes, setNotes] = useState([]);
  const[user,setUser] = useState({firstName: '',LastName:'',email:''});
  const navigate = useNavigate();

  const getFormattedDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };
  
  const getFormattedTime = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });
  };
  

  

  
  const handleSend = async () => {
    try {
      const response = await API.post('/DMC/add-note', {
        id,
        noteContent: htmlContent,
        senderEmail: user.email, // Ensure the current user's email is passed
        user:user
      });
  
      if (response.status === 200) {
        setHTMLContent(''); // Clear the editor
        const notesResponse = await API.get(`/DMC/notes/${id}`);
        setNotes(notesResponse.data.notes); // Update the notes with the new data
      } else {
        console.error('Failed to add note');
      }
    } catch (error) {
      console.error('Error adding note:', error);
    }
  };

  const stripHtmlTags = (html) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || '';
  };

  useEffect(() => {
    const fetchProfileAndNotes = async () => {
      try {
        const profileResponse = await API.get('/DMC/get-profile', {
          params: { id },
          withCredentials: true,
        });
        setProfile(profileResponse.data);
  
        const notesResponse = await API.get(`/DMC/notes/${id}`);
        setNotes(notesResponse.data.notes);
      } catch (error) {
        console.error('Error fetching profile or notes:', error);
      }
    };
  
    const fetchUserData = async () => {
      try {
        const response = await API.get(`/DMC/staff/details`);
        setUser({
          firstName: response.data.staff_firstname,
          lastName: response.data.staff_lastname,
          email: response.data.staff_email,
        });
      } catch (error) {
        console.error(`Error fetching user data`, error);
      }
    };
  
    fetchProfileAndNotes();
    fetchUserData();
  }, [id]);
  

  const renderRow = (label, value, id, clickable = false, type = 'none') => {
    const formatValue = (val) => {
      if (val === null || val === undefined) {
        return '';
      } else if (typeof val === 'boolean') {
        return val ? 'Yes' : 'No';
      } else if (Array.isArray(val)) {
        return val.map(item => typeof item === 'object' ? item.label : item).join(', ');
      } else if (typeof val === 'object') {
        try {
          return JSON.stringify(val);
        } catch (e) {
          return '[Circular]';
        }
      }
      return val;
    };

    const handleEmailClick = (email) => {
      window.location.href = `mailto:${email}`;
    };

    const handleLinkClick = (url) => {
      const urlWithProtocol = ensureProtocol(url);
      window.open(urlWithProtocol, '_blank', 'noopener,noreferrer');
    };

    const handleDownload = (url) => {
      const urlWithProtocol = ensureProtocol(url);
      window.open(urlWithProtocol, '_blank', 'noopener,noreferrer');
    };

    const ensureProtocol = (url) => {
      if (!/^(?:f|ht)tps?:\/\//.test(url)) {
        return 'http://' + url;
      }
      return url;
    };

    const isValidUrl = (string) => {
      try {
        new URL(string);
        return true;
      } catch (_) {
        return false;
      }
    };

    const renderValue = (val) => {
      const formattedValue = formatValue(val);

      if (type === 'email') {
        return (
          <button onClick={() => handleEmailClick(formattedValue)} style={{ color: 'blue', textDecoration: 'underline', background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}>
            {formattedValue}
          </button>
        );
      } else if (type === 'phone') {
        const formattedPhone = `+${formattedValue.replace(/[^0-9]/g, '')}`;
        return (
          <button onClick={() => {
            window.location.href = `tel:${formattedPhone}`;
          }} style={{ color: 'blue', textDecoration: 'underline', background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}>
            {formattedPhone}
          </button>
        );
      } else if (type === 'whatsapp') {
        const formattedPhone = `+${formattedValue.replace(/[^0-9]/g, '')}`;
        return (
          <button onClick={() => {
            window.location.href = `https://wa.me/${formattedPhone}`;
          }} style={{ color: 'blue', textDecoration: 'underline', background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}>
            {formattedPhone}
          </button>
        );
      } else if (clickable && isValidUrl(formattedValue)) {
        return (
          <button onClick={() => handleLinkClick(formattedValue)} style={{ color: 'blue', textDecoration: 'underline', background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}>
            {formattedValue}
          </button>
        );
      } else if (clickable && formattedValue) {
        return (
          <span
            className="file-link"
            style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => handleDownload(formattedValue)}
          >
            {formattedValue}
          </span>
        );
      }
      return formattedValue;
    };

    return (
      <Row id={id} key={id} className="my-3 text-left">
        <Col md={3}>{label}</Col>
        <Col>{renderValue(value)}</Col>
      </Row>
    );
  };

  const handleDownload = async (companyName, directory, fileName) => {
    try {
      const response = await API.post('/DMC/getFile', {
        companyName,
        directory,
        fileName,
      });

      const { base64File } = response.data;

      // Create a blob from the base64 string
      const blob = new Blob([Uint8Array.from(atob(base64File), c => c.charCodeAt(0))], { type: 'application/octet-stream' });

      // Create a link to download the blob
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  const getStringBeforeT = (inputString) => {
    if (inputString && typeof inputString === 'string') {
      const index = inputString.indexOf('T');
      if (index !== -1) {
        return inputString.substring(0, index);
      }
    }
    return inputString;
  };

  const formatReadableDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  const printBool = (bool) => (bool ? "Yes" : "No");


  return (
    <div style={{ display: 'flex', width: '100%', margin: '0' }}>
      <div className='ml-5 mt-5 half-page-container'>
        {/* Display Data Sections */}
        {profile && (
          <>
            {/* Basic Company Information */}
            <Row>
              <Col>
                <Row className="align-items-center mb-3">
                  <Col className="d-flex align-items-center">
                    <h2 className="mb-0">Basic Company Information</h2>
                   
                  </Col>
                </Row>
                {renderRow("Company Legal Name:", profile.basicCompanyInfo.data.companyName, "basicCompanyInfo.data.companyName")}
                {renderRow("Year of Incorporation:", profile.basicCompanyInfo.data.yearOfIncorporation, "basicCompanyInfo.data.yearOfIncorporation")}
                {renderRow("Is your company a family-run business:", printBool(profile.basicCompanyInfo.data.isFamilyRun), "basicCompanyInfo.data.isFamilyRun")}
                {renderRow("Company Representative Name:", profile.basicCompanyInfo.data.companyRepresentativeName, "basicCompanyInfo.data.companyRepresentativeName")}
                {renderRow("Phone Number:", `${profile.basicCompanyInfo.data.phoneCode}${profile.basicCompanyInfo.data.phoneNumber}`, "basicCompanyInfo.data.phoneNumber", false, 'phone')}
                {renderRow("Email:", profile.basicCompanyInfo.data.email, "basicCompanyInfo.data.email", false, 'email')}
                {renderRow("Designation:", profile.basicCompanyInfo.data.designation, "basicCompanyInfo.data.designation")}
                <Row id='basicCompanyInfo.data.companyOwners' className="my-3 text-left">
                  <Col md={3}>Company Owners:</Col>
                  <Col>
                    <table className='table table-custom'>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Phone Number</th>
                        </tr>
                      </thead>
                      <tbody>
                        {profile.basicCompanyInfo.data.companyOwners.map((owner, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{owner.name}</td>
                            <td>
                              <a href={`mailto:${owner.email}`} style={{ color: 'blue', textDecoration: 'underline' }}>
                                {owner.email}
                              </a>
                            </td>
                            <td>  <a href={`tel:${owner.code}${owner.phoneNumber}`} style={{ color: 'blue', textDecoration: 'underline' }}> {owner.code ? `${owner.code}${owner.phoneNumber || ''}` : ''}
                              </a></td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Col>
                </Row>
                {renderRow("Are the company owners actively involved in the day-to-day running of the business?", printBool(profile.basicCompanyInfo.data.areCompanyOwnersInvolved), "basicCompanyInfo.data.areCompanyOwnersInvolved")}
                {profile.basicCompanyInfo.data.areCompanyOwnersInvolved && renderRow(
                  "Company owners actively involved:",
                  profile.basicCompanyInfo.data.ownersInvolved.map(owner => owner.label).join(', '),
                  "basicCompanyInfo.data.ownersInvolved"
                )}
                {renderRow("Company website:", ensureProtocol(profile.basicCompanyInfo.data.companyWebsite), "basicCompanyInfo.data.companyWebsite", true)}

                {profile.basicCompanyInfo.data.platforms.map((platform, index) => (
                  <Row key={index} id={`basicCompanyInfo.data.platforms`} className="my-3 text-left">
                    <Col md={3}>Platform {index + 1}:</Col>
                    <Col>
                      <p>
                        {platform.platform}
                        <a href={ensureProtocol(platform.url)} target="_blank" rel="noopener noreferrer" style={{ color: 'blue', textDecoration: 'underline', marginLeft: '5px' }}>{platform.url}</a>
                      </p>
                    </Col>
                  </Row>
                ))}
              </Col>
            </Row>
            <hr style={{ height: '3px', backgroundColor: 'black', border: 'none' }} />

            {/* Company Profile */}
            <Row>
              <Col>
                <Row className="align-items-center mb-3">
                  <Col className="d-flex align-items-center">
                    <h2 className="mb-0">Company Profile</h2>
                    
                  </Col>
                </Row>
                <Row className="my-3 text-left">
                  <Col md={3}>Member of any local or international travel industry associations:</Col>
                  <Col>{printBool(profile.companyProfileStage.data.isMemberOfAnyLocalOrInternatonalTravelIndustryAss)}</Col>
                </Row>
                {profile.companyProfileStage.data.isMemberOfAnyLocalOrInternatonalTravelIndustryAss && (
                  <Row id='companyProfileStage.data.travelIndustryAssociations' className="my-3 text-left">
                    <Col md={3}>Travel Industry Associations:</Col>
                    <Col>
                      <table className='table table-custom'>
                        <thead>
                          <tr>
                            <th style={{ width: '50%' }}>Association</th>
                            <th style={{ width: '50%' }}>Membership Start Year</th>
                          </tr>
                        </thead>
                        <tbody>
                          {profile.companyProfileStage.data.travelIndustryAssociations.map((association, index) => (
                            <tr key={index}>
                              <td>{association.association}</td>
                              <td>{association.membershipStartYear}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                )}
                <Row id='companyProfileStage.data.businessLicense'>
                  <Col md={3}>Business License:</Col>
                  <Col>
                    <table className='table table-custom'>
                      <thead>
                        <tr>
                          <th style={{ width: '30%' }}>Country</th>
                          <th style={{ width: '40%' }}>File Name</th>
                          <th style={{ width: '30%' }}>Expiry</th>
                        </tr>
                      </thead>
                      <tbody>
                        {profile.companyProfileStage.data.businessLicense.map((license, index) => (
                          <tr key={index}>
                            <td>{license.selectedCountry}</td>
                            <td>
                              <span
                                className="file-link"
                                style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                                onClick={() => handleDownload(profile.basicCompanyInfo.data.companyName, 'Business License(s)', license.name)}
                              >
                                {license.name.length > 50 ? license.name.slice(0, 50) + '...' : license.name}
                              </span>
                            </td>
                            <td>{getStringBeforeT(license.expiryDate)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Col>
                </Row>
                {profile.companyProfileStage.data.companyInfo.map((info, index) => (
                  <Row key={index} id={`companyProfileStage.data.companyInfo.${index}`} className="my-3 text-left">
                    <Col md={3}>Company Info {index + 1}:</Col>
                    <Col>
                      <p>
                        <span
                          className="file-link"
                          style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                          onClick={() => handleDownload(profile.basicCompanyInfo.data.companyName, 'CompanyInfo', info.name)}
                        >
                          {info.name.length > 50 ? info.name.slice(0, 25) + '...' : info.name}
                        </span>
                      </p>
                    </Col>
                  </Row>
                ))}
                {renderRow("Countries Provide Tour:", profile.companyProfileStage.data.countriesProvideTour.map(country => country.label).join(', '), "companyProfileStage.data.countriesProvideTour")}
                {/* {renderRow("Countries Own Offices:", profile.companyProfileStage.data.countriesOwnOffices.map(country => country.label).join(', '), "companyProfileStage.data.countriesOwnOffices")} */}
                {profile?.companyProfileStage?.data?.countriesOwnOffices && (
                  <Row id='servicesAndOperations.data.countriesOwnOffices'>
                  <Col md={3}>Offices:</Col>
                  <Col>
                    <table className='table table-custom'>
                      <thead>
                        <tr>
                          <th>Country</th>
                          <th>City</th>
                          <th>Address</th>
                        </tr>
                      </thead>
                      <tbody>
                        {profile?.companyProfileStage?.data?.countriesOwnOffices.map((country, index) => (
                          <tr key={index}>
                            <td>{country.label}</td>
                            <td>{country.city}</td>
                            <td>{country.officeAddress}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Col>
                </Row>
                )}
                {renderRow("Number of Full-time Employees:", profile.companyProfileStage.data.numFullTimeEmployees, "companyProfileStage.data.numFullTimeEmployees")}
                {renderRow("Handled UAE GCC:", printBool(profile.companyProfileStage.data.handledUAEGCC), "companyProfileStage.data.handledUAEGCC")}
                {renderRow("GCC Countries:", profile.companyProfileStage.data.GCCCountries.map(country => country.label).join(', '), "companyProfileStage.data.GCCCountries")}
                {renderRow("Operates B2B:", printBool(profile.companyProfileStage.data.operatesB2B), "companyProfileStage.data.operatesB2B")}
                {renderRow("Registration Guidelines:", profile.companyProfileStage.data.registrationGuidelines, "companyProfileStage.data.registrationGuidelines")}
                {renderRow("Bookings in Past 5 Years:", printBool(profile.companyProfileStage.data.bookingsPast5Years), "companyProfileStage.data.bookingsPast5Years")}
              </Col>
            </Row>
            <hr style={{ height: '3px', backgroundColor: 'black', border: 'none' }} />

            {/* Services and Operations */}
            <Row>
              <Col>
                <Row className="align-items-center mb-3">
                  <Col className="d-flex align-items-center">
                    <h2 className="mb-0">Services and Operations</h2>
                  
                  </Col>
                </Row>
                {renderRow("Segments Catered To:", profile.servicesAndOperations.data.segmentsCaterTo.join(', '), "servicesAndOperations.data.segmentsCaterTo")}
                <SourceMarketsWithPieChart profile={profile} />
                {renderRow("Do you currently supply services to travel agents in the UAE?", printBool(profile.servicesAndOperations.data.supplyToTravelAgentsUAE), "servicesAndOperations.data.supplyToTravelAgentsUAE")}
                {profile.servicesAndOperations.data.supplyToTravelAgentsUAE && (
                  <Row id='servicesAndOperations.data.UAETravelAgents'>
                    <Col md={3}>Travel Agents in UAE:</Col>
                    <Col>
                      <table className='table table-custom'>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Company Name</th>
                            <th>Agent Name</th>
                            <th>Number</th>
                            <th>Email</th>
                          </tr>
                        </thead>
                        <tbody>
                          {profile.servicesAndOperations.data.UAETravelAgents.map((agent, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{agent.designation}</td>
                              <td>{agent.name}</td>
                              <td> <a href={`tel:${agent.selectedPrefix}${agent.phoneNumber}`} style={{ color: 'blue', textDecoration: 'underline' }}> {`${agent.selectedPrefix}${agent.phoneNumber}`} </a></td>
                              <td>
                                <a href={`mailto:${agent.email}`} style={{ color: 'blue', textDecoration: 'underline' }}>
                                  {agent.email}
                                </a>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                )}
                {renderRow("Does your company operate its own seat-in-coach tours?", printBool(profile.servicesAndOperations.data.providesSIC), "servicesAndOperations.data.providesSIC")}
                {profile.servicesAndOperations.data.providesSIC && renderRow("Do you have partnerships with companies that offer SIC Tours?", printBool(profile.servicesAndOperations.data.partnershipsWithSIC), "servicesAndOperations.data.partnershipsWithSIC")}
                {profile.servicesAndOperations.data.providesSIC && profile.servicesAndOperations.data.partnershipsWithSIC && renderRow("Please select the countries where you can book SIC tours for FIT guests through 3rd party vendors:", profile.servicesAndOperations.data.countriesBookSICToursThirdParty.map(country => country.label).join(', '), "servicesAndOperations.data.countriesBookSICToursThirdParty")}
                {renderRow("Do you accept FIT Bookings?", printBool(profile.servicesAndOperations.data.acceptsFIT), "servicesAndOperations.data.acceptsFIT")}
                <PieChart profile={profile} />
                {renderRow("Tour Guide Languages:", profile.servicesAndOperations.data.tourGuideLanguages.map(country => country.label).join(', '), "servicesAndOperations.data.tourGuideLanguages")}
                {renderRow("Do you own & maintain your own vehicle fleet?", printBool(profile.servicesAndOperations.data.maintainOwnVehicleFleet), "servicesAndOperations.data.maintainOwnVehicleFleet")}
                {renderRow(profile.servicesAndOperations.data.maintainOwnVehicleFleet ? 'Enter Fleet Details:' : 'Indicate the vehicle types you offer to different group sizes', profile.servicesAndOperations.data.vehicleDetails, "servicesAndOperations.data.vehicleDetails")}
                {renderRow("Do you provide visa-related services?", printBool(profile.servicesAndOperations.data.provideVisaRelatedServices), "servicesAndOperations.data.provideVisaRelatedServices")}
                {profile.servicesAndOperations.data.provideVisaRelatedServices && (
                  <Row id='servicesAndOperations.data.visaCountryAndDescription' className="my-3 text-left">
                    <Col md={3}>Visa Details:</Col>
                    <Col>
                      <table className='table table-custom'>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Country</th>
                            <th>Visa Services Explained</th>
                          </tr>
                        </thead>
                        <tbody>
                          {profile.servicesAndOperations.data.visaCountryAndDescription.map((license, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{license.country}</td>
                              <td>{license.text}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                )}
                {renderRow("Do any of your offices operate as an outbound travel agency?", printBool(profile.servicesAndOperations.data.officesOperateOutboundTravel), "servicesAndOperations.data.officesOperateOutboundTravel")}
                {profile.servicesAndOperations.data.officesOperateOutboundTravel && (
                  renderRow("Please select the offices that sell outbound", profile.servicesAndOperations.data.officesOperatedOutbound.map(country => country.label).join(', '), "servicesAndOperations.data.officesOperatedOutbound")
                )}
              </Col>
            </Row>
            <hr style={{ height: '3px', backgroundColor: 'black', border: 'none' }} />

            {/* Contact Details */}
            <Row>
              <Col>
                <Row className="align-items-center mb-3">
                  <Col className="d-flex align-items-center">
                    <h2 className="mb-0">Contact Details</h2>
                    
                  </Col>
                </Row>
                {renderRow("Single contact to send quote requests:", printBool(profile.contactDetails.data.isSingle), "contactDetails.data.isSingle")}
                {profile.contactDetails.data.contactDetailsQuotes && (
                  <>
                    <Row id='contactDetails.data.contactDetailsQuotes' className="my-3">
                      <Col md={3}>Email addresses for us to send quote requests:</Col>
                      <Col>
                        <table className='table table-custom'>
                          <thead>
                            <tr>
                              <th style={{ width: '30%' }}>Country</th>
                              <th style={{ width: '20%' }}>Name</th>
                              <th style={{ width: '20%' }}>Phone Number</th>
                              <th style={{ width: '30%' }}>Email</th>
                            </tr>
                          </thead>
                          <tbody>
                            {profile.contactDetails.data.contactDetailsQuotes.map((quote, index) => {
                              const formattedPhone = `+${quote.code.replace(/[^\d]/g, '')}${quote.phoneNumber}`;
                              return (
                                <tr key={index}>
                                  <td>
                                    {profile.contactDetails.data.isSingle
                                      ? profile.companyProfileStage.data.countriesProvideTour.map(country => country.label).join(',')
                                      : quote.country}
                                  </td>
                                  <td>{quote.name}</td>
                                  <td>
                                    <a href={`tel:${formattedPhone}`} style={{ color: 'blue', textDecoration: 'underline' }}>
                                      {formattedPhone}
                                    </a>
                                  </td>
                                  <td>
                                    <a href={`mailto:${quote.email}`} style={{ color: 'blue', textDecoration: 'underline' }}>
                                      {quote.email}
                                    </a>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </Col>
                    </Row>
                  </>
                )}
                {renderRow("Single escalation point of contact (Supervisor/Team Leader):", printBool(profile.contactDetails.data.isSingle1), "contactDetails.data.isSingle1")}
                {profile.contactDetails.data.escalationPointsOfContact && (
                  <>
                    <Row id='contactDetails.data.escalationPointsOfContact' className="my-3">
                      <Col md={3}>Escalation Point of Contact (Supervisor/Team Leader):</Col>
                      <Col>
                        <table className='table table-custom'>
                          <thead>
                            <tr>
                              <th style={{ width: '30%' }}>Country</th>
                              <th style={{ width: '20%' }}>Name</th>
                              <th style={{ width: '20%' }}>Phone Number</th>
                              <th style={{ width: '30%' }}>Email</th>
                            </tr>
                          </thead>
                          <tbody>
                            {profile.contactDetails.data.escalationPointsOfContact.map((contact, index) => {
                              const formattedPhone = `+${contact.code.replace(/[^\d]/g, '')}${contact.phoneNumber}`;
                              return (
                                <tr key={index}>
                                  <td>
                                    {profile.contactDetails.data.isSingle1
                                      ? profile.companyProfileStage.data.countriesProvideTour.map(country => country.label).join(',')
                                      : contact.country}
                                  </td>
                                  <td>{contact.name}</td>
                                  <td>
                                    <a href={`tel:${formattedPhone}`} style={{ color: 'blue', textDecoration: 'underline' }}>
                                      {formattedPhone}
                                    </a>
                                  </td>
                                  <td>
                                    <a href={`mailto:${contact.email}`} style={{ color: 'blue', textDecoration: 'underline' }}>
                                      {contact.email}
                                    </a>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </Col>
                    </Row>
                  </>
                )}
                {renderRow("Single Finance/Accounting Contact:", printBool(profile.contactDetails.data.isSingle2), "contactDetails.data.isSingle2")}
                {profile.contactDetails.data.financeOrAccountingDetails && (
                  <>
                    <Row id='contactDetails.data.financeOrAccountingDetails' className="my-3">
                      <Col md={3}>Finance/Accounting:</Col>
                      <Col>
                        <table className='table table-custom'>
                          <thead>
                            <tr>
                              <th style={{ width: '30%' }}>Country</th>
                              <th style={{ width: '20%' }}>Name</th>
                              <th style={{ width: '20%' }}>Phone Number</th>
                              <th style={{ width: '30%' }}>Email</th>
                            </tr>
                          </thead>
                          <tbody>
                            {profile.contactDetails.data.financeOrAccountingDetails.map((contact, index) => {
                              const formattedPhone = `+${contact.code.replace(/[^\d]/g, '')}${contact.phoneNumber}`;
                              return (
                                <tr key={index}>
                                  <td>
                                    {profile.contactDetails.data.isSingle2
                                      ? profile.companyProfileStage.data.countriesProvideTour.map(country => country.label).join(',')
                                      : contact.country}
                                  </td>
                                  <td>{contact.name}</td>
                                  <td>
                                    <a href={`tel:${formattedPhone}`} style={{ color: 'blue', textDecoration: 'underline' }}>
                                      {formattedPhone}
                                    </a>
                                  </td>
                                  <td>
                                    <a href={`mailto:${contact.email}`} style={{ color: 'blue', textDecoration: 'underline' }}>
                                      {contact.email}
                                    </a>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </Col>
                    </Row>
                  </>
                )}
                {profile.contactDetails.data.officeSetupDetailsDuringOfficeHours && (
                  <Row id="contactDetails.data.officeSetupDetailsDuringOfficeHours" className="my-3 text-left">
                    <Col md={3}>Office Setup Details During Office Hours:</Col>
                    <Col md={9}>
                      <table className='table table-custom'>
                        <thead>
                          <tr>
                            <th style={{ width: '30%' }}>Country</th>
                            <th style={{ width: '20%' }}>Phone Number</th>
                            <th style={{ width: '30%' }}>WhatsApp Phone Number</th>
                          </tr>
                        </thead>
                        <tbody>
                          {profile.contactDetails.data.officeSetupDetailsDuringOfficeHours.map((details, index) => {
                            const formattedPhone1 = `+${details.code1.replace(/[^\d]/g, '')}${details.phoneNumber1}`;
                            const formattedPhone2 = `+${details.code2.replace(/[^\d]/g, '')}${details.phoneNumber2}`;
                            return (
                              <tr key={index}>
                                <td>{details.country}</td>
                                <td>
                                  <a href={`tel:${formattedPhone1}`} style={{ color: 'blue', textDecoration: 'underline' }}>
                                    {formattedPhone1}
                                  </a>
                                </td>
                                <td>
                                  <span style={{ marginRight: '8px' }}>
                                    {formattedPhone2}
                                  </span>
                                  <a href={`https://wa.me/${formattedPhone2}`} target="_blank" rel="noopener noreferrer">
                                    <img src={`${process.env.PUBLIC_URL}/images/whatsapp.svg`} alt="WhatsApp" style={{ width: '20px', height: '20px', cursor: 'pointer' }} />
                                  </a>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                )}

                {profile.contactDetails.data.officeSetupDetailsAfterOfficeHours && (
                  <Row id="contactDetails.data.officeSetupDetailsAfterOfficeHours" className="my-3 text-left">
                    <Col md={3}>Office Setup Details After Office Hours:</Col>
                    <Col md={9}>
                      <table className='table table-custom'>
                        <thead>
                          <tr>
                            <th style={{ width: '30%' }}>Country</th>
                            <th style={{ width: '20%' }}>Phone Number</th>
                            <th style={{ width: '30%' }}>WhatsApp Phone Number</th>
                          </tr>
                        </thead>
                        <tbody>
                          {profile.contactDetails.data.officeSetupDetailsAfterOfficeHours.map((details, index) => {
                            const formattedPhone1 = `+${details.code1.replace(/[^\d]/g, '')}${details.phoneNumber1}`;
                            const formattedPhone2 = `+${details.code2.replace(/[^\d]/g, '')}${details.phoneNumber2}`;
                            return (
                              <tr key={index}>
                                <td>{details.country}</td>
                                <td>
                                  <a href={`tel:${formattedPhone1}`} style={{ color: 'blue', textDecoration: 'underline' }}>
                                    {formattedPhone1}
                                  </a>
                                </td>
                                <td>
                                  <span style={{ marginRight: '8px' }}>
                                    {formattedPhone2}
                                  </span>
                                  <a href={`https://wa.me/${formattedPhone2}`} target="_blank" rel="noopener noreferrer">
                                    <img src={`${process.env.PUBLIC_URL}/images/whatsapp.svg`} alt="WhatsApp" style={{ width: '20px', height: '20px', cursor: 'pointer' }} />
                                  </a>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                )}

                <Row id='contactDetails.data.workingDays' className="my-3 text-left">
                  <Col md={3}>Working days:</Col>
                  <Col md={9}>
                    <table className="table table-custom">
                      <thead>
                        <tr>
                          <th style={{ width: '30%' }}>Country </th>
                          <th>Working Days</th>
                        </tr>
                      </thead>
                      <tbody>
                        {profile.contactDetails.data.workingDays.map((details, index) => (
                          <tr key={index}>
                            <td>{details.country}</td>
                            <td>{details.days.join(', ')}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Col>
                </Row>
                <Row id='contactDetails.data.bankDetails' className="my-3 text-left">
                  <Col md={3}>
                    <label>Bank Account Details with proof:</label>
                  </Col>
                  <Col md={9}>
                    <table className='table table-custom'>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Country</th>
                          <th>File</th>
                        </tr>
                      </thead>
                      <tbody>
                        {profile.contactDetails.data.bankDetails.map((info, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{(info.selectedCountry || []).join(', ')}</td>
                            <td>
                              <span
                                className="file-link"
                                style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                                onClick={() => handleDownload(profile.basicCompanyInfo.data.companyName, 'BankDetails', info.name)}
                              >
                                {info.name.length > 25 ? info.name.slice(0, 25) + '...' : info.name}
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Col>
                </Row>
                {renderRow("Can you accept payments for bookings by a secure credit card payment link?", printBool(profile.contactDetails.data.canAcceptPaymentsCreditCard), "contactDetails.data.canAcceptPaymentsCreditCard")}
              </Col>
            </Row>
            <hr style={{ height: '3px', backgroundColor: 'black', border: 'none' }} />

            {/* Packages & Tariff Upload */}
            <Row>
              <Col>
                <Row className="align-items-center mb-3">
                  <Col className="d-flex align-items-center">
                    <h2 className="mb-0">Packages & Tariff Upload</h2>
                    
                  </Col>
                </Row>
                {profile.packagesAndTariff.data.wantsToAddReady && (
                  <div className="mb-4">
                    <Row id="packagesAndTariff.data.readyMadePackages">
                      <Col md={3}>
                        <p className="mb-3">Ready Made Packages:</p>
                      </Col>
                      <Col md={9}>
                        <table className="table table-custom">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Country</th>
                              <th>File/URL</th>
                              <th>Start Date</th>
                              <th>End Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {profile.packagesAndTariff.data.readyMadePackages.map((pack, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{pack.country}</td>
                                {pack.url ? (
                                  <td>
                                    <p>
                                      <a
                                        href={ensureProtocol(pack.url)}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ color: 'blue', textDecoration: 'underline', marginLeft: '5px' }}
                                      >
                                        {pack.url.length > 25 ? pack.url.slice(0, 25) + '...' : pack.url}
                                      </a>
                                    </p>
                                  </td>
                                ) : (
                                  <td>
                                    <span
                                      className="file-link"
                                      style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                                      onClick={() => handleDownload(profile.basicCompanyInfo.data.companyName, 'ReadyMadePackages', pack.fileName)}
                                    >
                                      {pack.fileName.length > 25 ? pack.fileName.slice(0, 25) + '...' : pack.fileName}
                                    </span>
                                  </td>
                                )}
                                <td>{getStringBeforeT(pack.start)}</td>
                                <td>{getStringBeforeT(pack.end)}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </Col>
                    </Row>
                  </div>
                )}
                {profile.packagesAndTariff.data.wantsToAddItemized && (
                  <div className="mb-4">
                    <Row id="packagesAndTariff.data.itemizedTariffSheets">
                      <Col md={3}>
                        <p className="mb-3">Itemized Tariff Sheets:</p>
                      </Col>
                      <Col md={9}>
                        <table className="table table-custom">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Country</th>
                              <th>Service</th>
                              <th>File/URL</th>
                              <th>Start Date</th>
                              <th>End Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {profile.packagesAndTariff.data.itemizedTariffSheets.map((pack, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{pack.country}</td>
                                <td>{pack.service}</td>
                                {pack.url ? (
                                  <td>
                                    <p>
                                      <a
                                        href={ensureProtocol(pack.url)}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ color: 'blue', textDecoration: 'underline', marginLeft: '5px' }}
                                      >
                                        {pack.url.length > 25 ? pack.url.slice(0, 25) + '...' : pack.url}
                                      </a>
                                    </p>
                                  </td>
                                ) : (
                                  <td>
                                    <span
                                      className="file-link"
                                      style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                                      onClick={() => handleDownload(profile.basicCompanyInfo.data.companyName, 'ItemizedTariffSheets', pack.fileName)}
                                    >
                                      {pack.fileName.length > 25 ? pack.fileName.slice(0, 25) + '...' : pack.fileName}
                                    </span>
                                  </td>
                                )}
                                <td>{getStringBeforeT(pack.start)}</td>
                                <td>{getStringBeforeT(pack.end)}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </Col>
                    </Row>
                  </div>
                )}
                <Row id="packagesAndTariff.data.mediaLibraryLinks" className="my-3 text-left">
                  <Col md={3}>Media Library Links:</Col>
                  <Col>
                    {profile.packagesAndTariff.data.mediaLibraryLinks.map((url, index) => (
                      <p key={index}>
                        <a href={ensureProtocol(url.url)} target="_blank" rel="noopener noreferrer" style={{ color: 'blue', textDecoration: 'underline', marginLeft: '5px' }}>
                          {url.url.length > 25 ? url.url.slice(0, 25) + '...' : url.url}
                        </a>
                      </p>
                    ))}
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        )}
        {!profile && (
          <Row className="my-3">
            <Col>
              <p>Loading...</p>
            </Col>
          </Row>
        )}
      </div>

      <div className="full-width-container mr-5 mt-5">
  <div className="editor-container">
    <RichTextEditor
      htmlContent={htmlContent}
      setHTMLContent={setHTMLContent}
      placeholder="Type something here..."
    />
    <button onClick={handleSend} className="button">Send</button>
  </div>
  <div className="notes-container">
    {notes.slice().reverse().map(note => {
      console.log(`Rendering note ${note._id}:`, note);
      return (
        <div key={note._id} className="note">
          <div className="note-header" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>
              {note.user ? `${note.user.firstName} ${note.user.lastName}` : 'Unknown User'}
            </span>
            <span>
              {getFormattedDate(note.createdAt)}, {getFormattedTime(note.createdAt)}
            </span>
          </div>
          <div dangerouslySetInnerHTML={{ __html: note.content }}></div>
        </div>
      );
    })}
  </div>
</div>


    </div>
  );
};

export default ViewForm;
