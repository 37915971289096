import React from 'react'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { Button } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import Modal from "@mui/material/Modal";
import Box from '@mui/material/Box';
import ToolTip from "@mui/material/Tooltip";
import 'animate.css';
import API from '../API';
const Notifications = () => {
    const [notifications, setNotifications] = useState([]);
    const [animatingOut, setAnimatingOut] = useState({});
    const [openRescheduleModal, setOpenRescheduleModal] = useState(false);
    const [notificationIdInModal, setNotificationIdInModal] = useState(null);
    const fetchNotifications = async () => {
        const response = await API.get(`/ivisa/notifications`);
        setNotifications(response.data);
        // Initialize animatingOut state
        let initialAnimatingState = {};
        response.data.forEach(notification => {
            initialAnimatingState[notification._id] = false;
        });
        setAnimatingOut(initialAnimatingState);
    };

    const handleSubmit = async (id, notificationType) => {
        // Start the animation
        setAnimatingOut(prev => ({ ...prev, [id]: true }));
        // Wait for the animation to finish (adjust duration as needed)
        if (notificationType==='visa_reschedule') {
            setTimeout(async () => {
                await API.post(`/ivisa/approveReschedule/${id}`);
                fetchNotifications(); // Re-fetch or remove the notification from state
            }, 300); // This duration should match the CSS animation duration            
        }
    };

    const handlePreCancel = async (id, notificationType) => {
        if (notificationType==='visa_reschedule') {
            setOpenRescheduleModal(true);
            setNotificationIdInModal(id);
        }
    };

    const handleCancel = async () => {
        setOpenRescheduleModal(false);
        // Start the animation
        setAnimatingOut(prev => ({ ...prev, [notificationIdInModal]: true }));

        // Wait for the animation to finish (adjust duration as needed)
        setTimeout(async () => {
            await API.patch(`/ivisa/notifications/${notificationIdInModal}`, { notification_status: 'cancelled' });
            fetchNotifications(); // Re-fetch or remove the notification from state
        }, 500); // This duration should match the CSS animation duration
    };

    const createNewAppAndCancel = async() => {
        setOpenRescheduleModal(false);
        setAnimatingOut(prev => ({ ...prev, [notificationIdInModal]: true }));
        setTimeout(async () => {
            await API.post(`/ivisa/disapproveReschedule/${notificationIdInModal}`);
            fetchNotifications(); 
        }, 500);
    }

    const handleCloseRescheduleModal = () => {
        setOpenRescheduleModal(false);
        setNotificationIdInModal(null);
    };

    const parseISODate = (date) => {
        const parsedDate = new Date(date)
        // date and time
        return `${parsedDate.getFullYear()}-${parsedDate.getMonth() + 1}-${parsedDate.getDate()} ${parsedDate.getHours()}:${parsedDate.getMinutes()}:${parsedDate.getSeconds()}`
    }
    
    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        display: "flex",
        flexDirection: "column",
    };

    useEffect( () => {
        fetchNotifications()
    }
    , [])
    // Please confirm reschedule of {old_document['name']}, {old_document['reference_number']} from {old_document['visa_appointment_date']} to {entry['visa_appointment_date']}"
    // Please confirm reschedule of Test Name, Test-TUD28185933697/1 from 2025-10-04 10:20:00 to 2025-10-10 10:20:00
  return (
    <>
    <Modal open={openRescheduleModal} onClose={handleCloseRescheduleModal}>
        <Box sx={modalStyle}>
            Please select appropriate action
            <br /><br />
            <ToolTip title="CREATES A SEPARATE ENTRY">
                <Button variant="contained" color="primary" onClick={createNewAppAndCancel}>
                    1. This is a separate entry for a separate visa application
                </Button>
            </ToolTip>
            <br />
            <Button variant="contained" color="secondary" onClick={handleCancel}>
                2. Ignore this notification and take no action
            </Button>
        </Box>
    </Modal>    
    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "30px", padding: "5px", flexDirection: "column" }}>
        <h4 style={{ marginBottom: "20px", fontFamily: "monospace" }}><i>Notifications</i></h4>
        {notifications.length > 0 ? notifications.map((entry, index) => (
            <div key={index} className={`make-it-slow ${animatingOut[entry._id] ? 'animate__animated animate__slideOutRight' : ''}`} style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "10px", border: "1px solid black", margin: "5px" }}>
                <h6><i style={{ fontFamily: "monospace" }}>{parseISODate(entry.notification_created_at)}</i></h6>
                <h6 style={{ marginRight: "10px", marginLeft: "5px" }}><i>{entry.notification_str}</i></h6>
                <div>
                    <Button variant="contained" color="success" size='small' sx={{ marginRight: "5px" }} onClick={() => handleSubmit(entry._id, entry.notification_type)}>
                        <CheckIcon />
                    </Button>
                    <Button variant="contained" color="error" size='small' onClick={() => handlePreCancel(entry._id, entry.notification_type)}>
                        <CancelIcon />
                    </Button>
                </div>
            </div>
        )) : <h6 style={{ fontFamily: "monospace" }}>No notifications</h6>}
    <style>
        {`
        .make-it-slow {
            box-shadow: 0 1px 2px rgba(0,0,0,0.15);
            transition: box-shadow 0.001s ease-in-out, transform 0.001s ease, background-color 0.001s linear;
            background-color: white;
            padding: 20px;
            border-radius: 8px;
        }

        .make-it-slow:hover {
            box-shadow: 0 5px 15px rgba(0,0,0,0.3);
            transform: scale(1.03);
            background-color: #f8f8f8;
        }
        @media (max-width: 768px) {
            .make-it-slow {
                width: 100%;
                flex-direction: column;
            }
        }
        `}
        </style>
    </div>
    </>
  )
}


export default Notifications;