import React, { useState, useEffect } from "react";

import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  Icon,
  IconButton,
  Input,
  InputLabel,
  Menu,
  MenuItem,
  Modal,
  NativeSelect,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from "@mui/icons-material/Send";
import toast from "react-hot-toast";
import API from "../../Visa/API";
import { useParams } from "react-router-dom";
import SaveIcon from "@mui/icons-material/Save";
import countryCode from "../countryCode";

const VisaUpload = () => {
  const { id } = useParams();
  const [nationality, setNationality] = useState("");
  const [edit, setEdit] = useState(false);
  const [name, setName] = useState("");
  const [uid, setUid] = useState("");
  const [entryPermit, setEntryPermit] = useState("");
  const [sponserName, setSponserName] = useState("");
  const [dob, setDob] = useState("");
  const [issue_date, setIssueDate] = useState("");
  const [expiry_date, setExpiryDate] = useState("");
  const [communication, setCommunication] = useState([]);
  const [filesToUpload, setFilesToUpload] = useState({
    visa: [],
    insurance: [],
  });
  const [lastDatetoExit, setLastDateToExit] = useState(null);
  const [uploadedExtension, setUploadedExtension] = useState([]);
  const [extension, setExtension] = useState([]);
  const [duration, setDuration] = useState(30);
  const [pdfPreviewbase64, setPdfPreviewbase64] = useState(null);
  const [hubspotemail, setHubspotemail] = useState("");
  const [guarantorEmail, setGuarantorEmail] = useState("");
  const [validation, setValidation] = useState(false);
  const [hubspot_id, setHubspot_id] = useState(null);
  const [guarantor_id, setGuarantor_id] = useState(null);
  const [entry_date, setEntry_date] = useState("");
  const [exit_date, setExit_date] = useState("");
  const [visaFileName, setVisaFileName] = useState("");
  const [insuranceFileName, setInsuranceFileName] = useState("");
  const [visatype, setVisaType] = useState("");
  const [onedrive_url, setOnedriveUrl] = useState("");
  const [passport_number, setPassportNumber] = useState("");
  const [insurancefiletoupload,setInsurancefiletoupload] = useState([]);
  const createFileObjects = async () => {
    const visaFiles = filesToUpload?.visa || [];
    const insuranceFiles = filesToUpload?.insurance || [];

    const visaFileObjects =
      visaFiles.length > 0
        ? await Promise.all(
            visaFiles.map(async (file) => {
              const fileContent = await file.arrayBuffer();
              const fileContentBase64 = btoa(
                new Uint8Array(fileContent).reduce(
                  (data, byte) => data + String.fromCharCode(byte),
                  ""
                )
              );
              return {
                fileName: file.name,
                fileContent: fileContentBase64,
                fileType: file.type,
              };
            })
          )
        : [];

    const insuranceFileObjects =
      insuranceFiles.length > 0
        ? await Promise.all(
            insuranceFiles.map(async (file) => {
              const fileContent = await file.arrayBuffer();
              const fileContentBase64 = btoa(
                new Uint8Array(fileContent).reduce(
                  (data, byte) => data + String.fromCharCode(byte),
                  ""
                )
              );
              return {
                fileName: file.name,
                fileContent: fileContentBase64,
                fileType: file.type,
              };
            })
          )
        : [];

    return [...visaFileObjects, ...insuranceFileObjects];
  };
  const createExtensionObject = async () => {
    const extensionFiles = extension || [];
    const extensionFileObjects =
      extensionFiles.length > 0
        ? await Promise.all(
            extensionFiles.map(async (file) => {
              const fileContent = await file.arrayBuffer();
              const fileContentBase64 = btoa(
                new Uint8Array(fileContent).reduce(
                  (data, byte) => data + String.fromCharCode(byte),
                  ""
                )
              );
              return {
                fileName: file.name,
                fileContent: fileContentBase64,
                fileType: file.type,
              };
            })
          )
        : [];

    return [...extensionFileObjects];
  };
  const createTemporaryFileObject = async () => {
    const temporaryFiles = temporaryFile || [];
    const temporaryFileObjects =
      temporaryFiles.length > 0
        ? await Promise.all(
            temporaryFiles.map(async (file) => {  
              const fileContent = await file.arrayBuffer();
              const fileContentBase64 = btoa(
                new Uint8Array(fileContent).reduce(
                  (data, byte) => data + String.fromCharCode(byte),
                  ""
                )
              );
              return {
                fileName: file.name,
                fileContent: fileContentBase64,
                fileType: file.type,
              };
            })
          )
        : [];
        return [...temporaryFileObjects];
  };
  const createInsuranceFileObject = async () => {
    const insuranceFiles = insurancefiletoupload || [];
    
    if (insuranceFiles.length === 0) {
      // Handle case where no files are selected
      return [];
    }
  
    const insuranceFileObjects = await Promise.all(
      insuranceFiles.map(async (file) => {
        if (file) {
          const fileContent = await file.arrayBuffer();
          const fileContentBase64 = btoa(
            new Uint8Array(fileContent).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ""
            )
          );
          return {
            fileName: file.name,
            fileContent: fileContentBase64,
            fileType: file.type,
          };
        } else {
          // Handle the case where the file is undefined or null
          return null;
        }
      })
    );
  
    return insuranceFileObjects.filter(obj => obj !== null); // Filter out any null objects
  };
  
  const handleInsuranceFileUpload = async () => {
    if (!insurancefiletoupload.length > 0) {
      toast.error("Please select a file to upload");
      return;
    }
    const fileContentArray = await createInsuranceFileObject();
    toast.loading("Uploading file...");
    try {
      const response = await API.post(
        `/uaevisa/uploadInsurance`,
        { fileContentArray, id },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      toast.dismiss(); // Dismiss the loading toast
      toast.success("File uploaded successfully!");
    } catch (error) {
      toast.dismiss(); // Dismiss the loading toast
      toast.error("Error uploading file");
      console.error(error.response ? error.response.data : error.message);
    }
  }
  const handleExtensionUpload = async () => {
    if (!extension.length > 0) {
      toast.error("Please select a file to upload");
      return;
    }
    const fileContentArray = await createExtensionObject();
    toast.loading("Uploading file...");
    try {
      const response = await API.post(
        `/uaevisa/uaeExtensionUpload`,
        { fileContentArray, id, hubspotemail, guarantorEmail },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      toast.dismiss(); // Dismiss the loading toast
      toast.success("File uploaded successfully!");
    } catch (error) {
      toast.dismiss(); // Dismiss the loading toast
      toast.error("Error uploading file");
      console.error(error.response ? error.response.data : error.message);
    }
  };
  const handleTemporaryFileUpload = async () => {
    if (!temporaryFile.length > 0) {
      toast.error("Please select a file to upload");
      return;
    }
    const fileContentArray = await createTemporaryFileObject();
    toast.loading("Uploading file...");
    try {
      const response = await API.post(
        `/uaevisa/temporaryFileUpload`,
        { fileContentArray,id },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setPdfPreviewbase64(response.data.base64File);
      toast.dismiss(); // Dismiss the loading toast
      toast.success("File uploaded successfully!");
    } catch (error) {
      toast.dismiss(); // Dismiss the loading toast
      toast.error("Error uploading file");
      console.error(error.response ? error.response.data : error.message);
    }
  }
  const handleSendExtensionEmail = async () => {
    try{
      if(!hubspotemail && !guarantorEmail){
        toast.error("Please fill the email fields");
        return;
      }
      const response = await API.post("/uaevisa/sendExtensionEmail", {
        hubspotemail,
        guarantorEmail,
        id,
      });
      if (response.data.message === "Email sent successfully") {
        toast.success("Email sent successfully");
      }
    } catch (error) {
      toast.error("Error sending email");
    }
  }
  const handleUpload = async () => {
    if (!filesToUpload.visa.length > 0) {
      toast.error("Please select a file to upload");
    } else {
      const fileContentArray = await createFileObjects();

      toast.loading("Uploading file...");

      try {
        const response = await API.post(
          `/uaevisa/uaeVisaUploadFile`,
          { fileContentArray,id },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        toast.dismiss(); // Dismiss the loading toast
        toast.success("File uploaded successfully!");

        if (response.data.message === "File processed successfully") {
          setName(response.data.result.name);
          setUid(response.data.result.uid);
          setEntryPermit(response.data.result.entry_permit_number);
          setDob(response.data.result.date_of_birth);
          setIssueDate(response.data.result.issue_date);
          setExpiryDate(response.data.result.expiry_date);
          setVisaFileName(fileContentArray[0].fileName);
          setInsuranceFileName(
            fileContentArray[1] ? fileContentArray[1].fileName : ""
          );
          setVisaType(response.data.result.visa_type);
          setPassportNumber(response.data.result.passport_number);
          setNationality(response.data.result.nationality);
          let result = response.data.result.duration_of_visa.replace(
            /days|hours|Hours|Days/gi,
            ""
          );
          let finalResult = result.replace(/\s+/g, "");

          const duration = Number(finalResult);
          setDuration(duration);

          const base64File = await API.get("/uaevisa/getFile", {
            params: {
              fileName: fileContentArray[0].fileName,
            },
          });
          setPdfPreviewbase64(base64File.data.base64File);
        }
      } catch (error) {
        toast.dismiss(); // Dismiss the loading toast
        toast.error("Error uploading file");
        console.error(error.response ? error.response.data : error.message);
      }
    }
  };

  const handleSubmit = async () => {
    try {
      if (
        !name &&
        !uid &&
        !entryPermit &&
        !sponserName &&
        !dob &&
        !issue_date &&
        !expiry_date &&
        !duration &&
        (!hubspotemail || !guarantorEmail) &&
        !passport_number
      ) {
        toast.error("Please fill all the fields");
        return;
      }
      const fileContentArray = await createFileObjects();
      const response = await API.post("/uaevisa/uaeVisaUpdate", {
        name,
        uid,
        entryPermit,
        sponserName,
        dob,
        issue_date,
        expiry_date,
        duration,
        hubspot_id,
        entry_date,
        exit_date,
        hubspotemail,
        visaFileName,
        insuranceFileName,
        fileContentArray,
        guarantorEmail,
        guarantor_id,
        visa_type: visatype,
        onedrive_url,
        passport_number,
        nationality,
      });
      toast.success("Data updated successfully and email sent to the user");
    } catch (error) {
      toast.error("Error updating data");
    }
  };
  const handleVisaFileChange = (event) => {
    setFilesToUpload((prevState) => ({
      ...prevState,
      visa: [event.target.files[0]],
    }));
  };

  const handleInsuranceFileChange = (event) => {
    setFilesToUpload((prevState) => ({
      ...prevState,
      insurance: [event.target.files[0]],
    }));
  };
  const [open, setOpen] = useState(false);
  const handleHubspotEmail = async () => {
    try {
      if (!hubspotemail) {
        if(!guarantorEmail){
          setValidation(false);
        } else{
          setValidation(true);
        }
        return;
      }
      const response = await API.get(
        `/flights/user/hubspotemail/${hubspotemail}`
      );
      if (response.data) {
        toast.success("Hubspot Contact Found");
        setValidation(true);
        setHubspot_id(response.data.id);
      }
    } catch (error) {
      setOpen(true);
      
    }
  };
  const handleGuarantorEmail = async () => {
    try {
      if (!guarantorEmail) {
        if (!hubspotemail) {
          setValidation(false);
        } else{
          setValidation(true);
        }
        return;
      }
      const response = await API.get(
        `/flights/user/hubspotemail/${guarantorEmail}`
      );
      console.log(response.data);
      if (response.data) {
        toast.success("Hubspot Contact Found");
        setValidation(true);
        setGuarantor_id(response.data.id);
      }
    } catch (error) {
      setOpen(true);
    }
  };
  const handleEmail = async () => {
    try {
      if(!hubspotemail && !guarantorEmail){
        toast.error("Please fill the email fields");
        return;
      }
      const response = await API.post("/uaevisa/sendReleaseEmail", {
        hubspotemail,
        guarantorEmail,
        visaFileName,
        insuranceFileName,
        id,
        name,
      });
      if (response.data.message === "Email sent successfully") {
        toast.success("Email sent successfully");
      }
    } catch (error) {
      toast.error("Error sending email");
    }
  };
  const handleSave = async () => {
    try {
      if (
        !name &&
        !uid &&
        !entryPermit &&
        !sponserName &&
        !dob &&
        !issue_date &&
        !expiry_date &&
        !duration &&
        !hubspotemail &&
        !passport_number &&
        !nationality && staff === ""
      ) {
        toast.error("Please fill all the fields");
        return;
      }
      const response = await API.post("/uaevisa/uaeVisaUpdate/save", {
        id,
        name,
        uid,
        entryPermit,
        sponserName,
        dob,
        issue_date,
        expiry_date,
        duration,
        hubspot_id,
        entry_date,
        exit_date,
        hubspotemail,
        lastDatetoExit,
        visaFileName,
        insuranceFileName,
        guarantorEmail,
        guarantor_id,
        visa_type: visatype,
        onedrive_url,
        passport_number,
        nationality,
        staff_name: staff,
      });
      toast.success("Data updated successfully");
    } catch (error) {
      toast.error("Error updating data");
    }
  };
  const handleInsuranceFileChanger = (event) => {
    // Ensure the event has files and assign them to the state
    if (event.target.files && event.target.files.length > 0) {
      setInsurancefiletoupload([event.target.files[0]]);
    } else {
      setInsurancefiletoupload([]);
    }
  };
  
  const handleExtensionFileChange = (event) => {
    setExtension([event.target.files[0]]);
  };
  const [temporaryFile, setTemporaryFile] = useState([]);
  const handleTemporaryFileChange = (event) => {
    setTemporaryFile([event.target.files[0]]);
  };
  useEffect(() => {
    if (id) {
      setEdit(true);
      // Load the visa details from the server using the id
      const fetchVisaDetails = async () => {
        try {
          const response = await API.get(`/uaevisa/getVisaDetails/${id}`);

          const visa = response.data;
          const base64File = await API.get("/uaevisa/getFile", {
            params: {
              fileName: visa.visaFileName,
            },
          });
          setPdfPreviewbase64(base64File.data.base64File || null);
          setName(visa.name);
          setUid(visa.uid);
          setEntryPermit(visa.entryPermit);
          setSponserName(visa.sponserName);
          setDob(visa.dob?.split("T")[0]);
          setIssueDate(visa.issue_date?.split("T")[0]);
          setExpiryDate(visa.expiry_date?.split("T")[0]);
          setVisaFileName(visa.visaFileName);
          setInsuranceFileName(visa.insuranceFileName);
          setVisaType(visa.visa_type);
          setDuration(visa.duration);
          setLastDateToExit(visa.last_date_to_exit?.split("T")[0]); 
          setEntry_date(visa.entry_date?.split("T")[0]);
          setExit_date(visa.exit_date?.split("T")[0]);
          setHubspot_id(visa.hubspot_id);
          setGuarantor_id(visa.guarantor_id);
          setOnedriveUrl(visa.onedrive_url);
          setPassportNumber(visa.passport_number);
          setCommunication(visa.communication);
          setNationality(visa.nationality);
          setUploadedExtension(visa.extension);
          setStaff(visa.staff_name);
          if (visa.hubspot_id && visa.hubspot_id > 0) {
            const response = await API.get(
              `/flights/user/hubspot/${visa.hubspot_id}`
            );
            setHubspotemail(response.data.properties.email);
            setValidation(true);
          }
          if (visa.guarantor_id && visa.guarantor_id > 0) {
            const response = await API.get(
              `/flights/user/hubspot/${visa.guarantor_id}`
            );
            setGuarantorEmail(response.data.properties.email);
            setValidation(true);
          }
        } catch (error) {
          toast.error("Error fetching visa details");
          console.error(error);
        }
      };

      fetchVisaDetails();
    }
  }, [id]);
  const [staff, setStaff] = useState("");
  const handleDeleteExtension = (file) => async () => {
    try {
      if(window.confirm("Are you sure that you want to delete this extension")){
        const response = await API.post("/uaevisa/deleteExtension", {
          id,
          fileName: file.fileName,
        });
        if (response.data.message === "Extension deleted successfully") {
          toast.success("File deleted successfully");
          setUploadedExtension((prevState) =>
            prevState.filter((ext) => ext.fileName !== file.fileName)
          );
        }
      } else{return;}
    } catch (error) {
      toast.error("Error deleting file");
    }
  }
  return (
    <>
      <div
        className="container"
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          justifyContent: "center",
          height: "80vh",
          marginTop: "40px",
          gap: "40px",
        }}
      >
        <div style={{ flex: 1 ,width:"100%"}}>
          {!edit && (
            <>
              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px" }}>
                  <InputLabel required focused>Upload Visa</InputLabel>
                  <Input type="file" onChange={handleVisaFileChange} />
                </div>
                <div>
                  <InputLabel focused>Upload Insurance</InputLabel>
                  <Input type="file" onChange={handleInsuranceFileChange} />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="contained"
                  endIcon={<SendIcon />}
                  onClick={handleUpload}
                  sx={{ m: 2 }}
                >
                  Upload
                </Button>
              </div>
            </>
          )}

          <Grid
            container
            spacing={2}
            style={{ width: "100%", marginTop: "5px" }}
          >
            <Grid item xs={12} sm={6}>
              <InputLabel required focused>Visa Holder Name</InputLabel>
              <Input
                fullWidth
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel required focused>Visa Holder UID</InputLabel>
              <Input
                fullWidth
                value={uid}
                onChange={(e) => {
                  setUid(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel required focused>Entry Permit Number</InputLabel>
              <Input
                fullWidth
                value={entryPermit}
                onChange={(e) => {
                  setEntryPermit(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel required focused>Sponsor Name</InputLabel>
              <Select value={sponserName} fullWidth onChange={(e) => setSponserName(e.target.value)}>
                <MenuItem value="Hadaf">Hadaf</MenuItem>
                <MenuItem value="Cozmo">Cozmo</MenuItem>
                <MenuItem value="Al Rais">Al Rais</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel required focused>Date of Birth</InputLabel>
              <Input
                type="date"
                fullWidth
                value={dob}
                onChange={(e) => setDob(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel required focused>Issue Date</InputLabel>
              <Input
                type="date"
                fullWidth
                value={issue_date}
                onChange={(e) => setIssueDate(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel required focused>Expiry Date</InputLabel>
              <Input
                type="date"
                fullWidth
                value={expiry_date}
                onChange={(e) => setExpiryDate(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel required focused>Duration</InputLabel>
              <NativeSelect
                value={duration}
                onChange={(e) => setDuration(e.target.value)}
              >
                <option defaultChecked value={null}>
                  --
                </option>
                <option value={10}>10 Days</option>
                <option value={14}>14 Days</option>
                <option value={30}>30 Days</option>
                <option value={60}>60 Days</option>
                <option value={90}>90 Days</option>
                <option value={96}>96 Hours</option>
                <option value={48}>48 Hours</option>
              </NativeSelect>
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel focused>Entry Date</InputLabel>
              <Input
                type="date"
                fullWidth
                value={entry_date}
                onChange={(e) => setEntry_date(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel focused>Exit Date</InputLabel>
              <Input
                type="date"
                fullWidth
                value={exit_date}
                onChange={(e) => setExit_date(e.target.value)}
              />
            </Grid>
            
            <Grid item xs={12} sm={6}>
              <InputLabel required focused>Passport Number</InputLabel>
              <Input
                fullWidth
                value={passport_number}
                onChange={(e) => setPassportNumber(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                disablePortal
                options={countryCode}
                sx={{ width: 250 }}
                value={nationality ? { label: nationality } : null}
                onChange={(event, value) =>
                  setNationality(value ? value.label : "")
                }
                renderInput={(params) => (
                  <TextField {...params} label="Nationality" />
                )}
              />
            </Grid>
            {edit && (
              <Grid item xs={12} sm={6}>
              <InputLabel focused>Last Date to Exit</InputLabel>
              <Input fullWidth type="date" value={lastDatetoExit} onChange={(e) => setLastDateToExit(e.target.value)} />
            </Grid>
            )}
            <Grid item xs={12} sm={6}>
              <InputLabel focused>Visitor Email</InputLabel>
              <TextField
                fullWidth
                type="email"
                color="success"
                value={hubspotemail}
                onChange={(e) => {
                  setHubspotemail(e.target.value);
                }}
                onBlur={(e) => {
                  handleHubspotEmail();
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth sx={{mt:4}}>
              <InputLabel focused>Staff</InputLabel>
              <Select label="Staff" value={staff} onChange={(e) => setStaff(e.target.value)}>
                <MenuItem value="Clarisse Suraide">Clarisse Suraide</MenuItem>
                <MenuItem value="Jhonatela Cascas">Jhonatela Cascas</MenuItem>
                <MenuItem value="Alyn Fundal">Alyn Fundal</MenuItem>
              </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel focused>Guarantor Email</InputLabel>
              <TextField
                fullWidth
                type="email"
                color="success"
                value={guarantorEmail}
                onChange={(e) => {
                  setGuarantorEmail(e.target.value);
                }}
                onBlur={(e) => {
                  handleGuarantorEmail();
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel focused>One Drive URL</InputLabel>
              <TextField
                fullWidth
                type="text"
                color="success"
                value={onedrive_url}
                onChange={(e) => {
                  setOnedriveUrl(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              {!edit ? (
                <Button
                  variant="contained"
                  endIcon={<SendIcon />}
                  onClick={handleSubmit}
                  sx={{ mt: 1, mb: 2 }}
                  fullWidth
                  disabled={!validation}
                >
                  Submit
                </Button>
              ) : (
                <Button
                  variant="contained"
                  endIcon={<SaveIcon />}
                  onClick={handleSave}
                  sx={{ mt: 1, mb: 2 }}
                  fullWidth
                  disabled={!validation}
                >
                  Save
                </Button>
              )}
            </Grid>
            <hr style={{border:"solid 2px black",
width: "100%",
color: "black",
backgroundColor: "black",
height: "1px"}}/>
            <Grid item xs={12}>
              {edit && (
                <div style={{ display:"flex",marginRight: "10px" }}>
                <div>
                  <InputLabel focused>Temporary File Upload</InputLabel>
                  <Input type="file" onChange={handleTemporaryFileChange} />
                </div>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleTemporaryFileUpload}
                    endIcon={<SendIcon />}
                  >
                    Upload File
                  </Button>
                </div>
              </div>
              )}
            </Grid>
            <Grid item xs={12} sx={{marginBottom:"20px"}}>
              {edit && (
                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: "10px" }}>
                    <InputLabel focused>Upload Extension</InputLabel>
                    <Input type="file" onChange={handleExtensionFileChange} />
                  </div>
                  <div style={{ margin: "10px",display:"flex",alignItems:"center",justifyContent:"center" }}>
                    <Button
                      variant="contained"
                      onClick={handleExtensionUpload}
                      endIcon={<SendIcon />}
                    >
                      Upload Extension
                    </Button>
                    <Button  style={{marginLeft:"5px"}} onClick={handleSendExtensionEmail} endIcon={<SendIcon/>}>
                       Extension Email
                    </Button>
                  </div>
                </div>
              )}
              
            </Grid>
            <Grid item xs={12} sx={{marginBottom:"20px"}}>
              {edit && (
                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: "10px" }}>
                    <InputLabel focused>Upload Insurance</InputLabel>
                    <Input type="file" onClick={handleInsuranceFileChanger} />
                  </div>
                  <div style={{ margin: "10px",display:"flex",alignItems:"center",justifyContent:"center" }}>
                    <Button
                      variant="contained"
                      onClick={handleInsuranceFileUpload}
                      endIcon={<SendIcon />}
                    >
                      Upload Insurance
                    </Button>
                  </div>
                </div>
              )}
              
            </Grid>
            {communication
                .filter((comm) => comm.comm_id === "VISA_EXTENSION_EMAIL")
                .map((filteredComm) => {
                  return (
                    <Grid
                      sx={{ mt: 1 }}
                      item
                      xs={12} 
                      key={filteredComm.comm_id}
                    >
                      <InputLabel focused>
                        {filteredComm.comm_id} {": Sent on"}{" "}
                        {new Date(filteredComm.comm_date).toLocaleDateString(
                          "en-GB"
                        )}
                      </InputLabel>
                    </Grid>
                  );
                })}
                <Grid item xs={12}><Typography variant="subtitle2">Uploaded Extension Files</Typography></Grid>
                {uploadedExtension && uploadedExtension.length > 0 ? uploadedExtension.map((file) => {
                  return (
                    <Grid sx={{display:"flex",alignItems:"center"}} item xs={12} key={file.fileName}>
                      <IconButton onClick={handleDeleteExtension(file)}><DeleteIcon/></IconButton>
                      <Typography>{file.fileName}</Typography>
                    </Grid>
                  )
                }) : <Grid item xs={12}><Typography>No Extension Files Uploaded</Typography></Grid>}  
          </Grid>
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              height: "700px",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {!pdfPreviewbase64 && edit && <div className="loader"></div>}
            {pdfPreviewbase64 && (
              <object
                data={`data:application/pdf;base64,${pdfPreviewbase64}`}
                type="application/pdf"
                width="100%"
                height="100%"
              >
                <embed
                  width="100%"
                  height="100%"
                  src={`data:application/pdf;base64,${pdfPreviewbase64}`}
                  type="application/pdf"
                />
              </object>
            )}
          </div>
          {edit && (
            <>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  sx={{ mt: 4 }}
                  endIcon={<SendIcon />}
                  onClick={handleEmail}
                  variant="contained"
                >
                  Send Release Email
                </Button>
              </Grid>
              {communication
                .filter((comm) => comm.comm_id === "VISA_RELEASE_EMAIL")
                .map((filteredComm) => {
                  return (
                    <Grid
                      sx={{ mt: 1 }}
                      item
                      xs={12}
                      key={filteredComm.comm_id}
                    >
                      <InputLabel focused>
                        {filteredComm.comm_id} {": Sent on"}{" "}
                        {new Date(filteredComm.comm_date).toLocaleDateString(
                          "en-GB"
                        )}
                      </InputLabel>
                    </Grid>
                  );
                })}
            </>
          )}
          <ModalAddContact open={open} handleClose={() => setOpen(false)} setHubspot_id={setHubspot_id} hubspotemail={hubspotemail} date_of_birth={dob} contact_name={name} national={nationality}/>
        </div>
        <style>
          {`
        @media (max-width: 600px) {
          .container {
            flex-direction: column;
            height: auto;
            width: 90vw;
            padding: 20px;
            gap: 20px;
            }
            .container > div {
              flex-direction: column;
              align-items: stretch;
              }
              .container input, .container button, .container select {
                font-size: 16px;
                }
                .dropzone {
                  padding: 20px;
                  font-size: 14px;
                  }
                  button, select {
                    min-height: 44px;
                    font-size: 16px;
                    }
                    input {
                      margin-left: 10px;
                      }
                      }
.loader {
  width: 80px;
  aspect-ratio: 2;
  --c:linear-gradient(#FFD412 25%,#ABD406 0 50%,#FF821C 0 75%,#FFD412 0);
  background: var(--c), var(--c), var(--c), var(--c);
  background-size: 26% 400%;
  background-position: calc(0*100%/3) 100%,calc(1*100%/3) 100%,calc(2*100%/3) 100%,calc(3*100%/3) 100%;
  background-repeat: no-repeat;
  animation: l10 2s infinite; 
}
@keyframes l10 {
  0%     {background-position: calc(0*100%/3) calc(3*100%/3),calc(1*100%/3) calc(3*100%/3),calc(2*100%/3) calc(3*100%/3),calc(3*100%/3) calc(3*100%/3)}
  8.33%  {background-position: calc(0*100%/3) calc(2*100%/3),calc(1*100%/3) calc(3*100%/3),calc(2*100%/3) calc(3*100%/3),calc(3*100%/3) calc(3*100%/3)}
  16.67% {background-position: calc(0*100%/3) calc(2*100%/3),calc(1*100%/3) calc(2*100%/3),calc(2*100%/3) calc(3*100%/3),calc(3*100%/3) calc(3*100%/3)}
  25%    {background-position: calc(0*100%/3) calc(2*100%/3),calc(1*100%/3) calc(2*100%/3),calc(2*100%/3) calc(2*100%/3),calc(3*100%/3) calc(3*100%/3)}
  30%,
  33.33% {background-position: calc(0*100%/3) calc(2*100%/3),calc(1*100%/3) calc(2*100%/3),calc(2*100%/3) calc(2*100%/3),calc(3*100%/3) calc(2*100%/3)}
  41.67% {background-position: calc(0*100%/3) calc(1*100%/3),calc(1*100%/3) calc(2*100%/3),calc(2*100%/3) calc(2*100%/3),calc(3*100%/3) calc(2*100%/3)}
  50%    {background-position: calc(0*100%/3) calc(1*100%/3),calc(1*100%/3) calc(1*100%/3),calc(2*100%/3) calc(2*100%/3),calc(3*100%/3) calc(2*100%/3)}
  58.33% {background-position: calc(0*100%/3) calc(1*100%/3),calc(1*100%/3) calc(1*100%/3),calc(2*100%/3) calc(1*100%/3),calc(3*100%/3) calc(2*100%/3)}
  63%,
  66.67% {background-position: calc(0*100%/3) calc(1*100%/3),calc(1*100%/3) calc(1*100%/3),calc(2*100%/3) calc(1*100%/3),calc(3*100%/3) calc(1*100%/3)}
  75%    {background-position: calc(0*100%/3) calc(0*100%/3),calc(1*100%/3) calc(1*100%/3),calc(2*100%/3) calc(1*100%/3),calc(3*100%/3) calc(1*100%/3)}
  83.33% {background-position: calc(0*100%/3) calc(0*100%/3),calc(1*100%/3) calc(0*100%/3),calc(2*100%/3) calc(1*100%/3),calc(3*100%/3) calc(1*100%/3)}
  91.67% {background-position: calc(0*100%/3) calc(0*100%/3),calc(1*100%/3) calc(0*100%/3),calc(2*100%/3) calc(0*100%/3),calc(3*100%/3) calc(1*100%/3)}
  97%,
  100%   {background-position: calc(0*100%/3) calc(0*100%/3),calc(1*100%/3) calc(0*100%/3),calc(2*100%/3) calc(0*100%/3),calc(3*100%/3) calc(0*100%/3)}
}`}
        </style>
      </div>
    </>
  );
};
const ModalAddContact = ({ open, handleClose, setHubspot_id, hubspotemail, date_of_birth, contact_name, national }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [dob, setDob] = useState("");
  const [phone, setPhone] = useState("");
  const [nationality, setNationality] = useState("");

  useEffect(() => {
    if (open) {
      setEmail(hubspotemail);
      setName(contact_name);
      setDob(date_of_birth);
      setNationality(national);
    }
  }, [open, hubspotemail, contact_name, date_of_birth, national]);

  const handleSubmit = async () => {
    try {
      if (!email && !name && !phone && !dob && !nationality) {
        toast.error("Please fill all the fields");
        return;
      }
      const response = await API.post("/uaevisa/createContact", {
        email: email,
        firstname: name,
        phone: phone,
        date_of_birth: dob,
        nationality: nationality,
      });
      if (response.status === 200) {
        toast.success("Contact created successfully");
        setHubspot_id(response.data.id);
        handleClose();
      }
    } catch (error) {
      toast.error("Error creating contact");
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={{ ...style, width: 400 }}>
        <h2 id="parent-modal-title" style={{ margin: "10px" }}>
          Create a Contact
        </h2>
        <InputLabel focused>Email</InputLabel>
        <Input
          fullWidth
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <InputLabel focused>Name</InputLabel>
        <Input
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <InputLabel focused>Phone</InputLabel>
        <Input
          fullWidth
          placeholder="9715xxxxxxx"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
        <InputLabel focused>Date of Birth</InputLabel>
        <Input
          type="date"
          fullWidth
          value={dob}
          onChange={(e) => setDob(e.target.value)}
          style={{ marginBottom: "10px" }}
        />
        <Autocomplete
          disablePortal
          options={countryCode}
          sx={{ width: 250 }}
          value={nationality ? { label: nationality } : null}
          onChange={(event, value) =>
            setNationality(value ? value.label : "")
          }
          renderInput={(params) => (
            <TextField {...params} label="Nationality" />
          )}
        />

        <Button sx={{ mt: 2 }} variant="contained" fullWidth color="primary" onClick={handleSubmit}>
          Save
        </Button>
        <Button
          sx={{ mt: 2 }}
          variant="contained"
          fullWidth
          color="secondary"
          onClick={handleClose}
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
};

export default VisaUpload;
