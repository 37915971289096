import React from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
const UaeVisaNavbar = () => {
  return (
    <>
      <Navbar bg="dark" expand="lg" variant="dark">
        <Container>
          <Navbar.Brand href="/">
            <img
              src="	https://marcopolo-holidays.com/wp-content/uploads/2021/08/Marcopolo-Grey-transparent-logo-200-px.png"
              width="120px"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav>
              <a href="/uaevisa/home" style={{ margin: "10px" }}>
                Home
              </a>
              <a href="/uaevisa/uploadpdf" style={{ margin: "10px" }}>
                UAE Visa Upload
              </a>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <style>
        {`
            .me-auhref a {
                color: white;
                text-decoration: none;
                }
                .me-auhref a:hover {
                    color: grey;
                    }`}
      </style>
    </>
  );
};

export default UaeVisaNavbar;
