import React, { useState } from 'react';
// import '../css/DMCInvite.css';
import { Tabs, Tab, Box, Typography, Grid, TextField, Button } from '@mui/material';
import API from "../../Visa/API";
import toast from 'react-hot-toast';

const InviteDMC = () => {
  const [DMCEmail, setDMCEmail] = useState('');
  const [DMCName, setDMCName] = useState('');
  const [DMCRepName, setDMCRepName] = useState('');
  const [selectedTab, setSelectedTab] = useState(0);
  const [ownEmail, setOwnEmail] = useState('');

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const isEmailValid = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  }

  const handleSubmitOwn = async () => {
    if (!isEmailValid(DMCEmail)) {
      return toast.error('Please enter a valid email address');
    }
    if (!DMCEmail || !DMCName || !DMCRepName) {
      return toast.error('Please enter DMC Name, Email, and Representative Name');
    }
    try {
      const initialRes = await API.get('/DMC/staff-email', { withCredentials: true });
      const AgentEmail = initialRes.data.email;
      const AgentName = initialRes.data.name;
      const response = await API.post('/DMC/invite-agent', { 
        DMCEmail: DMCEmail,
        DMCName: DMCName,
        DMCRepName: DMCRepName,
        AgentEmail: AgentEmail,
        AgentName: AgentName
      }, { withCredentials: true });

      console.log('Response:', response);

      if (response.status === 200) {
        alert(response.data.message); // Ensure using response.data.message
      } 
    } catch (error) {
      if (error.response) {
        // Server responded with a status other than 2xx
        if (error.response.data.error) {
          toast.error(error.response.data.error);
        }
      } else if (error.request) {
        // Request was made but no response received
        console.error('Error request:', error.request);
      } else {
        // Something else caused the error
        console.error('Error:', error.message);
      }
    }
  };

  const handleSubmit = async () => {
    if (!isEmailValid(DMCEmail)) {
      return toast.error('Please enter a valid email address');
    }
    if (selectedTab === 0) {
      if (!DMCEmail || !DMCName || !DMCRepName) {
        return toast.error('Please enter DMC Name, Email, and Representative Name');
      }
    } else {
      if (!DMCEmail) {
        return toast.error('Please enter DMC Email');
      }
    }
    try {
      const response = await API.post('/DMC/invite', { 
        DMCEmail: DMCEmail,
        DMCName: DMCName,
        DMCRepName: DMCRepName,
        isUpdate: selectedTab === 1 
      }, { withCredentials: true });
  
      console.log('Response:', response);
  
      if (response.status === 200) {
        alert(response.data.message); // Ensure using response.data.message
      } 
    } catch (error) {
      if (error.response) {
        // Server responded with a status other than 2xx
        if (error.response.status === 404) {
          alert('Error sending invite: No profile found with this email!');
        } else if (error.response.data.error) {
          toast.error(error.response.data.error);
        }
      } else if (error.request) {
        // Request was made but no response received
        console.error('Error request:', error.request);
      } else {
        // Something else caused the error
        console.error('Error:', error.message);
      }
    }
  };
  
  return (
    <Box sx={{ width: '100%' }}>
      <Tabs value={selectedTab} onChange={handleTabChange} centered>
        <Tab label="Invite a new DMC" />
        <Tab label="Request Update to existing DMC" />
        <Tab label="Self Register a DMC" />
      </Tabs>
      <TabPanel value={selectedTab} index={0}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h6">Invite a new DMC</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField 
              label="DMC Name" 
              variant="standard" 
              className='m-3'
              required
              value={DMCName}
              onChange={(e) => setDMCName(e.target.value)}
              fullWidth               
            />
          </Grid>
          <Grid item xs={12}>
            <TextField 
              label="DMC Representative Email" 
              variant="standard" 
              className='m-3'
              required
              value={DMCEmail}
              onChange={(e) => setDMCEmail(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField 
              label="DMC Representative Name" 
              variant="standard" 
              className='m-3'
              required
              value={DMCRepName}
              onChange={(e) => setDMCRepName(e.target.value)}
              fullWidth
            />
          </Grid>            
          <Grid item xs={12}>
            <Button onClick={handleSubmit} className="invite-button">
              Send Email Invite
            </Button>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={selectedTab} index={1}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h6">Request Update to existing DMC</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField 
              label="DMC Representative Email" 
              variant="standard" 
              className='m-3'
              required
              value={DMCEmail}
              onChange={(e) => setDMCEmail(e.target.value)}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container justifyContent="center" sx={{ marginTop: 2 }}>
          <Grid item xs={12}>
            <Button onClick={handleSubmit} className="invite-button">
              Send Email Invite
            </Button>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={selectedTab} index={2}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h6">Self Register a DMC</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField 
              label="DMC Name" 
              variant="standard" 
              className='m-3'
              required
              value={DMCName}
              onChange={(e) => setDMCName(e.target.value)}
              fullWidth               
            />
          </Grid>
          <Grid item xs={12}>
            <TextField 
              label="DMC Representative Email" 
              variant="standard" 
              className='m-3'
              required
              value={DMCEmail}
              onChange={(e) => setDMCEmail(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField 
              label="DMC Representative Name" 
              variant="standard" 
              className='m-3'
              required
              value={DMCRepName}
              onChange={(e) => setDMCRepName(e.target.value)}
              fullWidth
            />
          </Grid>            
          <Grid item xs={12}>
            <Button onClick={handleSubmitOwn} className="invite-button">
              Send Email Invite to logged in EMail
            </Button>
          </Grid>
        </Grid>
      </TabPanel>
    </Box>    
  );
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
};

export default InviteDMC;
