import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import Pagination from "../components/FlightPagination";
import UserTable1 from "../components/FlightUserTable1";
import { useSelector, useDispatch } from "react-redux";
import { getDataStart, getDataSuccess } from "../dataSlice";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import MultipleSelectCheckmarks from "../components/FlightMultipleSelect";
import RefreshIcon from "@mui/icons-material/Refresh";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import toast from "react-hot-toast";
import API from '../../Visa/API'

const itemsPerPage = 10;
const FlightDeletedUsers = () => {
 
  const dispatch = useDispatch();

  const data = useSelector((state) => state.data.data);
  const filter = useSelector((state) => state.filter.filter);
  const loading = useSelector((state) => state.data.loading);
  const [currentPage, setCurrentPage] = useState(1);

  const isFilterActive = (filterName) => filter.includes(filterName);
  const handleNameClick = async (name) => {
    setSearch(name); // Update the search bar with the clicked name
    setSearch2("");
    setSearch4("");
    setSearch3("");
    setSearch5("");
    setSearch6("");
    setSearch1("");
    await submitSearch(name); // Implement a search submission function
  };
  const handlePNRClick = async (pnr) => {
    setSearch1(pnr); // Update the search bar with the clicked name
    setSearch(""); // Update the search bar with the clicked name
    setSearch2("");
    setSearch4("");
    setSearch3("");
    setSearch5("");
    setSearch6("");
    await submitPNRSearch(pnr); // Implement a search submission function
  };
  const submitPNRSearch = async (pnr) => {
    dispatch(getDataStart());
    const response = await API.get(
      `/flights/search?pnr=${pnr}&token=${localStorage.getItem(
        "authToken"
      )}`
    );
    dispatch(getDataSuccess(response.data));
  };

  const submitSearch = async (name) => {
    dispatch(getDataStart());
    const response = await API.get(
      `/flights/search?name=${name}&token=${localStorage.getItem(
        "authToken"
      )}`
    );
    dispatch(getDataSuccess(response.data));
  };
  const fetchdata = async () => {
    dispatch(getDataStart());
    const response = await API.get(
      `/flights/tickets/deleted?token=${localStorage.getItem(
        "authToken"
      )}`
    );
    dispatch(getDataSuccess(response.data));
  };
  const handleDelete = async (selectedRows) => {
    if (selectedRows && selectedRows.length > 0) {
      try {
        await API.get(
          `/flights/user?selectedRows=${selectedRows}&token=${localStorage.getItem(
            "authToken"
          )}`
        );
        // Show a success alert
        toast.success("User(s) status changed to deleted successfully");

        // Refresh the window after a brief delay
        setTimeout(() => {
          window.location.reload();
        }, 500); // Refresh after 1 second (adjust the delay as needed)
      } catch (err) {
        console.error("Error deleting Users", err);
        toast.error(
          "Error changing status to deleted. Please try again after a few moments."
        );
      }
    } else {
      alert("Please select a row to change status");
    }
  };
  const handleRefund = async (selectedRows) => {
    if (selectedRows && selectedRows.length > 0) {
      try {
        const response = API.get(
          `/flights/user/refund?selectedRows=${selectedRows}&token=${localStorage.getItem(
            "authToken"
          )}`
        );
        // Show a success alert
        toast.promise(response, {
          loading: "Refunding...",
          success: "User(s) status changed to refunded successfully",
          error: "Error refunding",
        });
        setTimeout(() => {
          window.location.reload();
        }, 500); // Refresh after 1 second (adjust the delay as needed)
      } catch (err) {
        console.error("Error deleting Users", err);
        toast.error(
          "Error changing status to refunded. Please try again after a few moments."
        );
      }
    } else {
      alert("Please select a row to change status");
    }
  };
  const handleCancel = async (selectedRows) => {
    if (selectedRows && selectedRows.length > 0) {
      try {
        const response = API.get(
          `/flights/user/cancel?selectedRows=${selectedRows}&token=${localStorage.getItem(
            "authToken"
          )}`
        );
        // Show a success alert
        toast.promise(response, {
          loading: "Cancelling...",
          success: "User(s) status changed to cancelled successfully",
          error: "Error cancelling",
        });
        setTimeout(() => {
          window.location.reload();
        }, 500); // Refresh after 1 second (adjust the delay as needed)
      } catch (err) {
        console.error("Error deleting Users", err);
        toast.error(
          "Error changing status to confirmed. Please try again after a few moments."
        );
      }
    } else {
      alert("Please select a row to change status");
    }
  };
  const handleConfirm = async (selectedRows) => {
    if (selectedRows && selectedRows.length > 0) {
      try {
        const response = API.get(
          `/flights/user/confirm?selectedRows=${selectedRows}&token=${localStorage.getItem(
            "authToken"
          )}`
        );
        // Show a success alert
        toast.promise(response, {
          loading: "Confirming...",
          success: "User(s) status changed to confirmed successfully",
          error: "Error confirming",
        });

        setTimeout(() => {
          window.location.reload();
        }, 500); // Refresh after 1 second (adjust the delay as needed)
      } catch (err) {
        console.error("Error deleting Users", err);
        toast.error(
          "Error changing status to confirmed. Please try again after a few moments."
        );
      }
    } else {
      alert("Please select a row to change status");
    }
  };

  useEffect(() => {
    fetchdata();
  }, []);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedData = data.slice(startIndex, endIndex);
  const currentDate = new Date();
  const day = currentDate.getDate();
  const month = currentDate.getMonth() + 1; // Months are zero-based
  const year = currentDate.getFullYear();
  const formattedDate = `${day}/${month}/${year}`;
  const [search, setSearch] = useState("");
  const [search1, setSearch1] = useState("");
  const [search2, setSearch2] = useState(formattedDate);
  const [search3, setSearch3] = useState("");
  const [search4, setSearch4] = useState("");
  const [search5, setSearch5] = useState("");
  const [search6, setSearch6] = useState("");
  const handleSubmit = async (e) => {
    if (
      (search || search1 || search2 || search4 || search5 || search6) &&
      (search.length > 0 ||
        search1.length > 0 ||
        search2.length > 0 ||
        search4.length > 0 ||
        search5.length > 0 ||
        search6.length > 0)
    ) {
      e.preventDefault();
      dispatch(getDataStart());
      const response = await API.get(
        `/flights/search?name=${search}&pnr=${search1}&depdate=${search2}&todepdate=${search3}&depcode=${search4}&arrcode=${search5}&airlinename=${search6}&token=${localStorage.getItem(
          "authToken"
        )}`
      );
      console.log(response.data);
      dispatch(getDataSuccess(response.data));
    } else {
      alert("Please enter a value to search");
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const clearFilter = () => {
    setSearch("");
    setSearch1("");
    setSearch2(formattedDate);
    setSearch3("");
    setSearch4("");
    setSearch5("");
    setSearch6("");
    fetchdata();
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className="d-flex flex-column flex-sm-column flex-lg-row searching">
        <div className="input-group rounded mb-3 mr-2">
          {
            <div className="mr-2">
              <label for="name">Name</label>
              <input
                id="name"
                type="search"
                className={`form-control rounded mr-2 ${
                  search ? "border-success border-4" : ""
                }`}
                placeholder="Enter Name"
                aria-label="Search"
                aria-describedby="search-addon"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          }
          <div className="mr-2">
            <label for="pnr">PNR</label>
            <input
              id="pnr"
              type="search"
              className={`form-control rounded mr-2 ${
                search1 ? "border-success border-4" : ""
              }`}
              placeholder="Enter PNR"
              aria-label="Search"
              aria-describedby="search-addon"
              value={search1}
              onChange={(e) => setSearch1(e.target.value)}
            />
          </div>

          <div className="mr-2">
            <label for="depdate">From Departure Date</label>
            <input
              id="depdate"
              type="search"
              className={`form-control rounded mr-2 ${
                search2 ? "border-success border-4" : ""
              }`}
              placeholder="dd/mm/yyyy"
              aria-label="Search"
              aria-describedby="search-addon"
              value={search2}
              onChange={(e) => setSearch2(e.target.value)}
            />
          </div>

          {isFilterActive("To Departure Date") && (
            <div className="mr-2">
              <label for="todepdate">To Departure Date</label>
              <input
                id="todepdate"
                type="search"
                className={`form-control rounded mr-2 ${
                  search3 ? "border-success border-4" : ""
                }`}
                placeholder="dd/mm/yyyy"
                aria-label="Search"
                aria-describedby="search-addon"
                value={search3}
                onChange={(e) => setSearch3(e.target.value)}
              />
            </div>
          )}
          {isFilterActive("Departure Code") && (
            <div className="mr-2">
              <label for="depcode">Departure Code</label>
              <input
                id="depcode"
                type="search"
                className={`form-control rounded mr-2 ${
                  search4 ? "border-success border-4" : ""
                }`}
                placeholder="Enter departure code"
                aria-label="Search"
                aria-describedby="search-addon"
                value={search4}
                onChange={(e) => setSearch4(e.target.value)}
              />
            </div>
          )}
          {isFilterActive("Arrival Code") && (
            <div className="mr-2">
              <label for="arrcode">Arrival Code</label>
              <input
                id="arrcode"
                type="search"
                className={`form-control rounded mr-2 ${
                  search5 ? "border-success border-4" : ""
                }`}
                placeholder="Enter arrival code"
                aria-label="Search"
                aria-describedby="search-addon"
                value={search5}
                onChange={(e) => setSearch5(e.target.value)}
              />
            </div>
          )}
          {isFilterActive("Airline Name") && (
            <div className="mr-2">
              <label for="airlinename">Airline Name</label>
              <input
                id="airlinename"
                type="search"
                className={`form-control rounded mr-2 ${
                  search6 ? "border-success border-4" : ""
                }`}
                placeholder="Enter Airline Name"
                aria-label="Search"
                aria-describedby="search-addon"
                value={search6}
                onChange={(e) => setSearch6(e.target.value)}
              />
            </div>
          )}
        </div>
        <div className="mr-4" style={{ display: "flex", alignItems: "center" }}>
          <Button
            type="submit"
            sx={{ height: "60px", width: "40px" }}
            variant="contained"
            className="btn btn-primary mr-4"
            onClick={handleSubmit}
          >
            <SearchIcon />
          </Button>
          <Button
            sx={{
              height: "60px",
              width: "40px",
              marginRight: "10px",
            }}
            variant="contained"
            onClick={() => {
              clearFilter();
            }}
          >
            <RefreshIcon />
          </Button>
          <MultipleSelectCheckmarks />
        </div>
      </div>
      {loading ? (
        <Box sx={{ display: "flex" }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <UserTable1
            data={displayedData}
            onDelete={handleDelete}
            onRefund={handleRefund}
            onCancel={handleCancel}
            onNameClick={handleNameClick}
            onPNRClick={handlePNRClick}
          />
          <Pagination
            totalItems={data.length}
            itemsPerPage={itemsPerPage}
            onPageChange={handlePageChange}
          />
        </>
      )}
      <style>
        {`
      input::placeholder{
        opacity:0.5 !important;
      }
       @media (max-width: 767px) {
    .searching {
        margin-left: 20px;
    }`}
      </style>
    </div>
  );
};

export default FlightDeletedUsers;
