import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import Pagination from "../components/FlightPagination";
import UserTable1 from "../components/FlightUserTable1";
import { useSelector, useDispatch } from "react-redux";
import { getDataStart, getDataSuccess } from "../dataSlice";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
// import MultipleSelectCheckmarks from "../components/FlightMultipleSelect";
import RefreshIcon from "@mui/icons-material/Refresh";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useLocation } from "react-router-dom";
import toast from "react-hot-toast";

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';

import Modal from "@mui/material/Modal";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from 'dayjs';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import TextField from "@mui/material/TextField";
import API from "../../Visa/API";
import { useNavigate } from "react-router-dom";
const itemsPerPage = 10;

const FlightUserDetails = () => {
  const location = useLocation();
  const passedPage = location.state?.currentPage;
  const passedSearch = location.state?.search || "";
  const passedSearch1 = location.state?.search1 || "";
  const passedSearch8 = location.state?.search8 || "";
  const passedSearch4 = location.state?.search4 || "";
  const passedSearch5 = location.state?.search5 || "";
  const passedSearch6 = location.state?.search6 || "";
  const passedSearch7 = location.state?.search7 || "";  
  const passedSearchTicketType = location.state?.searchTicketType || "";
  const passedSearchDate = location.state?.searchDate || "";
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const data = useSelector((state) => state.data.data);
  // const filter = useSelector((state) => state.filter.filter);
  const loading = useSelector((state) => state.data.loading);
  
  const [filter, setFilter] = useState([]);  
  const [currentPage, setCurrentPage] = useState(passedPage?passedPage:1);
  const [searchDate, setSearchDate] = useState(passedSearchDate?passedSearchDate:[
      {
        startDate: null,
        endDate: null,
        key: 'selection'
      }
    ]);  
  const [isDatePickerVisible, setDatePickerVisible] = useState(false);
  const DatePickerStyle = {
    datePickerWrapper: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  };
  const dateRangeContainer = {
    dateRangeContainer: {
      position: 'absolute',
      zIndex: '1000', // Use camelCase for zIndex
      background: 'white',
      marginTop: '75px', // Add 70px padding to the top
      // Add more styling as needed
    }
  };

  const formatDate = (date) => {
    const options = { month: 'short', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  };

  const isFilterActive = (filterName) => filter.includes(filterName);
  const toggleFilters = (filterNames) => {
    const updatedFilter = [...filter];

    filterNames.forEach(filterName => {
      if (updatedFilter.includes(filterName)) {
        // Remove filter
        const index = updatedFilter.indexOf(filterName);
        if (index > -1) {
          updatedFilter.splice(index, 1);
        }
      } else {
        // Add filter
        updatedFilter.push(filterName);
      }
    });

    setFilter(updatedFilter);
  };
  const handleNameClick = async (name) => {
    setCurrentPage(1);
    setSearch(name); // Update the search bar with the clicked name
    setSearchDate([{
        startDate: null,
        endDate: null,
        key: 'selection'      
    }]);
    setDatePickerVisible(false);
    setSearch4("");
    setSearch5("");
    setSearch6("");
    setSearch1("");
    setSearch7("");
    setSearch8("");
    await submitSearch(name); // Implement a search submission function
  };
  const handlePNRClick = async (pnr) => {
    setCurrentPage(1);
    setSearch1(pnr); // Update the search bar with the clicked name
    setSearch(""); // Update the search bar with the clicked name
    setSearchDate([{
        startDate: null,
        endDate: null,
        key: 'selection'      
    }]);
    setDatePickerVisible(false);
    setSearch4("");
    setSearch5("");
    setSearch6("");
    setSearch7("");
    setSearch8("");

    await submitPNRSearch(pnr); // Implement a search submission function
  };
  const submitPNRSearch = async (pnr) => {
    dispatch(getDataStart());
    const response = await API.get(
      `/flights/search?pnr=${pnr}&token=${localStorage.getItem(
        "authToken"
      )}`
    );
    dispatch(getDataSuccess(response.data));
  };

  const submitSearch = async (name) => {
    dispatch(getDataStart());
    const response = await API.get(
      `/flights/search?name=${name}&token=${localStorage.getItem(
        "authToken"
      )}`
    );
    dispatch(getDataSuccess(response.data));
  };

  const fetchdata = async () => {
    dispatch(getDataStart());
    try{
      const response = await API.get(
        `/flights/tickets?token=${localStorage.getItem(
          "authToken"
        )}`
      );
      // setCurrentPage(1);      
      dispatch(getDataSuccess(response.data));
    } catch(error){
      if(error.response && error.response.status===401){
        navigate('/login');
      }
    
  };
  };
  const handleDateSelection = async (selectedDates) => {
    if (selectedDates){
      console.log(selectedDates);
    }
  } 
  const handleDelete = async (selectedRows) => {
    if (selectedRows && selectedRows.length > 0) {
      try {
        await API.get(
          `/flights/user?selectedRows=${selectedRows}&token=${localStorage.getItem(
            "authToken"
          )}&=`
        );
        // Show a success alert
        toast.success("User(s) status changed to deleted successfully");

        // Refresh the window after a brief delay
        setTimeout(() => {
          window.location.reload();
        }, 500); // Refresh after 1 second (adjust the delay as needed)
      } catch (err) {
        console.error("Error deleting Users", err);
        toast.error(
          "Error changing status to deleted. Please try again after a few moments."
        );
      }
    } else {
      alert("Please select a row to change status");
    }
  };
  const handleRefund = async (selectedRows) => {
    if (selectedRows && selectedRows.length > 0) {
      try {
        const response = API.get(
          `/flights/user/refund?selectedRows=${selectedRows}&token=${localStorage.getItem(
            "authToken"
          )}`
        );
        // Show a success alert
        toast.promise(response, {
          loading: "Refunding...",
          success: "User(s) status changed to refunded successfully",
          error: "Error refunding",
        });
        setTimeout(() => {
          window.location.reload();
        }, 500); // Refresh after 1 second (adjust the delay as needed)
      } catch (err) {
        console.error("Error deleting Users", err);
        toast.error(
          "Error changing status to refunded. Please try again after a few moments."
        );
      }
    } else {
      alert("Please select a row to change status");
    }
  };
  const modalStyle = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
      display: "flex",
      flexDirection: "column",
  };  
  const handlePreCancel = async (selectedRows, latestTimeOfSelected) => {
    setFutureDate(latestTimeOfSelected);
    setOpenCancelModal(true);
    setSelectedRows(selectedRows);
  }
  const handleCancel = async () => {
    if (selectedRows && selectedRows.length > 0) {
      try {
        const response = API.get(
          `/flights/user/cancel?selectedRows=${selectedRows}&useByDate=${useByDate}&token=${localStorage.getItem(
            "authToken"
          )}`
        );
        // Show a success alert
        toast.promise(response, {
          loading: "Cancelling...",
          success: "User(s) status changed to cancelled successfully",
          error: "Error cancelling",
        });
        setTimeout(() => {
          window.location.reload();
        }, 500); // Refresh after 1 second (adjust the delay as needed)
      } catch (err) {
        console.error("Error deleting Users", err);
        toast.error(
          "Error changing status to confirmed. Please try again after a few moments."
        );
      }
    } else {
      alert("Please select a row to change status");
    }
  };
  const handleConfirm = async (selectedRows) => {
    if (selectedRows && selectedRows.length > 0) {
      try {
        const response = API.get(
          `/flights/user/confirm?selectedRows=${selectedRows}&useByDate=${useByDate}&token=${localStorage.getItem(
            "authToken"
          )}`
        );
        // Show a success alert
        toast.promise(response, {
          loading: "Confirming...",
          success: "User(s) status changed to confirmed successfully",
          error: "Error confirming",
        });

        setTimeout(() => {
          window.location.reload();
        }, 500); // Refresh after 1 second (adjust the delay as needed)
      } catch (err) {
        console.error("Error deleting Users", err);
        toast.error(
          "Error changing status to confirmed. Please try again after a few moments."
        );
      }
    } else {
      alert("Please select a row to change status");
    }
  };

  useEffect(() => {
    const e = { preventDefault: () => {} };
    handleSubmit(e, passedPage);
  }, [passedPage, passedSearch, passedSearch1, passedSearch4, passedSearch5, passedSearch6, passedSearch7, passedSearch8, passedSearchTicketType, passedSearchDate]); 

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedData = data.slice(startIndex, endIndex);    
  const currentDate = new Date();
  const day = currentDate.getDate();
  const month = currentDate.getMonth() + 1; // Months are zero-based
  const year = currentDate.getFullYear();

  const formattedDay = day < 10 ? `0${day}` : `${day}`;
  const formattedMonth = month < 10 ? `0${month}` : `${month}`;
  const [search, setSearch] = useState(passedSearch?passedSearch:"");
  const [search1, setSearch1] = useState(passedSearch1?passedSearch1:"");
  const [search4, setSearch4] = useState(passedSearch4?passedSearch4:"");
  const [search5, setSearch5] = useState(passedSearch5?passedSearch5:"");
  const [search6, setSearch6] = useState(passedSearch6?passedSearch6:"");
  const [search7, setSearch7] = useState(passedSearch7?passedSearch7:"");
  const [search8, setSearch8] = useState(passedSearch8?passedSearch8:"");
  const [search9, setSearch9] = useState();
  const [searchTicketType, setSearchTicketType] = useState(passedSearchTicketType?passedSearchTicketType:"");
  const [useByDate, setUseByDate] = useState(dayjs());
  const [openCancelModal, setOpenCancelModal] = useState(false);  
  const [selectedRows, setSelectedRows] = useState([]);

  const handleSubmit = async (e, passedPage) => {
    const fromDepartureDate = searchDate[0].startDate ? new Date(searchDate[0].startDate) : "";
    const TodepartureDate = searchDate[0].endDate ? new Date(new Date(searchDate[0].endDate).getTime() + 24 * 60 * 60 * 1000)  : "";
    if (
      (search ||
        search1 ||
        fromDepartureDate ||
        searchTicketType ||
        search4 ||
        search5 ||
        search6 ||
        search7 || search8 || search9) &&
      (search.length > 0 ||
        search1.length > 0 ||
        search4.length > 0 ||
        fromDepartureDate ||
        searchTicketType.length > 0 ||
        search5.length > 0 ||
        search6.length > 0 ||
        search7.length > 0 || search8.length > 0 || search9.length > 0)
    ) {
      setCurrentPage(passedPage?passedPage:1);
      e.preventDefault();
      setDatePickerVisible(false);
      dispatch(getDataStart());
      const response = await API.get(
        `/flights/search?name=${search.trim()}&pnr=${search1.trim()}&depdate=${fromDepartureDate}&todepdate=${TodepartureDate}&depcode=${search4}&arrcode=${search5}&airlinename=${search6.trim()}&status=${search7}&hubspot=${search8.trim()}&ticketType=${searchTicketType}&token=${localStorage.getItem(
          "authToken"
        )}&contact_number=${search9}`
      );
      dispatch(getDataSuccess(response.data));
    } else {
      fetchdata();
    }
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const clearFilter = () => {
    setCurrentPage(1);
    setSearch("");
    setSearch1("");
    setSearch4("");
    setSearch5("");
    setSearch6("");
    setSearch7("");
    setSearch8("");
    setSearchDate([{
        startDate: null,
        endDate: null,
        key: 'selection'      
    }]);
    setSearchTicketType("");
    setDatePickerVisible(false);
    fetchdata();
  };
  const setFutureDate = (latestTimeOfSelected) => {
    const futureDate = dayjs(latestTimeOfSelected).add(360, 'day');
    setUseByDate(futureDate);
  };
  const handleCloseCancelModal = () => {
      setOpenCancelModal(false);
      setUseByDate("");
  };

  return ( 
    <>
    <Modal open={openCancelModal} onClose={handleCloseCancelModal}>
        <Box sx={modalStyle}>
            <IconButton
              onClick={handleCloseCancelModal}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <div className="mt-3">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  label="Enter Use By Date"
                  value={useByDate}
                  onChange={(date) => setUseByDate(date)}
                />
              </LocalizationProvider>
            <button
              className="btn btn-warning m-2 button"
              onClick={() => handleCancel()}>
                Cancel
            </button>
            </div>
        </Box>
    </Modal>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}>
      <div className="d-flex flex-column flex-sm-column flex-lg-row searching container">
        <div className="input-group rounded mb-3 mr-2 row">
          {
            <div className="mt-2 col-md-3">
              <label for="name">PAX Name</label>
              <input
                id="name"
                type="search"
                className={`form-control rounded mr-2 ${
                  search ? "border-success border-4" : ""
                }`}
                placeholder="Enter name as in ticket"
                aria-label="Search"
                aria-describedby="search-addon"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          }
          <div className="mt-2 col-md-3">
            <div>
              <label for="pnr">PNR</label>
              <input
                id="pnr"
                type="search"
                className={`form-control rounded mr-2 ${
                  search1 ? "border-success border-4" : ""
                }`}
                placeholder="Enter PNR"
                aria-label="Search"
                aria-describedby="search-addon"
                value={search1}
                onChange={(e) => setSearch1(e.target.value)}
              />
            </div>
          </div>
          <div className="mt-2 col-md-3">
            <div style={DatePickerStyle.datePickerWrapper}>
              <label for="">Date</label>
              <button onClick={() => setDatePickerVisible(!isDatePickerVisible)} className={`form-control rounded mr-2 ${
                searchDate[0].startDate ? "border-success border-4" : ""}`}>
                {searchDate[0].startDate || searchDate[0].endDate || isDatePickerVisible ? (
                  <span>
                    {searchDate[0].startDate ? formatDate(searchDate[0].startDate) : ''}
                    {searchDate[0].endDate ? ` - ${formatDate(searchDate[0].endDate)}` : ''}
                    {isDatePickerVisible?<span style={{whiteSpace: 'pre'}}>{'\u0009\u2715'}</span>:''}                    
                  </span>
                ) : 'Select Date'}
              </button>          
              {isDatePickerVisible && (
                <div style={dateRangeContainer.dateRangeContainer}>
                  <DateRange
                    editableDateInputs={false}
                    onChange={item => setSearchDate([item.selection])}
                    moveRangeOnFirstSelection={false}
                    ranges={searchDate}
                  />
                </div>
              )}          
            </div>
          </div>
          <div className="mt-2 col-md-3">
          <label for="contact_search">Contact Search</label>
          <input
            id="contact_search"
            name="contact_search"
            type="search"
            className={`form-control rounded mr-2 ${
              search8 ? "border-success border-4" : ""
            }`}
            placeholder="Enter Detail"
            aria-label="Search"
            aria-describedby="search-addon"
            value={search8}
            onChange={(e) => setSearch8(e.target.value)}
          />
          </div>          
          {isFilterActive("Departure Code") && (
          <div className="mt-2 col-md-3">
            <label className="" for="depcode">Segment Code</label>
            <div className="row"> 
              <div className="col-md-6"> 
                <input
                  id="depcode"
                  type="search"
                  maxlength="3"
                  className={`form-control rounded mr-2 ${
                    search4 ? "border-success border-4" : ""
                  }`}
                  placeholder="DEP"
                  aria-label="Search"
                  aria-describedby="search-addon"
                  value={search4}
                  onChange={(e) => setSearch4(e.target.value)}
                />
              </div>
              <div className="col-md-6"> 
                <input
                  id="arrcode"
                  type="search"
                  maxlength="3"                  
                  className={`form-control rounded ${
                    search5 ? "border-success border-4" : ""
                  }`}
                  placeholder="ARR"
                  aria-label="Search"
                  aria-describedby="search-addon"
                  value={search5}
                  onChange={(e) => setSearch5(e.target.value)}
                />
              </div>
            </div>
          </div>
          )}

          {isFilterActive("Airline Name") && (
          <div className="mt-2 col-md-3">
            <label for="airlinename">Airline Name</label>
            <input
              id="airlinename"
              type="search"
              className={`form-control rounded mr-2 ${
                search6 ? "border-success border-4" : ""
              }`}
              placeholder="Enter Airline Name"
              aria-label="Search"
              aria-describedby="search-addon"
              value={search6}
              onChange={(e) => setSearch6(e.target.value)}
            />
          </div>
          )}
          {isFilterActive("Status") && (
            <div className="mt-2 col-md-3">
              <label for="status">Status</label>
              <select
                id="status"
                className={`form-control form-select form-select-lg rounded mr-2 ${
                  search7 ? "border-success border-4" : ""
                }`}
                value={search7}
                onChange={(e) => setSearch7(e.target.value)}
                aria-label="Select Status"
              >
                <option value="">Select Status</option>
                <option value="Active">Active</option>
                <option value="Cancelled">Cancelled</option>
                <option value="Refunded">Refunded</option>
                <option value="Changed">Changed</option>
              </select>
            </div>
          )}
          {isFilterActive("Ticket Type") && (
            <div className="mt-2 col-md-3">
              <label for="status">Ticket Type</label>
              <select
                id="status"
                className={`form-control form-select form-select-lg rounded mr-2 ${
                  searchTicketType ? "border-success border-4" : ""
                }`}
                value={searchTicketType}
                onChange={(e) => setSearchTicketType(e.target.value)}
                aria-label="Select Status"
              >
                <option value="">Select Status</option>
                <option value="SABRE">SABRE</option>
                <option value="NDC">NDC</option>
                <option value="LCC">LCC</option>
                <option value="TBO">TBO</option>
              </select>
            </div>
          )}          
{/*          {isFilterActive("Phone Number") && (
            <div className="mt-2 col-md-3">
              <label for="phone_number">Phone Number</label>
              <input
                id="phone_number"
                type="search"
                className={`form-control rounded mr-2 ${
                  search9 ? "border-success border-4" : ""
                }`}
                placeholder="Enter Phone Number"
                aria-label="Search"
                aria-describedby="search-addon"
                value={search9}
                onChange={(e) => setSearch9(e.target.value)}
              />
            </div>
          )}*/}
        </div>
        <div className="" style={{ display: "flex", alignItems: "center" }}>
          <Button
            type="submit"
            sx={{ width: "30px" }}
            variant="contained"
            className="btn btn-primary mr-1"
            onClick={handleSubmit}
          >
            <SearchIcon />
          </Button>
          <Button
            sx={{ width: "30px" }}
            variant="contained"
            className="btn btn-primary mr-1"
            onClick={() => {
              clearFilter();
            }}
          >
            <RefreshIcon />
          </Button>
          <Button
            sx={{ width: "40px" }}
            variant="contained"
            className="btn btn-primary mr-1"
            onClick={() => toggleFilters(["Ticket Type", "Departure Code","Arrival Code","Airline Name","Status","Phone Number"])}
          >

            <ExpandMoreIcon />
          </Button>          
        </div>
{/*        <div style={{ display: "flex", alignItems: "center" }}>
          <MultipleSelectCheckmarks />
        </div>*/}
      </div>
      {loading ? (
        <Box sx={{ display: "flex" }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <UserTable1
            data={displayedData}
            onDelete={handleDelete}
            onRefund={handleRefund}
            onCancel={handlePreCancel}
            onNameClick={handleNameClick}
            onPNRClick={handlePNRClick}
            onConfirm={handleConfirm}
            currentPage={currentPage}
            search={search}
            search1={search1}
            search8={search8}
            search4={search4}
            search5={search5}
            search6={search6}
            search7={search7}
            searchTicketType={searchTicketType}
            searchDate={searchDate}            
            submitSearch={handleSubmit}
          />
          <Pagination
            totalItems={data.length}
            itemsPerPage={itemsPerPage}
            onPageChange={handlePageChange}
            currPage={currentPage}
          />
        </>
      )}
      <style>
        {`
          input::placeholder{
            opacity:0.5 !important;
          }
          @media (max-width: 767px) {
            .searching {
              margin-left: 20px;
            }
          }
        `}
      </style>
    </div>
    </>
  );
};

export default FlightUserDetails;
