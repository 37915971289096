import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function RichTextEditor({ htmlContent, setHTMLContent, style, placeholder }) {
    return (
        <ReactQuill
            theme="snow"
            value={htmlContent}
            onChange={(content) => setHTMLContent(content)}
            style={style}
            placeholder={placeholder}
        />
    );
}

export default RichTextEditor;