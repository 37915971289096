const countryCodes = [
  {"label": "Afghanistan", "code": 209, "flagIcon": "🇦🇫"},
  {"label": "Albania", "code": 473, "flagIcon": "🇦🇱"},
  {"label": "Andorra", "code": 445, "flagIcon": "🇦🇩"},
  {"label": "United Arab Emirates", "code": 101, "flagIcon": "🇦🇪"},
  {"label": "Argentina", "code": 605, "flagIcon": "🇦🇷"},
  {"label": "Armenia", "code": 263, "flagIcon": "🇦🇲"},
  {"label": "Antigua and Barbuda", "code": 251, "flagIcon": "🇦🇬"},
  {"label": "Australia", "code": 701, "flagIcon": "🇦🇺"},
  {"label": "Austria", "code": 417, "flagIcon": "🇦🇹"},
  {"label": "Azerbaijan", "code": 265, "flagIcon": "🇦🇿"},
  {"label": "Belgium", "code": 411, "flagIcon": "🇧🇪"},
  {"label": "Benin", "code": 369, "flagIcon": "🇧🇯"},
  {"label": "Burkina Faso", "code": 351, "flagIcon": "🇧🇫"},
  {"label": "Bangladesh", "code": 207, "flagIcon": "🇧🇩"},
  {"label": "Bulgaria", "code": 465, "flagIcon": "🇧🇬"},
  {"label": "Bahrain", "code": 107, "flagIcon": "🇧🇭"},
  {"label": "Bahamas", "code": 619, "flagIcon": "🇧🇸"},
  {"label": "Bosnia and Herzegovina", "code": 463, "flagIcon": "🇧🇦"},
  {"label": "Belarus", "code": 483, "flagIcon": "🇧🇾"},
  {"label": "Belize", "code": 651, "flagIcon": "🇧🇿"},
  {"label": "Bermuda", "code": 634, "flagIcon": "🇧🇲"},
  {"label": "Bolivia", "code": 609, "flagIcon": "🇧🇴"},
  {"label": "Brazil", "code": 603, "flagIcon": "🇧🇷"},
  {"label": "Barbados", "code": 657, "flagIcon": "🇧🇧"},
  {"label": "Brunei", "code": 253, "flagIcon": "🇧🇳"},
  {"label": "Bhutan", "code": 211, "flagIcon": "🇧🇹"},
  {"label": "Central African Republic", "code": 363, "flagIcon": "🇨🇫"},
  {"label": "Canada", "code": 501, "flagIcon": "🇨🇦"},
  {"label": "Switzerland", "code": 415, "flagIcon": "🇨🇭"},
  {"label": "Chile", "code": 607, "flagIcon": "🇨🇱"},
  {"label": "China", "code": 219, "flagIcon": "🇨🇳"},
  {"label": "Ivory Coast", "code": 323, "flagIcon": "🇨🇮"},
  {"label": "Cameroon", "code": 309, "flagIcon": "🇨🇲"},
  {"label": "Congo (Democratic Republic)", "code": 313, "flagIcon": "🇨🇩"},
  {"label": "Colombia", "code": 611, "flagIcon": "🇨🇴"},
  {"label": "Comoros", "code": 301, "flagIcon": "🇰🇲"},
  {"label": "Cape Verde", "code": 365, "flagIcon": "🇨🇻"},
  {"label": "Costa Rica", "code": 623, "flagIcon": "🇨🇷"},
  {"label": "Cuba", "code": 621, "flagIcon": "🇨🇺"},
  {"label": "Cayman Islands", "code": 503, "flagIcon": "🇰🇾"},
  {"label": "Cyprus", "code": 431, "flagIcon": "🇨🇾"},
  {"label": "Czech Republic", "code": 452, "flagIcon": "🇨🇿"},
  {"label": "Germany", "code": 407, "flagIcon": "🇩🇪"},
  {"label": "Djibouti", "code": 141, "flagIcon": "🇩🇯"},
  {"label": "Dominica", "code": 642, "flagIcon": "🇩🇲"},
  {"label": "Denmark", "code": 423, "flagIcon": "🇩🇰"},
  {"label": "Dominican Republic", "code": 625, "flagIcon": "🇩🇴"},
  {"label": "Algeria", "code": 131, "flagIcon": "🇩🇿"},
  {"label": "Ecuador", "code": 613, "flagIcon": "🇪🇨"},
  {"label": "Egypt", "code": 125, "flagIcon": "🇪🇬"},
  {"label": "Eritrea", "code": 303, "flagIcon": "🇪🇷"},
  {"label": "Spain", "code": 437, "flagIcon": "🇪🇸"},
  {"label": "Estonia", "code": 459, "flagIcon": "🇪🇪"},
  {"label": "Ethiopia", "code": 317, "flagIcon": "🇪🇹"},
  {"label": "Finland", "code": 433, "flagIcon": "🇫🇮"},
  {"label": "Fiji", "code": 705, "flagIcon": "🇫🇯"},
  {"label": "France", "code": 403, "flagIcon": "🇫🇷"},
  {"label": "Faroe Islands", "code": 276, "flagIcon": "🇫🇴"},
  {"label": "United Kingdom", "code": 401, "flagIcon": "🇬🇧"},
  {"label": "Georgia", "code": 273, "flagIcon": "🇬🇪"},
  {"label": "Ghana", "code": 319, "flagIcon": "🇬🇭"},
  {"label": "Guinea", "code": 321, "flagIcon": "🇬🇳"},
  {"label": "Gambia", "code": 381, "flagIcon": "🇬🇲"},
  {"label": "Guinea-Bissau", "code": 385, "flagIcon": "🇬🇼"},
  {"label": "Equatorial Guinea", "code": 750, "flagIcon": "🇬🇶"},
  {"label": "Greece", "code": 431, "flagIcon": "🇬🇷"},
  {"label": "Grenada", "code": 649, "flagIcon": "🇬🇩"},
  {"label": "French Guiana", "code": 659, "flagIcon": "🇬🇫"},
  {"label": "Guatemala", "code": 627, "flagIcon": "🇬🇹"},
  {"label": "Guyana", "code": 653, "flagIcon": "🇬🇾"},
  {"label": "Hong Kong", "code": 223, "flagIcon": "🇭🇰"},
  {"label": "Honduras", "code": 647, "flagIcon": "🇭🇳"},
  {"label": "Croatia", "code": 453, "flagIcon": "🇭🇷"},
  {"label": "Haiti", "code": 639, "flagIcon": "🇭🇹"},
  {"label": "Hungary", "code": 467, "flagIcon": "🇭🇺"},
  {"label": "Indonesia", "code": 243, "flagIcon": "🇮🇩"},
  {"label": "India", "code": 205, "flagIcon": "🇮🇳"},
  {"label": "Ireland", "code": 427, "flagIcon": "🇮🇪"},
  {"label": "Iran", "code": 201, "flagIcon": "🇮🇷"},
  {"label": "Iraq", "code": 113, "flagIcon": "🇮🇶"},
  {"label": "Iceland", "code": 443, "flagIcon": "🇮🇸"},
  {"label": "Italy", "code": 405, "flagIcon": "🇮🇹"},
  {"label": "Jamaica", "code": 629, "flagIcon": "🇯🇲"},
  {"label": "Jordan", "code": 121, "flagIcon": "🇯🇴"},
  {"label": "Japan", "code": 231, "flagIcon": "🇯🇵"},
  {"label": "Kazakhstan", "code": 261, "flagIcon": "🇰🇿"},
  {"label": "Kenya", "code": 325, "flagIcon": "🇰🇪"},
  {"label": "Kyrgyzstan", "code": 268, "flagIcon": "🇰🇬"},
  {"label": "Cambodia", "code": 215, "flagIcon": "🇰🇭"},
  {"label": "Kiribati", "code": 391, "flagIcon": "🇰🇮"},
  {"label": "Saint Kitts and Nevis", "code": 487, "flagIcon": "🇰🇳"},
  {"label": "South Korea", "code": 227, "flagIcon": "🇰🇷"},
  {"label": "Kuwait", "code": 105, "flagIcon": "🇰🇼"},
  {"label": "Laos", "code": 245, "flagIcon": "🇱🇦"},
  {"label": "Lebanon", "code": 117, "flagIcon": "🇱🇧"},
  {"label": "Liberia", "code": 327, "flagIcon": "🇱🇷"},
  {"label": "Libya", "code": 127, "flagIcon": "🇱🇾"},
  {"label": "Saint Lucia", "code": 663, "flagIcon": "🇱🇨"},
  {"label": "Liechtenstein", "code": 449, "flagIcon": "🇱🇮"},
  {"label": "Sri Lanka", "code": 217, "flagIcon": "🇱🇰"},
  {"label": "Lesotho", "code": 377, "flagIcon": "🇱🇸"},
  {"label": "Lithuania", "code": 457, "flagIcon": "🇱🇹"},
  {"label": "Luxembourg", "code": 413, "flagIcon": "🇱🇺"},
  {"label": "Latvia", "code": 461, "flagIcon": "🇱🇻"},
  {"label": "Macau", "code": 259, "flagIcon": "🇲🇴"},
  {"label": "Morocco", "code": 133, "flagIcon": "🇲🇦"},
  {"label": "Monaco", "code": 439, "flagIcon": "🇲🇨"},
  {"label": "Moldova", "code": 482, "flagIcon": "🇲🇩"},
  {"label": "Madagascar", "code": 329, "flagIcon": "🇲🇬"},
  {"label": "Maldives", "code": 257, "flagIcon": "🇲🇻"},
  {"label": "Mexico", "code": 601, "flagIcon": "🇲🇽"},
  {"label": "Marshall Islands", "code": 727, "flagIcon": "🇲🇭"},
  {"label": "North Macedonia", "code": 430, "flagIcon": "🇲🇰"},
  {"label": "Mali", "code": 335, "flagIcon": "🇲🇱"},
  {"label": "Malta", "code": 435, "flagIcon": "🇲🇹"},
  {"label": "Myanmar", "code": 213, "flagIcon": "🇲🇲"},
  {"label": "Montenegro", "code": 488, "flagIcon": "🇲🇪"},
  {"label": "Mongolia", "code": 249, "flagIcon": "🇲🇳"},
  {"label": "Northern Mariana Islands", "code": 721, "flagIcon": "🇲🇵"},
  {"label": "Mozambique", "code": 337, "flagIcon": "🇲🇿"},
  {"label": "Mauritania", "code": 135, "flagIcon": "🇲🇷"},
  {"label": "Martinique", "code": 661, "flagIcon": "🇲🇶"},
  {"label": "Mauritius", "code": 367, "flagIcon": "🇲🇺"},
  {"label": "Malawi", "code": 333, "flagIcon": "🇲🇼"},
  {"label": "Malaysia", "code": 241, "flagIcon": "🇲🇾"},
  {"label": "Namibia", "code": 373, "flagIcon": "🇳🇦"},
  {"label": "New Caledonia", "code": 715, "flagIcon": "🇳🇨"},
  {"label": "Niger", "code": 339, "flagIcon": "🇳🇪"},
  {"label": "Nigeria", "code": 341, "flagIcon": "🇳🇬"},
  {"label": "Nicaragua", "code": 631, "flagIcon": "🇳🇮"},
  {"label": "Netherlands", "code": 409, "flagIcon": "🇳🇱"},
  {"label": "Norway", "code": 421, "flagIcon": "🇳🇴"},
  {"label": "Nepal", "code": 235, "flagIcon": "🇳🇵"},
  {"label": "Nauru", "code": 737, "flagIcon": "🇳🇷"},
  {"label": "New Zealand", "code": 703, "flagIcon": "🇳🇿"},
  {"label": "Oman", "code": 111, "flagIcon": "🇴🇲"},
  {"label": "Pakistan", "code": 203, "flagIcon": "🇵🇰"},
  {"label": "Panama", "code": 633, "flagIcon": "🇵🇦"},
  {"label": "Peru", "code": 615, "flagIcon": "🇵🇪"},
  {"label": "Philippines", "code": 237, "flagIcon": "🇵🇭"},
  {"label": "Palau", "code": 669, "flagIcon": "🇵🇼"},
  {"label": "Papua New Guinea", "code": 731, "flagIcon": "🇵🇬"},
  {"label": "Poland", "code": 471, "flagIcon": "🇵🇱"},
  {"label": "Puerto Rico", "code": 641, "flagIcon": "🇵🇷"},
  {"label": "North Korea", "code": 229, "flagIcon": "🇰🇵"},
  {"label": "Portugal", "code": 425, "flagIcon": "🇵🇹"},
  {"label": "Paraguay", "code": 645, "flagIcon": "🇵🇾"},
  {"label": "Palestine", "code": 123, "flagIcon": "🇵🇸"},
  {"label": "Qatar", "code": 109, "flagIcon": "🇶🇦"},
  {"label": "Reunion", "code": 469, "flagIcon": "🇷🇪"},
  {"label": "Romania", "code": 469, "flagIcon": "🇷🇴"},
  {"label": "Russia", "code": 477, "flagIcon": "🇷🇺"},
  {"label": "Rwanda", "code": 343, "flagIcon": "🇷🇼"},
  {"label": "Saudi Arabia", "code": 103, "flagIcon": "🇸🇦"},
  {"label": "Sudan", "code": 137, "flagIcon": "🇸🇩"},
  {"label": "Senegal", "code": 345, "flagIcon": "🇸🇳"},
  {"label": "Singapore", "code": 225, "flagIcon": "🇸🇬"},
  {"label": "Sierra Leone", "code": 347, "flagIcon": "🇸🇱"},
  {"label": "El Salvador", "code": 635, "flagIcon": "🇸🇻"},
  {"label": "San Marino", "code": 447, "flagIcon": "🇸🇲"},
  {"label": "Somalia", "code": 139, "flagIcon": "🇸🇴"},
  {"label": "Serbia", "code": 466, "flagIcon": "🇷🇸"},
  {"label": "South Sudan", "code": 138, "flagIcon": "🇸🇸"},
  {"label": "Sao Tome and Principe", "code": 395, "flagIcon": "🇸🇹"},
  {"label": "Suriname", "code": 655, "flagIcon": "🇸🇷"},
  {"label": "Slovakia", "code": 462, "flagIcon": "🇸🇰"},
  {"label": "Slovenia", "code": 455, "flagIcon": "🇸🇮"},
  {"label": "Sweden", "code": 419, "flagIcon": "🇸🇪"},
  {"label": "Eswatini", "code": 379, "flagIcon": "🇸🇿"},
  {"label": "Seychelles", "code": 383, "flagIcon": "🇸🇨"},
  {"label": "Syria", "code": 119, "flagIcon": "🇸🇾"},
  {"label": "Chad", "code": 311, "flagIcon": "🇹🇩"},
  {"label": "Togo", "code": 355, "flagIcon": "🇹🇬"},
  {"label": "Thailand", "code": 239, "flagIcon": "🇹🇭"},
  {"label": "Tajikistan", "code": 267, "flagIcon": "🇹🇯"},
  {"label": "Turkmenistan", "code": 269, "flagIcon": "🇹🇲"},
  {"label": "Timor-Leste", "code": 709, "flagIcon": "🇹🇱"},
  {"label": "Tonga", "code": 255, "flagIcon": "🇹🇴"},
  {"label": "Trinidad and Tobago", "code": 637, "flagIcon": "🇹🇹"},
  {"label": "Tunisia", "code": 129, "flagIcon": "🇹🇳"},
  {"label": "Turkey", "code": 475, "flagIcon": "🇹🇷"},
  {"label": "Tuvalu", "code": 735, "flagIcon": "🇹🇻"},
  {"label": "Taiwan", "code": 221, "flagIcon": "🇹🇼"},
  {"label": "Tanzania", "code": 353, "flagIcon": "🇹🇿"},
  {"label": "Uganda", "code": 357, "flagIcon": "🇺🇬"},
  {"label": "Ukraine", "code": 479, "flagIcon": "🇺🇦"},
  {"label": "Uruguay", "code": 643, "flagIcon": "🇺🇾"},
  {"label": "United States", "code": 502, "flagIcon": "🇺🇸"},
  {"label": "Uzbekistan", "code": 271, "flagIcon": "🇺🇿"},
  {"label": "Vatican City", "code": 441, "flagIcon": "🇻🇦"},
  {"label": "Saint Vincent and the Grenadines", "code": 665, "flagIcon": "🇻🇨"},
  {"label": "Venezuela", "code": 617, "flagIcon": "🇻🇪"},
  {"label": "Vietnam", "code": 233, "flagIcon": "🇻🇳"},
  {"label": "Vanuatu", "code": 733, "flagIcon": "🇻🇺"},
  {"label": "Samoa", "code": 729, "flagIcon": "🇼🇸"},
  {"label": "Yemen", "code": 115, "flagIcon": "🇾🇪"},
  {"label": "South Africa", "code": 349, "flagIcon": "🇿🇦"},
  {"label": "Zambia", "code": 359, "flagIcon": "🇿🇲"}
];

export default countryCodes;