import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate if using React Router
import axios from 'axios';
import API from '../API';

const Logout = () => {
    const [error, setError] = useState(false);
    const navigate = useNavigate(); // Use this for React Router

    const logout = async () => {
        try {
            await API.get('/auth/logout');
            navigate('/login'); // Use this for React Router
            // window.location.href = '/login'; // Uncomment this if not using React Router
        } catch (error) {
            setError(true);
        }
    }

    useEffect(() => {
        logout();
    }, []);

    if (!error) {
        return null; // Or a loader/spinner can be shown here
    }

    const logoutStyle = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        textAlign: 'center',
        backgroundColor: '#f2f2f2',
        color: '#333',
        fontFamily: 'Arial, sans-serif',
    };

    const buttonStyle = {
        marginTop: '20px',
        padding: '10px 20px',
        fontSize: '18px',
        color: 'white',
        backgroundColor: '#007bff',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
    };

    return (
        <div style={logoutStyle}>
            <h1>Thank You for Visiting</h1>
            <h2>Marcopolo Dashboard</h2>
            <button style={buttonStyle} onClick={() => navigate('/login')}>Log In</button>
            {/* Use window.location.href = '/login' if not using React Router */}
        </div>
    );
}

export default Logout;
