import {
  Checkbox,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Tooltip,
  IconButton,
  Collapse,
  Box,
  Typography,
  Modal,
  TextField,
  Button,
  InputLabel,
  Input,
  Autocomplete,
  FormControlLabel,
} from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import GoogleIcon from "@mui/icons-material/Google";
import EditIcon from "@mui/icons-material/Edit";
import countryCodes from "../countryCode";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CircleIcon from "@mui/icons-material/Circle";
import React, { useEffect, useState } from "react";
import API from "../../Visa/API";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Link, useLocation } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import toast from "react-hot-toast";
import queryString from "query-string";
import FolderIcon from "@mui/icons-material/Folder";
import Loader from "./Loader.js";
const Row = ({ row, handleRefresh, handleSearch, params }) => {
  const location = useLocation();
  const [duration, setDuration] = useState(row.duration);
  const [durationString, setDurationString] = useState(null);
  const [hubspotemail, setHubspotemail] = useState(null);
  const [hubspotphone, setHubspotphone] = useState(null);
  const [guarantorphone, setGuarantorphone] = useState(null);
  const [guarantoremail, setGuarantoremail] = useState(null);
  const [open, setOpen] = useState(false);
  const [lastday, setLastday] = useState(null);
  const extension = row?.visa_extension || 0;
  const [modalOpen, setModalOpen] = useState(false);
  const issue_date = new Date(row.issue_date).toLocaleDateString("en-GB");
  const country = countryCodes.find(
    (country) => country.label === row?.nationality
  );
  const country_code = country ? country.code : null;
  const country_flag = country ? country.flagIcon : null;
  const getHubspotDetails = async (hubspot_id) => {
    try {
      const response = await API.get(`/flights/user/hubspot/${hubspot_id}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching hubspot details:", error);
    }
  };

  const entry_date = row.entry_date
    ? new Date(row.entry_date).toLocaleDateString("en-GB")
    : null;
  const expiry_date = row.expiry_date
    ? new Date(row.expiry_date).toLocaleDateString("en-GB")
    : null;
  const date_of_birth = row.dob
    ? new Date(row.dob).toLocaleDateString("en-GB")
    : null;
  const exit_date = row.exit_date
    ? new Date(row.exit_date).toLocaleDateString("en-GB")
    : null;

  let visa_type_title = "";
  let visa_type = row?.visa_type ? row?.visa_type.charAt(0).toUpperCase() : "";
  switch (visa_type) {
    case "S":
      visa_type_title = "Single Entry";
      break;
    case "M":
      visa_type_title = "Multiple Entry";
    default:
      break;
  }
  const getStatusColor = (status) => {
    switch (status) {
      case "2_Active":
        return "green"; // Green color for "Active"
      case "1_Used":
        return "red"; // Red color for "Used"
      case "3_Closed":
        return "black"; // Black color for "Closed"
      case "4_Expired":
        return "blue"; // Blue color for "Expired"
      default:
        return "black"; // Default color for other statuses
    }
  };
  const color = getStatusColor(row.status);

  const handleClick = (id) => async () => {
    try {
      if (
        window.confirm(
          "Are you sure you want to verify/unverify this entry date?"
        )
      ) {
        await API.post("/uaevisa/verifyEntryDate", { id });
        toast.success("Entry Date Verified Successfully");
        if (Object.keys(params).length !== 0) {
          handleSearch(params);
        } else {
          handleRefresh();
        }
      }
    } catch (error) {
      console.error("Error verifying entry date:", error);
    }
  };
  const [exitDateModalOpen, setExitDateModalOpen] = useState(false);
  const handleClickExit = (id) => async () => {
    try {
      if (row?.exit_date_verified === false) {
        setExitDateModalOpen(true);
      } else {
        if (
          window.confirm("Are you sure you want to unverify this exit date?")
        ) {
          await API.post("/uaevisa/verifyExitDate", { id });
          toast.success("Exit Date Verified Successfully");
          if (Object.keys(params).length !== 0) {
            handleSearch(params);
          } else {
            handleRefresh();
          }
        }
      }
    } catch (error) {
      console.error("Error verifying exit date:", error);
    }
  };

  useEffect(() => {
    const number_of_extensions = extension / 30;
    if (number_of_extensions === 0) {
      setDurationString(String(duration));
    } else if (number_of_extensions === 1) {
      setDurationString(String(duration) + " + 30");
    } else if (number_of_extensions === 2) {
      setDurationString(String(duration) + " + 30 + 30");
    } else if (number_of_extensions === 3) {
      setDurationString(String(duration) + " + 30 + 30 + 30");
    }
    if (row.duration === 48) {
      setDuration(2);
    } else if (row.duration === 96) {
      setDuration(4);
    }
    if (row.entry_date) {
      setLastday(
        new Date(row.entry_date).setDate(
          new Date(row.entry_date).getDate() + Number(duration) + extension
        )
      );
    }
  }, [row.duration, row.entry_date, duration, extension]);

  const fetchHubspotDetails = async () => {
    try {
      if (row.hubspot_id) {
        const response = await getHubspotDetails(row.hubspot_id);
        setHubspotemail(response.properties.email);
        setHubspotphone(response.properties.phone);
      }
      if (row.guarantor_id) {
        const response = await getHubspotDetails(row.guarantor_id);
        setGuarantoremail(response.properties.email);
        setGuarantorphone(response.properties.phone);
      }
    } catch (error) {
      console.error("Error fetching Hubspot details:", error);
    }
  };

  function getDateDifference() {
    const today = new Date();
    const last_date_to_exit = new Date(row?.last_date_to_exit);
    const diffTime = Math.abs(last_date_to_exit - today);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (diffDays < 4) {
      return "rgba(255, 0, 0, 0.7)";
    } else if (diffDays >= 4 && diffDays <= 7) {
      return "rgb(255,127,80)";
    }
  }
  function getFontWeight() {
    if (row.entry_date_verified) {
      return "bold";
    }
  }
  function getGoogleIconColor() {
    if (
      row?.g_review?.matched === "Auto" ||
      row?.g_review?.matched === "Manual"
    ) {
      return "green";
    } else if (row?.g_review?.matched === "None") {
      return "red";
    }
  }
  const [reviewData, setReviewData] = useState("");
  const getreviewData = async () => {
    const response = await API.get(`/uaevisa/getGoogleReviews/${row?._id}`);
    if (response.status === 200) {
      if (response.data.message === "No Review Found") {
        setReviewData("");
      } else {
        setReviewData(response.data.review_text);
      }
    } else {
      toast.error("Error Fetching Review Data");
    }
  };

  return (
    <>
      <style>
        {`.no-decoration {
            text-decoration: none;
            color: inherit;
          }
            .no-decoration:hover {
  text-decoration: none;
  color: inherit;
}
@font-face {
  font-family: NotoColorEmojiLimited;
  unicode-range: U+1F1E6-1F1FF;
  src: url(https://raw.githack.com/googlefonts/noto-emoji/main/fonts/NotoColorEmoji.ttf);
}`}
      </style>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={(event) => {
              event.stopPropagation(); // Prevent click event from bubbling up
              setOpen(!open);
              fetchHubspotDetails();
            }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          <IconButton
            size="small"
            onClick={async (event) => {
              event.stopPropagation();
              setModalOpen(true);
              await getreviewData();
            }}
          >
            <Tooltip title="Google Review">
              <GoogleIcon sx={{ color: getGoogleIconColor() }} />
            </Tooltip>
          </IconButton>
          {row?.status && (
            <Tooltip title={row?.status?.replace(/\d|_/g, "")}>
              <CircleIcon sx={{ color: color, margin: "5px" }} />
            </Tooltip>
          )}

          {row.name}
        </TableCell>
        <TableCell>
          <Tooltip title={visa_type_title}>
            {row.visa_type ? (
              <span
                style={{
                  border: "1px solid white",
                  borderRadius: "30px",
                  padding: "5px 10px",
                  backgroundColor: "#EFEEE5",
                  marginRight: "10px",
                  cursor: "pointer",
                }}
              >
                {visa_type}
              </span>
            ) : null}
          </Tooltip>
          {durationString?.length === 1 ? "0" + durationString : durationString}{" "}
        </TableCell>
        <TableCell>
          {row.uid}
          <Tooltip title="Copy UID">
            <IconButton
              size="small"
              onClick={() => navigator.clipboard.writeText(row.uid)}
              color="primary"
            >
              {row.uid ? (
                <ContentCopyIcon
                  sx={{ width: "18px", height: "18px" }}
                  color="action"
                />
              ) : null}
            </IconButton>
          </Tooltip>
        </TableCell>
        <TableCell style={{ fontFamily: "NotoColorEmojiLimited" }}>
          <Tooltip style={{ cursor: "pointer" }} title={country?.label}>
            {country_flag}
          </Tooltip>
          <Tooltip title="Copy Nationality Code">
            <IconButton
              size="small"
              onClick={() => {
                navigator.clipboard.writeText(country_code);
              }}
              color="primary"
            >
              {row?.nationality ? (
                <ContentCopyIcon
                  sx={{ width: "18px", height: "18px" }}
                  color="action"
                />
              ) : null}
            </IconButton>
          </Tooltip>
        </TableCell>
        <TableCell>
          {date_of_birth}
          <Tooltip title="Copy DOB">
            <IconButton
              size="small"
              onClick={() => navigator.clipboard.writeText(date_of_birth)}
              color="primary"
            >
              {row.dob ? (
                <ContentCopyIcon
                  sx={{ width: "18px", height: "18px" }}
                  color="action"
                />
              ) : null}
            </IconButton>
          </Tooltip>
        </TableCell>
        <TableCell>{issue_date}</TableCell>
        <TableCell>{expiry_date}</TableCell>
        <TableCell>
          {entry_date &&
            (row.entry_date_verified === false ? (
              <Tooltip title="Not Verified" color="warning">
                <IconButton
                  size="small"
                  onClick={handleClick(row._id)}
                  color="warning"
                >
                  <PriorityHighIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Verified" color="success">
                <IconButton onClick={handleClick(row._id)} color="success">
                  <CheckCircleIcon />
                </IconButton>
              </Tooltip>
            ))}
          {entry_date}
        </TableCell>
        <TableCell>
          {exit_date &&
            (row.exit_date_verified === false ? (
              <Tooltip title="Not Verified" color="warning">
                <IconButton onClick={handleClickExit(row._id)} color="warning">
                  <PriorityHighIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Verified" color="success">
                <IconButton onClick={handleClickExit(row._id)} color="success">
                  <CheckCircleIcon />
                </IconButton>
              </Tooltip>
            ))}
          {exit_date}
        </TableCell>

        <TableCell
          sx={{
            backgroundColor: row?.exit_date_verified
              ? null
              : getDateDifference(),
            fontWeight: getFontWeight(),
          }}
        >
          {row?.exit_date_verified
            ? " "
            : row?.last_date_to_exit &&
              new Date(row?.last_date_to_exit).toLocaleDateString("en-GB")}
        </TableCell>
        <TableCell>
          <Tooltip title="Edit">
            <IconButton size="small">
              <Link to={`/uaevisa/edit/${row._id}`}>
                <EditIcon />
              </Link>
            </IconButton>
          </Tooltip>
          {row?.visaFileName && (
            <Tooltip title="Visa">
              <IconButton size="small">
                <Link
                  to={`https://marcopoloholidays151.sharepoint.com/sites/MarcopoloITDept/Shared%20Documents/UAE%20VISA/${row?.visaFileName}`}
                  target="_blank"
                >
                  <PictureAsPdfIcon />
                </Link>
              </IconButton>
            </Tooltip>
          )}
          {row?.insuranceFileName && (
            <Tooltip title="Insurance">
              <IconButton size="small">
                <Link
                  to={`https://marcopoloholidays151.sharepoint.com/sites/MarcopoloITDept/Shared%20Documents/Insurance/${row?.insuranceFileName}`}
                  target="_blank"
                >
                  <PictureAsPdfIcon />
                </Link>
              </IconButton>
            </Tooltip>
          )}
          {row?.extension?.length > 0 && (
            <Tooltip title="Extension">
              <IconButton size="small">
                <Link
                  to={row?.extension[row?.extension?.length - 1]?.fileURL 
            ? row?.extension[row?.extension?.length - 1]?.fileURL : `https://marcopoloholidays151.sharepoint.com/sites/MarcopoloITDept/Shared%20Documents/UAE%20VISA/${
                    row?.extension[row?.extension?.length - 1]?.fileName
                  }`}
                  target="_blank"
                >
                  <PictureAsPdfIcon />
                </Link>
              </IconButton>
            </Tooltip>
          )}
          {row?.onedrive_url && (
            <Tooltip title="OneDrive">
              <IconButton size="small">
                <Link to={row?.onedrive_url} target="_blank">
                  <FolderIcon />
                </Link>
              </IconButton>
            </Tooltip>
          )}
        </TableCell>
      </TableRow>
      {open && (
        <>
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={11}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box sx={{ margin: 1, border: "2px solid black", p: 2 }}>
                  <Typography variant="h6" gutterBottom component="div">
                    Additional Information
                  </Typography>
                  <Table>
                    <TableHead>
                      <TableCell>Visitor Phone Number</TableCell>
                      <TableCell>Visitor Email</TableCell>
                      <TableCell>Guarantor Phone Number</TableCell>
                      <TableCell>Guarantor Email</TableCell>
                      <TableCell>Entry Permit Number</TableCell>
                      <TableCell>Passport Number</TableCell>
                      <TableCell>Staff Name</TableCell>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        {hubspotphone ? (
                          <TableCell>
                            {hubspotphone}{" "}
                            <Link
                              to={`https://wa.me/${hubspotphone}`}
                              target="_blank"
                            >
                              <img
                                style={{
                                  height: "20px",
                                  width: "20px",
                                  marginLeft: "5px",
                                }}
                                src="/whatsapp.svg"
                              />
                            </Link>
                          </TableCell>
                        ) : (
                          <TableCell> </TableCell>
                        )}
                        {hubspotemail ? (
                          <TableCell>
                            {hubspotemail}
                            <Link
                              to={`https://app.hubspot.com/contacts/20340520/contact/${row?.hubspot_id}`}
                              target="_blank"
                            >
                              <img
                                style={{
                                  height: "20px",
                                  width: "20px",
                                  marginLeft: "5px",
                                }}
                                src="/hubspot.svg"
                              />
                            </Link>
                          </TableCell>
                        ) : (
                          <TableCell> </TableCell>
                        )}
                        {guarantorphone ? (
                          <TableCell>
                            {guarantorphone}
                            <Link
                              to={`https://wa.me/${guarantorphone}`}
                              target="_blank"
                            >
                              <img
                                src="/whatsapp.svg"
                                style={{
                                  height: "20px",
                                  width: "20px",
                                  marginLeft: "5px",
                                }}
                              />
                            </Link>
                          </TableCell>
                        ) : (
                          <TableCell> </TableCell>
                        )}
                        {guarantoremail ? (
                          <TableCell>
                            {guarantoremail}
                            <Link
                              to={`https://app.hubspot.com/contacts/20340520/contact/${row?.guarantor_id}`}
                              target="_blank"
                            >
                              <img
                                style={{
                                  height: "20px",
                                  width: "20px",
                                  marginLeft: "5px",
                                }}
                                src="/hubspot.svg"
                              />
                            </Link>
                          </TableCell>
                        ) : (
                          <TableCell> </TableCell>
                        )}
                        <TableCell>{row?.entryPermit || " "}</TableCell>
                        <TableCell>{row?.passport_number || " "}</TableCell>
                        <TableCell>{row?.staff_name || " "}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        </>
      )}
      <ModalReview
        reviewData={reviewData}
        open={modalOpen}
        id={row?._id}
        handleClose={() => setModalOpen(false)}
      />
      <ModalExitDate
        open={exitDateModalOpen}
        handleClose={() => setExitDateModalOpen(false)}
        data={row}
      />
    </>
  );
};
const ModalExitDate = ({ data, open, handleClose}) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };
  const getHubspotDetails = async (hubspot_id) => {
    try {
      const response = await API.get(`/flights/user/hubspot/${hubspot_id}`);
      setEmail(response.data.properties.email);
      setPhone(response.data.properties.phone);
    } catch (error) {
      console.error("Error fetching hubspot details:", error);
    }
  };
  const [email, setEmail] = useState(  "");
  const [name, setName] = useState(data?.name || "");
  const [phone, setPhone] = useState( "");
  const [dob, setDob] = useState(data?.dob || "");
  const [nationality, setNationality] = useState(data?.nationality || "");
  const [isResident, setIsResident] = useState(false); // Checkbox state

  useEffect(() => {
    setName(data?.name);
    if (data?.dob) {
      const date = new Date(data.dob);
      const formattedDate = date.toISOString().split("T")[0]; // Convert to YYYY-MM-DD format
      setDob(formattedDate);
      if(data?.hubspot_id && open){
        getHubspotDetails(data?.hubspot_id);
      }

    }
    setNationality(data?.nationality);
  }, [open]);
  const handleSubmit = async () => {
    const response = await API.post("/uaevisa/exitDateVerificationWithHubspot", {
      id: data._id,
      email,
      name,
      phone,
      dob,
      nationality,
    });
    if (response.status === 200) {
      toast.success("Exit Date Verified Successfully");
      handleClose();
    } else {
      toast.error("Error Verifying Exit Date");
    }
  }
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={{ ...style, width: 400 }}>
        <h2 id="parent-modal-title" style={{ margin: "10px" }}>
          Verification of Exit date
        </h2>

        <FormControlLabel
          control={
            <Checkbox
              checked={isResident}
              onChange={(e) => setIsResident(e.target.checked)}
            />
          }
          label="Is this person turning into a resident?"
        />

        {isResident && (
          <>
            <InputLabel focused>Email</InputLabel>
            <Input
              fullWidth
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <InputLabel focused>Name</InputLabel>
            <Input
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <InputLabel focused>Phone</InputLabel>
            <Input
              fullWidth
              placeholder="9715xxxxxxx"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <InputLabel focused>Date of Birth</InputLabel>
            <Input
              type="date"
              fullWidth
              value={dob}
              onChange={(e) => setDob(e.target.value)}
              style={{ marginBottom: "10px" }}
            />
            <Autocomplete
              disablePortal
              options={countryCodes}
              sx={{ width: 250 }}
              value={nationality ? { label: nationality } : null}
              onChange={(event, value) =>
                setNationality(value ? value.label : "")
              }
              renderInput={(params) => (
                <TextField {...params} label="Nationality" />
              )}
            />
          </>
        )}

        <Button sx={{ mt: 2 }} variant="contained" fullWidth color="primary" onClick={handleSubmit}>
          Save
        </Button>
        <Button
          sx={{ mt: 2 }}
          variant="contained"
          fullWidth
          color="secondary"
          onClick={handleClose}
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
};
const ModalReview = ({ open, handleClose, id, reviewData }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const [reviewContent, setReviewContent] = useState("");
  const handleSubmit = async () => {
    const response = await API.post("/uaevisa/googleReview", {
      id,
      reviewContent,
    });
    if (response.status === 200) {
      toast.success("Review Added Successfully");
      handleClose();
    } else {
      toast.error("Error Adding Review");
    }
  };
  return (
    <Modal
      open={open}
      onClose={() => {
        handleClose();
        setReviewContent("");
      }}
    >
      <Box sx={{ ...style, width: 400 }}>
        <h2 id="parent-modal-title" style={{ margin: "10px" }}>
          Google Review
        </h2>
        <p>{reviewData}</p>
        <TextField
          value={reviewContent}
          onChange={(e) => setReviewContent(e.target.value)}
          rows={4}
          fullWidth
          multiline
          variant="outlined"
        />
        <Button
          sx={{ mt: 2 }}
          variant="contained"
          fullWidth
          color="primary"
          onClick={handleSubmit}
        >
          Save
        </Button>
        <Button
          sx={{ mt: 2 }}
          variant="contained"
          fullWidth
          color="secondary"
          onClick={handleClose}
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
};
const VisaUserTable = ({ data, handleRefresh, handleSearch }) => {
  const location = useLocation();
  const params = queryString.parse(location.search);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);
  return (
    <Paper
      sx={{
        width: "100%",
        overflow: "hidden",
        border: "none",
        boxShadow: "none",
      }}
    >
      <TableContainer sx={{ border: "none" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell>Customer Name</TableCell>
              <TableCell>Duration</TableCell>
              <TableCell>UID</TableCell>
              <TableCell>Nationality</TableCell>
              <TableCell>Date of Birth</TableCell>
              <TableCell>Issue Date</TableCell>
              <TableCell>Expiry Date</TableCell>
              <TableCell>Entry Date</TableCell>
              <TableCell>Exit Date</TableCell>

              <TableCell>Last Date to Exit</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100vh",
                }}
              >
                <Loader />
              </div>
            ) : (
              data.map((row) => (
                <Row
                  key={row._id}
                  row={row}
                  handleRefresh={handleRefresh}
                  handleSearch={handleSearch}
                  params={params}
                />
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default VisaUserTable;
