import React from 'react';
import { Document, Packer, Paragraph, TextRun } from 'docx';
import { saveAs } from 'file-saver';
import { Grid, Button } from '@mui/material';
import API from "../../Visa/API";
  
const shareByEmail = true;

function PartnerNOCDocGenerator({  
    countrySelectVal,
    diplomaticMissionVal,
    UAEEmirateVal,
    schengenEntries,
    nonSchengenSearchDate,
    applicationDate,
    titleVal,
    nocApplicantName,
    applicantNationality,
    nocApplicantPassportNumber,
    applicantGender,
    sharePercentage,
    companyName,
    isSchengen,
    drawsSalary,
    salaryAmount,
    nocGranterName,
    granterNationality,
    nocGranterPassportNumber,
    nocGranterPhoneNumber,
    shareToEmail,
    freeTextField,
    shouldDisplayPartnerNOCDocGenerator,
  }) {


  const formatDate = (dateString, isCountryList) => {
    if (!dateString) return 'Date not set';
    const date = new Date(dateString);
    const monthNames = [
      "Jan", "Feb", "Mar", "Apr", "May", "June",
      "July", "Aug", "Sept", "Oct", "Nov", "Dec"
    ];
    if (isCountryList){
      return [
        date.getDate().toString().padStart(2, '0'),
        monthNames[date.getMonth()], // Use the month name from the array
        (date.getFullYear() % 100).toString()
      ].join('-');
    } else {
      return [
        date.getDate().toString().padStart(2, '0'),
        monthNames[date.getMonth()], // Use the month name from the array
        date.getFullYear()
      ].join('-');
    }
  }; 

  const countries_travelling_to = () => {
    if (isSchengen){
      return schengenEntries.map((entry, index, array) => {
        const isLast = index === array.length - 1;
        const prefix = isLast && array.length > 1 ? 'and ' : '';
        const suffix = !isLast ? ', ' : '';
        return (
          `${prefix}${entry.countryName} from ` +
          `${formatDate(entry.searchDate[0].startDate, true)} to ` +
          `${formatDate(entry.searchDate[0].endDate, true)}${suffix}`
        );
      }).join('').trim();
    } else {
      return `${countrySelectVal} from ` +
      `${formatDate(nonSchengenSearchDate[0].startDate, true)} to ` +
      `${formatDate(nonSchengenSearchDate[0].endDate, true)}`
    }
  };

  const closing_line = () => {
    if (isSchengen){
      return `Your assistance in granting the Schengen visa will be much appreciated.`
    } else {
      return `Your assistance in granting the necessary visa to enter ${countrySelectVal} will be much appreciated.`
    }
  };  

  const salaryStatement = () => {
    if (drawsSalary) {
      return  [ new Paragraph({}),
                new Paragraph({
                  children: [
                    new TextRun(`${applicantGender==='male'?'He':(applicantGender==='female'?'She':'He/She')} draws a salary of ${salaryAmount} AED per month from the aforementioned company.`),
                  ]
                }),
              ]
    } else {
      return ``
    }
  } 

  const freeText = () => {
    if (freeTextField.trim()) {
      return  [ new Paragraph({}),
                new Paragraph({
                  children: [
                    new TextRun(`${freeTextField.trim()}`),
                  ]
                }),
              ]
    } else {
      return ``
    }
  }  

  const handleGenerateNOC = () => {
    const numbering = {
      config: [
        {
          reference: 'my-numbering', // A unique identifier for this numbering configuration
          levels: [
            {
              level: 0,
              format: 'decimal', // This will create a numbered list
              text: '%1.', // The %1 indicates where the level number will appear
              alignment: 'left', // How the text should be aligned
              style: {
                paragraph: {
                  indent: { left: 720, hanging: 260 }, // How much indentation you want
                },
              },
            },
          ],
        },
      ],
    };
    const doc = new Document({
      styles: {
        default: {
          document: {
            run: {
              font: "Calibri",
              size: 24, 
            },
          },
        },
      },
      numbering: numbering,
      sections: [
      {
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text:`(To be printed on original company letterhead bearing original company stamp)`
              })
            ],
            alignment: 'center',
          }),
          new Paragraph({}),
          new Paragraph({}),
          new Paragraph({}),          
          new Paragraph({
            children: [
              new TextRun({
                text:`${formatDate(applicationDate, false)}\n`,
                bold: true
              })
            ]
          }),
          new Paragraph({}),
          new Paragraph({
            children: [
              new TextRun({
                text: "To: The Visa Section\n",
                bold: true 
              })
            ]
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: `${diplomaticMissionVal} of ${countrySelectVal}\n`,
                bold: true 
              })
            ]
          }), 
          new Paragraph({
            children: [
              new TextRun({
                text: `${UAEEmirateVal} – United Arab Emirates\n\n`,
                bold: true 
              })
            ]
          }),
          new Paragraph({}),
          new Paragraph({
            children: [
              new TextRun({
                text: "Subject: No Objection Certificate for Travel\n\n",
                bold: true,
                underline: true
              })
            ]
          }),
          new Paragraph({}),
          new Paragraph({
            children: [
              new TextRun("Dear Sir/Madam,\n\n")]
          }),
          new Paragraph({}),
          new Paragraph({
            children: [
              new TextRun({
                text: "This is to confirm that we do not have any objection to ",
              }),
              new TextRun({
                text: `${titleVal} ${nocApplicantName}`,
                bold: true,
              }),
              new TextRun({
                text: `'s, holder of ${applicantNationality} passport number ${nocApplicantPassportNumber}, `+
                      `travel plans to ${countries_travelling_to()}. ${applicantGender==='male'?'He':(applicantGender==='female'?'She':'He/She')} is `+
                      `the ${sharePercentage}% share partner of ${companyName}. Please find the enclosed company trade `+
                      `license, which states ${applicantGender==='male'?'his':(applicantGender==='female'?'her':'his/her')} name.`,
              }),
            ],
          }),
          ...salaryStatement(),
          ...freeText(),
          new Paragraph({}),
          new Paragraph({
            children: [
              new TextRun(`${closing_line()}\n\n`)]
          }),
          new Paragraph({}),
          new Paragraph({}),
          new Paragraph({
            children: [
              new TextRun("Kind Regards,\n\n\n")]
          }),
          new Paragraph({}),
          new Paragraph({}),
          new Paragraph({}),
          new Paragraph({
            children: [
              new TextRun(`Name: ${nocGranterName}\n`)]
          }),
          new Paragraph({
            children: [
              new TextRun(`Nationality: ${granterNationality}\n`)]
          }),
          new Paragraph({
            children: [
              new TextRun(`Passport Number: ${nocGranterPassportNumber}\n`)]
          }),
          new Paragraph({
            children: [
              new TextRun(`Cell: ${nocGranterPhoneNumber}`)]
          }), 
          new Paragraph({}),
          new Paragraph({
            text: 'Enclosed:',
          }),
          new Paragraph({
            text: 'Company Trade License',
             numbering: {
              reference: 'my-numbering', // Reference your numbering definition here
              level: 0, // The level of the item (0 in this case)
            },
          }),
          new Paragraph({
            text: 'Passport Copy, UAE Residence Visa & Emirates ID of Signatory',
            numbering: {
              reference: 'my-numbering', // Reference your numbering definition here
              level: 0, // The level of the item (0 in this case)
            },
          }),
        ]        
      }],
    });
  
    Packer.toBlob(doc).then(blob => {
      saveAs(blob, `NOC_${nocApplicantName}_${countrySelectVal}.docx`);
      console.log(shareByEmail);
      if (shareByEmail){
        const formData = new FormData();
        formData.append('file', blob, `NOC_${nocApplicantName}_${countrySelectVal}.docx`);
        formData.append('to_email_address', shareToEmail);
        formData.append('applicant_name', `${nocApplicantName}`);
        formData.append('country_name', `${countrySelectVal}`);
        formData.append('isEmployee', false);
        formData.append('isInvestor', false);
        formData.append('isPartner', true);
        formData.append('isFamily', false);
        try {
          // Send the document to the backend
          const result = API.post('/ivisa/senddoc', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
          
          console.log('Document sent successfully.');
        } catch (error) {
          console.error('Error sending document:', error);
        }        
      }
    });
  };
  
  return (
    <Grid item>
      <Button type="submit" variant="contained" disabled={!shouldDisplayPartnerNOCDocGenerator} onClick={handleGenerateNOC}>{shareByEmail?'Generate & Share NOC':'Generate NOC'}</Button>
    </Grid>
  );
}

export default PartnerNOCDocGenerator;