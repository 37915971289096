import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useEffect, useState, useMemo } from "react";
import Pagination from "../components/Pagination";
import UserTable1 from "../components/UserTable1";
import { useSelector, useDispatch } from "react-redux";
import { getDataStart, getDataSuccess } from "../dataSlice";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

import RefreshIcon from "@mui/icons-material/Refresh";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import { useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import Select from 'react-select';
import ToolTip from "@mui/material/Tooltip";

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import countryList from 'react-select-country-list'
import API from "../API";
import { Grid, Modal } from "@mui/material";
const itemsPerPage = 10;

const UserDetails = () => {
  const location = useLocation();
  const passedPage = location.state?.currentPage;
  const passedSearch = location.state?.search;
  const passedSearch2 = location.state?.search2;
  const passedSearch4 = location.state?.search4;
  const passedSearchDate = location.state?.searchDate;
  const passedSearch8 = location.state?.search8;
  const passedSearch7 = location.state?.search7;
  const passedSearch9 = location.state?.search9;
  const passedSearchCountry = location.state?.searchCountry;
  const [currentPage, setCurrentPage] = useState(passedPage?passedPage:1);
  const [openIssueApproveDateModal, setOpenIssueApproveDateModal] = useState(false);
  const [issueApprovedateData, setIssueApprovedateData] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const data = useSelector((state) => state.data.data);
  // const filter = useSelector((state) => state.filter.filter);
  const loading = useSelector((state) => state.data.loading);
  const [filter, setFilter] = useState([]);    
  const [searchDate, setSearchDate] = useState(passedSearchDate?passedSearchDate:[
      {
        startDate: null,
        endDate: null,
        key: 'selection'
      }
    ]);  
  const [isDatePickerVisible, setDatePickerVisible] = useState(false);
  const DatePickerStyle = {
    datePickerWrapper: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  };
  const dateRangeContainer = {
    dateRangeContainer: {
      position: 'absolute',
      zIndex: '1000', // Use camelCase for zIndex
      background: 'white',
      marginTop: '75px', // Add 70px padding to the top
      // Add more styling as needed
    }
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
    maxHeight: "90vh",
    width: "80vw",
    overflowY: "auto",
  }; 

  const handleDateChange = (index, key, newValue) => {
    const updatedData = issueApprovedateData.map((row, i) => {
      if (i === index) {
        return { ...row, [key]: dayjs(newValue) };
      }
      return row;
    });
    setIssueApprovedateData(updatedData);
  };  

  const formatDate = (date) => {
    const options = { month: 'short', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  };

  const isFilterActive = (filterName) => filter.includes(filterName);
  const toggleFilters = (filterNames) => {
    const updatedFilter = [...filter];

    filterNames.forEach(filterName => {
      if (updatedFilter.includes(filterName)) {
        // Remove filter
        const index = updatedFilter.indexOf(filterName);
        if (index > -1) {
          updatedFilter.splice(index, 1);
        }
      } else {
        // Add filter
        updatedFilter.push(filterName);
      }
    });

    setFilter(updatedFilter);
  };

  const countryOptions = useMemo(() => countryList().getData(), []);

  const handleNameClick = async (name) => {
    setCurrentPage(1);
    setDatePickerVisible(false);
    setSearch(name); // Update the search bar with the clicked name
    setSearch2("");
    setSearch4("");
   
    await submitSearch(name); // Implement a search submission function
  };
  const handlegroupreferenceClick = async (groupreference) => {
    setDatePickerVisible(false);
    setSearch4(groupreference); // Update the search bar with the clicked name
    setSearch2("");
    setSearch("");
    setCurrentPage(1);
    await submitReferenceSearch(groupreference); // Implement a search submission function
  };
  const submitSearch = async (name) => {
    dispatch(getDataStart());
    const response = await API.get(
      `/ivisa/search?named=${name}`
    );
    dispatch(getDataSuccess(response.data));
  };
  const submitReferenceSearch = async (reference) => {
    dispatch(getDataStart());
    const response = await API.get(
      `/ivisa/search?reference=${reference}`
    );
    dispatch(getDataSuccess(response.data));
  };
  const fetchdata = async () => {
    dispatch(getDataStart());
    try {
        const response = await API.get(`/ivisa/visa`);
        setCurrentPage(1); 
        dispatch(getDataSuccess(response.data));
    } catch (error) {
        if (error.response && error.response.status === 401) {
            if(error.preventDefault){
              error.preventDefault();
            }
            // If a 401 response was returned, navigate to the login page
            navigate('/login');
        } else {
            // Handle other types of errors here (optional)
            console.error('An error occurred:', error);
        }
    }
  };

  const dropdownStyle = {
    control: (provided) => ({
      ...provided
    }),    
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      width: '300px' // You can adjust this value to suit your needs
    })
  };
 
  const getIssueAndApprovalDate = async (selectedRows) => {
    selectedRows = await Promise.all(selectedRows.map(async (row) => {
      const response = await API.get(`/ivisa/GetIvisaDetails/${row}`);
      return response.data;
    }));
    setIssueApprovedateData(selectedRows);
    setOpenIssueApproveDateModal(true);
  }

  const handleApproveSubmit = async () => {   
    if (issueApprovedateData && issueApprovedateData.length > 0) {
      console.log(issueApprovedateData);
      try {
        if(issueApprovedateData.some(row => !row.visa_appointment_date || !row.visa_approval_date)){
          toast.error("Please fill in all the required fields");
          return;
        }
        // WE need to make sure that all approval dates are greater than the visa appointment date
        if(issueApprovedateData.some(row => row.visa_appointment_date > row.visa_approval_date)){
          toast.error("Approval date cannot be before the visa appointment date");
          return;
        }
        const response = await API.patch(
          `/ivisa/edit/status/approved`,
          {selectedRows:issueApprovedateData}
        );
        // Show a success alert
        toast.success("User(s) status changed to approved successfully")
        // Refresh the window after a brief delay
        setTimeout(() => {
          handleSubmit({ preventDefault: () => {} });
        }, 500); // Refresh after 1 second (adjust the delay as needed)
      } catch (err) {
        console.error("Error deleting Users", err);
        toast.error(
          "Error changing status to Approved. Please try again after a few moments."
        );
      }
    } else {
      alert("Please select a row to change status");
    }
  }

  const handleApprove = async (selectedRows) => {
    setDatePickerVisible(false);
    getIssueAndApprovalDate(selectedRows);
  };

  const handleCancel = async (selectedRows) => {
    setDatePickerVisible(false);
    if (selectedRows && selectedRows.length > 0) {
      try {
        const response = await API.patch(
          `/ivisa/edit/status/cancelled`,
          {selectedRows:selectedRows}
        );
        // Show a success alert
        toast.success("User(s) status changed to cancelled successfully");
        // Refresh the window after a brief delay
        setTimeout(() => {
          handleSubmit({ preventDefault: () => {} });
        }, 500); // Refresh after 1 second (adjust the delay as needed)
      }
      catch (err) {
        console.error("Error deleting Users", err);
        toast.error(
          "Error changing status to cancelled. Please try again after a few moments."
        );
      }
    } else {
      alert("Please select a row to change status");
    }
  };

  const handleRejected = async (selectedRows) => {
    setDatePickerVisible(false);
    if (selectedRows && selectedRows.length > 0) {
      try {
        const response = await API.patch(
          `/ivisa/edit/status/rejected`,
          {selectedRows:selectedRows}
        );
        // Show a success alert
        toast.success("User(s) status changed to rejected successfully")

        setTimeout(() => {
          handleSubmit({ preventDefault: () => {} });
        }, 500); // Refresh after 1 second (adjust the delay as needed)
      } catch (err) {
        console.error("Error deleting Users", err);
        toast.error(
          "Error changing status to refunded. Please try again after a few moments."
        );
      }
    } else {
      alert("Please select a row to change status");
    }
  };
  const handleProcess = async (selectedRows) => {
    setDatePickerVisible(false);
    if (selectedRows && selectedRows.length > 0) {
      try {
        const response = await API.patch(
          `/ivisa/edit/status/processing`,
          {selectedRows:selectedRows}
        );
        toast.success("User(s) status changed to processing successfully")
        setTimeout(() => {
          handleSubmit({ preventDefault: () => {} });
        }, 500); // Refresh after 1 second (adjust the delay as needed)
      } catch (err) {
        console.error("Error deleting Users", err);
        toast.error(
          "Error changing status to processing. Please try again after a few moments."
        );
      }
    } else {
      alert("Please select a row to change status");
    }
  };
  
  const searchPast15Days = async () => {
    const currentDateToday = new Date();
    currentDateToday.setDate(currentDateToday.getDate() - 15);
    dispatch(getDataStart());
    const response = await API.get(`/ivisa/search?toAppointmentDate=${currentDateToday.toISOString().slice(0,10)}&underprocess=true`);
    dispatch(getDataSuccess(response.data));
  };
  const searchNoContactLinked = async () => {
    dispatch(getDataStart());
    const response = await API.get(`/ivisa/search?searchNoContactLinked=true`);
    dispatch(getDataSuccess(response.data));
  };

  useEffect(() => {
    const e = { preventDefault: () => {} };
    handleSubmit(e, passedPage);
  }, [passedPage, passedSearch, passedSearch2, passedSearch4, passedSearchDate, passedSearch8, passedSearch7, passedSearch9]); 
  

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedData = data.slice(startIndex, endIndex);
  const currentDate = new Date();
  const day = currentDate.getDate();


  const month = currentDate.getMonth() + 1; // Months are zero-based
  const year = currentDate.getFullYear();

  const formattedDay = day < 10 ? `0${day}` : `${day}`;
  const formattedMonth = month < 10 ? `0${month}` : `${month}`;
  const formattedDate = `${formattedMonth}/${formattedDay}/${year}`;
  const [search, setSearch] = useState(passedSearch?passedSearch:"");
  // const [search3, setSearch3] = useState("");
  const [search2, setSearch2] = useState(passedSearch2?passedSearch2:"");
  const [searchCountry, setSearchCountry] = useState(passedSearchCountry?passedSearchCountry:null);
  // const [search5, setSearch5] = useState("");
  const [search4, setSearch4] = useState(passedSearch4?passedSearch4:"");
  const [search7, setSearch7] = useState(passedSearch7?passedSearch7:"");
  const [search8, setSearch8] = useState(passedSearch8?passedSearch8:"");
  const [search9,setSearch9] = useState(passedSearch9?passedSearch9:"");
  const [switchcontrol, setSwitchcontrol] = useState(false);
  const [noContactCheckbox, setNoContactCheckbox] = useState(false);
  const handleSubmit = async (e, passedPage) => {
    const country = search2;
    const fromAppointmentDate = searchDate[0].startDate ? new Date(searchDate[0].startDate) : "";
    const toAppointmentDate = searchDate[0].endDate ? new Date(new Date(searchDate[0].endDate).getTime() + 24 * 60 * 60 * 1000)  : "";
    if (
      (search ||
        search2 ||
        fromAppointmentDate ||
        search4 || search8 || search7 || search9 ) &&
      (search.length > 0 ||
        fromAppointmentDate ||
        search2.length > 0 ||
        search4?.length > 0 || search8?.length > 0 || search7.length > 0 || search9.length > 0
       )
    ) {
      setCurrentPage(passedPage?passedPage:1);
      setDatePickerVisible(false);
      e.preventDefault();
      dispatch(getDataStart());
      const response = await API.get(
        `/ivisa/search?named=${search}&country=${country}&reference=${search4}&fromAppointmentDate=${fromAppointmentDate}&toAppointmentDate=${toAppointmentDate}&hubspot=${search8}&status=${search7}&type=${search9}`
      ); 
      dispatch(getDataSuccess(response.data));
    } else {
      fetchdata();
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const clearFilter = (allClear) => {
    setCurrentPage(1);
    setSearch("");
    setSearch9("");
    setSwitchcontrol(false);
    setDatePickerVisible(false);
    setSearchDate([ 
          {
            startDate: null,
            endDate: null,
            key: 'selection'
          }
        ]);
    setSearch2("");
    setSearchCountry(null);
    setSearch4("");
    setSearch7("");
    setSearch8("");
    if (allClear===true){
      setSwitchcontrol(false);
      setNoContactCheckbox(false);  
      fetchdata();
    }    
  };
  const handleCheckboxChange = (e) => {
    clearFilter(false);
    setSwitchcontrol(e.target.checked);
    if (e.target.checked) {
      searchPast15Days();
    } else {
      window.location.reload();
    }
  };
  const handleNoContactCheckbox = (e) => {
    clearFilter(false);
    setNoContactCheckbox(e.target.checked);
    if (e.target.checked) {
      searchNoContactLinked();
    } else {
      window.location.reload();
    }
  };
  const handleSearch2Change = useCallback((e) => {
    if (e?.label){
        if (e.label === 'Turkey'){
          setSearch2("Türkiye");
        } else {
          setSearch2(e.label);  
        }
        setSearchCountry(e);
    }
  }, []);

  const handleSearchChange = useCallback((e) => {
    setSearch(e.target.value);
  }, []);
  const handleSearch4Change = useCallback((e) => {
    setSearch4(e.target.value);
  }, []);
  return (
    <>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className="d-flex flex-column flex-sm-column flex-lg-row searching container">
        <div className="input-group rounded mb-3 mr-2 row">
          <div className="mt-2 col-md-6">
            <label for="hubspot_id">Applicant Name</label>
            <input
              id="name"
              type="search"
              className={`form-control rounded ${
                search ? "border-success border-2" : ""
              }`}
              placeholder="Enter Name"
              aria-label="Search"
              aria-describedby="search-addon"
              value={search}
              onChange={handleSearchChange}                
            />
          </div>   
          <div className="mt-2 col-md-6">
            <label for="hubspot_id">Contact Search</label>
            <input
              id="hubspot_id"
              type="search"
              className={`form-control rounded${
                search8 ? "border-success border-2" : ""
              }`}
              placeholder="Enter Details"
              aria-label="Search"
              aria-describedby="search-addon"
              value={search8}
              onChange={(e) => setSearch8(e.target.value)}
            />
          </div>                       
          <div className="mt-2 col-md-6">
            <label for="country">Visa Country</label>
            <Select  
                styles={dropdownStyle} 
                className={`${
                  search2 ? "border-success border-2" : ""
                }`}              
                id="country" 
                name="country" 
                label="Enter Country"  
                value={searchCountry}
                options={countryOptions} 
                onChange={handleSearch2Change} />
          </div>
          <div className="mt-2 col-md-6" style={{display:"flex",flexDirection:"column"}}>
              {/*<input type="date" id="fromAppointmentDate" value={search5} onChange={(e) => setSearch5(e.target.value)} style={{padding:"5px"}}/>*/}
              <div style={DatePickerStyle.datePickerWrapper}>
                <label for="">Submission Date</label>
                <button onClick={() => setDatePickerVisible(!isDatePickerVisible)} className={`form-control rounded ${
                  searchDate[0].startDate ? "border-success border-2" : ""}`}>
                  {searchDate[0].startDate || searchDate[0].endDate || isDatePickerVisible ? (
                    <span>
                      {searchDate[0].startDate ? formatDate(searchDate[0].startDate) : ''}
                      {searchDate[0].endDate ? ` - ${formatDate(searchDate[0].endDate)}` : ''}
                      {isDatePickerVisible?<span style={{whiteSpace: 'pre'}}>{'\u0009\u2715'}</span>:''}                    
                    </span>
                  ) : 'Select Date'}
                </button>          
                {isDatePickerVisible && (
                  <div style={dateRangeContainer.dateRangeContainer}>
                    <DateRange
                      editableDateInputs={false}
                      onChange={item => setSearchDate([item.selection])}
                      moveRangeOnFirstSelection={false}
                      ranges={searchDate}
                    />
                  </div>
                )}          
              </div>
          </div>     
          {isFilterActive("Reference No") && (
            <div className="mt-2 col-md-3">
              <label for="reference_number">Reference Number</label>
              <input
                id="reference_number"
                type="search"
                className={`form-control rounded ${
                  search4 ? "border-success border-2" : ""
                }`}
                placeholder="Enter Reference Number"
                aria-label="Search"
                aria-describedby="search-addon"
                value={search4}
                onChange={handleSearch4Change}                
              />
            </div>
          )}
          {isFilterActive("Visa Type") && (
              <div className="mt-2 col-md-3">
              <label for="visatype">Visa Type</label>
              <select
                id="visatype"
                className={`form-select form-select-lg rounded ${
                  search9 ? "border-success border-2" : ""
                }`}
                value={search9}
                onChange={(e) => setSearch9(e.target.value)}
                aria-label="Select Visa Type"
                >
                  <option value="">Select Visa Type</option>
                  <option value="Stamp">Stamp</option>
                  <option value="E-Visa">E-Visa</option>
                </select>
              </div>
          )}
          {isFilterActive("Status") && (
            <div className="mt-2 col-md-3">
              <label for="status">Status</label>
              <select
                id="status"
                className={`form-select form-select-lg rounded ${
                  search7 ? "border-success border-2" : ""
                }`}
                value={search7}
                onChange={(e) => setSearch7(e.target.value)}
                aria-label="Select Status"
              >
                <option value="">Select Status</option>
                <option value="Approved">Approved</option>
                <option value="Rejected">Rejected</option>
                <option value="Under Process">Under Process</option>
                <option value="Changed">Changed</option>
                <option value="Cancelled">Cancelled</option>
              </select>
            </div>
          )}
          <div className="col-md-9 mt-4" style={{ display: "flex", alignItems: "center" }}>
            <div className="row">
              <div className="col-md-1 mt-1">
                <Button
                  type="submit"
                  variant="contained"
                  className="btn btn-primary mr-1"
                  onClick={(handleSubmit)}
                >
                  <SearchIcon />
                </Button>
              </div>
              <div className="col-md-1 mt-1">
                <Button
                  variant="contained"
                  className="btn btn-primary mr-1"
                  onClick={() => {
                    clearFilter(true);
                  }}
                >
                  <RefreshIcon />
                </Button>   
              </div> 
              <div className="col-md-1 mt-1">
                <Button  
                  className="btn btn-primary mr-1" 
                  variant="contained"  
                  onClick={() => toggleFilters([
                      "To Appointment Date",
                      "Reference No",
                      "Status",
                      "Hubspot",
                      "Visa Type",
                      "Phone Number",
                    ])}>
                  <ExpandMoreIcon />
                </Button>
              </div>
              <div className="col-md-3 mt-1">
                <div className="m-3 form-check" style={{display:"flex",alignItems:"center",width:"200px"}}>
                  <ToolTip title="Under Process entries that are 15 days and older">
                    <input className="form-check-input checker" type="checkbox" checked={switchcontrol} onChange={handleCheckboxChange} id="flexCheckDefault"/>
                    <label className="form-check-label" for="flexCheckDefault">
                      UP &gt; 15 Days
                    </label>
                  </ToolTip>
                </div>
              </div>
              <div className="col-md-3 mt-1">
                <div className="m-3 form-check" style={{display:"flex",alignItems:"center",width:"250px"}}>
                  <ToolTip title="Entries that have no Hubspot Contact linked or any phone/email">
                    <input className="form-check-input checker" type="checkbox" checked={noContactCheckbox} onChange={handleNoContactCheckbox} id="noContactCheckBox"/>
                    <label className="form-check-label" for="noContactCheckBox">
                      Entries with No Contact Details
                    </label>
                  </ToolTip>
                </div>            
              </div>
            </div>
          </div>  
          <div className="mt-4 col-md-3 justify-content-end" style={{ display: "flex", alignItems: "center" }}>        
            <Button  className="btn btn-primary mr-1" variant="contained" onClick={()=> {
                  window.location.href = "/visa/newvisaentry"
                }}>
              New Application Entry
            </Button>
          </div>          
        </div>

        </div>
        <div className="row">
{/*        <div style={{ display: "flex", alignItems: "center" }}>
          <MultipleSelectCheckmarks />
        </div>*/}

      </div>
      {loading ? (
        <Box sx={{ display: "flex" }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <UserTable1
            data={displayedData}
            onApprove={handleApprove}
            onRefund={handleRejected}
            onProcess={handleProcess}
            onCancel = {handleCancel}
            onNameClick={handleNameClick}
            onGroupReferenceClick={handlegroupreferenceClick}
            submitSearch={handleSubmit}
            currentPage={currentPage}
            search={search}
            search2={search2}            
            search4={search4}
            search7={search7}
            search8={search8}
            search9={search9}
            searchDate={searchDate}
            searchCountry={searchCountry}
          />
          <Pagination
            totalItems={data.length}
            itemsPerPage={itemsPerPage}
            onPageChange={handlePageChange}
            currPage={currentPage}
          />
        </>
      )}
      <style>
        {`
      input::placeholder{
        opacity:0.5 !important;
      }
      @media (max-width: 767px) {
        .searching {
            margin-left: 20px;
        }
      }

      `}
      </style>
    </div>
    <Modal open={openIssueApproveDateModal} onClose={() => setOpenIssueApproveDateModal(false)}>
    <Box sx={style}>
        <h3>Please Update Visa Appointment and Approval date</h3>
        <p>*Required to compute processing time</p>
        <br />
         <Grid container spacing={2}> 
        {issueApprovedateData.map((row, index) => (
          <>
          <Grid item xs={3}>
            <p>{row.name}</p>
          </Grid>
          <Grid item xs={3}>
            <p>{row.visa_country} - {row.visa_appointment_date}</p>
          </Grid>
          <Grid item xs={3}>  
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Visa Appointment Date"
                  value={dayjs(row.visa_appointment_date)}
                  onChange={(newValue) => {
                    handleDateChange(index, "visa_appointment_date", newValue);
                  }} 
                  renderInput={(params) => <TextField {...params} required />}
                />
            </LocalizationProvider> 
          </Grid>
          <Grid item xs={3}>  
             <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Approval Date"
                value={dayjs(row.visa_approval_date)}
                onChange={(newValue) => {
                  handleDateChange(index, "visa_approval_date", newValue);
                }} 
                renderInput={(params) => <TextField {...params} required />}
              />
            </LocalizationProvider> 
          </Grid>          
          </>
        ))}
        </Grid>           

   
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" , margin: "10px", "gap": "10px" }}>
            <Button variant="contained" color="secondary" onClick={() => setOpenIssueApproveDateModal(false)}>
                Cancel
            </Button>
            <Button variant="contained" color="primary" onClick={() => handleApproveSubmit()}>
                Submit
            </Button>
        </div>
    </Box>
  </Modal>
  </>
  );
};

export default UserDetails;
