import React from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';

import HomePage from './HomePage';
import MyNavbar from './Flights/components/FlightNavbar';
import FlightDeletedUsers from './Flights/pages/FlightDeletedUsers';
import FlightLatest from './Flights/pages/FlightLatest';
import FlightStaff from './Flights/pages/FlightStaff';
import FlightUserDetails from './Flights/pages/FlightUserDetails';
import FlightContactForm from './Flights/components/FlightContactForm';
import FlightEditForm from './Flights/components/FlightEditForm';
import FlightUpload from './Flights/components/FlightUpload';
import FlightContactDirectory from './Flights/pages/FlightContactDirectory';
import UserDetails from './Visa/pages/UserDetails';
import MyNavbar1 from './Visa/components/Navbar';
import FileUpload from './Visa/components/Upload';
import Requirements from './Visa/pages/Requirements';
import Notes from './Visa/components/Notes';
import Latest from './Visa/pages/Latest';
import VisaCharts from './Visa/components/Charts';
import Charts from './Flights/components/FlightCharts';
import StaffNavbar from './StaffNavbar';
import EditForm from './Visa/components/EditForm';
import ContactForm from './Visa/components/ContactForm';
import Notifications from './Visa/components/Notifications';
import 'animate.css';
import NewEntry from './Visa/components/NewEntry';
import EVisa from './Visa/pages/EVisa';
import EVisaNotes from './Visa/components/EVisaNotes';
import { Toaster } from 'react-hot-toast';
import Contact from './Visa/pages/Contact';
import Login from './Login';
import Logout from './Visa/pages/Logout';
import API from './Visa/API';
import NOCGenerator from './DocGenerator/pages/NOCGenerator'
import { CookiesProvider } from 'react-cookie';
import { Navigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import EmailTemplates from './Visa/pages/EmailTemplates';
import "./loader.css";

//ALL DMC IMPORTS 
import  DMCNavbar from './DMC/components/DMCNavbar'
import DMCHome from './DMC/pages/DMCHome'
import InviteDMC from './DMC/pages/DMCInvite';
import ReviewPage from './DMC/pages/DMCReviewPage';
import DMCForms from './DMC/pages/DMCReviewForms';
import AdminSettings from './DMC/pages/AdminSettings';
import CompanyProfileDirectory from './DMC/pages/ContactDirectory';
import ViewForm from './DMC/pages/DMCMainForm';
import VisaUpload from './UaeVisa/pages/VisaUpload';
import VisaHomePage from './UaeVisa/pages/VisaHomePage';
import UaeVisaNavbar from './UaeVisa/components/UaeVisaNavbar';
import Customer from './CustomerPortal/customer';
import CustomerNavbar from './CustomerPortal/CustomerNavbar';
import EVisaUpload from './Visa/pages/EVisaUpload';
import VisaProcessingTime from './Visa/pages/VisaProcessingTime';
import SmsTable from './internalApp/SmsTable';
import SMSNavbar from './internalApp/SmsNavbar';

import Groups from './Groups/pages/groupList';
import GroupsNavbar from './Groups/pages/groupNavBar';
import GroupVerify from './Groups/pages/groupVerify';

function ProtectedRoute({ children }){
  const [authStatus, setAuthStatus] = useState({ checked: false, isAuthenticated: false });
  useEffect(() => {
      API.get('/auth/authenticated')
          .then(response => {
              // Assume the response contains a flag indicating authentication status
              setAuthStatus({ checked: true, isAuthenticated: response.data.isAuthenticated });
          })
          .catch(() => {
              setAuthStatus({ checked: true, isAuthenticated: false });
          });
  }, []);

  if (!authStatus.checked) {
      // Optionally, render a loading indicator while checking auth status
      return <div class="loader-container"><div class="loader"></div></div>;

  }
  if (!authStatus.isAuthenticated) {
      // Redirect to the login page if not authenticated
      return <Navigate to="/login" replace />;
  }  
  return children;
};

function App() {
  return (
    <CookiesProvider>
      <BrowserRouter>
        <RoutesWithNavbar />
      </BrowserRouter>
    </CookiesProvider>
  );
}

function RoutesWithNavbar() {
  const location = useLocation();
  const path = location.pathname;
  let navbar;
  if (path.startsWith("/visa")) {
    navbar = <MyNavbar1 />;
  } else if (path.startsWith("/flights")) {
    navbar = <MyNavbar />;
  } else if (path.startsWith("/DMC")) {
    navbar = <DMCNavbar />;
  } else if (path.startsWith("/uaevisa")) {
    navbar = <UaeVisaNavbar/>
  } else if (path.startsWith("/internal")){
    navbar = <SMSNavbar/>;
  } else if (path.startsWith("/groups")) {
    navbar = <GroupsNavbar />;
  } 
  else  {
    navbar = <></>;
  }
  return (
    <>
      <Toaster />
      {navbar}
      <Routes>
        <Route path="/" element={<ProtectedRoute><HomePage /></ProtectedRoute>} />
        <Route path="/staff" element={<ProtectedRoute><FlightStaff/></ProtectedRoute>} />

        <Route path="/flights/charts" element={<ProtectedRoute><Charts /></ProtectedRoute>} />
        <Route path="/flights/users/deleted" element={<ProtectedRoute><FlightDeletedUsers /></ProtectedRoute>} />
        <Route path="/flights/latest" element={<ProtectedRoute><FlightLatest /></ProtectedRoute>} />
        <Route path="/flights/tickets" element={<ProtectedRoute><FlightUserDetails /></ProtectedRoute>} />
        <Route path="/flights/edit/:id" element={<ProtectedRoute><FlightEditForm /></ProtectedRoute>} />
        {/*Please check if there is any breaking change here*/}
        <Route path="/flights/edit/contact/:id" element={<ProtectedRoute><FlightContactForm /></ProtectedRoute>} /> 
        <Route path="/flights/uploadpdf" element={<ProtectedRoute><FlightUpload /></ProtectedRoute>} />
        <Route path="/flights/contact-directory" element={<ProtectedRoute><FlightContactDirectory /></ProtectedRoute>} />

        <Route path='/visa/applications' element={<ProtectedRoute><UserDetails/></ProtectedRoute>}/>
        <Route path='/visa/uploadpdf' element={<ProtectedRoute><FileUpload /></ProtectedRoute>} />
        <Route path='/visa/requirements' element={<ProtectedRoute><Requirements /></ProtectedRoute>} />
        {/* <Route path='/visa/notes/:id' element={<ProtectedRoute><Notes /></ProtectedRoute>} /> */}
        <Route path='/visa/latest' element={<ProtectedRoute><Latest /></ProtectedRoute>} />
        <Route path='/visa/charts' element={<ProtectedRoute><VisaCharts /></ProtectedRoute>} />
        <Route path='/visa/edit/:id' element={<ProtectedRoute><EditForm /></ProtectedRoute>} />
        <Route path='/visa/edit/contact/:id' element={<ProtectedRoute><ContactForm /></ProtectedRoute>} />
        <Route path='/visa/notifications' element={<ProtectedRoute><Notifications /></ProtectedRoute>} />
        <Route path='/visa/newvisaentry' element={<ProtectedRoute><NewEntry/></ProtectedRoute>} />
        <Route path='/visa/e-visa' element={<ProtectedRoute><EVisa/></ProtectedRoute>} />
        <Route path="/visa/evisaupload" element={<ProtectedRoute><EVisaUpload/></ProtectedRoute>}></Route>
        <Route path='/visa/visaprocessing' element={<ProtectedRoute><VisaProcessingTime/></ProtectedRoute>} />

        {/* <Route path='/visa/e-visa/notes/:id' element={<ProtectedRoute><EVisaNotes /></ProtectedRoute>} /> */}
        <Route path='/visa/contact' element={<ProtectedRoute><Contact /></ProtectedRoute>} />
        <Route path='/visa/emailstemplates' element={<ProtectedRoute><EmailTemplates /></ProtectedRoute>} />
        <Route path='/login' element={<Login />} />
        <Route path='/logout' element={<ProtectedRoute><Logout /></ProtectedRoute>} />

        <Route path='/visa/nocgenerator' element={<ProtectedRoute><NOCGenerator/></ProtectedRoute>}/>

        {/*THESE ADDTIONS ARE FOR THE NEW DMC FORM APPLICATION UI */}
        <Route path = '/DMC/main' element={<ProtectedRoute><DMCHome/></ProtectedRoute>}/>
        <Route path = '/DMC/invite' element = {<ProtectedRoute><InviteDMC /></ProtectedRoute>} />
        <Route path = '/DMC/review-forms' element = {<ProtectedRoute><DMCForms /></ProtectedRoute>} />
        <Route path = '/DMC/review-forms/:id' element = {<ProtectedRoute><ReviewPage /></ProtectedRoute>} />
        <Route path = '/DMC/main-forms/:id' element = {<ProtectedRoute><ViewForm /></ProtectedRoute>} />
        <Route path = '/DMC/admin-settings' element = {<ProtectedRoute><AdminSettings /></ProtectedRoute>} />
        <Route path = '/DMC/emergency-contacts' element = {<ProtectedRoute><CompanyProfileDirectory /></ProtectedRoute>} />
        
        {/* tThese are the UAE visa ROutes */}
        <Route path='/uaevisa/uploadpdf' element={<ProtectedRoute><VisaUpload /></ProtectedRoute>} />
        <Route path='/uaevisa/home' element={<ProtectedRoute><VisaHomePage /></ProtectedRoute>} />
        <Route path='/uaevisa/edit/:id' element={<ProtectedRoute><VisaUpload/></ProtectedRoute>} />
        
        {/*These are the customer portal routes*/ }
        <Route path='/customerportal/:id' element={<ProtectedRoute><Customer /></ProtectedRoute>} />

        <Route path='/internal/home' element={<ProtectedRoute><SmsTable/></ProtectedRoute>} />

        {/* Routes For Group Management */}
        <Route path='/groups' element={<ProtectedRoute><Groups /></ProtectedRoute>} />
        <Route path='/group-verification/:id' element={<ProtectedRoute><GroupVerify/></ProtectedRoute>} />
      </Routes>
    </>
  );
}
export default App;
