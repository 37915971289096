import React, { useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import PhoneIcon from "@mui/icons-material/Phone";
import CloudDoneRoundedIcon from '@mui/icons-material/CloudDoneRounded';
import GoogleIcon from '@mui/icons-material/Google';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch } from "react-redux";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
// import {
//   getDataAscending,
//   getDataDescending,
//   sortByDepartureDateAscending,
//   sortByDepartureDateDescending,
// } from "../dataSlice";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import CircleTwoToneIcon from '@mui/icons-material/CircleTwoTone';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ToolTip from "@mui/material/Tooltip";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import { Link } from "react-router-dom";
import API from "../API";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { TextField } from "@mui/material";
  const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
      display: "flex",
      flexDirection: "column",
  };

export default function UserTable1({
  data,
  onApprove,
  onRefund,
  onProcess,
  onCancel,
  onGroupReferenceClick,
  onConfirm,
  submitSearch,
  currentPage,
  search,
  search2,            
  search4,
  search7,
  search8,
  search9,
  search10,
  searchDate,
  searchCountry
}) {
  const [selectedRows, setSelectedRows] = useState([]);
  const [mainCheck, setMainCheck] = useState(false);
  const [openNoRequirementsModal, setOpenNoRequirementsModal] = useState(false);
  const navigate = useNavigate();  
  const onMainCheck = () => {
    setMainCheck((prevMainCheck) => {
      const newCheck = !prevMainCheck;

      if (newCheck) {
        // If main checkbox is checked, select all rows
        const allIds = data.map((row) => row._id);
        setSelectedRows(allIds);
      
      } else {
        // If main checkbox is unchecked, clear selection
        setSelectedRows([]);
      }

      return newCheck;
    });
  };
  const handleConfirm = () => {
    onConfirm(selectedRows);
    setSelectedRows([]);
  };

  const handleApprove = () => {
    onApprove(selectedRows);
    setSelectedRows([]);
  };
  const handleRefund = () => {
    onRefund(selectedRows);
    setSelectedRows([]);
  };
  const handleProcess = () => {
    onProcess(selectedRows);
    setSelectedRows([]);
  };
  const handleCancel = () => {
    onCancel(selectedRows);
    setSelectedRows([]);
  };
  const getAirportName = async (code) => {
    const options = {
      method: "GET",
      url: "https://airport-info.p.rapidapi.com/airport",
      params: { iata: code },
      headers: {
        "X-RapidAPI-Key": "adb92967f9mshc7a132f0c140613p1bd516jsn45c226219870",
        "X-RapidAPI-Host": "airport-info.p.rapidapi.com",
      },
    };

    try {
      const response = await axios.request(options);
      return response.data.location + " - " + response.data.name;
    } catch (error) {
      console.error(error);
    }
  };
  const getHubspotData = async (id) => {
    let response = await API.get(
      `/flights/user/hubspot/${id}`
    );
    return response.data;
  };

  const getRequirement = async (appointmentId) => {
    if (appointmentId) {
      try {
        const response = await API.get(`/ivisa/get_requirement/${appointmentId}`);
        const requirement = response.data;
        if (requirement && requirement.link) {
          window.open(requirement.link, '_blank');
        } else {
          setOpenNoRequirementsModal(true); // Open the dialog
        }
      } catch (error) {
        console.error("Error fetching the requirement:", error);
      }
    }
  };  

  const convertDateToString = (date2) => {
    if (!date2) {
      return "-"; // Return a default value or handle the case when date2 is undefined/null
    }
  
    const newdate = date2.split("T");
    const date = newdate[0];
    const time = newdate[1].split(":");
    const splitted_date = date.split("-");
    const year = splitted_date[0].slice(2, 4);
    const month = giveStringMonth(splitted_date[1]);
    const day = splitted_date[2];
    const time1 = `${time[0]}:${time[1]}`;
    const date1 = `${day}-${month}-${year} ${time1}`;
    return date1;
  };
  
  const giveStringMonth = (month) => {
    // i get the month in digit and i return the first three letter of the month in string
    let monthString = "";
    switch (month) {
      case "01":
        monthString = "Jan";
        break;
      case "02":
        monthString = "Feb";
        break;
      case "03":
        monthString = "Mar";
        break;
      case "04":
        monthString = "Apr";
        break;
      case "05":
        monthString = "May";
        break;
      case "06":
        monthString = "Jun";
        break;
      case "07":
        monthString = "Jul";
        break;
      case "08":
        monthString = "Aug";
        break;
      case "09":
        monthString = "Sep";
        break;
      case "10":
        monthString = "Oct";
        break;
      case "11":
        monthString = "Nov";
        break;
      case "12":
        monthString = "Dec";
        break;
      default:
        monthString = "Jan";
        break;
    }
    return monthString;
  };

  const Row = ({ row }) => {
    // need to subtract today and row.appointment_date
    function getDateDifference(){
      const today = new Date();
      const appointment_date = new Date(row.visa_appointment_date);
      const diffTime = Math.abs(appointment_date - today);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
      if(diffDays < 4){
        return "rgba(255, 0, 0, 0.7)";
      }
      else if (diffDays >= 4 && diffDays <= 7){
        return "rgb(255,127,80)";
      }
    } 

    const [rowcolor, setRowcolor] = useState("");
    const [open, setOpen] = useState(false);

    const handleCheckboxChange = (_id) => {
      const newSelectedRows = selectedRows.includes(_id)
        ? selectedRows.filter((id) => id !== _id)
        : [...selectedRows, _id];
      setSelectedRows(newSelectedRows);
    };  
    const [hubspotName, setHubspotName] = useState("");
    const [hubspotEmail, setHubspotEmail] = useState("");
    const [hubspotNumber, setHubspotNumber] = useState("");
    const [copySuccess, setCopySuccess] = useState("");
    const [openGReviewModal, setOpenGReviewModal] = useState(false);
    const [gReview, setGReview] = useState({});
    const [activeGVisaForReview, setActiveGVisaForReview] = useState({});
    const [openCreateEditReviewModal, setOpenCreateEditReviewModal] = useState(false);
    // useEffect(() => {
    //   getDateDifference();
    // }, []);
    useEffect(() => {
      if (open) {
        if (row.hubspot_id) {
          getHubspotData(row.hubspot_id)
            .then((res) => {
              setHubspotName(res.properties.firstname);
              setHubspotEmail(res.properties.email);
              setHubspotNumber(res.properties.phone);
            })
            .catch((error) => {
              console.error("Error getting hubspot data", error);
            });
        }
      }
    }, [open, row.departure_code, row.arrival_code]);
    const getStatusColor = (status) => {
      console.log(status);
      switch (status) {
        case "Approved":
          return "green"; // Green color for "Active"
        case "Rejected":
          return "red"; // Yellow color for "Refunded"
        case "Under Process":
          return "orange";
        case "Changed":
          return "blue";
        case "Cancelled":
          return "black";
        default:
          return "black"; // Default color for other statuses
      }
    };
    // const color = getStatusColor(row.visa_status);

    const onViewGoogleReviewClick = async (row) => {
      const googleReview = await API.get(`/ivisa/review/${row.g_review?.review_id}`)
      setGReview(googleReview.data);
      setActiveGVisaForReview(row);
      setOpenGReviewModal(true);
    };

    const removeGoogleReview = async () => {
      try {
        const response = await API.post(`/ivisa/edit_review/${activeGVisaForReview._id}`, {  
          app: "ivisa",
          matched: null,
          review_id: null,
          score: null,
        });
        if (response.status === 200) {
          toast.success("Google Review Removed Successfully");
          setOpenGReviewModal(false);
          setOpenCreateEditReviewModal(true);
          setTimeout(() => {
            submitSearch();
          }, 500);      
        }
      } catch (error) {
        console.error("Error removing google review:", error);
      }
    }

    const onCreateGoogleReviewClick = async (row) => {
      setActiveGVisaForReview(row);
      setGReview({"full_name": row.name, "matched": "Manual", "review_text": ""});
      setOpenCreateEditReviewModal(true);
      setOpenGReviewModal(false);
    };

    const saveGoogleReview = async () => {
      let newGReview = {};
      try{
        newGReview = await API.post(`/ivisa/add_new_review`, {
          full_name: gReview.full_name,
          review_text: gReview.review_text,
        });
      } catch (error) {
        console.error("Error saving google review:", error);
      }
      try {
        const response = await API.post(`/ivisa/edit_review/${activeGVisaForReview._id}`, {
          app: "ivisa",
          matched: "Manual",
          review_id: newGReview.data._id,
          score: 100,
        });
        if (response.status === 200) {
          toast.success("Google Review Added Successfully");
          setOpenGReviewModal(false);
          setOpenCreateEditReviewModal(false);
          setTimeout(() => {
            submitSearch();
          }, 500);          
        }
      } catch (error) {
        console.error("Error removing google review:", error);
      }
    }      

    const regex = /https?:\/\/[^\s",]+/g;  // The 'g' flag is for global search
    const matches = row?.appointment_file_url?.match(regex);
    const matches_od_folder = row?.onedrive_url?.match(regex);
    const RowDelete = async (id) => {
      try {
        if(window.confirm("Are you sure you want to delete")){
          const response = await API.delete(`/ivisa/row/${id}`);
          if (response.status === 200) {
            toast.success("Entry Deleted Successfully");
            submitSearch();
          }
        }
        // Handle further actions like showing success message or redirecting
      } catch (error) {
        console.error("Error submitting form:", error);
        // Handle errors (e.g., display error message)
      }
    }

    const copyToClipBoard = async copyMe => {
      let name = '';
      let email = '';
      let phoneNumber = '';

      if (copyMe.hubspot_id) {
        try {
          const res = await getHubspotData(copyMe.hubspot_id);
          name = res.properties.firstname;
          email = res.properties.email;
          phoneNumber = res.properties.phone;

          // Update the states, but these will not be used immediately.
          setHubspotName(name);
          setHubspotEmail(email);
          setHubspotNumber(phoneNumber);
        } catch (error) {
          console.error("Error getting hubspot data", error);
        }
      }      

      // Use the directly assigned variables here.
      const textToClipboard = `Name: ${copyMe.name}\nCountry: ${copyMe.visa_country}\nHubSpot Name: ${name}\nHubSpot Email: ${email}\nHubSpot Phone Number: ${phoneNumber}\nAlt Email: ${copyMe.contact_email}\nAlt Phone: ${copyMe.phone_number}`;
      console.log(textToClipboard);
      try {
        await navigator.clipboard.writeText(textToClipboard);
        setCopySuccess('Copied!');
      } catch (err) {
        console.eroor(err)
        setCopySuccess('Failed to copy!');
      }
    };

    return (
      <>
        <Modal open={openCreateEditReviewModal}>
          <Box sx={style}>
            <p className="m-2">Google Review</p>
            <h3 className="m-1">{gReview.full_name}</h3>
            <br />
            <TextField 
              label="Review Text"
              multiline
              rows={4}
              variant="outlined"
              value={gReview.review_text}
              onChange={(e) => setGReview({...gReview, review_text: e.target.value})}
            />
            <br />
            <Button variant="contained" color="success" onClick={() => saveGoogleReview()}>
              Save Review
            </Button>
            <br />
            <Button variant="contained" color="primary" onClick={() => setOpenCreateEditReviewModal(false)}>
              Close
            </Button>
          </Box>
        </Modal>
        <Modal open={openGReviewModal}>
          <Box sx={style}>
            <p className="m-2">Google Review</p>
            <h3 className="m-1">{gReview.full_name}</h3>
            <p className="mb-3">{gReview.review_text}</p>
            <br />
            <Button variant="contained" color="secondary" onClick={() => removeGoogleReview()}>
              Remove Review
            </Button>            
            <br />
            <Button variant="contained" color="primary" onClick={() => setOpenGReviewModal(false)}>
              Close
            </Button>
          </Box>
        </Modal>
        <Modal open={openNoRequirementsModal}>
            <Box sx={style}>
                <h3 className="m-4">No Information Found</h3>
                <Button variant="contained" color="primary" onClick={() => setOpenNoRequirementsModal(false)}>
                    Close
                </Button>
            </Box>
        </Modal>        
        <TableRow key={row._id} sx={{ "& > *": { borderBottom: "unset" }}}>
          <TableCell>
            <Checkbox
              checked={selectedRows.includes(row._id)}
              onChange={() => handleCheckboxChange(row._id)}
            />
          </TableCell>
          <TableCell>
            <ToolTip title={`${row.visa_status}`}>
              <CircleTwoToneIcon sx={{ color: getStatusColor(row.visa_status),borderRadius: "100%"}} />
            </ToolTip>
              {(row.g_review && (row.g_review.matched === "Auto" || row.g_review.matched === "Manual")) && 
                <ToolTip title={<div style={{ whiteSpace: 'pre-line' }}>{`${row.g_review.matched} matched with score ${row.g_review.score}. \nClick for more details`}</div>}>
                  <GoogleIcon 
                    style={{ color: 'green' }}
                    onClick={() => onViewGoogleReviewClick(row)}
                  /> 
                </ToolTip>
              }
              {(row.g_review && (row.g_review.matched === "None" || row.g_review.matched === null)) && 
                <ToolTip title={`Click to add Review`}>
                  <GoogleIcon 
                    style={{ color: 'red' }}
                    onClick={() => onCreateGoogleReviewClick(row)}  
                  />
                </ToolTip>
              }
          </TableCell>
          <TableCell
            component="th"
            scope="row"
          >
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={(event) => {
                event.stopPropagation(); // Prevent click event from bubbling up
                setOpen(!open);
              }}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
            {row.name || "-"}
          </TableCell>
          
          <TableCell>
            {row.visa_category}
          </TableCell>
          <TableCell sx={{cursor:"pointer"}} onClick={() => onGroupReferenceClick(row.reference_number)}>{row.reference_number}</TableCell>
          <TableCell 
            sx={{ cursor: row.group_URN_reference_number ? "pointer" : "default" }} 
            onClick={() => {
                if (row.group_URN_reference_number) {
                    onGroupReferenceClick(row.group_URN_reference_number);
                }
            }}
          >
              {row.group_URN_reference_number}
          </TableCell>

          {/* backgroundColor:"rgba(255,0,0,0.5)" */}
          <TableCell sx={{backgroundColor:getDateDifference()}}  >
            <b>{convertDateToString(row.visa_appointment_date)}</b>
          </TableCell>
          <TableCell onClick={() => getRequirement(row._id)} style={{ cursor: 'pointer' }}>
            {row.visa_country}
          </TableCell>
          
          <TableCell>
            
          {matches && matches.length > 0 ? matches.map((link) => (
              <a
                key={row._id}
                href={link}
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                <PictureAsPdfIcon />&nbsp;&nbsp; 
              </a>
            )): null
          }
          {
            matches_od_folder && matches_od_folder.length > 0 ? matches_od_folder.map((link) => (
              <a
                key={row._id}
                href={link}
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                <CloudDoneRoundedIcon/>
              </a>
            )): null
          }
          </TableCell>
          <TableCell>
            <ToolTip title={copySuccess} sx={{cursor:"pointer"}} onClick={() => copyToClipBoard(row)}>
              <ContentCopyIcon style={{ color: "green" }} />
            </ToolTip>
            <ToolTip title="Edit">
              <Button 
                onClick={() => navigate(`/visa/edit/${row._id}`,
                  {state: { currentPage: currentPage,
                            search: search,
                            search2: search2,
                            search4: search4,
                            search7: search7,
                            search8: search8,
                            search9: search9,
                            search10: search10,
                            searchDate: searchDate,
                            searchCountry: searchCountry
                  }})} 
                style={{textDecoration: "none", padding: 0, minWidth: "auto", border: 'none', display: 'inline-flex'}}
              >
                {row.onedrive_url ?(
                    <EditIcon style={{ color: "green" }} />
                    ):(
                    <EditIcon style={{ color: "red" }} />
                    )
                }
              </Button>
            </ToolTip>                      
            <ToolTip title="Contact">
              <Button onClick={() => navigate(`/visa/edit/contact/${row._id}`,
                {state: { currentPage: currentPage,
                          search: search,
                          search2: search2,
                          search4: search4,
                          search7: search7,
                          search8: search8,
                          search9: search9,
                          search10: search10,
                          searchDate: searchDate,
                          searchCountry: searchCountry
                }})}
                style={{textDecoration: "none", padding: 0, minWidth: "auto", border: 'none', display: 'inline-flex'}}>
                {(row.hubspot_id || (row.phone_number && row.contact_email)) ?(
                  <PhoneIcon style={{ color: "green" }} />
                  ):(
                  <PhoneIcon style={{ color: "red" }} />
                )}
              </Button>
            </ToolTip>
            <ToolTip title="Delete" sx={{color:"red",cursor:"pointer"}} onClick={() => RowDelete(row._id)}>
              <DeleteIcon style={{ color: "red" }} />
            </ToolTip>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={11}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1, border: "2px solid black", p: 2 }}>
                <Typography variant="h6" gutterBottom component="div">
                  Additional Information
                </Typography>
                <Table size="small" aria-label="tickets">
                  <TableHead>
                    <TableCell>
                      Passport Number
                      </TableCell>
                      <TableCell>
                
                        Booking Date
                     </TableCell>
                      <TableCell>
                        Appointment Addresss
                      </TableCell>
                  </TableHead>
                  <TableBody>
                    <TableCell>{row.passport_number}</TableCell>
                    <TableCell>{row.issue_date ? convertDateToString(row.issue_date) : "-"}</TableCell>
                    <TableCell>{row.application_center_details}</TableCell>
                  </TableBody>
                </Table>
                <Table>
                  <TableHead>
                    <TableCell>HubSpot Name</TableCell>
                    <TableCell>HubSpot Phone Number</TableCell>
                    <TableCell>HubSpot Email</TableCell>
                    {/* <TableCell>Contact Email</TableCell>
                    <TableCell>Contact Number</TableCell> */}
                    <TableCell>Hubspot ID</TableCell>
                    <TableCell>Service Staff</TableCell>
                    <TableCell>Sales Staff</TableCell>
                    <TableCell>Alt Phone</TableCell>
                    <TableCell>Alt Email</TableCell>
                  </TableHead>
                  <TableBody>
                

                    {row.hubspot_id ? (
                      <a
                        href={`https://app.hubspot.com/contacts/20340520/record/0-1/${row.hubspot_id}`}
                        target="_blank"
                      >
                        <TableCell>{hubspotName}</TableCell>
                      </a>
                    ) : (
                      "-"
                    )}
                    <TableCell sx={{cursor:"pointer"}}><Link target="_blank" to={`https://wa.me/${hubspotNumber}`}>{hubspotNumber}</Link></TableCell>
                    <TableCell>
                      <Link to={`mailto:${hubspotEmail}`}>
                        {hubspotEmail}
                      </Link>
                    </TableCell>
                    {/* <TableCell>{row.contact_email}</TableCell>
                    <TableCell>{row.contact_number}</TableCell> */}
                    <TableCell>{row.hubspot_id}</TableCell>
                    <TableCell>{row.service_staff}</TableCell>
                    <TableCell>{row.closing_staff}</TableCell>
                    <TableCell><Link target="_blank" to={`https://wa.me/${row.phone_number}`}>{row.phone_number}</Link></TableCell>
                    <TableCell><Link target="_blank" to={`mailto:${row.contact_email}`}>{row.contact_email}</Link></TableCell>
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  };
  const [sort, setSort] = useState(false);
  const [sortdepDate, setSortdepDate] = useState(false);
  const dispatch = useDispatch();
  return (
    <Paper sx={{ width: "100%"}}>
      <TableContainer sx={{ maxHeight: 600 }}>
        <Table stickyHeader aria-label="collapsible table">
          <TableHead className="fixed">
            <TableRow>
              <TableCell>
                <Checkbox checked={mainCheck} onChange={onMainCheck} />
              </TableCell>
              <TableCell>Status</TableCell>
              {/* <TableCell>Review</TableCell> */}
              <TableCell>
                {/* <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => {
                    setSort(!sort);
                    sort
                      ? dispatch(getDataAscending())
                      : dispatch(getDataDescending());
                  }}
                >
                  {sort ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                </IconButton> */}
                Applicant Name
              </TableCell>  
              <TableCell>
                   Type
              </TableCell>
              <TableCell>Reference Number</TableCell>
              <TableCell>Group URN Reference Number</TableCell>
              <TableCell>
              {/* <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => {
                    setSortdepDate(!sortdepDate);
                    sortdepDate
                      ? dispatch(sortByDepartureDateAscending())
                      : dispatch(sortByDepartureDateDescending());
                  }}
                >
                  {sortdepDate ? (
                    <KeyboardArrowDownIcon />
                  ) : (
                    <KeyboardArrowUpIcon />
                  )}
                </IconButton> */}
                Submission Date</TableCell>
              <TableCell>Country</TableCell>
              <TableCell>Attachments</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 ? (
              data.map((row) => <Row key={row._id} row={row} />)
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                No Results Found. Please Search For Something Else
              </div>
            )}
          </TableBody>
        </Table>
        <div className="fixed-bottom">
          <button
            className="btn btn-warning col-2 col-md-2 col-sm-4 mr-2 button"
            onClick={handleProcess}
          >
            UNDER PROCESS
          </button>
          <button
            className="btn btn-danger col-2 col-md-2 col-sm-4 mr-2 button"
            onClick={handleRefund}
          >
            REJECTED
          </button>
          <button
            className="btn btn-success col-2 col-md-2 col-sm-4 mr-2 button"
            onClick={handleApprove}
          >
            APPROVED
          </button>
          <button className="btn btn-secondary col-2 col-md-2 col-sm-4 button" onClick={handleCancel}>
            CANCELLED
          </button>
          <style>
            {` @media (max-width: 576px) { /* Adjust for small screens */
              .button {
                font-size: 8px; /* Reduce the font size */
                padding: .375rem .75rem; /* Adjust padding if needed */
              }
            }`}
          </style>
        </div>
      </TableContainer>
    </Paper>
  );
}