import React, { useState, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import axios from "axios";

import DownloadIcon from '@mui/icons-material/Download';
import toast from "react-hot-toast";
import API from "../API";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "30px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "grey",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "grey",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const FileUpload = () => {
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [excelFilesToUpload, setExcelFilesToUpload] = useState([]);
  const [uploadStartTime, setUploadStartTime] = useState(0);
  // PDF FILES
  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    acceptedFiles,
    fileRejections,
  } = useDropzone({
    accept: "application/pdf",
    validator: (file) => {
      if (!file.name.endsWith(".pdf")) {
        return {
          code: "not-a-pdf",
          message: "Only PDF files are accepted",
        };
      }
      return null;
    },
    onDrop: (acceptedFiles) => {
      setFilesToUpload(acceptedFiles);
    },
  });
  // EXCEL FILES
  const {
    getRootProps: getExcelRootProps,
    getInputProps: getExcelInputProps,
    isFocused: isExcelFocused,
    isDragAccept: isExcelDragAccept,
    isDragReject: isExcelDragReject,
    acceptedFiles: acceptedExcelFiles,
    fileRejections: excelFileRejections,
  } = useDropzone({
    accept: 'text/csv, .csv',
    validator: (file) => {
      if (!file.name.endsWith(".csv")) {
        return {
          code: "not-a-csv",
          message: "Only CSV files are accepted",
        };
      }
      return null;
    },
    onDrop: (acceptedFiles) => {
      setExcelFilesToUpload(acceptedFiles);
    },
  });
  const acceptedExcelFileItems = acceptedExcelFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const excelFileRejectionItems = excelFileRejections.map(({ file, errors }) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
      <ul>
        {errors.map((e) => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>
    </li>
  ));
  const handleExcelUpload = () => {
    if (excelFilesToUpload.length === 0) {
      toast.error("Please select a file to upload");
    }
    else{
      try{
        console.log(excelFilesToUpload[0]);
        const formData = new FormData();
        formData.append("file", excelFilesToUpload[0]);
        console.log(Object.fromEntries(formData.entries()));
        const startTime = Date.now();
        setUploadStartTime(startTime);
        const response = API.post(
          `/ivisa/uploadexcel`, formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        toast.promise(response, {
          loading: "Uploading...",
          success: () => {
                const duration = ((Date.now() - uploadStartTime) / 1000).toFixed(2); // Duration in seconds
                return `File uploaded successfully in ${duration} seconds`;
            },
          error: "Error uploading file",
        });
      } catch (error) {
        console.error(error.response ? error.response.data : error.message);
      }
    }
  };
  const handleUpload = () => {
    if (filesToUpload.length === 0) {
      toast.error("Please select a file to upload");
    }
    else {
      try {
        console.log(filesToUpload[0]);
        const formData = new FormData();
        formData.append("file", filesToUpload[0]);
        console.log(Object.fromEntries(formData.entries()));
        const startTime = Date.now();
        setUploadStartTime(startTime);        
        const response = API.post(
          `/flights/uploadpdf?token=${localStorage.getItem(
            "authToken"
          )}&provider=ivisa`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        toast.promise(response, {
          loading: "Uploading...",
          success: () => {
                const duration = ((Date.now() - uploadStartTime) / 1000).toFixed(2); // Duration in seconds
                return `File uploaded successfully in ${duration} seconds`;
            },
          error: "Error uploading file",
        });
      } catch (error) {
        console.error(error.response ? error.response.data : error.message);
      }
    }
  };
  const acceptedFileItems = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
      <ul>
        {errors.map((e) => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>
    </li>
  ));
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );
  const excelStyle = useMemo(
    () => ({
      ...baseStyle,
      ...(isExcelFocused ? focusedStyle : {}),
      ...(isExcelDragAccept ? acceptStyle : {}),
      ...(isExcelDragReject ? rejectStyle : {}),
    }),
    [isExcelFocused, isExcelDragAccept, isExcelDragReject]
  );
  return (
    

<div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        padding: "20px"
      }}
    >
      <div
        className="container"
        style={{
          display: "flex",
          flexDirection: "row", // Changed to row to place elements side by side
          alignItems: "flex-start",
          gap: "20px", // Adjust the gap as needed
          justifyContent: "center",
        }}
      >
        {/* Dropzone for PDF Files */}
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <p>Drag 'n' drop PDF files here, or click to select files</p>
          <aside>
            <h4>Accepted PDF files</h4>
            <ul>{acceptedFileItems}</ul>
            <h4>Rejected PDF files</h4>
            <ul>{fileRejectionItems}</ul>
          </aside>
        </div>

        <Button
          variant="contained"
          endIcon={<SendIcon />}
          onClick={handleUpload}
          sx={{ marginTop: 2 }}
        >
          Upload PDF
        </Button>

        {/* Dropzone for Excel Files */}
        <div {...getExcelRootProps({ style: excelStyle })}>
          <input {...getExcelInputProps()} />
          <p>Drag 'n' drop Excel files here, or click to select files</p>
          <aside>
            <h4>Accepted Excel files</h4>
            <ul>{acceptedExcelFileItems}</ul>
            <h4>Rejected Excel files</h4>
            <ul>{excelFileRejectionItems}</ul>
          </aside>
        </div>

        <div>
        <Button
          variant="contained"
          endIcon={<SendIcon />}
          onClick={handleExcelUpload}
          
        >
          Upload Excel
        </Button>
        <Button 
          variant="contained" 
          href="https://marcopoloholidays151-my.sharepoint.com/:x:/g/personal/jasir_marcopolo-holidays_com/EQh6QJYtpQZHlKzsdyz8r3kB4VL7OotKRcGsCPQuuiN86w?e=cURiRW&download=1"
          sx={{marginLeft: 2}}
        >
          <DownloadIcon/>
        </Button>
        </div>
      </div>

      <style>
        {`
        @media (max-width: 600px)
    .container {
      height: auto;
      width: 50vw; /* Adjust width to fit smaller screens */
      padding: 20px; /* Add padding for better spacing */
      gap: 20px; /* Reduce gap for compact layout */
    }
    .container > div {
      flex-direction: column; /* Stack elements vertically */
      align-items: stretch; /* Stretch elements to full width */
    }
    .container input, .container button, .container select {
      font-size: 16px; /* Increase font size for better readability */
    }
    /* Adjust the dropzone style for smaller screens */
    .dropzone {
      padding: 20px; /* Increase padding for better touch area */
      font-size: 14px; /* Adjust font size */
    }
    /* Increase the size of buttons and selects for easier interaction */
    button, select {
      min-height: 44px; /* Increase height for better touch area */
      font-size: 16px; /* Increase font size */
    }
    `}
      </style>
    </div>

  );
};
export default FileUpload;
