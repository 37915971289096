import React, { useState, useEffect, Fragment } from 'react';
import CountrySelect from "../../Visa/components/CountrySelect";
import DiplomaticMissionSelect from "../components/DiplomaticMissionSelect";
import UAEEmirateSelect from "../components/UAEEmirateSelect";
import DateTimePicker from "../components/DateTimePicker";
import NOCCategorySelect from "../components/NOCCategorySelect";
import ApplicantRelationSelect from "../components/ApplicantRelationSelect";
import FamilyNOCDocGenerator from "../components/FamilyNOCDocGenerator";
import InvestorNOCDocGenerator from "../components/InvestorNOCDocGenerator";
import PartnerNOCDocGenerator from "../components/PartnerNOCDocGenerator";
import EmployeeNOCDocGenerator from "../components/EmployeeNOCDocGenerator";
import TitleSelect from "../components/TitleSelect";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import { Grid, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';

const schengenCountries = ["Austria", "Belgium", "Czech Republic", "Denmark", "Estonia", "Finland", "France", "Germany", "Greece", "Hungary", "Iceland", "Italy", "Latvia", "Liechtenstein", "Lithuania", "Luxembourg", "Malta", "Netherlands", "Norway", "Poland", "Portugal", "Slovakia", "Slovenia", "Spain", "Sweden", "Switzerland"];

function NOCGenerator() {
  const [countrySelectVal, setCountrySelectVal] = useState(null);
  const [applicantNationality, setApplicantNationality] = useState(null);
  const [granterNationality, setGranterNationality] = useState(null);
  const [diplomaticMissionVal, setDiplomaticMissionVal] = useState(null);
  const [UAEEmirateVal, setUAEEmirateVal] = useState(null);
  const [isSchengen, setIsSchengen] = useState(false);
  const [drawsSalary, setDrawsSalary] = useState(false);
  const [shareByEmail, setShareByEmail] = useState(false);
  const [NOCCategoryVal, setNOCCategoryVal] = useState('');
  const [nocGranterName, setNocGranterName] = useState('');
  const [applicantRelationVal, setApplicantRelationVal] = useState('');
  const [nocGranterPassportNumber, setNocGranterPassportNumber] = useState('');
  const [nocApplicantPassportNumber, setNocApplicantPassportNumber] = useState('');
  const [titleVal, setTitleVal] = useState('');
  const [applicantGender, setApplicantGender] = useState('');
  const [nocApplicantName, setNocApplicantName] = useState('');
  const [sharePercentage, setSharePercentage] = useState('');
  const [salaryAmount, setSalaryAmount] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [shareToEmail, setShareToEmail] = useState('');
  const [employmentStartDate, setEmploymentStartDate] = React.useState(null);
  const [applicationDate, setApplicationDate] = React.useState(null);
  const [nocGranterPhoneNumber, setNocGranterPhoneNumber] = React.useState(null);
  const [isNonSchenDatePickerVisible, setNonSchenDatePickerVisible] = useState(false);
  const [sepFinSponsor, setSepFinSponsor] = useState(false);
  const [finSponsorName, setFinSponsorName] = useState('');
  const [finSponsorNationality, setFinSponsorNationality] = useState(null);
  const [finSponsorPhoneNumber, setFinSponsorPhoneNumber] = useState('');
  const [finSponsorPassportNumber, setFinSponsorPassportNumber] = useState('');   
  const [freeTextField, setFreeTextField] = useState('');
  const [nonSchengenSearchDate, setNonSchengenSearchDate] = useState([{ startDate: null, endDate: null, key: 'selection' }])
  const [schengenEntries, setSchengenEntries] = useState([
    { countryName: '', isDatePickerVisible: false, searchDate: [{ startDate: null, endDate: null, key: 'selection' }] }
  ]);

  const resetNOCForm = () => {
    // Display the confirmation dialog
    const isConfirmed = window.confirm("Are you sure you want to reset the form?");

    // Check if the user confirmed
    if (isConfirmed) {
      // User clicked OK, proceed with form reset
      setApplicantNationality('');
      setGranterNationality('');
      setShareByEmail(false);
      setNocGranterName('');
      setApplicantRelationVal('');
      setNocGranterPassportNumber('');
      setNocApplicantPassportNumber('');
      setTitleVal('');
      setApplicantGender('');
      setNocApplicantName('');
      setSharePercentage('');
      setSalaryAmount('');
      setCompanyName('');
      setJobTitle('');
      setShareToEmail('');
      setEmploymentStartDate(null);
      setApplicationDate(null);
      setNocGranterPhoneNumber('');
    } else {
      // User clicked Cancel, do not reset the form
      console.log("Form reset cancelled by user.");
    }
  };

  // Inside your component
  const closeAllDatePickers = () => {
      setSchengenEntries(currentEntries => [
        ...currentEntries.map(entry => ({ ...entry, isDatePickerVisible: false }))
      ]);
    setNonSchenDatePickerVisible(false)
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.datePicker')) { // Use an actual class or attribute that matches your components
        closeAllDatePickers();
      }      
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  // Effect to check if the selected country is part of Schengen
  useEffect(() => {
    if (countrySelectVal && schengenCountries.includes(countrySelectVal)) {
      setIsSchengen(true);
    } else {
      setIsSchengen(false);
    }
  }, [countrySelectVal]);

  useEffect(() => {
    if (applicantNationality === "XXXXXX" || granterNationality === "XXXXXX"){
      setGranterNationality('XXXXXX');
      setApplicantNationality("XXXXXX");
      setNocGranterName('XXXXXX');
      setApplicantRelationVal("XXXXXX");
      setNocGranterPassportNumber('XXXXXX');
      setNocApplicantPassportNumber('XXXXXX');
      setNocApplicantName('XXXXXX');
      setSharePercentage('XX');
      setSalaryAmount('XXXXXX');
      setCompanyName('XXXXXX');
      setJobTitle('XXXXXX');
      setShareToEmail('ivisa@marcopolo-holidays.com');
      setNocGranterPhoneNumber('XXXXXX');  
      setFinSponsorName("XXXXXX");
      setFinSponsorNationality("XXXXXX");
      setFinSponsorPassportNumber("XXXXXX");
      setFinSponsorPhoneNumber("XXXXXX");
      setTitleVal("Mr/Ms");
      setApplicantGender("unknown");

    }
  }, [applicantNationality, granterNationality])

  const handleAddSchengenEntry = () => {
    setSchengenEntries(currentEntries => [
      ...currentEntries.map(entry => ({ ...entry, isDatePickerVisible: false })),
      { countryName: '', isDatePickerVisible: false, searchDate: [{ startDate: null, endDate: null, key: 'selection' }] }
    ]);
  };

  // const handleCountryNameChange = (index, value) => {
  //   const updatedEntries = schengenEntries.map((entry, i) => {
  //     if (i === index) {
  //       return { ...entry, countryName: value, isDatePickerVisible: false };
  //     }
  //     return { ...entry, isDatePickerVisible: false };
  //   });
  //   setSchengenEntries(updatedEntries);
  // };

  const handleRemoveSchengenEntry = (indexToRemove) => {
    const filteredEntries = schengenEntries.filter((_, index) => index !== indexToRemove);
    const updatedEntries = filteredEntries.map(entry => ({ ...entry, isDatePickerVisible: false }));
    setSchengenEntries(updatedEntries);
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'Date not set';
    const date = new Date(dateString);
    const monthNames = [
      "Jan", "Feb", "Mar", "Apr", "May", "June",
      "July", "Aug", "Sept", "Oct", "Nov", "Dec"
    ];
    return [
      date.getDate().toString().padStart(2, '0'),
      monthNames[date.getMonth()], // Use the month name from the array
      // date.getFullYear()
    ].join('-');
  };

  // const handleGenerateNOC = () => {
  //   console.log("here")
  // }

  const isEmail = (email) => {
    const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    return regex.test(email);
  };

  const shouldDisplayFamilyNOCDocGenerator =  diplomaticMissionVal && 
                                              countrySelectVal && 
                                              UAEEmirateVal && 
                                              nocGranterName && 
                                              granterNationality && 
                                              nocGranterPassportNumber && 
                                              applicantRelationVal && 
                                              titleVal && 
                                              nocApplicantName && 
                                              applicantNationality && 
                                              nocApplicantPassportNumber && 
                                              schengenEntries && 
                                              schengenEntries.length > 0 && 
                                              nonSchengenSearchDate && 
                                              nonSchengenSearchDate.length > 0 && 
                                              applicantGender &&
                                              applicationDate &&
                                              isEmail(shareToEmail);

  const shouldDisplayInvestorNOCDocGenerator = countrySelectVal &&
                                            diplomaticMissionVal &&
                                            UAEEmirateVal &&
                                            schengenEntries &&
                                            schengenEntries.length > 0 &&
                                            nonSchengenSearchDate &&
                                            nonSchengenSearchDate.length > 0 &&
                                            applicationDate &&
                                            titleVal &&
                                            nocApplicantName &&
                                            applicantNationality &&
                                            nocApplicantPassportNumber &&
                                            applicantGender &&
                                            sharePercentage &&
                                            companyName &&
                                            isEmail(shareToEmail);

  const shouldDisplayPartnerNOCDocGenerator =   countrySelectVal &&
                                                diplomaticMissionVal &&
                                                UAEEmirateVal &&
                                                schengenEntries &&
                                                nonSchengenSearchDate &&
                                                applicationDate &&
                                                titleVal &&
                                                nocApplicantName &&
                                                applicantNationality &&
                                                nocApplicantPassportNumber &&
                                                applicantGender &&
                                                sharePercentage &&
                                                companyName &&
                                                isEmail(shareToEmail);

  const shouldDisplayEmployeeNOCDocGenerator =  countrySelectVal &&
                                                diplomaticMissionVal &&
                                                UAEEmirateVal &&
                                                schengenEntries &&
                                                nonSchengenSearchDate &&
                                                applicationDate &&
                                                titleVal &&
                                                nocApplicantName &&
                                                applicantNationality &&
                                                nocApplicantPassportNumber &&
                                                applicantGender &&
                                                companyName &&
                                                salaryAmount &&
                                                employmentStartDate &&
                                                jobTitle &&
                                                isEmail(shareToEmail);                                           

  return (
    <div className="container mt-5" style={{ marginBottom: '400px' }}>
      <Grid container spacing={3} alignItems="center" justifyContent="center">
        <Grid item xs={12} sm={4}>
          <CountrySelect countrySelectVal={countrySelectVal} setCountrySelectVal={setCountrySelectVal} fieldLabel={"Application Country *"} isSchengen={false} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <DiplomaticMissionSelect diplomaticMissionVal={diplomaticMissionVal} setDiplomaticMissionVal={setDiplomaticMissionVal} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <UAEEmirateSelect UAEEmirateVal={UAEEmirateVal} setUAEEmirateVal={setUAEEmirateVal} />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox disabled checked={isSchengen} onChange={(e) => setIsSchengen(e.target.checked)} />}
            label="Belongs to Schengen Territory"
          />
        </Grid>
        {isSchengen && schengenEntries.map((entry, index) => (
          <React.Fragment key={index}>
            <Grid item xs={11} sm={5} display="flex" alignItems="center" justifyContent="end">
              <CountrySelect
                countrySelectVal={entry.countryName}
                setCountrySelectVal={(newValue) => {
                  const updatedEntries = schengenEntries.map((entry, i) => {
                    if (i === index) {
                      return { ...entry, countryName: newValue, isDatePickerVisible: false };
                    }
                    return { ...entry, isDatePickerVisible: false };
                  });
                  setSchengenEntries(updatedEntries);
                }}
                fieldLabel="Schengen Country Name *"
                isSchengen={isSchengen}
              />            
            </Grid>
            <Grid item xs={8} sm={5} alignItems="center" justifyContent="start">
              <DateTimePicker
                isDatePickerVisible={entry.isDatePickerVisible}
                setDatePickerVisible={(isVisible) => {
                  const updatedEntries = [...schengenEntries];
                  updatedEntries[index].isDatePickerVisible = isVisible;
                  setSchengenEntries(updatedEntries);
                }}
                searchDate={entry.searchDate}
                setSearchDate={(newDate) => {
                  const updatedEntries = [...schengenEntries];
                  updatedEntries[index].searchDate = newDate;
                  setSchengenEntries(updatedEntries);
                }}
              />
            </Grid>
            <Grid item xs={1} sm={1} display="flex" alignItems="center" justifyContent="end"sx={{ ml: 1 }}>
              <IconButton onClick={() => handleRemoveSchengenEntry(index)}>
                <CloseIcon />
              </IconButton>
            </Grid>            
          </React.Fragment>
        ))}
        {isSchengen && (
          <Grid item xs={12}>
            <Button variant="contained" onClick={handleAddSchengenEntry}>Add Another Country</Button>
          </Grid>
        )}        
        {(!isSchengen) && (
          <React.Fragment>         
            <Grid item xs={11} sm={5.5}>
              <TextField
                disabled
                fullWidth
                label="Country Name"
                variant="outlined"
                name="countryName"
                value={countrySelectVal}
              />
            </Grid>
            <Grid item xs={11} sm={5.5}>         
              <DateTimePicker
                isDatePickerVisible={isNonSchenDatePickerVisible}
                setDatePickerVisible={setNonSchenDatePickerVisible}
                searchDate={nonSchengenSearchDate}
                setSearchDate={setNonSchengenSearchDate}
              />
            </Grid>
          </React.Fragment>          
        )}
      </Grid>
      {((nonSchengenSearchDate[0].endDate || schengenEntries[schengenEntries.length - 1].searchDate[0].endDate) 
          && countrySelectVal && diplomaticMissionVal && UAEEmirateVal) && (
        <div className="container mt-5">
          <Grid container spacing={3} alignItems="center" justifyContent="start">
            <Grid item>
              <span>I am</span>
            </Grid>
            <Grid item>
              <NOCCategorySelect NOCCategoryVal={NOCCategoryVal} setNOCCategoryVal={setNOCCategoryVal} />
            </Grid>
            <Grid item>
              <span>as per my UAE Residence Visa</span>
            </Grid>          
          </Grid>
        </div>
      )}
      {(NOCCategoryVal === 'Investor' || NOCCategoryVal === 'Partner') && (
      <div className="container mt-2">
        <Grid container spacing={3} alignItems="center" justifyContent="start">
          <Grid item>
            <FormControlLabel
              control={<Checkbox checked={drawsSalary} onChange={(e) => setDrawsSalary(e.target.checked)} />}
              label="Draws Salary from the company"
            />
          </Grid>           
        </Grid>
      </div>
      )}
      {(NOCCategoryVal === 'Family Sponsored (Inc. GOLDEN VISA Dependent)') && (
      <div className="container mt-2">
        <Grid container spacing={3} alignItems="center" justifyContent="start">
          <Grid item>
            <FormControlLabel
              control={<Checkbox checked={sepFinSponsor} onChange={(e) => setSepFinSponsor(e.target.checked)} />}
              label="Financial Sponsor is different from Visa Sponsor"
            />
          </Grid>           
        </Grid>
      </div>
      )}      
      <div className="container mt-2">
        <Grid container spacing={3} alignItems="center" justifyContent="start">      
          <Grid item xs={12}>
            <Button variant="contained" onClick={resetNOCForm}>Reset Form</Button>
          </Grid>
        </Grid>
      </div>
      {NOCCategoryVal === 'Family Sponsored (Inc. GOLDEN VISA Dependent)' && (
        <>
          <hr style={{ border: '1px solid #000', marginBottom: '3rem' }} />
          <div className="container mt-2">
            <Grid container spacing={3} alignItems="center" justifyContent="start">
              <Grid item>
                <span>Submission Date:</span>
              </Grid>            
              <Grid item>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Submission Date *"
                    value={applicationDate}
                    onChange={(newValue) => {
                      setApplicationDate(newValue);
                    }} 
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Grid>          
            </Grid>
            <br />
            <Grid container spacing={3} alignItems="center" justifyContent="start">
              <Grid item>
                <span>This is to confirm that I, </span>
              </Grid>
              <Grid item>
                <TextField required id="noc_granter_name" name="noc_granter_name" label="Sponsor Full Name" variant="outlined"
                  onChange={(e) => setNocGranterName(e.target.value)} value={nocGranterName} />
              </Grid>
              <Grid item>
                <span>, holder of </span>
              </Grid>
              <Grid item>
                <CountrySelect countrySelectVal={granterNationality} setCountrySelectVal={setGranterNationality} fieldLabel={"Sponsor Nationality *"} isSchengen={false} />
              </Grid>
              <Grid item> 
                <span>passport number</span> 
              </Grid>
              <Grid item> 
                <TextField required id="noc_granter_passport_number" name="noc_granter_passport_number" label="Sponsor Passport Number"
                  variant="outlined" onChange={(e) => setNocGranterPassportNumber(e.target.value)} value={nocGranterPassportNumber} />
              </Grid>
              <Grid item> 
                <span>, have no objection in my</span> 
              </Grid>
              <Grid item> 
                <ApplicantRelationSelect applicantRelationVal={applicantRelationVal} setApplicantRelationVal={setApplicantRelationVal} />
              </Grid>
              <Grid item> 
                <span>,&nbsp;</span> 
              </Grid>
              <Grid item> 
                <TitleSelect titleVal={titleVal} setTitleVal={setTitleVal} setApplicantGender={setApplicantGender} fieldLabel="Title *" />
              </Grid>
              <Grid item>
                <TextField required id="noc_applicant_name" name="noc_applicant_name" label="Applicant Full Name" variant="outlined"
                  onChange={(e) => setNocApplicantName(e.target.value)} value={nocApplicantName} />
              </Grid>
              <Grid item> 
                <span>, holder of </span> 
              </Grid>
              <Grid item>
                <CountrySelect countrySelectVal={applicantNationality} setCountrySelectVal={setApplicantNationality} fieldLabel={"Applicant Nationality *"} isSchengen={false} />
              </Grid>
              <Grid item> 
                <span> passport number </span> 
              </Grid>
              <Grid item> 
                <TextField required id="noc_applicant_passport_number" name="noc_applicant_passport_number" label="Applicant Passport Number"
                  variant="outlined" onChange={(e) => setNocApplicantPassportNumber(e.target.value)} value={nocApplicantPassportNumber} />
              </Grid>
              <Grid item> 
                <span>, travelling to</span> 
              </Grid>
              {isSchengen && (    
                <Grid item> 
                  <span>
                    {schengenEntries.map((entry, index, array) => {
                      const isLast = index === array.length - 1;
                      const prefix = isLast && array.length > 1 ? ' and ' : '';
                      const suffix = !isLast ? ', ' : '';
                      return (
                        `${prefix}${entry.countryName} from ` +
                        `${formatDate(entry.searchDate[0].startDate)} to ` +
                        `${formatDate(entry.searchDate[0].endDate)}${suffix}`
                      );
                    }).join('').trim()}&#46;
                  </span>              
                </Grid>
              )}
              {!isSchengen && (    
                <Grid item> 
                  <span>
                    {`${countrySelectVal} from ` +
                        `${formatDate(nonSchengenSearchDate[0].startDate)} to ` +
                        `${formatDate(nonSchengenSearchDate[0].endDate)}.`
                    }
                  </span>              
                </Grid>
              )}            
            </Grid>
            <br />
            <br />
            <Grid container spacing={3} alignItems="center" justifyContent="start">
              <TextField id="free_text_field" name="free_text_field" label="Free Text"
                variant="outlined" fullWidth onChange={(e) => setFreeTextField(e.target.value)} value={freeTextField} />
            </Grid>
            <br />
            {!sepFinSponsor && (
              <>
                <Grid container spacing={3} alignItems="center" justifyContent="start">
                  <Grid item> 
                    <span>
                    Please treat my personal bank statements, from where&nbsp;
                    {(applicantGender==='male')?'he':((applicantGender==='female')?'she':'he/she')}&nbsp;
                    will utilize funds, as supporting documents to&nbsp;
                    {/*my {applicantRelationVal.toLowerCase()}'s&nbsp;*/}
                    {(applicantGender==='male')?'his':((applicantGender==='female')?'her':'his/her')}&nbsp;
                    {(isSchengen)?'Schengen':countrySelectVal}&nbsp;
                    Visa Application.
                    </span> 
                  </Grid>
                </Grid>
              </>
            )}
            <Grid container spacing={3} alignItems="center" justifyContent="start">
              <Grid item> 
                <span>Your assistance in granting the {(isSchengen)?'schengen visa':'necessary visa to enter '+countrySelectVal} will be much appreciated.</span> 
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={3} alignItems="center" justifyContent="start">
              <Grid item> 
                <span>Sponsor Phone Number: </span>
              </Grid>
              <Grid item> 
                <TextField id="noc_granter_phone_number" name="noc_granter_phone_number" label="Sponsor Phone Number"
                  variant="outlined" onChange={(e) => setNocGranterPhoneNumber(e.target.value)} value={nocGranterPhoneNumber} />
              </Grid>              
            </Grid>            
          </div>
          {sepFinSponsor && (
          <>
            <hr style={{ border: '1px solid #000', marginBottom: '1rem' }} />
            <br />
            <Grid container spacing={3} alignItems="center" justifyContent="start">
              <Grid item> 
                <span>Financial Sponsor Name: </span>
              </Grid>
              <Grid item> 
                <TextField id="sepFinSponsorName" name="sepFinSponsorName" label="Financial Sponsor Name"
                  variant="outlined" onChange={(e) => setFinSponsorName(e.target.value)} value={finSponsorName} />
              </Grid>              
            </Grid>
            <br />
            <Grid container spacing={3} alignItems="center" justifyContent="start">
              <Grid item> 
                <span>Financial Sponsor Nationality: </span>
              </Grid>
              <Grid item> 
                <CountrySelect countrySelectVal={finSponsorNationality} setCountrySelectVal={setFinSponsorNationality} fieldLabel={"Financial Sponsor Nationality"} isSchengen={false} />
              </Grid>              
            </Grid>
            <br />
            <Grid container spacing={3} alignItems="center" justifyContent="start">
              <Grid item> 
                <span>Financial Sponsor Passport Number: </span>
              </Grid>
              <Grid item> 
                <TextField id="sepFinSponsorName" name="sepFinSponsorName" label="Financial Sponsor Name"
                  variant="outlined" onChange={(e) => setFinSponsorPassportNumber(e.target.value)} value={finSponsorPassportNumber} />
              </Grid>              
            </Grid>
            <br />
            <Grid container spacing={3} alignItems="center" justifyContent="start">
              <Grid item> 
                <span>Financial Sponsor Phone Number: </span>
              </Grid>
              <Grid item> 
                <TextField id="sepFinSponsorName" name="sepFinSponsorName" label="Financial Sponsor Name"
                  variant="outlined" onChange={(e) => setFinSponsorPhoneNumber(e.target.value)} value={finSponsorPhoneNumber} />
              </Grid>              
            </Grid>
          </>
          )}
          <hr style={{ border: '1px solid #000', marginBottom: '3rem' }} />
          <Grid item xs={12}>
            <br />
            <TextField required id="shareToEmail" name="shareToEmail" label="Email Id"
              variant="outlined" onChange={(e) => setShareToEmail(e.target.value)} value={shareToEmail} />     
          </Grid>
          <br />               
          <Grid item xs={12}>
            {<FamilyNOCDocGenerator  
              shouldDisplayFamilyNOCDocGenerator={shouldDisplayFamilyNOCDocGenerator}
              applicationDate={applicationDate}
              diplomaticMissionVal={diplomaticMissionVal} 
              countrySelectVal={countrySelectVal}
              UAEEmirateVal={UAEEmirateVal}
              nocGranterName={nocGranterName}
              granterNationality={granterNationality}
              nocGranterPassportNumber={nocGranterPassportNumber}
              applicantRelationVal={applicantRelationVal}
              titleVal={titleVal}
              nocApplicantName={nocApplicantName}
              applicantNationality={applicantNationality}
              nocApplicantPassportNumber={nocApplicantPassportNumber}
              schengenEntries={schengenEntries}
              isSchengen={isSchengen}
              nonSchengenSearchDate={nonSchengenSearchDate}
              applicantGender={applicantGender}
              nocGranterPhoneNumber={nocGranterPhoneNumber}
              freeTextField={freeTextField}
              shareToEmail={shareToEmail}
              sepFinSponsor={sepFinSponsor}
              finSponsorName={finSponsorName}
              finSponsorNationality={finSponsorNationality}
              finSponsorPhoneNumber={finSponsorPhoneNumber}
              finSponsorPassportNumber={finSponsorPassportNumber}
            />}
          </Grid>                
        </>
      )}  
      {(NOCCategoryVal === 'Partner' ) && (
        <>
          <hr style={{ border: '1px solid #000', marginBottom: '3rem' }} />
          <div className="container mt-2">
            <Grid container spacing={3} alignItems="center" justifyContent="start">
              <Grid item>
                <span>Submission Date:</span>
              </Grid>            
              <Grid item>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Submission Date *"
                    value={applicationDate}
                    onChange={(newValue) => {
                      setApplicationDate(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Grid>          
            </Grid>
            <br />          
            <Grid container spacing={3} alignItems="center" justifyContent="start">
              <Grid item>
                <span>This is to confirm that we do not have any objection to </span>
              </Grid>
              <Grid item> 
                <TitleSelect titleVal={titleVal} setTitleVal={setTitleVal} setApplicantGender={setApplicantGender} fieldLabel="Title *" />
              </Grid>
              <Grid item>
                <TextField required id="noc_applicant_name" name="noc_applicant_name" label="Applicant Full Name" variant="outlined"
                  onChange={(e) => setNocApplicantName(e.target.value)} value={nocApplicantName} />
              </Grid>
              <Grid item>
                <span>'s , holder of </span>
              </Grid>
              <Grid item>
                <CountrySelect countrySelectVal={applicantNationality} setCountrySelectVal={setApplicantNationality} fieldLabel={"Applicant Nationality *"} isSchengen={false} />
              </Grid>
              <Grid item> 
                <span> passport number </span> 
              </Grid>
              <Grid item> 
                <TextField required id="noc_applicant_passport_number" name="noc_applicant_passport_number" label="Applicant Passport Number"
                  variant="outlined" onChange={(e) => setNocApplicantPassportNumber(e.target.value)} value={nocApplicantPassportNumber} />
              </Grid>
              {isSchengen && (    
                <Grid item> 
                  <span>, travel plans to&nbsp;
                    {schengenEntries.map((entry, index, array) => {
                      const isLast = index === array.length - 1;
                      const prefix = isLast && array.length > 1 ? ' and ' : '';
                      const suffix = !isLast ? ', ' : '';
                      return (
                        `${prefix}${entry.countryName} from ` +
                        `${formatDate(entry.searchDate[0].startDate)} to ` +
                        `${formatDate(entry.searchDate[0].endDate)}${suffix}`
                      );
                    }).join('').trim()}&#46;
                  </span>              
                </Grid>
              )}
              {!isSchengen && (    
                <Grid item> 
                  <span> I plan to travel to&nbsp;
                    {`${countrySelectVal} from ` +
                        `${formatDate(nonSchengenSearchDate[0].startDate)} to ` +
                        `${formatDate(nonSchengenSearchDate[0].endDate)}.`
                    }
                  </span>              
                </Grid>
              )}
              <Grid item> 
                <span>{(applicantGender==='male')?'he':((applicantGender==='female')?'she':'he/she')} is the </span> 
              </Grid>
              <Grid item> 
                <TextField required id="noc_applicant_share_percentage" name="noc_applicant_share_percentage" label="Share Percentage"
                  variant="outlined" onChange={(e) => setSharePercentage(e.target.value)} value={sharePercentage} />
              </Grid>
              <Grid item> 
                <span>% share partner of </span> 
              </Grid>
              <Grid item> 
                <TextField required id="noc_applicant_company" name="noc_applicant_company" label="Company Name"
                  variant="outlined" onChange={(e) => setCompanyName(e.target.value)} value={companyName} />
              </Grid>              
              <Grid item> 
                <span>. Please find the encolsed company trade license, which states {(applicantGender==='male')?'his':((applicantGender==='female')?'her':'his/her')} name.</span> 
              </Grid>
            </Grid>
            <br />            
            <Grid container spacing={3} alignItems="center" justifyContent="start">
              {(drawsSalary) && (
                <>  
                  <Grid item> 
                    <span>{(applicantGender==='male')?'He':((applicantGender==='female')?'She':'He/She')} draws a salary of </span> 
                  </Grid>               
                  <Grid item> 
                    <TextField required id="noc_applicant_salary" name="noc_applicant_salary" label="Salary Amount"
                      variant="outlined" onChange={(e) => setSalaryAmount(e.target.value)} value={salaryAmount} />
                  </Grid>                
                  <Grid item> 
                    <span> AED per month from the aforementioned company.</span> 
                  </Grid>
                </>
              )}                    
            </Grid>
            <br />
            <br />
            <Grid container spacing={3} alignItems="center" justifyContent="start">
              <TextField id="free_text_field" name="free_text_field" label="Free Text"
                variant="outlined" fullWidth onChange={(e) => setFreeTextField(e.target.value)} value={freeTextField} />
            </Grid>
            <br />            
            <Grid container spacing={3} alignItems="center" justifyContent="start">
              <Grid item> 
                <span>Your assistance in granting the {(isSchengen)?'schengen visa':'necessary visa to enter '+countrySelectVal} will be much appreciated.</span> 
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={3} alignItems="center" justifyContent="start">
              <Grid item> 
                <span>Signing Partner Name: </span>
              </Grid>
              <Grid item> 
                <TextField id="noc_granter_phone_number" name="noc_granter_phone_number" label="Partner Name"
                  variant="outlined" onChange={(e) => setNocGranterName(e.target.value)} value={nocGranterName} />
              </Grid>              
            </Grid>             
            <br />
            <Grid container spacing={3} alignItems="center" justifyContent="start">
              <Grid item> 
                <span>Nationality: </span>
              </Grid>
              <Grid item> 
                <CountrySelect countrySelectVal={granterNationality} setCountrySelectVal={setGranterNationality} fieldLabel={"Partner Nationality"} isSchengen={false} />
              </Grid>              
            </Grid>             
            <br />
            <Grid container spacing={3} alignItems="center" justifyContent="start">
              <Grid item> 
                <span>Passport Number: </span>
              </Grid>
              <Grid item> 
                <TextField id="noc_granter_phone_number" name="noc_granter_phone_number" label="Partner Phone Number"
                  variant="outlined" onChange={(e) => setNocGranterPassportNumber(e.target.value)} value={nocGranterPassportNumber} />
              </Grid>              
            </Grid>             
            <br />
            <Grid container spacing={3} alignItems="center" justifyContent="start">
              <Grid item> 
                <span>Phone Number: </span>
              </Grid>
              <Grid item> 
                <TextField id="noc_granter_phone_number" name="noc_granter_phone_number" label="Partner Phone Number"
                  variant="outlined" onChange={(e) => setNocGranterPhoneNumber(e.target.value)} value={nocGranterPhoneNumber} />
              </Grid>              
            </Grid>
          </div>
          <hr style={{ border: '1px solid #000', marginBottom: '3rem' }} />
          <br />
          <Grid item xs={12}>
            <br />
            <TextField required id="shareToEmail" name="shareToEmail" label="Email Id"
              variant="outlined" onChange={(e) => setShareToEmail(e.target.value)} value={shareToEmail} />     
          </Grid>
          <br />              
          <Grid item xs={12}>
            {<PartnerNOCDocGenerator  
              shouldDisplayPartnerNOCDocGenerator={shouldDisplayPartnerNOCDocGenerator}
              countrySelectVal={countrySelectVal}
              diplomaticMissionVal={diplomaticMissionVal}
              UAEEmirateVal={UAEEmirateVal}
              schengenEntries={schengenEntries}
              nonSchengenSearchDate={nonSchengenSearchDate}
              applicationDate={applicationDate}
              titleVal={titleVal}
              nocApplicantName={nocApplicantName}
              applicantNationality={applicantNationality}
              nocApplicantPassportNumber={nocApplicantPassportNumber}
              applicantGender={applicantGender}
              sharePercentage={sharePercentage}
              companyName ={companyName}
              nocGranterName={nocGranterName}
              granterNationality={granterNationality}
              nocGranterPassportNumber={nocGranterPassportNumber}
              nocGranterPhoneNumber={nocGranterPhoneNumber}
              isSchengen={isSchengen}
              drawsSalary={drawsSalary}
              salaryAmount={salaryAmount}
              freeTextField={freeTextField}
              shareToEmail={shareToEmail}
            />}
          </Grid>          
        </>
      )}
      {(NOCCategoryVal === 'Investor' ) && (
        <>
          <hr style={{ border: '1px solid #000', marginBottom: '3rem' }} />
          <div className="container mt-2">
            <Grid container spacing={3} alignItems="center" justifyContent="start">
              <Grid item>
                <span>Submission Date:</span>
              </Grid>            
              <Grid item>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Submission Date *"
                    value={applicationDate}
                    onChange={(newValue) => {
                      setApplicationDate(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Grid>          
            </Grid>
            <br />          
            <Grid container spacing={3} alignItems="center" justifyContent="start">
              <Grid item>
                <span>This is to confirm that I, </span>
              </Grid>
              <Grid item> 
                <TitleSelect titleVal={titleVal} setTitleVal={setTitleVal} setApplicantGender={setApplicantGender} fieldLabel="Title *" />
              </Grid>
              <Grid item>
                <TextField required id="noc_applicant_name" name="noc_applicant_name" label="Applicant Full Name" variant="outlined"
                  onChange={(e) => setNocApplicantName(e.target.value)} value={nocApplicantName} />
              </Grid>
              <Grid item>
                <span>, holder of </span>
              </Grid>
              <Grid item>
                <CountrySelect countrySelectVal={applicantNationality} setCountrySelectVal={setApplicantNationality} fieldLabel={"Applicant Nationality *"} isSchengen={false} />
              </Grid>
              <Grid item> 
                <span> passport number </span> 
              </Grid>
              <Grid item> 
                <TextField required id="noc_applicant_passport_number" name="noc_applicant_passport_number" label="Applicant Passport Number"
                  variant="outlined" onChange={(e) => setNocApplicantPassportNumber(e.target.value)} value={nocApplicantPassportNumber} />
              </Grid>
              <Grid item> 
                <span>, am the</span> 
              </Grid>               
              <Grid item> 
                <TextField required id="noc_applicant_share_percentage" name="noc_applicant_share_percentage" label="Share Percentage"
                  variant="outlined" onChange={(e) => setSharePercentage(e.target.value)} value={sharePercentage} />
              </Grid>
              <Grid item> 
                <span>% {NOCCategoryVal==='Investor'?'owner':'share partner'} of</span> 
              </Grid>
              <Grid item> 
                <TextField required id="noc_applicant_company" name="noc_applicant_company" label="Company Name"
                  variant="outlined" onChange={(e) => setCompanyName(e.target.value)} value={companyName} />
              </Grid>              
              <Grid item> 
                <span>&#46; Please find enclosed company trade license that states my name.</span> 
              </Grid>
            </Grid>
            <br />            
            <Grid container spacing={3} alignItems="center" justifyContent="start">
              {(drawsSalary) && (
                <>  
                  <Grid item> 
                    <span>I draw a salary of </span> 
                  </Grid>               
                  <Grid item> 
                    <TextField required id="noc_applicant_salary" name="noc_applicant_salary" label="Salary Amount"
                      variant="outlined" onChange={(e) => setSalaryAmount(e.target.value)} value={salaryAmount} />
                  </Grid>                
                  <Grid item> 
                    <span> AED per month from the aforementioned company.</span> 
                  </Grid>
                </>
              )}                    
              {isSchengen && (    
                <Grid item> 
                  <span> I plan to travel to&nbsp;
                    {schengenEntries.map((entry, index, array) => {
                      const isLast = index === array.length - 1;
                      const prefix = isLast && array.length > 1 ? ' and ' : '';
                      const suffix = !isLast ? ', ' : '';
                      return (
                        `${prefix}${entry.countryName} from ` +
                        `${formatDate(entry.searchDate[0].startDate)} to ` +
                        `${formatDate(entry.searchDate[0].endDate)}${suffix}`
                      );
                    }).join('').trim()}&#46;
                  </span>              
                </Grid>
              )}
              {!isSchengen && (    
                <Grid item> 
                  <span> I plan to travel to&nbsp;
                    {`${countrySelectVal} from ` +
                        `${formatDate(nonSchengenSearchDate[0].startDate)} to ` +
                        `${formatDate(nonSchengenSearchDate[0].endDate)}.`
                    }
                  </span>              
                </Grid>
              )}
            </Grid>
            <br />
            <br />
            <Grid container spacing={3} alignItems="center" justifyContent="start">
              <TextField id="free_text_field" name="free_text_field" label="Free Text"
                variant="outlined" fullWidth onChange={(e) => setFreeTextField(e.target.value)} value={freeTextField} />
            </Grid>
            <br />            
            <Grid container spacing={3} alignItems="center" justifyContent="start">
              <Grid item> 
                <span>Your assistance in granting me the {(isSchengen)?'schengen visa':'necessary visa to enter '+countrySelectVal} will be much appreciated.</span> 
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={3} alignItems="center" justifyContent="start">
              <Grid item> 
                <span>Phone Number: </span>
              </Grid>
              <Grid item> 
                <TextField id="noc_granter_phone_number" name="noc_granter_phone_number" label="Sponsor Phone Number"
                  variant="outlined" onChange={(e) => setNocGranterPhoneNumber(e.target.value)} value={nocGranterPhoneNumber} />
              </Grid>              
            </Grid>             
          </div>
          <hr style={{ border: '1px solid #000', marginBottom: '3rem' }} />
          <br />
          <Grid item xs={12}>
            <br />
            <TextField required id="shareToEmail" name="shareToEmail" label="Email Id"
              variant="outlined" onChange={(e) => setShareToEmail(e.target.value)} value={shareToEmail} />     
          </Grid>
          <br />          
          <Grid item xs={12}>
            {<InvestorNOCDocGenerator 
                shouldDisplayInvestorNOCDocGenerator= {shouldDisplayInvestorNOCDocGenerator}
                countrySelectVal={countrySelectVal}
                diplomaticMissionVal={diplomaticMissionVal}
                UAEEmirateVal={UAEEmirateVal}
                schengenEntries={schengenEntries}
                nonSchengenSearchDate={nonSchengenSearchDate}
                applicationDate={applicationDate}
                titleVal={titleVal}
                nocApplicantName={nocApplicantName}
                applicantNationality={applicantNationality}
                nocApplicantPassportNumber={nocApplicantPassportNumber}
                sharePercentage={sharePercentage}
                companyName={companyName}
                nocGranterPhoneNumber={nocGranterPhoneNumber}
                isSchengen={isSchengen}
                drawsSalary={drawsSalary}
                salaryAmount={salaryAmount}
                freeTextField={freeTextField}
                shareToEmail={shareToEmail}
            />}
          </Grid>           
        </>
      )}
      {(NOCCategoryVal === 'Employee (Inc. GOLDEN VISA)') && (
        <>
          <hr style={{ border: '1px solid #000', marginBottom: '3rem' }} />
          <div className="container mt-2">
            <Grid container spacing={3} alignItems="center" justifyContent="start">
              <Grid item>
                <span>Submission Date:</span>
              </Grid>            
              <Grid item>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Submission Date *"
                    value={applicationDate}
                    onChange={(newValue) => {
                      setApplicationDate(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Grid>          
            </Grid>
            <br />          
            <Grid container spacing={3} alignItems="center" justifyContent="start">
              <Grid item>
                <span>This is to confirm </span>
              </Grid>
              <Grid item> 
                <TitleSelect titleVal={titleVal} setTitleVal={setTitleVal} setApplicantGender={setApplicantGender} fieldLabel="Title *" />
              </Grid>
              <Grid item>
                <TextField required id="noc_applicant_name" name="noc_applicant_name" label="Applicant Full Name" variant="outlined"
                  onChange={(e) => setNocApplicantName(e.target.value)} value={nocApplicantName} />
              </Grid>
              <Grid item>
                <span>, holder of </span>
              </Grid>
              <Grid item>
                <CountrySelect countrySelectVal={applicantNationality} setCountrySelectVal={setApplicantNationality} fieldLabel={"Applicant Nationality *"} isSchengen={false} />
              </Grid>
              <Grid item> 
                <span> passport number </span> 
              </Grid>
              <Grid item> 
                <TextField required id="noc_applicant_passport_number" name="noc_applicant_passport_number" label="Applicant Passport Number"
                  variant="outlined" onChange={(e) => setNocApplicantPassportNumber(e.target.value)} value={nocApplicantPassportNumber} />
              </Grid>
              <Grid item> 
                <span>, is employed with our company&nbsp;</span> 
              </Grid>
              <Grid item> 
                <TextField required id="noc_applicant_company" name="noc_applicant_company" label="Company Name"
                  variant="outlined" onChange={(e) => setCompanyName(e.target.value)} value={companyName} />
              </Grid>
              <Grid item> 
                <span>since</span> 
              </Grid>
              <Grid item>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Employment Start Date *"
                    value={employmentStartDate}
                    onChange={(newValue) => {
                      setEmploymentStartDate(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item> 
                <span>as</span> 
              </Grid>
              <Grid item> 
                <TextField required id="noc_applicant_job_title" name="noc_applicant_job_title" label="Job Title"
                  variant="outlined" onChange={(e) => setJobTitle(e.target.value)} value={jobTitle} />
              </Grid>
              <Grid item> 
                <span>with a salary of AED</span> 
              </Grid>              
              <Grid item> 
                <TextField required id="noc_applicant_salary" name="noc_applicant_salary" label="Salary Amount"
                  variant="outlined" onChange={(e) => setSalaryAmount(e.target.value)} value={salaryAmount} />
              </Grid>                
              <Grid item> 
                <span>per month (all-inclusive).</span> 
              </Grid>            
            </Grid>
            <br />
            <br />
            <Grid container spacing={3} alignItems="center" justifyContent="start">
              <TextField id="free_text_field" name="free_text_field" label="Free Text"
                variant="outlined" fullWidth onChange={(e) => setFreeTextField(e.target.value)} value={freeTextField} />
            </Grid>
            <br />
            <Grid container spacing={3} alignItems="center" justifyContent="start">          
              {isSchengen && (    
                <Grid item> 
                  <span> We have no objection in {(applicantGender==='male')?'his':((applicantGender==='female')?'her':'his/her')} travel to &nbsp;
                    {schengenEntries.map((entry, index, array) => {
                      const isLast = index === array.length - 1;
                      const prefix = isLast && array.length > 1 ? ' and ' : '';
                      const suffix = !isLast ? ', ' : '';
                      return (
                        `${prefix}${entry.countryName} from ` +
                        `${formatDate(entry.searchDate[0].startDate)} to ` +
                        `${formatDate(entry.searchDate[0].endDate)}${suffix}`
                      );
                    }).join('').trim()}&#46;
                  </span>              
                </Grid>
              )}
              {!isSchengen && (    
                <Grid item> 
                  <span> We have no objection in {(applicantGender==='male')?'his':((applicantGender==='female')?'her':'his/her')} travel to &nbsp;
                    {`${countrySelectVal} from ` +
                        `${formatDate(nonSchengenSearchDate[0].startDate)} to ` +
                        `${formatDate(nonSchengenSearchDate[0].endDate)}.`
                    }
                  </span>              
                </Grid>
              )}
            </Grid>
            <br />
            <Grid container spacing={3} alignItems="center" justifyContent="start">
              <Grid item> 
                <span>Your assistance in granting the {(isSchengen)?'schengen visa':'necessary visa to enter '+countrySelectVal} will be much appreciated.</span>  
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={3} alignItems="center" justifyContent="start">
              <Grid item> 
                <span>Company Signatory Name: </span>
              </Grid>
              <Grid item> 
                <TextField id="noc_granter_name" name="noc_granter_name" label="Company Signatory Name"
                  variant="outlined" onChange={(e) => setNocGranterName(e.target.value)} value={nocGranterName} />
              </Grid>              
            </Grid>            
          </div>
          <hr style={{ border: '1px solid #000', marginBottom: '3rem' }} />
          <br />
          <Grid item xs={12}>
            <br />
            <TextField required id="shareToEmail" name="shareToEmail" label="Email Id"
              variant="outlined" onChange={(e) => setShareToEmail(e.target.value)} value={shareToEmail} />     
          </Grid>
          <br />
          <Grid item xs={12}>
            { <EmployeeNOCDocGenerator 
              shouldDisplayEmployeeNOCDocGenerator={shouldDisplayEmployeeNOCDocGenerator}
              countrySelectVal={countrySelectVal}
              diplomaticMissionVal={diplomaticMissionVal}
              UAEEmirateVal={UAEEmirateVal}
              schengenEntries={schengenEntries}
              nonSchengenSearchDate={nonSchengenSearchDate}
              applicationDate={applicationDate}
              titleVal={titleVal}
              nocApplicantName={nocApplicantName}
              applicantNationality={applicantNationality}
              nocApplicantPassportNumber={nocApplicantPassportNumber}
              applicantGender={applicantGender}
              companyName={companyName}
              salaryAmount={salaryAmount}
              isSchengen={isSchengen}
              employmentStartDate={employmentStartDate}
              jobTitle={jobTitle}
              nocGranterName={nocGranterName}
              freeTextField={freeTextField}
              shareToEmail={shareToEmail}
            />}
          </Grid>
        </>
      )}
    </div>

  );
}

export default NOCGenerator;