import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container, Grid, Card, CardContent, Typography, Box
} from '@mui/material';
import API from "../../Visa/API";
import '../css/styles.css'; // Update the path if needed
 
function getStringBeforeT(str) {
  const indexOfT = str.indexOf('T');
  if (indexOfT !== -1) {
    return str.substring(0, indexOfT);
  }
  return str;
}
 
function formatDate(dateStr) {
  if (!dateStr) return 'N/A';
  const date = new Date(dateStr);
  if (isNaN(date.getTime())) return 'N/A';
  const options = { day: 'numeric', month: 'short', year: 'numeric' };
  return date.toLocaleDateString('en-US', options);
}
 
const DMCForms = () => {
  const [forms, setForms] = useState([]);
  const [statusData, setStatusData] = useState({});
  const navigate = useNavigate();
 
  const checkDMCStatus = async (name, email, declaration) => {
    console.log(name, email, declaration.isDeclarationValid, 'checkDMCStatus');
    if (!declaration || !name || !email) return 'UNAVAILABLE';
    if (declaration?.isDeclarationValid === false) return 'INCOMPLETE';
    try {
      const response = await API.post('/DMC/check-dmc-status', { name, email });
      return response.data.status;
    } catch (error) {
      console.error('Error checking DMC status:', error);
      return 'UNAVAILABLE';
    }
  };
 
  useEffect(() => {
    const fetchForms = async () => {
      try {
        const initialCheck = await API.get('/DMC/staff-role', { withCredentials: true });
        console.log(initialCheck);
        if (initialCheck.data.role !== 'SuperAdmin') {
          alert('You cannot access this page!');
          navigate('/DMC/main');
        }
        const response = await API.get('/DMC/to-be-reviewed', { withCredentials: true });
        if (response.data) {
          const forms = response.data;
          setForms(forms);
          console.log(forms);
          const statusChecks = await Promise.all(forms.map(item =>
            checkDMCStatus(item?.basicCompanyInfo?.data?.companyName, item?.basicCompanyInfo?.data?.email, item?.declaration[item?.declaration?.length - 1])
          ));
 
          const statusData = forms.reduce((acc, item, index) => {
            acc[item._id] = statusChecks[index];
            return acc;
          }, {});
 
          setStatusData(statusData);
        }
      } catch (error) {
        console.error('Error fetching DMC forms:', error);
      }
    };
 
    fetchForms();
  }, [navigate]);
 
  const handleCardClick = (id) => {
    navigate(`/DMC/review-forms/${id}`);
  };
 
  const getLatestUpdatedDate = (form) => {
    const dates = [
      form.basicCompanyInfo.metadata.updatedDate,
      form.companyProfileStage.metadata.updatedDate,
      form.servicesAndOperations.metadata.updatedDate,
      form.contactDetails.metadata.updatedDate,
      form.packagesAndTariff.metadata.updatedDate,
    ].map(dateStr => new Date(dateStr));
 
    const validDates = dates.filter(date => !isNaN(date.getTime()));
    if (validDates.length === 0) return 'N/A';
   
    const latestDate = new Date(Math.max(...validDates.map(date => date.getTime())));
    return formatDate(latestDate.toISOString());
  };
 
  return (
    <Container>
      <Typography variant="h5" gutterBottom align="center" style={{ fontSize: '40px', marginTop: '10px' }}>
        Please Review DMC Forms
      </Typography>
      <Grid container spacing={3} marginTop={3}>
        {forms.map((form) => (
          <Grid item xs={12} md={6} lg={4} key={form._id}>
            <Card
              onClick={() => handleCardClick(form._id)}
              sx={{
                cursor: 'pointer',
                transition: 'transform 0.2s, box-shadow 0.2s',
                '&:hover': {
                  transform: 'scale(1.05)',
                  boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
                },
              }}
            >
              <Box
                sx={{
                  height: '30px',
                  backgroundColor: statusData[form._id] === 'Awaiting Review - UPDATED' ? 'dodgerblue' : (statusData[form._id] === 'Awaiting Review - NEW'? 'forestgreen' : (statusData[form._id] === 'INCOMPLETE' ? 'firebrick' : 'gray')),
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography variant="h6" sx={{ color: 'white', fontWeight: 'bold' }}>
                  {statusData[form._id]}
                </Typography>
              </Box>
              <CardContent>
                <Typography variant="h6" align="center" style={{ marginTop: '8px', fontSize: 32 }}>
                  {form.basicCompanyInfo.data.companyName}
                </Typography>
                <Typography>Founded: <strong>{form.basicCompanyInfo.data.yearOfIncorporation}</strong></Typography>
                <Typography>Destinations: <strong>{form.companyProfileStage.data.countriesProvideTour.map(country => country.label).join(', ')}</strong></Typography>
                <Typography>Offices: <strong>{form.companyProfileStage.data.countriesOwnOffices.map(country => country.label).join(', ')}</strong></Typography>
                <Typography>Segments: <strong>{form.servicesAndOperations.data.segmentsCaterTo.join(', ')}</strong></Typography>
                <Typography fontSize={13}>Create Date: <strong>{formatDate(form.basicCompanyInfo.metadata.createdDate) || 'N/A'}</strong></Typography>
                <Typography fontSize={13}>Last Updated:
                  <strong> {getLatestUpdatedDate(form)}</strong>
                </Typography>
                <Box display="flex" justifyContent="center" alignItems="center" flexWrap="wrap" gap={1} marginTop={2}>
                  {form.servicesAndOperations.data.acceptsFIT && (
                    <Box
                      component="span"
                      sx={{
                        display: 'inline-block',
                        bgcolor: '#F5F5F5',
                        color: '#000',
                        border: '1px solid #ccc',
                        borderRadius: '50px',
                        padding: '0 10px',
                        fontSize: '1rem'
                      }}
                    >
                      FIT
                    </Box>
                  )}
                  {form.servicesAndOperations.data.providesSIC && (
                    <Box
                      component="span"
                      sx={{
                        display: 'inline-block',
                        bgcolor: '#F5F5F5',
                        color: '#000',
                        border: '1px solid #ccc',
                        borderRadius: '50px',
                        padding: '0 10px',
                        fontSize: '1rem'
                      }}
                    >
                      SIC
                    </Box>
                  )}
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Box sx={{ height: '50px' }}></Box> {/* Extra spacing at the bottom of the page */}
    </Container>
  );
};
 
export default DMCForms;