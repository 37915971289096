import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { useDropzone } from "react-dropzone";
import dayjs from 'dayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { NavLink, useNavigate } from "react-router-dom"; 
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import ReactSelect from 'react-select';
import InputLabel from '@mui/material/InputLabel';
import toast from 'react-hot-toast';
import API from '../API';
import countryList from 'react-select-country-list'

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "30px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "grey",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "grey",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

export default function AddressForm() {
    const [hubspotData, setHubspotData] = React.useState(null);
    const [hubspotEmail, setHubspotEmail] = React.useState('');
    const [filesToUpload, setFilesToUpload] = React.useState([]);
    const {
      getRootProps,
      getInputProps,
      isFocused,
      isDragAccept,
      isDragReject,
      acceptedFiles,
      fileRejections,
    } = useDropzone({
      accept: "application/pdf",
      validator: (file) => {
        if (!file.name.endsWith(".pdf")) {
          return {
            code: "not-a-pdf",
            message: "Only PDF files are accepted",
          };
        }
        return null;
      },
      onDrop: (acceptedFiles) => {
        setFilesToUpload(acceptedFiles);
      },
    });  
    const navigate = useNavigate();
    const [selectedDate, setSelectedDate] = React.useState(dayjs(new Date()));
    const [submitted,setSubmitted] = React.useState([]);
    const [formCountry, setFormCountry] = React.useState(null);
    const [formVType, setFormVType] = React.useState(null);
    const [formEmirate, setFormEmirate] = React.useState(null);
    const [appointmentFileURL, setappointmentFileURL] = React.useState(null);
    const [formData, setFormData] = React.useState({
        visacountry: '',
        emirate: '',
        visaType: '',
        hubspot: '',
        groupURN: '',
        applicationcenteraddress: '',
        onedrive_url: '',
        appointment_file_url: '',
        appointment_date: '',
        fullName: '',
        referenceNumber: '',
        passportnumber: '',
        phone_number: '',
    });
    const [validatedGroupURN, setValidatedGroupURN] = React.useState(false);

    let formValid = formData.visacountry &&
                    formData.emirate &&
                    formData.visaType &&
                    formData.hubspot &&
                    formData.groupURN &&
                    formData.applicationcenteraddress &&
                    formData.onedrive_url &&
                    formData.fullName &&
                    validatedGroupURN;

    async function fetchHubspotDataFromEmail() {
      try {
        const response = await API.get(
          `/flights/user/hubspotemail/${hubspotEmail}`
        );  
        console.log(response.data);
        if (response.status === 200) {
          setHubspotData(response.data);
          setFormData({ ...formData, hubspot: response.data.properties.hs_object_id || ''});
        } else {
          setHubspotData(null);
        }
      } catch (error) {
        setHubspotData(null);
        console.error('Error fetching Hubspot data:', error);
      }
    }

    const handleEmailBlur = () => {
      fetchHubspotDataFromEmail();
      console.log(hubspotData);
    };    

    React.useEffect(() => {
      const entryCount = submitted.length + 1;
      if(!formData.groupURN) return;
      setFormData({ ...formData, referenceNumber: `${formData.groupURN}-${entryCount}`});
    }, [formData.groupURN]);
    
    React.useEffect(() => {
      async function uploadFile() {
      console.log(filesToUpload);
      if (filesToUpload && filesToUpload.length > 0) {
        toast.loading('Uploading File...'); // Add toast loading here
        const fileFormData = new FormData();
        fileFormData.append('file', filesToUpload[0]); 
        try {
        const response = await API.post('/ivisa/uploadappointmentFile', fileFormData, {
          headers: {
          'Content-Type': 'multipart/form-data'
          }
        });
        if (response.status === 201) {
          toast.dismiss();
          toast.success(response.data.message || 'File Uploaded Successfully');
          setFormData({ ...formData, appointment_file_url: response.data.url });
          setappointmentFileURL(response.data.url);
        } else {
          toast.error('Error Uploading File');
        }
        } catch (error) {
        console.error('Error submitting form:', error);
        }
        toast.dismiss(); // Dismiss toast loading
      }
      }
      uploadFile();

    }, [filesToUpload]);

    const removeAppointmentFile = async () => {
      try {
        setFilesToUpload([]);
        setappointmentFileURL(null);
        setFormData({ ...formData, appointment_file_url: '' });
      } catch (error) {
        console.error('Error Removing Appointment File', error);
      }
    };

    const countryOptions = React.useMemo(() => countryList().getData(), []);

    const handleCountryChange = (e) => {
      if (e?.label){
          if (e.label === 'Turkey'){
            setFormData({ ...formData, visacountry: "Türkiye" });
          } else {
            setFormData({ ...formData, visacountry: e.label }); 
          }
          setFormCountry(e);       
      }
    };

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
      };

    const handleHubspotEmailChange = (e) => {
      setFormData({ ...formData, hubspot: hubspotData?.properties.hs_object_id || ''});
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const handleVisaTypeChange = (event) => {
      setFormData({ ...formData, visaType: event.value });
      setFormVType(event)
    };

    const handleEmirateChange = (event) => {
      setFormData({ ...formData, emirate: event.value });
      setFormEmirate(event)
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault(); // Prevent default form submission behavior
      try {
        const response = await API.post('/ivisa/newentry', {
          ...formData,
          appointmentDate: selectedDate.format('YYYY-MM-DDTHH:mm:ss'),
        });
        if(response.status === 201){
          setSubmitted([...submitted,response.data]);
          toast.success('Entry Added Successfully');
          setFormData({
            ...formData, fullName: '',
            referenceNumber: `${formData.groupURN}-${(submitted.length + 2)}`,
            passportnumber: '',});
        }
        // Handle further actions like showing success message or redirecting
      } catch (error) {
        console.error('Error submitting form:', error);
        // Handle errors (e.g., display error message)
      }
    };
    const handleImmediateDelete = async (id) => {
      try {
        const response = await API.delete(`/ivisa/deletenewentry/${id}`);
        if(response.status === 200){
          toast.success('Entry Deleted Successfully');
          setSubmitted(submitted.filter((entry) => entry._id !== id));
        }
        // Handle further actions like showing success message or redirecting
      } catch (error) {
        console.error('Error submitting form:', error);
        // Handle errors (e.g., display error message)
      }
    }
    const validateGroupURN  = async () => {
      setValidatedGroupURN(false);
      try {
        if (!formData.groupURN) {
          toast.info('Please enter a Group URN.');
          return;
        }
        const response = await API.get(`/ivisa/validategroupurn/${formData.groupURN}`, {
          validateStatus: function (status) {
            return status < 500; // Reject only if the status code is greater than or equal to 500
          }
        });
    
        if (response.status === 200) {
          toast.success(response.data.message || 'Group URN is valid');
          setValidatedGroupURN(true);
          console.log(validatedGroupURN)
        } else if (response.status === 409) {
          toast.error(response.data.message || 'Group URN already exists');
        } else {
          toast.error('Unexpected response from the server');
        }
        console.log(response);
      } catch (error) {
        console.error('Error validating group URN:', error);
        toast.error('An error occurred while validating the Group URN.');
      }
    };
    const dropdownStyle = {
      control: (provided) => ({
        ...provided
      }),    
      menu: (provided) => ({
        ...provided,
        zIndex: 9999,
        width: '300px' // You can adjust this value to suit your needs
      })
    };    

    let acceptedFileItems = acceptedFiles.map((file) => (
      <li key={file.path}>
        {file.path} - {file.size} bytes
      </li>
    ));
  
    const fileRejectionItems = fileRejections.map(({ file, errors }) => (
      <li key={file.path}>
        {file.path} - {file.size} bytes
        <ul>
          {errors.map((e) => (
            <li key={e.code}>{e.message}</li>
          ))}
        </ul>
      </li>
    ));

    const style = React.useMemo(
      () => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {}),
      }),
      [isFocused, isDragAccept, isDragReject]
    );

  return (

    <React.Fragment>
      <Button
        variant="contained"
        sx={{ margin: "5px" }}
        onClick={() =>
          navigate("/visa/applications")
        }
      >
        <ArrowBackIcon /> Go Back
      </Button>
      <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
        <Box sx={{width:"100%", display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
          <Box sx={{width:"50%", display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', margin: '20px', padding: '20px' }}>
            <Typography variant="h6" gutterBottom sx={{marginBottom: "40px"}}>
              Application Details (New Entry)
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <InputLabel id="country-label">Select Country *</InputLabel>
                <ReactSelect  
                  required
                    autoFocus 
                    labelId="visa-country-label"
                    styles={dropdownStyle}              
                    id="country" 
                    name="country" 
                    placeholder="Select Country"
                    label="Enter Country"  
                    value={formCountry}
                    options={countryOptions}
                    onChange={handleCountryChange}
                />            
              </Grid>
              <Grid item xs={12} md={3}>
                <InputLabel id="emirate-label">Emirate *</InputLabel>
                <ReactSelect  
                  required
                    labelId="emirate-label"
                    styles={dropdownStyle}     
                    id="emirate"
                    name="emirate"
                    value={formEmirate}
                    placeholder="Select Emirate"
                    options={[
                      { value: 'Dubai', label: 'Dubai' },
                      { value: 'Abu Dhabi', label: 'Abu Dhabi' },
                      { value: 'Sharjah', label: 'Sharjah' },
                      { value: 'VAYD', label: 'VAYD' }
                      ]}
                    onChange={handleEmirateChange}
                    variant="standard"
                /> 
              </Grid>             
              <Grid item xs={12} md={3}>
                <InputLabel id="visa-type-label">Visa Type *</InputLabel>            
                <ReactSelect  
                  required
                    labelId="visa-type-label"
                    styles={dropdownStyle}     
                    id="visaType"
                    name="visaType"
                    value={formVType}
                    placeholder="Select Type"
                    options={[
                      { value: 'Stamp', label: 'Stamp' },
                      { value: 'E-Visa', label: 'E-Visa' }
                      ]}
                    onChange={handleVisaTypeChange}
                    variant="standard"
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  id="hubspot"
                  name="hubspot"
                  label="Hubspot Email"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setHubspotEmail(e.target.value)}
                  value={hubspotEmail}
                  onBlur={handleEmailBlur}
                  />
              </Grid>            
              <Grid item xs={12} md={3}>
                <TextField
                  required
                  id="groupURN"
                  name="groupURN"
                  label="Group URN Reference"
                  fullWidth
                  variant="standard"
                  onChange={handleInputChange}
                  value={formData.groupURN}
                  inputProps={{ pattern: "[A-Za-z0-9-_/\ ]+" }}
                  onBlur={validateGroupURN}
                /> 
              </Grid>          

              <Grid item xs={12} md={6}>
                <TextField
                  required
                  id="applicationcenteraddress"
                  name="applicationcenteraddress"
                  label="Application Center Address"
                  fullWidth
                  onChange={handleInputChange}
                  variant="standard"
                  value={formData.applicationcenteraddress}
                  />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  required
                  id="onedrive_url"
                  name="onedrive_url"
                  label="One Drive URL"
                  fullWidth
                  variant="standard"
                  onChange={handleInputChange}
                  value={formData.onedrive_url}
                  />
              </Grid>
              <Grid item xs={12} md={4}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={['DateTimePicker']}>
                          <DateTimePicker label="Submission Date *" value={selectedDate} onChange={handleDateChange} />
                      </DemoContainer>
                  </LocalizationProvider>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{width:"50%", display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', margin: '20px', padding: '20px' }}>  
            <Grid container spacing={3}>
              <Grid item xs={12}>
              <div
                className="container"
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  gap: "50px",
                  justifyContent: "center",
                  height: "30vh",
                  marginTop: "10px",
                }}
              >
                <div {...getRootProps({ style })}>
                  <input {...getInputProps()} />
                  <p>Please Upload One Appointment File</p>
                  <aside>
                    <h4>Selected File:</h4>
                    <ul>{acceptedFileItems.slice(0,20)}</ul>
                  </aside>
                </div>
              </div>    
              </Grid>
              {appointmentFileURL && (
                <>
                <Grid item xs={12} md={6}>
                  <Button 
                    variant="contained" 
                    color="primary"
                    onClick={() => window.open(appointmentFileURL, '_blank')}
                  >
                    Show Appointment File in Sharepoint
                  </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button 
                    variant="contained" 
                    color="error"
                    onClick={() => removeAppointmentFile()}
                  >
                    Remove Appointment File
                  </Button>
                </Grid>   
                </>
              )}
            </Grid>
          </Box>
        </Box>
        { hubspotData && (
        <Box sx={{width:"90%", display: 'flex', flexDirection: 'column', justifyContent: 'centre', alignItems: 'centre', margin: '20px', padding: '20px' }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={3}>
                  <a href={`https://app.hubspot.com/contacts/20340520/record/0-1/`+hubspotData.properties.hs_object_id} target="_blank" rel="noreferrer">
                {hubspotData.properties.firstname}&nbsp;{hubspotData.properties.lastname}
                  </a>
                <Grid item xs={12} md={3}></Grid>
                {hubspotData.properties.phone}&nbsp;
                
                <Grid item xs={12} md={3}></Grid>
                {hubspotData.properties.email}&nbsp;
                </Grid>
              </Grid>
        </Box>
        )}
        <Box  sx={{width:"90%", display: 'flex', flexDirection: 'column', justifyContent: 'centre', alignItems: 'centre', margin: '20px', padding: '20px' }}>
          <Grid container spacing={3} sx={{marginTop:"20px"}}>
            <Grid item xs={12} md={6}>
              <TextField
                required
                id="fullName"
                name="fullName"
                label="Full Name"
                fullWidth
                autoComplete="given-name"
                variant="standard"
                onChange={handleInputChange}
                value={formData.fullName}
                inputProps={{ pattern: "[A-Za-z0-9\ ]+" }}
                />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                disabled
                id="referenceNumber"
                name="referenceNumber"
                label="Reference Number"
                fullWidth
                autoComplete="family-name"
                variant="standard"
                onChange={handleInputChange}
                value={formData.referenceNumber}
                />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                id="passportnumber"
                name="passportnumber"
                label="Passport Number"
                fullWidth
                autoComplete="shipping address-line1"
                variant="standard"
                onChange={handleInputChange}
                value={formData.passportnumber}
                inputProps={{ pattern: "[A-Za-z0-9]+" }}
                />
            </Grid>
              <Grid item xs={12} sx={{marginBottom:"20px"}}>
                <Button type="submit" variant="contained" color="primary" disabled={!formValid}>
                  Add
                </Button>
              </Grid>
          </Grid>        
        {
          submitted.length > 0 && submitted.map((entry,index) => (
            <div key={index} style={{display:"flex",justifyContent:"space-between",alignItems:"center",border:"1px solid green",fontFamily:"monospace",padding:"5px",borderRadius:"10px",margin:"15px"}}>
              <h6>{ " Added: " + entry.name + " - " + entry.reference_number + " - " + entry.passport_number}</h6>
              <Button variant="contained" color="error" size='mdall' onClick={() => handleImmediateDelete(entry._id)}><CloseIcon/></Button>
            </div>
          ))
        }        
        </Box>
      </Box>        
    </React.Fragment>
  );
}