import { useState, useEffect, useRef  } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { Button } from 'react-bootstrap';
import API from "../../Visa/API";
import CountrySelect from '../../Visa/components/CountrySelect.js';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { TextField, Select, MenuItem } from '@mui/material';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import toast from "react-hot-toast";
import LaunchIcon from '@mui/icons-material/Launch';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';    
import EmailIcon from '@mui/icons-material/Email';         
import WhatsAppIcon from '@mui/icons-material/WhatsApp';   

const DocumentTable = ({ fields, handleEditChange, editingField, setEditingField, onEditClick, handleVerification }) => {

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');  // Get the day and ensure it's 2 digits
    const month = String(date.getMonth() + 1).padStart(2, '0');  // Get the month (getMonth() returns 0-based index)
    const year = date.getFullYear();  // Get the full year
    return `${day}/${month}/${year}`;  // Format as dd/mm/yyyy
  };

  const renderFieldComponent = (field) => {
    switch (field.type) {
      case 'checkbox':
        return (
          <input
            type='checkbox'
            checked={field?.value==='Yes'?true:false}
            autoFocus
            onBlur={() => setEditingField(null)}
            onClick={(e) => handleEditChange(field.fieldKey, e.target.checked)}
          />
        );
      case 'country':
        return (
          <CountrySelect
            countrySelectVal={field?.value}
            setCountrySelectVal={(e) => handleEditChange(field.fieldKey, e)}
            isSchengen={false}
            onBlur={() => setEditingField(null)}
            autoFocus
          />
        );
      case 'date':
        return (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label={field.label}
              value={field.value ? dayjs(field.value) : null} // Ensure value is a Day.js object
              onChange={(date) => handleEditChange(field.fieldKey, date ? date.toISOString() : null)} // Correct the onChange handler
              renderInput={(params) => <TextField {...params} />}
              onBlur={()=>setEditingField(null)}
              autoFocus
            />
          </LocalizationProvider>
        );
      case 'select':
        return (
          <Select
            value={field.value || ''} // Default to an empty string if no value is selected
            onChange={(e) => handleEditChange(field.fieldKey, e.target.value)}
            onBlur={() => setEditingField(null)}
            displayEmpty
            autoFocus
          >
            {field.options && field.options?.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        );
      default:
        return (
          <TextField
            value={field.value}
            onChange={(e) => handleEditChange(field.fieldKey, e.target.value)}
            onBlur={() => setEditingField(null)}
            autoFocus
          />
        );
    }
  };
  return (
    <table style={{ width: '100%' }}>
      <tbody>
        {fields?.map((field, index) => (
          <tr
            key={index}
            style={{
              height: '60px', // Set row height to 60px
              backgroundColor: index % 2 === 0 ? '#ffffff' : '', // Light grey for even rows, white for odd rows
            }}
          >
            <td style={{ width: '25%', fontWeight: '100', fontSize: 'smaller', textAlign: 'right' }}>
              {field.label}&nbsp;&nbsp;
              {field.type === 'email'
                  ? (
                      <a href={field.mailtoURL}><EmailIcon /></a>
                  )
                  : field.type === 'mobile'
                  ? (
                    <>
                      <a href={field.phoneURL}><PhoneIphoneIcon /></a>
                    </>
                  )
                  : field.type === 'whatsapp'
                  ? (
                    <>
                      <a href={field.whatsappURL}><WhatsAppIcon /></a>
                    </>
                  )
                  : ''
                }              
            </td>
            <td style={{ width: '50%', fontWeight: 'bold', fontSize: 'larger', textAlign: 'center', justifyContent: 'center' }}>
              {editingField === field.fieldKey ? (
                renderFieldComponent(field)
              ) : (
                <span onClick={() => onEditClick(field.fieldKey)}>
                {field.type === 'date'? formatDate(field.value):field.value || '---------'}
                </span>
              )}
            </td>
            <td style={{ width: '25%', fontWeight: '100', fontSize: 'smaller', textAlign: 'left' }}>
              {field.isVerified ? (
                <CheckIcon
                  onClick={() => handleVerification(field.verificationFieldKey, false)}
                  style={{ color: 'green' }}
                />
              ) : (
                <ClearIcon
                  onClick={() => handleVerification(field.verificationFieldKey, true)}
                  style={{ color: 'red' }}
                />
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const ParticipantDetails = ({ selectedParticipant, searchParams, tourId, fetchBasicDetails, showEID, tourName }) => {
  const [participant, setParticipant] = useState(selectedParticipant);
  const [editingField, setEditingField] = useState(null);
  const [buttonsDisabled, setButtonsDisabled] = useState(false);

  const fileInputRef = useRef(null);

  useEffect(() => {
    setParticipant(selectedParticipant);
  }, [selectedParticipant]);


  const handleToggleVerification = (fieldKey, newValue) => {
    handleEditChange('isVerified.'+fieldKey, newValue); 
  };  

  const handleEditChange = (fieldKey, newValue) => {
    setParticipant((prev) => {
      const updatedParticipant = { ...prev };
      const keys = fieldKey.split('.');
      const lastKey = keys.pop();
      // Traverse object by keys path
      const target = keys.reduce((obj, key) => {
        if (!obj[key]) obj[key] = {}; // Create path if it doesn't exist
        return obj[key];
      }, updatedParticipant);
      target[lastKey] = newValue; // Set the new value
      return updatedParticipant;
    });
  };

  const handleEditClick = (fieldKey) => {
    setEditingField(fieldKey);
  };

  const handleSaveClick = async () => {
    toast.loading("Saving Data")
    setButtonsDisabled(true);
    try {
      const tour = await API.put('/groups/updateParticipant', {
        tourId: tourId, fullName: participant.fullName, participantData: participant
      })
      setEditingField(null);
      toast.dismiss();
      toast.success("Data Updated!")            
    } catch (error) {
      console.error(error);
      toast.dismiss();
      toast.error("Something went wrong. Contact IT :(");
    }
    setButtonsDisabled(false);
    fetchBasicDetails();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0]; // Get the selected file
    if (!file) {
      console.error("File Not Found") // Store the file in state
    }
    handleUploadFile(file);
  }

  const handleUploadFile = (file) => {
    setButtonsDisabled(true)
    // Create a FileReader to read the file as an ArrayBuffer
    const reader = new FileReader();
    reader.onload = async (event) => {
      const fileContent = event.target.result.split(',')[1];
      const arrayBuffer = event.target.result; // The ArrayBuffer of the file
  
      // Convert the buffer to a base64 string (if needed)
      const buffer = new Uint8Array(arrayBuffer);
      const bufferString = Array.from(buffer)?.map(byte => String.fromCharCode(byte)).join('');
  
      // Get file data based on document type
      const fileData = () => {
        if (searchParams.get('documentType') === 'Passport') {
          return { name: 'passport', key: 'passportUrl', urlKey: 'typrformURLs.passportURL' };
        } else if (searchParams.get('documentType') === 'UAE Visa') {
          if (showEID === true) {
            return { name: 'emirates_id', key: 'emiratesIdUrl', urlKey: 'typrformURLs.emiratesIdUrl'  };
          } else {
            return { name: 'residence_visa', key: 'uaeResidenceVisa.visaDocumentUrl', urlKey: 'typrformURLs.uaeResidenceVisaURL'  };
          }
        } else {
          return null;
        }
      };
      const selectedFileData = fileData();
      toast.loading(`Uploading File ${selectedFileData.name}`)
      if (selectedFileData === null) {
        console.error("File Name Error");
        return;
      }
      // Prepare the payload
      const payload = {
        fileName: selectedFileData,
        fileExtension: file.name.split('.').pop(),
        tourId: tourId,
        tourName: tourName,
        participantFullName: participant.fullName,
        fileType: file.type,
        buffer: fileContent, // Include the ArrayBuffer in your request
      };
      try {
        // Make the POST request using Axios
        const response = await API.post('/groups/uploadParticipantAttachment', payload);
  
        if (response.status === 200) {
          console.log("File uploaded successfully");
          toast.dismiss();
          toast.success("Uploaded");
          fetchBasicDetails();
        } else {
          console.error("File upload failed");
          toast.dismiss();
          toast.error("Upload Error");
        }
      } catch (error) {
        console.error("Error uploading file:", error);
        toast.dismiss();
        toast.error("Upload Error");
      } finally{
        setButtonsDisabled(false);
      }
    };
  
    // Read the file as an ArrayBuffer
    if (file !== null) {
      reader.readAsDataURL(file);
    }
  };


  const renderEmergencyContact = () => {
    return (
        <>
        <h3 className="mt-5 d-flex justify-content-center">Emergency Contacts</h3>
        <table style={{ width: '100%', marginTop: '20px' }}>
          <tbody>
            {participant.emergencyContacts?.map((contact, index) => (
                <tr key={index}>
                    <td style={{ width: '25%', textAlign: 'center' }}>
                        {contact.name}
                    </td>
                    <td style={{ width: '25%', textAlign: 'center' }}>
                        {contact.phoneNumber}
                    </td>
                    <td style={{ width: '25%', textAlign: 'center' }}>
                        {contact.email}
                    </td>
                    <td style={{ width: '25%', textAlign: 'center' }}>
                        {contact.relationship}
                    </td>                                                            
                </tr>                
            ))}
          </tbody>
        </table>
        </>
      );
  }

  const documentType = searchParams.get('documentType');
  const fields = getFieldsForDocumentType(participant, documentType);

  return (
    <div style={{ width: '50%', height: '90vh', backgroundColor: '#e9ecef', padding: '10px', float: 'left' }}>
      <h5 className="d-flex justify-content-center">Participant Details&nbsp;({participant?.isVerifiedScore}/16)</h5>
      <br />
      {participant && participant.fullName && fields.length > 0 && (
        <DocumentTable
          fields={fields}
          handleEditChange={handleEditChange}
          editingField={editingField}
          setEditingField={setEditingField}
          onEditClick={handleEditClick}
          handleVerification={handleToggleVerification}
        />
      )}
      {documentType === 'Contact Info' && renderEmergencyContact()}
      <div className='mt-5' style={{ display: 'flex', justifyContent: 'center', alignItems:'center'}}>
        <SettingsBackupRestoreIcon 
          className="mx-3 text-center d-flex align-items-center custom-button"
          style={{color: '#007bff', cursor: 'pointer'}}
          onClick={fetchBasicDetails}
          />
        <Button 
          className="mx-3 text-center d-flex align-items-center custom-button" 
          disabled={buttonsDisabled}
          onClick={handleSaveClick} 
        >
          Save Changes
        </Button>
      </div>
      {(searchParams.get('documentType')==='Passport' || searchParams.get('documentType')==='UAE Visa') && (
        <div className='mt-5' style={{ display: 'flex', justifyContent: 'center', alignItems:'center'}}>
          <input type="file" style={{display:'none'}} ref={fileInputRef} onChange={handleFileChange} />
          <Button 
            variant='outline-secondary'
            className="mx-3 text-center d-flex align-items-center custom-button" 
            onClick={() => fileInputRef.current.click()} // Trigger file selection
            disabled={buttonsDisabled}          
          >
            Upload {searchParams.get('documentType')==='Passport'?'Passport':showEID===true?'Emirates ID':'UAE Visa'}
          </Button>
          <LaunchIcon 
            className="mx-3 text-center d-flex align-items-center custom-button"
            style={{color: '#6c757d', cursor: 'pointer'}}
            onClick={() => {
              if (searchParams.get('documentType')==='Passport'){
                window.open(participant?.typrformURLs?.passportURL,'_blank', 'width=1000,height=1000,menubar=no,toolbar=no,resizable=yes');
              } else if (searchParams.get('documentType')==='UAE Visa'){
                if (showEID===true){
                  window.open(participant?.typrformURLs?.emiratesIdUrl,'_blank', 'width=1000,height=1000,menubar=no,toolbar=no,resizable=yes');
                } else {
                  window.open(participant?.typrformURLs?.uaeResidenceVisaURL,'_blank', 'width=1000,height=1000,menubar=no,toolbar=no,resizable=yes');
                }
              }
            }}
          />
        </div>      
      )}

    </div>
  );
};

const getFieldsForDocumentType = (participant, documentType) => {
    const passportFields = [
        { 
          label: 'Full Name', 
          value: participant?.fullName, 
          isVerified: participant?.isVerified?.passportData?.fullName || false, 
          fieldKey: 'fullName', 
          verificationFieldKey: 'passportData.fullName',
          type: 'text'
        },
        { 
          label: 'First Name', 
          value: participant?.firstName, 
          isVerified: participant?.isVerified?.passportData?.firstName || false, 
          fieldKey: 'firstName', 
          verificationFieldKey: 'passportData.firstName',
          type: 'text'
        },
        { 
          label: 'Surname', 
          value: participant?.surName, 
          isVerified: participant?.isVerified?.passportData?.surName || false, 
          fieldKey: 'surName', 
          verificationFieldKey: 'passportData.surName',
          type: 'text'
        },                
        { 
          label: 'Gender', 
          value: participant?.gender, 
          isVerified: participant?.isVerified?.passportData?.gender || false, 
          fieldKey: 'gender', 
          verificationFieldKey: 'passportData.gender',
          type: 'select',
          options: ['Female', 'Male', 'Other'] 
        },
        { 
          label: 'Date Of Birth', 
          value: participant?.dateOfBirth, 
          isVerified: participant?.isVerified?.passportData?.dateOfBirth || false, 
          fieldKey: 'dateOfBirth', 
          verificationFieldKey: 'passportData.dateOfBirth',
          type: 'date' 
        },
        { 
          label: 'Nationality', 
          value: participant?.nationality, 
          isVerified: participant?.isVerified?.passportData?.nationality || false, 
          fieldKey: 'nationality', 
          verificationFieldKey: 'passportData.nationality',
          type: 'country' 
        },
        { 
          label: 'Travel Document Type', 
          value: participant?.travelDocumentType, 
          isVerified: participant?.isVerified?.passportData?.travelDocumentType || false, 
          fieldKey: 'travelDocumentType', 
          verificationFieldKey: 'passportData.travelDocumentType',
          type: 'select',
          options: ['Passport', 'Refugee Travel Document'] 
        },
        { 
          label: 'Expiry Date', 
          value: participant?.passportExpiryDate, 
          isVerified: participant?.isVerified?.passportData?.passportExpiryDate || false, 
          fieldKey: 'passportExpiryDate', 
          verificationFieldKey: 'passportData.passportExpiryDate',
          type: 'date'
        },           { 
          label: 'Passport Number', 
          value: participant?.passportNumber, 
          isVerified: participant?.isVerified?.passportData?.passportNumber || false, 
          fieldKey: 'passportNumber', 
          verificationFieldKey: 'passportData.passportNumber',
          type: 'text'
        },    
      ];
    
      if (participant?.nationality?.includes('UAE') || participant?.nationality?.includes('United Arab Emirates')) {
        passportFields.push({ 
          label: 'Is UAE Mentioned in Passport', 
          value: participant.isUAEMentionedUAENat ? 'Yes' : 'No', 
          isVerified: participant.isVerified?.passportData?.isUAEMentionedUAENat || false, 
          fieldKey: 'isUAEMentionedUAENat', 
          verificationFieldKey: 'passportData.isUAEMentionedUAENat' ,
          type: 'checkbox'
        });  
      }
    
      const uaeVisaFields = [
        { 
          label: 'Emirate Of Issue', 
          value: participant?.uaeResidenceVisa?.emirateOfIssue, 
          isVerified: participant?.isVerified?.uaeResidenceVisaData?.emirateOfIssue || false, 
          fieldKey: 'uaeResidenceVisa.emirateOfIssue', 
          verificationFieldKey: 'uaeResidenceVisaData.emirateOfIssue', 
          type: 'select',
          options: ['Sharjah', 'Dubai', 'Abu Dhabi', 'Ajman', 'Ras Al-Khaimah', 'Umm Al-Quwain', 'Fujairah'] 
        },
        { 
          label: 'Expiry Date', 
          value: participant?.uaeResidenceVisa?.dateOfExpiry, 
          isVerified: participant?.isVerified?.uaeResidenceVisaData?.dateOfExpiry || false, 
          fieldKey: 'uaeResidenceVisa.dateOfExpiry', 
          verificationFieldKey: 'uaeResidenceVisaData.dateOfExpiry' ,
          type: 'date'  
        },
        { 
          label: 'Residence Visa In Current Passport', 
          value: participant?.uaeResidenceVisa?.stampedInPassport ? 'Yes' : 'No', 
          isVerified: participant?.isVerified?.uaeResidenceVisaData?.stampedInPassport || false, 
          fieldKey: 'uaeResidenceVisa.stampedInPassport', 
          verificationFieldKey: 'uaeResidenceVisaData.stampedInPassport'  ,
          type: 'checkbox' 
        },
        { 
          label: 'Profession', 
          value: participant?.uaeResidenceVisa?.profession, 
          isVerified: participant?.isVerified?.uaeResidenceVisaData?.profession || false, 
          fieldKey: 'uaeResidenceVisa.profession', 
          verificationFieldKey: 'uaeResidenceVisaData.profession' ,
          type: 'select',
          options: ['Student', 'Other']
        },        
      ];
    
      const contactInfoFields = [
        { 
          label: "Mobile Number", 
          value: participant?.mobileNumber, 
          isVerified: participant?.isVerified?.contactInformation?.mobileNumber || false, 
          fieldKey: 'mobileNumber', 
          verificationFieldKey: 'contactInformation.mobileNumber'  ,
          type: 'mobile',
          phoneURL: `tel:${participant?.mobileNumber}`
        },
        { 
          label: "WhatsApp Number", 
          value: participant?.whatsappNumber, 
          isVerified: participant?.isVerified?.contactInformation?.whatsappNumber || false, 
          fieldKey: 'whatsappNumber', 
          verificationFieldKey: 'contactInformation.whatsappNumber'  ,
          type: 'whatsapp',
          whatsappURL: `https://wa.me/${participant?.whatsappNumber}` 
        },
        { 
          label: "Personal Email", 
          value: participant?.personalEmail, 
          isVerified: participant?.isVerified?.contactInformation?.personalEmail || false, 
          fieldKey: 'personalEmail',
          verificationFieldKey: 'contactInformation.personalEmail' ,
          type: 'email',
          mailtoURL: `mailto:${participant?.personalEmail}` 
        },
        { 
          label: "Secondary Email", 
          value: participant?.secondaryEmail, 
          isVerified: participant?.isVerified?.contactInformation?.secondaryEmail || false, 
          fieldKey: 'secondaryEmail', 
          verificationFieldKey: 'contactInformation.secondaryEmail'  ,
          type: 'email',
          mailtoURL: `mailto:${participant?.personalEmail}` 
        },
      ];

//   const otherFields = [
//     schengenVisa.obtained
//     // schengenVisa.recentVisaCopyUrl - add this to the file field on other
//     residentialAddress
//     emirateOfResidence
//     emergencyContacts
//     preferences
//     violations
//   ]

  switch (documentType) {
    case 'Passport':
      return passportFields;
    case 'UAE Visa':
      return uaeVisaFields;
    case 'Contact Info':
      return contactInfoFields;
    default:
      return [];
  }
};

export default ParticipantDetails;
