import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    filter: [],
}

export const filterSlice = createSlice({
    name: 'filter',
    initialState,
    reducers: {
        addFilter: (state, action) => {
            state.filter.push(action.payload);
        },
        removeFilter: (state, action) => {
            state.filter = state.filter.filter((item) => item !== action.payload);
        },
    },
});

export const { addFilter, removeFilter } = filterSlice.actions;

export default filterSlice.reducer;
