export const compareProfiles = (profileTemp, profileFinal) => {
    let changes = [];
  
    const compareObjects = (obj1, obj2, parentKey = '') => {
      for (let key in obj1) {
        const currentKey = parentKey ? `${parentKey}.${key}` : key;
        if (Array.isArray(obj1[key])) {
          if (!obj2[key] || !arraysEqual(obj1[key], obj2[key])) {
            changes.push({
              id: currentKey,
              oldValue: obj2[key] !== undefined ? obj2[key] : null,
              newValue: obj1[key] !== undefined ? obj1[key] : null
            });
          }
        } else if (typeof obj1[key] === 'object' && obj1[key] !== null) {
          if (!obj2[key] || typeof obj2[key] !== 'object') {
            changes.push({
              id: currentKey,
              oldValue: obj2[key] !== undefined ? obj2[key] : null,
              newValue: obj1[key] !== undefined ? obj1[key] : null
            });
          } else {
            compareObjects(obj1[key], obj2[key], currentKey);
          }
        } else {
          if (obj1[key] !== obj2[key]) {
            changes.push({
              id: currentKey,
              oldValue: obj2[key] !== undefined ? obj2[key] : null,
              newValue: obj1[key] !== undefined ? obj1[key] : null
            });
          }
        }
      }
  
      // Also check for keys present in obj2 but not in obj1
      for (let key in obj2) {
        if (!(key in obj1)) {
          const currentKey = parentKey ? `${parentKey}.${key}` : key;
          changes.push({
            id: currentKey,
            oldValue: obj2[key] !== undefined ? obj2[key] : null,
            newValue: undefined
          });
        }
      }
    };
  
    const arraysEqual = (arr1, arr2) => {
      if (!Array.isArray(arr1) || !Array.isArray(arr2)) return false;
      if (arr1.length !== arr2.length) return false;
      for (let i = 0; i < arr1.length; i++) {
        if (JSON.stringify(arr1[i]) !== JSON.stringify(arr2[i])) return false;
      }
      return true;
    };
  
    compareObjects(profileTemp, profileFinal);
    return changes;
  };
  