import React, { useState, useEffect } from "react";

const Pagination = ({ totalItems, itemsPerPage, onPageChange, currPage }) => {
  const [currentPage, setCurrentPage] = useState(currPage||1);
  const totalPageCount = Math.ceil(totalItems / itemsPerPage);
  const [isSmallDevice, setIsSmallDevice] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallDevice(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const paginationClass = isSmallDevice ? "pagination-md" : "pagination-lg";
  useEffect(() => {
    onPageChange(currentPage);
  }, [currentPage, onPageChange]);

  const handlePageClick = (page) => {
    if (page >= 1 && page <= totalPageCount) {
      setCurrentPage(page);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPageCount; i++) {
      pageNumbers.push(
        <li
          key={i}
          className={`page-item ${currentPage === i ? "active" : ""}`}
          onClick={() => handlePageClick(i)}
        >
          <span className="page-link">{i}</span>
        </li>
      );
    }
    return pageNumbers;
  };

  return (
    <nav aria-label="..." className="mb-6">
      <ul className={`pagination ${paginationClass} mt-2 mb-5`}>{renderPageNumbers()}</ul>
    </nav>
  );
};

export default Pagination;
