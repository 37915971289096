import React, { useState, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import axios from "axios";
import NativeSelect from "@mui/material/NativeSelect";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import toast from "react-hot-toast";
import API from '../../Visa/API'
const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "30px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "grey",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "grey",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const FileUpload = () => {
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    acceptedFiles,
    fileRejections,
  } = useDropzone({
    accept: "application/pdf",
    validator: (file) => {
      if (!file.name.endsWith(".pdf")) {
        return {
          code: "not-a-pdf",
          message: "Only PDF files are accepted",
        };
      }
      return null;
    },
    onDrop: (acceptedFiles) => {
      setFilesToUpload(acceptedFiles);
    },
  });
  const handleUpload = () => {
    if (filesToUpload.length === 0) {
      toast.error("Please select a file to upload");
    }
    else if (selectedOption == null || "") {
      toast.error("Please select a ticket type");
    } else {
      try {
        console.log(filesToUpload[0]);
        const formData = new FormData();
        formData.append("file", filesToUpload[0]);
        console.log(Object.fromEntries(formData.entries()));
        const response = API.post(
          `/flights/uploadpdf?token=${localStorage.getItem(
            "authToken"
          )}&provider=${selectedOption}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        toast.promise(response, {
          loading: "Uploading...",
          success: "File uploaded successfully",
          error: "Error uploading file",
        });
      } catch (error) {
        console.error(error.response ? error.response.data : error.message);
      }
    }
  };
  const acceptedFileItems = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
      <ul>
        {errors.map((e) => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>
    </li>
  ));
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <div
      className="container"
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        gap: "50px",
        justifyContent: "center",
        height: "60vh",
        marginTop: "10px",
      }}
    >
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop some files here, or click to select files</p>
        <aside>
          <h4>Accepted files</h4>
          <ul>{acceptedFileItems}</ul>
          <h4>Rejected files</h4>
          <ul>{fileRejectionItems}</ul>
        </aside>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          
        }}
      >
        <Button
          variant="contained"
          endIcon={<SendIcon />}
          onClick={handleUpload}
          sx={{ m: 2 }}
        >
          Upload
        </Button>
        <FormControl sx={{ m: 2 }} variant="standard">
          <InputLabel htmlFor="demo-customized-select-native">
            Ticket Type
          </InputLabel>
          <NativeSelect
            id="demo-customized-select-native"
            value={selectedOption}
            onChange={(e) => {
              setSelectedOption(e.target.value);
            }}
          >
            <option aria-label="None" value="" />
            <option value="LCC">LCC</option>
            <option value="SABRE">SABRE</option>
            <option value="TBO">TBO</option>
            <option value="NDC">NDC</option>
          </NativeSelect>
        </FormControl>
      </div>
      <style>
        {`
        @media (max-width: 600px)
          .container {
            height: auto;
            width: 50vw; /* Adjust width to fit smaller screens */
            padding: 20px; /* Add padding for better spacing */
            gap: 20px; /* Reduce gap for compact layout */
          }
          .container > div {
            flex-direction: column; /* Stack elements vertically */
            align-items: stretch; /* Stretch elements to full width */
          }
          .container input, .container button, .container select {
            font-size: 16px; /* Increase font size for better readability */
          }
          /* Adjust the dropzone style for smaller screens */
          .dropzone {
            padding: 20px; /* Increase padding for better touch area */
            font-size: 14px; /* Adjust font size */
          }
          /* Increase the size of buttons and selects for easier interaction */
          button, select {
            min-height: 44px; /* Increase height for better touch area */
            font-size: 16px; /* Increase font size */
          }
        `}
      </style>
    </div>
  );
};
export default FileUpload;
