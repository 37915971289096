import React, { useState, useEffect } from 'react';
import {
  TextField, MenuItem, Button, Grid, Chip, Box, Container, Typography, IconButton
} from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon, SentimentVeryDissatisfied as SadIcon, SentimentVerySatisfied as HappyIcon } from '@mui/icons-material';
import API from "../../Visa/API";
import { useNavigate } from 'react-router-dom';
 
const AdminSettings = () => {
  const [tags, setTags] = useState([]);
  const [newTagName, setNewTagName] = useState('');
  const [newTagColor, setNewTagColor] = useState('');
  const [selectedCompany, setSelectedCompany] = useState('');
  const [activeCompanyNames, setActiveCompanyNames] = useState([]);
  const [inactiveCompanyNames, setInactiveCompanyNames] = useState([]);
  const navigate = useNavigate();
 
  const handleAddTag = async () => {
    if (!newTagName || !newTagColor) {
      alert('Please enter both tag name and color.');
      return;
    }
    if (tags.some(tag => tag.name === newTagName && tag.color === newTagColor)) {
      alert('Duplicate tag name and color combination is not allowed.');
      return;
    }
    try {
      const response = await API.post('/DMC/tags', { name: newTagName, color: newTagColor });
      setTags([...tags, response.data]);
      setNewTagName('');
      setNewTagColor('');
    } catch (error) {
      console.error('Error adding tag:', error);
    }
  };
 
  const handleDeleteTag = async (tagId) => {
    try {
      await API.delete(`/DMC/tags/${tagId}`);
      setTags(tags.filter(tag => tag._id !== tagId));
      alert('Tag deleted successfully');
    } catch (error) {
      console.error('Error deleting tag:', error);
    }
  };
 
  const handleDeactivateDMC = async () => {
    try {
      await API.post('/DMC/deactivate-dmc', { name: selectedCompany }, { withCredentials: true });
      setActiveCompanyNames(activeCompanyNames.filter(name => name !== selectedCompany));
      setInactiveCompanyNames([...inactiveCompanyNames, selectedCompany]);
      setSelectedCompany('');
      alert('DMC deactivated successfully');
    } catch (error) {
      console.error('Error deactivating DMC:', error);
      alert('Error deactivating DMC!');
    }
  };
 
  const handleReactivateDMC = async () => {
    try {
      await API.post('/DMC/reactivate-dmc', { name: selectedCompany }, { withCredentials: true });
      setInactiveCompanyNames(inactiveCompanyNames.filter(name => name !== selectedCompany));
      setActiveCompanyNames([...activeCompanyNames, selectedCompany]);
      setSelectedCompany('');
      alert('DMC reactivated successfully');
    } catch (error) {
      console.error('Error reactivating DMC:', error);
      alert('Error reactivating DMC!');
    }
  };
 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const initialCheck = await API.get('/DMC/staff-role', { withCredentials: true });
        console.log(initialCheck);
        if (initialCheck.data.role !== 'SuperAdmin') {
          alert('You cannot access this page!');
          navigate('/DMC/main');
          return;
        }
 
        const [tagsResponse, dmcResponse] = await Promise.all([
          API.get('/DMC/tags'),
          API.get('/DMC/dmc-by-status', { withCredentials: true })
        ]);
        console.log(dmcResponse);
        setTags(tagsResponse.data);
 
 
        const activeCompanies = dmcResponse.data.DMCs.filter(item => !item.inactive).map(item => item.basicCompanyInfo.data.companyName);
        const inactiveCompanies = dmcResponse.data.DMCs.filter(item => item.inactive).map(item => item.basicCompanyInfo.data.companyName);
 
   
        setActiveCompanyNames(activeCompanies);
        setInactiveCompanyNames(inactiveCompanies);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
 
    fetchData();
  }, []);
 
  return (
    <Container>
      <Typography variant="h5" gutterBottom align="center">
        Admin Settings
      </Typography>
 
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" gutterBottom>
            Add and Delete Tags
          </Typography>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={4}>
              <TextField
                label="Tag Name"
                value={newTagName}
                onChange={(e) => setNewTagName(e.target.value)}
                fullWidth
                autoComplete='off'
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Tag Color"
                value={newTagColor}
                onChange={(e) => setNewTagColor(e.target.value)}
                type="color"
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleAddTag}>
                Add Tag
              </Button>
            </Grid>
          </Grid>
        </Grid>
 
        <Grid item xs={12}>
          <Box display="flex" flexWrap="wrap" gap={1}>
            {tags.map(tag => (
              <Chip
                key={tag._id}
                label={tag.name}
                style={{ backgroundColor: tag.color, color: 'white' }}
                onDelete={() => handleDeleteTag(tag._id)}
                deleteIcon={<DeleteIcon />}
              />
            ))}
          </Box>
        </Grid>
 
        <Grid item xs={12}>
          <Typography variant="subtitle1" gutterBottom>
            Deactivate DMC
          </Typography>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={8}>
              <TextField
                select
                label="Select a company to deactivate"
                value={selectedCompany}
                onChange={(e) => setSelectedCompany(e.target.value)}
                fullWidth
              >
                <MenuItem value="">Select a company</MenuItem>
                {activeCompanyNames.map((company, index) => (
                  <MenuItem key={index} value={company}>
                    {company}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={4}>
              <Button variant="contained" color="secondary" onClick={handleDeactivateDMC} startIcon={<SadIcon />}>
                Deactivate DMC
              </Button>
            </Grid>
          </Grid>
        </Grid>
 
        <Grid item xs={12}>
          <Typography variant="subtitle1" gutterBottom>
            Reactivate DMC
          </Typography>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={8}>
              <TextField
                select
                label="Select a company to reactivate"
                value={selectedCompany}
                onChange={(e) => setSelectedCompany(e.target.value)}
                fullWidth
              >
                <MenuItem value="">Select a company</MenuItem>
                {inactiveCompanyNames.map((company, index) => (
                  <MenuItem key={index} value={company}>
                    {company}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={4}>
              <Button variant="contained" color="primary" onClick={handleReactivateDMC} startIcon={<HappyIcon />}>
                Reactivate DMC
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};
 
export default AdminSettings;
 