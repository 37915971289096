import { Typography, Table, TableHead, TableRow, TableCell, TableBody, IconButton } from "@mui/material";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { useEffect, useState } from "react";
import SearchIcon from '@mui/icons-material/Search';
import API from "../Visa/API";

const SmsTable = () => {
    const [smsData, setSmsData] = useState([]);
    const [datestate, setDateState] = useState([null, null]);

    const handleSearch = async () => {
        const response = await API.post("/internal/searchSMS", {
            fromDate: datestate[0],
            toDate: datestate[1]
        });
        setSmsData(response.data.messages); // Assuming response.data.messages is the array of messages
    };

    return (
        <>
            <div style={{ display: "flex", alignItems: "center", padding: "10px", justifyContent: "space-between" }}>
                <div><Typography>Search for SMS's by selecting the date range</Typography></div>
                <div>
                    <DateRangePicker onChange={setDateState} value={datestate} />
                    <IconButton onClick={async () => await handleSearch()} style={{ marginLeft: "10px" }}>
                        <SearchIcon />
                    </IconButton>
                </div>
            </div>
            {
                smsData && smsData.length > 0 ? (
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Message ID</TableCell>
                                <TableCell>Mobile Number</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Message</TableCell>
                                <TableCell>Date/Time</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {smsData.map((sms, index) => (
                                <TableRow key={index}>
                                    <TableCell>{sms.messageId}</TableCell>
                                    <TableCell>{sms.mobileNumber}</TableCell>
                                    <TableCell>{sms.status}</TableCell>
                                    <TableCell>{sms.message}</TableCell>
                                    <TableCell>{sms.dateTime}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                ) : (
                    <div>Please search for SMS</div>
                )
            }
        </>
    );
};

export default SmsTable;
