import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import API from "../Visa/API";
import { TextField, Button, Container, Typography } from "@mui/material";
import CustomerNavbar from "./CustomerNavbar";
import toast from "react-hot-toast";

export default function Customer() {
  const { id } = useParams();
  const [customers, setCustomers] = useState([]);
  const [linkExpired, setLinkExpired] = useState(false);
  const [showExitDateFor, setShowExitDateFor] = useState(null);

  const getCustomers = async () => {
    try {
      const response = await API.get(`/uaevisa/getCustomersbyURL/${id}`);
      console.log(response.status);
      setCustomers(response.data);
    } catch (error) {
      if (error.response && error.response.status === 400) {
        alert("Link has expired. Kindly contact the admin.");
        setLinkExpired(true);
      } else {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await getCustomers();
    };
    fetchData();
  }, [id]);

  const handleSubmit = async (customerId, entryDate, exitDate) => {
    if (!entryDate) {
      alert("Please enter Entry Date");
      return;
    }

    if (new Date(exitDate) < new Date(entryDate)) {
      alert("Exit Date cannot be earlier than Entry Date");
      return;
    }

    try {
      const response = await API.post(`/uaevisa/updateCustomerDates`, {
        entryDate,
        exitDate,
        customerId,
        url: id,
      });
      console.log(response.data);
      if (response.status === 200) {
        toast.success("Dates updated successfully");
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message);
    }
  };

  return (
    <>
      {!linkExpired ? (
        <>
          {customers.map((customer, index) => (
            <div key={customer._id}>
              <CustomerNavbar customerName={customer.name} />
              <Container className="container">
                <Typography gutterBottom style={{ fontSize: "13px" }}>
                  {"Dear " + customer.name}
                </Typography>
                <Typography style={{ fontSize: "13px" }} gutterBottom>
                  Kindly Enter your Entry Date and Exit Date
                </Typography>
                {showExitDateFor === customer._id ? (
                  <TextField
                    className="input"
                    label="Exit Date"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={customer.exitDate}
                    onChange={(e) =>
                      setCustomers((prevCustomers) => {
                        const newCustomers = [...prevCustomers];
                        newCustomers[index].exitDate = e.target.value;
                        return newCustomers;
                      })
                    }
                    style={{ marginTop: "20px", marginBottom: "20px" }}
                  />
                ) : (
                  <TextField
                    className="input"
                    label="Entry Date"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={customer.entryDate}
                    onChange={(e) =>
                      setCustomers((prevCustomers) => {
                        const newCustomers = [...prevCustomers];
                        newCustomers[index].entryDate = e.target.value;
                        return newCustomers;
                      })
                    }
                    style={{ marginTop: "20px", marginBottom: "20px" }}
                  />
                )}
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    className="button"
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      handleSubmit(customer._id, customer.entryDate, customer.exitDate)
                    }
                    style={{ marginTop: "20px", marginBottom: "20px" }}
                  >
                    Submit
                  </Button>
                </div>
                <div className="arrow-parent">
                  <div className="arrow-buttons">
                    <button
                      className="arrow-button up"
                      onClick={() => setShowExitDateFor(null)}
                      disabled={showExitDateFor !== customer._id}
                    >
                      &#9650;
                    </button>
                    <button
                      className="arrow-button down"
                      onClick={() => setShowExitDateFor(customer._id)}
                      disabled={showExitDateFor === customer._id}
                    >
                      &#9660;
                    </button>
                  </div>
                </div>
              </Container>
            </div>
          ))}
          <style>
            {`
              .container {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 20px;
                width: 100%;
                justify-content: center;
                position: relative;
                top: -65px;
              }

              .input {
                margin-bottom: 15px;
                width: 100%;
              }

              .button {
                width: 100%;
              }

              @media (max-width: 600px) {
                .container {
                  padding: 10px;
                }

                .input {
                  width: 90%;
                }

                .button {
                  width: 90%;
                }
              }

              .arrow-parent {
                position: fixed;
                bottom: 20px;
                right: 20px;
              }

              .arrow-buttons {
                display: flex;
                background-color: #004D61;
                border-radius: 20px;
                overflow: hidden;
                align-items: center;
                justify-content: center;
              }

              .arrow-button {
                background-color: #004D61;
                color: white;
                border: none;
                outline: none;
                padding: 10px 10px;
                cursor: pointer;
                font-size: 18px;
                display: flex;
                align-items: center;
                justify-content: center;
              }

              .arrow-button:hover {
                background-color: #00343F;
              }

              .arrow-button.up {
                border-top-left-radius: 20px;
                border-bottom-left-radius: 20px;
              }

              .arrow-button.down {
                border-top-right-radius: 20px;
                border-bottom-right-radius: 20px;
              }

              .arrow-button:focus {
                outline: none;
                box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.2);
              }
            `}
          </style>
        </>
      ) : (
        <Container className="container">
          <Typography
            style={{
              fontSize: "25px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Link has expired. Kindly contact the admin.
          </Typography>
        </Container>
      )}
    </>
  );
}
