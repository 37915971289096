import React from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
const MyNavbar = () => {
  return (
    <Navbar bg="dark" expand="lg" variant="dark">
      <Container>
        <Navbar.Brand href="/">
          <img
            src="	https://marcopolo-holidays.com/wp-content/uploads/2021/08/Marcopolo-Grey-transparent-logo-200-px.png"
            width="120px"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auhref">
            <a href="/flights/charts" style={{ margin: "10px" }}>
              Dashboard
            </a>
            <a href="/flights/tickets" style={{ margin: "10px" }}>
              Tickets
            </a>
            <a href="/flights/users/deleted" style={{ margin: "10px" }}>
              Deleted
            </a>
            <a href="/flights/uploadpdf" style={{ margin: "10px" }}>
              Upload PDF
            </a>
            <a href="/flights/latest" style={{ margin: "10px" }}>
              Latest Entries
            </a>
            <a href="/flights/contact-directory" style={{ margin: "10px" }}>
              Contact Directory
            </a>            
            <a href="/staff" style={{ margin: "10px" }}>
              Staff
            </a>
            <a href="/logout" style={{margin:"10px"}}>Logout</a>
            {/* ... other as */}
          </Nav>
        </Navbar.Collapse>
      </Container>
      <style>
        {
          `
          .me-auhref a {
            color: white;
            text-decoration: none;
          }
          .me-auhref a:hover {
            color: grey;
          }`
        }
      </style>
    </Navbar>
  );
};

export default MyNavbar;
