import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function ApplicantRelationSelect({ applicantRelationVal, setApplicantRelationVal }) {

  const handleApplicantRelationChange = (event, value) => {
    setApplicantRelationVal(value);
  }

  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={applicantRelationOptions}
      sx={{ width: 150}}
      renderInput={(params) => <TextField {...params} label="Applicant Relation *" />}
      value={applicantRelationVal}
      onChange={handleApplicantRelationChange} 
      getOptionLabel={(applicantRelationOptions) => applicantRelationOptions}
    />
  );
}

const applicantRelationOptions = ["husband", "wife", "father", "mother", "sister", "brother", "son", "daughter", "XXXXXX"];