import { useState, useEffect } from "react";
import axios from "axios";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import { Link, Tab } from "@mui/material";
import Modal from "@mui/material/Modal";
import Box from '@mui/material/Box';
import TextField from "@mui/material/TextField";
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import API from "../API";
import { useNavigate } from "react-router-dom";
import toast from 'react-hot-toast';
export default function Requirements() {
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState("");
    const [requirements, setRequirements] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [openModal, setOpenModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [countryInput, setCountryInput] = useState("");
    const [emirateInput, setEmirateInput] = useState("");
    const [emailInput, setEmailInput] = useState("");
    const [editEmirate, setEditEmirate] = useState("");
    const [editCountry, setEditCountry] = useState("");
    const [contactphone1, setContactphone1] = useState("");
    const [contactphone2, setContactphone2] = useState("");
    const [deleteRequirement, setDeleteRequirement] = useState("");
    const [applicationCenter, setApplicationCenter] = useState("");
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        display: "flex",
        flexDirection: "column",
    };
    useEffect(() => {
        fetchRequirements();
    }, []);
    const [editId, setEditId] = useState("");
    const fetchRequirements = async () => {
        try {
            const response = await API.get("/ivisa/contact");
            setRequirements(response.data);
        } catch (error) {

            if (error.response && error.response.status === 401) {
            // If a 401 response was returned, navigate to the login page
            navigate('/login');
        } else {
            // Handle other types of errors here (optional)
            console.error('An error occurred:', error);
        }
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleSearchInputChange = (event) => {
        setSearchTerm(event.target.value);
    };
    const filteredRequirements = requirements.filter((requirement) =>
        requirement.country.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setCountryInput("");
        setEmailInput("");
    };

    const handleCountryInputChange = (event) => {
        setCountryInput(event.target.value);
    };
    const handleEditEmirateInputChange = (event) => {
        setEditEmirate(event.target.value);
    };
    const handleEmirateInputChange = (event) => {
        setEmirateInput(event.target.value);
    };
    const handleEmailInputChange = (event) => {
        setEmailInput(event.target.value);
    };
    const handleContactPhone1InputChange = (event) => {
        setContactphone1(event.target.value);
    };
    const handleContactPhone2InputChange = (event) => {
        setContactphone2(event.target.value);
    };
    const handleApplicationCenterInputChange = (event) => {
        setApplicationCenter(event.target.value);
    };
    const handleAddNewCountry = () => {
        // Add your logic to handle adding a new country here
        const response = API.post("/ivisa/contact/new", {
            country: countryInput,
            email: emailInput,
            emirate: emirateInput,
            application_center: applicationCenter,
            contact_phone1: contactphone1,
            contact_phone2: contactphone2,

        });
        window.location.reload();
    };

    const handleOpenEditModal = (country, email,_id,emirate,application_center,contact_phone1,contact_phone2) => {
        setOpenEditModal(true);
        setEditCountry(country);
        setEmailInput(email);
        setEditId(_id);
        setEditEmirate(emirate);
        setApplicationCenter(application_center);
        setContactphone1(contact_phone1);
        setContactphone2(contact_phone2);

    };

    const handleCloseEditModal = () => {
        setOpenEditModal(false);
        setEditCountry("");
        setEmailInput("");
        setEditEmirate("");
        setApplicationCenter("");
        setContactphone1("");
        setContactphone2("");
        setEditId("");
    };

    const handleOpenDeleteModal = ( _id ) => {
        setOpenDeleteModal(true);
        setDeleteRequirement( _id );
    };

    const handleCloseDeleteModal = ( _id ) => {
        setOpenDeleteModal(false);
        setDeleteRequirement("");        
    };

    const handleEditCountryInputChange = (event) => {
        setEditCountry(event.target.value);
    };
    const handleEditApplicationCenterInputChange = (event) => {
        setApplicationCenter(event.target.value);
    };
    const handleEditemailInputChange = (event) => {
        setEmailInput(event.target.value);
    };

    const handleEditCountry = (_id) => {
        // Add your logic to handle editing a country here
        const response = API.patch(`/ivisa/contact/edit/${_id}`, {
            country: editCountry,
            email: emailInput,
            emirate: editEmirate,
            application_center: applicationCenter,

        });
        toast.success("Edited Successfully");
        window.location.reload();
    };

    const handleDeleteCountry = ( _id ) => {
        // Add your logic to handle editing a country here
        const response = API.patch(`/ivisa/contact/delete/${_id}`);
        toast.success("Deleted Successfully");
        window.location.reload();        
    }

    return (
        <Paper>
            <Box sx={{ padding: 2,display:"flex",alignItems:"center",justifyContent:"center",width:"300px" }}>
                <TextField
                    fullWidth
                    label="Search by Country"
                    variant="outlined"
                    value={searchTerm}
                    onChange={handleSearchInputChange}
                />
            </Box>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Country</TableCell>
                            <TableCell>Application Center</TableCell>
                            <TableCell>Emirate</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Contact Phone 1</TableCell>
                            <TableCell>Contact Phone 2</TableCell>
                            <TableCell>Notes</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredRequirements
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((requirement) => (
                                <TableRow key={requirement._id}>
                                    <TableCell>{requirement.country}</TableCell>
                                    <TableCell>{requirement.application_center}</TableCell>
                                    <TableCell>{requirement.emirate!= "NaN" ? requirement.emirate : ""}</TableCell>
                                    <TableCell>
                                        <Link target="_blank" href={`mailto:${requirement.email}`}>
                                            {requirement.email}
                                        </Link>
                                    </TableCell>
                                    <TableCell>{requirement.contact_phone1}</TableCell>
                                    <TableCell>{requirement.contact_phone2}</TableCell>
                                    <TableCell>
                                        <Link target="_blank" href={`/visa/notes/${requirement._id}`}><TextSnippetIcon/></Link>
                                    </TableCell>
                                    <TableCell>
                                        <Button className="ms-2" variant="contained" color="secondary" onClick={() => handleOpenEditModal(requirement.country, requirement.email,requirement._id,requirement.emirate,requirement.application_center,requirement.contact_phone1,requirement.contact_phone2)}>
                                            Edit
                                        </Button>
                                        <Button className="ms-2" variant="contained" color="primary" onClick={() => handleOpenDeleteModal(requirement._id)}>
                                            Delete
                                        </Button>                                        
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10]}
                component="div"
                count={requirements.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <div style={{ position: "fixed", bottom: "10px", left: "10px" }}>
                <Button variant="contained" color="primary" onClick={handleOpenModal}>
                    Add New Country
                </Button>
            </div>
            <Modal open={openModal} onClose={handleCloseModal}>
                <Box sx={style}>
                    <TextField
                        label="Country"
                        value={countryInput}
                        onChange={handleCountryInputChange}
                        style={{ marginBottom: "10px" }}
                    />
                    <TextField
                        label="Email"
                        value={emailInput}
                        onChange={handleEmailInputChange}
                        style={{ marginBottom: "10px" }}
                    />
                    <TextField  
                        label="Emirate"
                        value={emirateInput}
                        onChange={handleEmirateInputChange}
                        style={{ marginBottom: "10px" }}
                    />
                    <TextField
                        label="Application Center"
                        value={applicationCenter}
                        onChange={handleApplicationCenterInputChange}
                        style={{ marginBottom: "10px" }}
                    />
                    <TextField
                        label="Contact Phone 1"
                        value={contactphone1}
                        onChange={handleContactPhone1InputChange}
                        style={{ marginBottom: "10px" }}
                    />
                    <TextField
                        label="Contact Phone 2"
                        value={contactphone2}
                        onChange={handleContactPhone2InputChange}
                        style={{ marginBottom: "10px" }}
                    />
                    <br />
                    <Button variant="contained" color="primary" onClick={handleAddNewCountry}>
                        Add
                    </Button>
                </Box>
            </Modal>
            <Modal open={openEditModal} onClose={handleCloseEditModal}>
                <Box sx={style}>
                    <TextField
                        label="Country"
                        value={editCountry}
                        onChange={handleEditCountryInputChange}
                        style={{ marginBottom: "10px" }}
                    />
                    <TextField
                        label="Email"
                        value={emailInput}
                        onChange={handleEditemailInputChange}
                        style={{ marginBottom: "10px" }}
                    />
                    <TextField
                        label="Emirate"
                        value={editEmirate}
                        onChange={handleEditEmirateInputChange}
                        style={{ marginBottom: "10px" }}
                    />
                    <TextField
                        label="Application Center"
                        value={applicationCenter}
                        onChange={handleEditApplicationCenterInputChange}
                        style={{ marginBottom: "10px" }}
                    />
                    <TextField
                        label="Contact Phone 1"
                        value={contactphone1}
                        onChange={handleContactPhone1InputChange}
                        style={{ marginBottom: "10px" }}
                    />
                    <TextField
                        label="Contact Phone 2"
                        value={contactphone2}
                        onChange={handleContactPhone2InputChange}
                        style={{ marginBottom: "10px" }}
                    />
                    <Button variant="contained" color="primary" onClick={() => handleEditCountry(editId)}>
                        Save
                    </Button>
                </Box>
            </Modal>
            <Modal open={openDeleteModal} onClose={handleCloseDeleteModal}>
                <Box sx={style}>
                    <h3>Are you sure you want to delete</h3>
                    
                    <Button variant="contained" color="primary" onClick={() => handleDeleteCountry(deleteRequirement)}>
                        Delete
                    </Button>
                </Box>
            </Modal>            
        </Paper>
    );
}
