import React, { useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import NotificationsIcon from '@mui/icons-material/Notifications';
import axios from "axios";
import API from "../API";
const MyNavbar1 = () => {
  const [notificationCount, setNotificationCount] = React.useState(0);
  const MyNotificationIcon = ({ notificationsCount }) => {
    return (
      <div style={{ position: 'relative', display: 'inline-block' }}>
        <a href="/visa/notifications" style={{ color: 'white' }}>
          <NotificationsIcon />
        </a>
        {notificationCount > 0 && (
          <span style={{
            position: 'absolute',
            top: 0,
            right: 0,
            backgroundColor: 'red',
            borderRadius: '50%',
            color: 'white',
            padding: '2px 6px',
            fontSize: '10px',
            lineHeight: '10px',
            minWidth: '20px',
            textAlign: 'center',
            height: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            transform: 'translate(50%, -50%)',
            boxShadow: '0 0 2px rgba(0, 0, 0, 0.5)'
          }}>
            {notificationCount}
         
  </span>
        )}
      </div>
    );
  };
  const fetchNotificationCount = async () => {
    const response = await API.get(`/ivisa/notifications/count`);
    setNotificationCount(response.data);
  }
  useEffect(()=> {
    fetchNotificationCount();
  })
  return (
    <Navbar bg="dark" expand="lg" variant="dark">
      <Container fluid style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center',justifyContent:"center" }}>
        <Navbar.Brand href="/">
          <img
            src="	https://marcopolo-holidays.com/wp-content/uploads/2021/08/Marcopolo-Grey-transparent-logo-200-px.png"
            width="120px"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auhref">
            <a href="/visa/charts" style={{ margin: "10px" }}>
              Dashboard
            </a>
            <a href="/visa/applications" style={{ margin: "10px" }}>
              Applications
            </a>
            {/* <a href="/visa/deleted" style={{ margin: "10px" }}>
              Deleted Appointments
            </a> */}
            <a href="/visa/uploadpdf" style={{ margin: "10px" }}>
              Upload PDF
            </a>
            <a href="/visa/evisaupload" style={{margin:"10px"}}>E-Visa Upload</a>
            <a href="/visa/latest" style={{ margin: "10px" }}>
              Latest Appointments
            </a>
            <a href="/staff" style={{ margin: "10px" }}>
              Staff
            </a>
            <a href="/visa/requirements" style={{margin:"10px"}}>Visa Requirements</a>
            <a href="/visa/e-visa" style={{margin:"10px"}}>E-Visa</a>
            <a href="/visa/contact" style={{ margin: "10px" }}>Contact Directory </a>
            <a href="/visa/nocgenerator" style={{ margin: "10px" }}>NOC Generator</a>
            <a href="/visa/emailstemplates" style={{ margin: "10px" }}>Emails Templates</a>
            <a href="/visa/visaprocessing" style={{ margin: "10px" }}>Visa Processing Time</a>
            <a href="/logout" style={{ margin: "10px" }}>Logout</a>
          </Nav>
          <div>
            <a href="/visa/notifications" style={{ color: "white" }}>
              <MyNotificationIcon notificationsCount={notificationCount} />
            </a>
            </div>
        </Navbar.Collapse>
      </Container>
      <style>
        {
          `
          .me-auhref a {
            color: white;
            text-decoration: none;
          }
          .me-auhref a:hover {
            color: grey;
          }`
        }
      </style>
    </Navbar>
  );
};

export default MyNavbar1;
