import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    data: [],
    loading: false,
    error: null,
}

export const dataSlice = createSlice({
    name: 'data',
    initialState,
    reducers: {
        getDataStart: (state) => {
            state.loading = true;
            state.error = null;
        },
        getDataSuccess: (state, action) => {
            state.data = action.payload;
            state.loading = false;
            state.error = null;
        },
        getDataFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        getDataAscending: (state) => {
            try {
                state.data.sort((a, b) => {
                    if (!a || !b || !a.name || !b.name) {
                        return 0;
                    }
                    return a.name.localeCompare(b.name);
                });
            } catch (error) {
                console.error('Error in getDataAscending:', error);
            }
        },
        getDataDescending: (state) => {
            try {
                state.data.sort((a, b) => {
                    if (!a || !b || !a.name || !b.name) {
                        return 0;
                    }
                    return b.name.localeCompare(a.name);
                });
            } catch (error) {
                console.error('Error in getDataDescending:', error);
            }
        },
        
        
        
        
        // New action to sort data by departure_date in ascending order
        sortByDepartureDateAscending: (state) => {
            console.log(state?.data)
            state.data.sort((a, b) => {
                return new Date(a.visa_appointment_date) - new Date(b.visa_appointment_date);
            });
        },

        // New action to sort data by departure_date in descending order
        sortByDepartureDateDescending: (state) => {
            console.log(state?.data)
            state.data.sort((a, b) => {
                return new Date(b.visa_appointment_date) - new Date(a.visa_appointment_date);
            });
        }
        
    },
});

export const {
  getDataStart,
  getDataSuccess,
  getDataFailure,
  getDataAscending,
  getDataDescending,
  sortByDepartureDateAscending,
  sortByDepartureDateDescending,
} = dataSlice.actions;

export default dataSlice.reducer;
