import React, { useEffect } from "react";
import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";

const Charts = () => {
  useEffect(() => {
    const sdk = new ChartsEmbedSDK({
      baseUrl: "https://charts.mongodb.com/charts-project-0-cboxu",
    });
    const dashboard = sdk.createDashboard({
      dashboardId: "d30aadd3-1ba2-419f-a31f-21827d6c2c62",
      height: "900px",
      width: "100%",
      theme: "dark",
    });
    dashboard.render(document.getElementById("dashboard"));
  }, []);

  return <div id="dashboard"></div>;
};

export default Charts;
