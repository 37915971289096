import React, { useEffect, useState } from "react";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import { Modal, Box, Typography } from "@mui/material";
import API from "../../Visa/API";
import toast from "react-hot-toast";
const columns = [
  {
    field: "staff_email",
    headerName: "Email",
    width: 200,
  },
  {
    field: "staff_firstname",
    headerName: "Full name",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
  },
  {
    field: "staff_role",
    headerName: "Role",
    width: 150,
  },
  {
    field: "actions",
    headerName: "Actions",
    width: 150,
  }
];

export default function FlightStaff() {
  const [data, setData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await API.get("/flights/staff");
        setData(response.data.staff);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [staffFirstName, setStaffFirstName] = useState("");
  const [staffLastName, setStaffLastName] = useState("");
  const [staffPassword, setStaffPassword] = useState("");
  // Add these to your existing state variables at the top of your component
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [selectedStaffIdForPasswordChange, setSelectedStaffIdForPasswordChange] = useState("");
  const [openChangePassword, setOpenChangePassword] = useState(false); // To control the modal

  const [staff_email, setStaffEmail] = useState("");
  const [staff_role, setStaffRole] = useState("");
  const [openDelete, setOpenDelete] = React.useState(false);
  const handleDeleteOpen = () => setOpenDelete(true);
  const handleDeleteClose = () => setOpenDelete(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const staff_detail = {
      staff_firstname: staffFirstName,
      staff_lastname: staffLastName,
      staff_password: staffPassword,
      staff_email: staff_email,
      staff_role: staff_role,
    };

    try {
      const response = await API.post(
        "/flights/staff/add",
        staff_detail
      );
      toast.promise(
        Promise.resolve(response), // wrap the response in a resolved promise
        {
          loading: "Adding Staff...",
          success: (data) => {
            return "Staff added successfully!";
          },
          error: (err) => {
            return `Error adding staff! ${err.message}`;
          },
        }
      );
      window.location.reload();
    } catch (error) {
      console.error(error);
      if(error.response.status === 403){
        toast.error(`Forbidden: You are not allowed to perform this action`);
      }
      handleClose();
    }
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    const id = selectedStaffId;
    try {
      const response =  API.delete(
        `/flights/staff/delete/${id}`
      );
      toast.promise(
        Promise.resolve(response), // wrap the response in a resolved promise
        {
          loading: "Deleting Staff...",
          success: (data) => {
            return "Staff deleted successfully!";
          },
          error: (err) => {
            return `Error deleting staff! ${err.message}`;
          },
        }
      );
    } catch (error) {
      console.error(error);
      handleDeleteClose();
    }
  };
  const handleChangePassword = async (e) => {
    e.preventDefault();
    const staff_detail = {
      staffId: selectedStaffIdForPasswordChange, // make sure this is set correctly
      oldPassword: oldPassword,
      newPassword: newPassword
    };
  
    try {
      const response = await API.post("/flights/staff/change-password", staff_detail);
  
      // Check response status and show corresponding toast message
      if (response.status === 200) {
        toast.success("Password changed successfully!");
      } 
      else {
        // Handle any other statuses
        toast.error(`Error: ${response.data.message}`);
      }
    } catch (error) {
      // Handle network errors or errors with the request itself
      console.error(error);
      if (error.response.status === 400) {
        toast.error(`Bad Request: Enter Your Old password`);
      } else if (error.response.status === 401) {
        toast.error(`Unauthorized: Old Password is required`);
      } else if (error.response.status === 403) {
        toast.error(`Forbidden: You can only change your own password`);
      } else if (error.response.status === 404) {
        toast.error(`Staff Member Not Found`);
      }
    }
  };
  
  
  const [selectedStaffId, setSelectedStaffId] = useState(null);
  return (
    <div className="container mt-4">
      <div className="row justify-content-center">
        <div className="col-auto">
          {data.length > 0 ? (
            <DataGrid
              rows={data}  
              getRowId={(row) => row?._id}
              columns={columns}
            />
          ) : (
            <h1>No data to display</h1>
          )}
        </div>
      </div>
      <div className="fixed-bottom">
        <button
          className="btn btn-dark col-2 col-md-3 col-sm-4 button mr-2"
          onClick={handleOpen}
        >
          Add Staff
        </button>
        <button
          className="btn btn-danger col-2 col-md-3 col-sm-4 button mr-2"
          onClick={handleDeleteOpen}
        >
          Delete Staff
        </button>
        <button className="btn btn-secondary col-2 col-md-3 com-sm-4 button" onClick={(e)=> setOpenChangePassword(true)}>
          Change Password
        </button>
      </div>
      <style>
        {`.button {
      font-size: 14px; /* Reduce the font size */
      padding: .375rem .75rem; /* Adjust padding if needed */
    }`}
      </style>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
    <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
            New Staff Member
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Enter Staff Details
        </Typography>
        <form method="POST" onSubmit={handleSubmit}>
            <input
                type="text"
                placeholder="Enter First Name"
                style={{ margin: "5px", padding: "5px" }}
                value={staffFirstName}
                onChange={(e) => setStaffFirstName(e.target.value)}
            />
            <input
                type="text"
                placeholder="Enter Last Name"
                style={{ margin: "5px", padding: "5px" }}
                value={staffLastName}
                onChange={(e) => setStaffLastName(e.target.value)}
            />
            <input
                type="email"
                placeholder="Enter Email"
                style={{ margin: "5px", padding: "5px" }}
                value={staff_email}
                onChange={(e) => setStaffEmail(e.target.value)}
            />
            <input
                type="password"
                placeholder="Enter Password"
                style={{ margin: "5px", padding: "5px" }}
                value={staffPassword}
                onChange={(e) => setStaffPassword(e.target.value)}
            />
            <select
                style={{ margin: "5px", padding: "5px" }}
                value={staff_role}
                onChange={(e) => setStaffRole(e.target.value)}
                
            >
                <option value="None">Select Role</option>
                <option value="SuperAdmin">SuperAdmin</option>
                <option value="Admin">Admin</option>
                <option value="User">User</option>
            </select>
            <br />
            <button type="submit" style={{ margin: "5px", padding: "5px" }}>
                Submit
            </button>
        </form>
    </Box>
</Modal>

      <Modal
        open={openDelete}
        onClose={handleDeleteClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Delete Staff Member
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ marginTop: 2, marginBottom: 2 }}
          >
            Select Staff Member to Delete
          </Typography>
          <select
            onChange={(e) => setSelectedStaffId(e.target.value)}
            class="form-select"
          >
            <option selected value="None">
              Select Passenger to Delete
            </option>
            {data.map((staff) => (
              <option value={staff._id}>{staff.staff_firstname + " " + staff.staff_lastname}</option>
            ))}
          </select>
          <br />
          <button
            type="submit"
            style={{ margin: "5px", padding: "5px" }}
            onClick={handleDelete}
          >
            Submit
          </button>
        </Box>
      </Modal>
      <Modal
  open={openChangePassword}
  onClose={() => setOpenChangePassword(false)}
  aria-labelledby="change-password-modal-title"
  aria-describedby="change-password-modal-description"
>
  <Box sx={style}>
    <Typography id="change-password-modal-title" variant="h6" component="h2">
      Change Password
    </Typography>
    <Typography id="change-password-modal-description" sx={{ mt: 2 }}>
      Update Staff Password
    </Typography>
    <form>
      <select
        style={{ margin: "5px", padding: "5px",border:"1px solid black" }}
        value={selectedStaffIdForPasswordChange}
        onChange={(e) => setSelectedStaffIdForPasswordChange(e.target.value)}
      >
        <option value="">Select Staff Member</option>
        {data.map((staff) => (
          <option value={staff._id}>{staff.staff_firstname + " " + staff.staff_lastname}</option>
        ))}
      </select>
      <input
        type="password"
        placeholder="Enter Old Password"
        style={{ margin: "5px", padding: "5px",border:"1px solid black" }}
        value={oldPassword}
        onChange={(e) => setOldPassword(e.target.value)}
      />
      <input
        type="password"
        placeholder="Enter New Password"
        style={{ margin: "5px", padding: "5px",border:"1px solid black" }}
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
      />
      <br />
      <button
        type="submit"
        style={{ margin: "5px", padding: "5px",border:"1px solid black" }}
        onClick={handleChangePassword} // Define this function to handle the password change
      >
        Change Password
      </button>
    </form>
  </Box>
</Modal>

    </div>
  );
}
