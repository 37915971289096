import { useState, useEffect, useMemo } from "react";
import axios from "axios";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import { Link, Tooltip } from "@mui/material";
import Modal from "@mui/material/Modal";
import Box from '@mui/material/Box';
import TextField from "@mui/material/TextField";
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import RefreshIcon from "@mui/icons-material/Refresh";
import API from "../API";
import { useNavigate } from "react-router-dom";
import toast from 'react-hot-toast';
import countryList from 'react-select-country-list'
import ReactSelect from 'react-select';
import DOMPurify from 'dompurify';
import RichTextEditor from "../../UtilComponents/RichTextEditor";
export default function Requirements() {
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState("");
    const [requirements, setRequirements] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [openModal, setOpenModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [countryInput, setCountryInput] = useState("");
    const [emirateInput, setEmirateInput] = useState("");
    const [linkInput, setLinkInput] = useState("");
    const [noteInput, setNoteInput] = useState("");
    const [editEmirate, setEditEmirate] = useState("");
    const [editCountry, setEditCountry] = useState("");
    const [editLink, setEditLink] = useState("");
    const [editNote, setEditNote] = useState("");
    const [deleteRequirement, setDeleteRequirement] = useState("");
    const [formCountry, setFormCountry] = useState(null);
    const [formSearchCountry, setFormSearchCountry] = useState(null);
    const [formEditCountry, setFormEditCountry] = useState(null);
    const countryOptions = useMemo(() => countryList().getData(), []);
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        display: "flex",
        flexDirection: "column",
    };
    useEffect(() => {
        fetchRequirements();
    }, []);
    const [editId, setEditId] = useState("");
    const fetchRequirements = async () => {
        try {
            const response = await API.get("/ivisa/requirements");
            setRequirements(response.data);
        } catch (error) {

            if (error.response && error.response.status === 401) {
            // If a 401 response was returned, navigate to the login page
            navigate('/login');
        } else {
            // Handle other types of errors here (optional)
            console.error('An error occurred:', error);
        }
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleSearchInputChange = (e) => {
          if (e?.label){
              if (e.label == 'Turkey'){
                setSearchTerm("Türkiye");
              } else {
                setSearchTerm(e.label);
              }     
      }
      setFormSearchCountry(e)
    };
    const filteredRequirements = requirements.filter((requirement) =>
        requirement.country.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setCountryInput("");
        setLinkInput("");
        setEmirateInput("");
        setNoteInput("");
    };

    const handleCountryInputChange = (e) => {
      if (e?.label){
          if (e.label == 'Turkey'){
            setCountryInput("Türkiye");
          } else {
            setCountryInput(e.label);
          }     
      }
      setFormCountry(e)

    };
    const handleEditEmirateInputChange = (event) => {
        setEditEmirate(event.target.value);
    };
    const handleEmirateInputChange = (event) => {
        setEmirateInput(event.target.value);
    };
    const handleLinkInputChange = (event) => {
        setLinkInput(event.target.value);
    };

    const handleAddNewCountry = () => {
        // Add your logic to handle adding a new country here
        const response = API.post("/ivisa/country/new", {
            country: countryInput,
            link: linkInput,
            emirate: emirateInput,
            notes: noteInput,
        });
        window.location.reload();
    };

    const handleOpenEditModal = (country, link,_id,emirate, note) => {
        setOpenEditModal(true);
        setEditCountry(country);
        setFormEditCountry(countryOptions.find(country_obj => country_obj.label === country.toString()));
        setEditLink(link);
        setEditId(_id);
        setEditEmirate(emirate);
        setEditNote(note);
    };

    const handleCloseEditModal = () => {
        setOpenEditModal(false);
        setEditCountry("");
        setEditLink("");
        setEditEmirate("");
    };

    const handleOpenDeleteModal = ( _id ) => {
        setOpenDeleteModal(true);
        setDeleteRequirement( _id );
    };

    const handleCloseDeleteModal = ( _id ) => {
        setOpenDeleteModal(false);
        setDeleteRequirement("");        
    };

    const handleEditCountryInputChange = (e) => {
      if (e?.label){
          if (e.label == 'Turkey'){
            setEditCountry("Türkiye");
          } else {
            setEditCountry(e.label);
          }     
      }
      setFormEditCountry(e)        
    };

    const handleEditLinkInputChange = (event) => {
        setEditLink(event.target.value);
    };

    const handleEditCountry = (_id) => {
        // Add your logic to handle editing a country here
        const response = API.patch(`/ivisa/country/edit/${_id}`, {
            country: editCountry,
            link: editLink,
            emirate: editEmirate,
            notes: editNote,
        });
        toast.success("Edited Successfully");
        window.location.reload();
    };

    const handleDeleteCountry = ( _id ) => {
        // Add your logic to handle editing a country here
        const response = API.patch(`/ivisa/country/delete/${_id}`);
        toast.success("Deleted Successfully");
        window.location.reload();        
    }

    const truncatedValue = (value, totalLength) => {
        if (value && value.length > totalLength) {
            return value.substring(0, totalLength) + '...';
        }
        return value;
    }

    const SafeHtml = ({ htmlContent }) => {
        // Sanitize the HTML content
        const sanitizedHtml = DOMPurify.sanitize(htmlContent);
      
        return (
          <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
        );
      };    


    const dropdownStyle = {
      control: (provided) => ({
        ...provided,
        width: '332px'
      }),    
      menu: (provided) => ({
        ...provided,
        zIndex: 9999,
        width: '332px' // You can adjust this value to suit your needs
      })
    };   
    const clearFilter = () => {
      setFormSearchCountry(null);
      setSearchTerm('');
    };     

    return (
        <Paper>
            <Box sx={{ padding: 2,display:"flex",alignItems:"center",justifyContent:"center",width:"500px" }}>
                <ReactSelect  
                    autoFocus 
                    styles={dropdownStyle} 
                    className="ms-5"             
                    id="country" 
                    name="country" 
                    placeholder="Search by Country"
                    label="Search by Country"  
                    value={formSearchCountry}
                    options={countryOptions}
                    onChange={handleSearchInputChange}
                />    
                <Button
                  variant="contained"
                  className="btn btn-primary mr-1"
                  className="ms-3" 
                  onClick={() => {
                    clearFilter();
                  }}
                >
                  <RefreshIcon />
                </Button>                               
            </Box>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Country</TableCell>
                            <TableCell>Emirate</TableCell>
                            <TableCell>URL</TableCell>
                            <TableCell>Notes</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredRequirements
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((requirement) => (
                                <TableRow key={requirement._id}>
                                    <TableCell>{requirement.country}</TableCell>
                                    <TableCell>{requirement.emirate!= "NaN" ? requirement.emirate : ""}</TableCell>
                                    <TableCell>
                                        <Tooltip title={requirement.link} placement="top-start">
                                            <Link target="_blank" href={requirement.link}>
                                                {truncatedValue(requirement.link, 50)}
                                            </Link>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell>
                                        <Tooltip title={<SafeHtml htmlContent= {requirement.notes} />} placement="top-start">
                                            <SafeHtml htmlContent= {truncatedValue(requirement.notes,100)} />
                                        </Tooltip>
                                        {/* <Link target="_blank" href={`/visa/notes/${requirement._id}`}><TextSnippetIcon/></Link> */}
                                    </TableCell>
                                    <TableCell>
                                        <Button className="ms-2" variant="contained" color="secondary" onClick={() => handleOpenEditModal(requirement.country, requirement.link,requirement._id,requirement.emirate, requirement.notes)}>
                                            Edit
                                        </Button>
                                        <Button className="ms-2" variant="contained" color="primary" onClick={() => handleOpenDeleteModal(requirement._id)}>
                                            Delete
                                        </Button>                                        
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10]}
                component="div"
                count={requirements.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <div style={{ position: "fixed", bottom: "10px", left: "10px" }}>
                <Button variant="contained" color="primary" onClick={handleOpenModal}>
                    Add New Country
                </Button>
            </div>
            <Modal open={openModal} onClose={handleCloseModal}>
                <Box sx={style}>
                    <ReactSelect  
                        autoFocus 
                        labelId="visa-country-label"
                        styles={dropdownStyle} 
                        className="mb-3"             
                        id="country" 
                        name="country" 
                        placeholder="Select Country"
                        label="Enter Country"  
                        value={formCountry}
                        options={countryOptions}
                        onChange={handleCountryInputChange}
                    />                      
                    <TextField
                        label="Link"
                        value={linkInput}
                        className="mb-3"   
                        onChange={handleLinkInputChange}
                    />
                    <TextField  
                        label="Emirate"
                        value={emirateInput}
                        className="mb-3"   
                        onChange={handleEmirateInputChange}
                    />
                    <RichTextEditor
                        htmlContent={noteInput}
                        setHTMLContent={setNoteInput}
                        style={{marginBottom: "10px"}}
                        placeholder={"Enter Notes Here"}
                    />
                    <Button variant="contained" color="primary" onClick={handleAddNewCountry}>
                        Add
                    </Button>
                </Box>
            </Modal>
            <Modal open={openEditModal} onClose={handleCloseEditModal}>
                <Box sx={style}>
                    <ReactSelect  
                        styles={dropdownStyle} 
                        className="mb-3"             
                        id="country" 
                        name="country" 
                        placeholder="Select Country"
                        label="Enter Country"  
                        value={formEditCountry}
                        options={countryOptions}
                        onChange={handleEditCountryInputChange}
                    /> 

                    <TextField
                        autoFocus 
                        label="Link"
                        value={editLink}
                        onChange={handleEditLinkInputChange}
                        style={{ marginBottom: "10px" }}
                    />
                    <TextField
                        label="Emirate"
                        value={editEmirate}
                        onChange={handleEditEmirateInputChange}
                        style={{ marginBottom: "10px" }}
                    />
                    <RichTextEditor 
                        htmlContent={editNote} 
                        setHTMLContent={setEditNote} 
                        style={{marginBottom: "10px"}}
                        placeholder={"Enter Notes Here"} 
                    />
                    <Button variant="contained" color="primary" onClick={() => handleEditCountry(editId)}>
                        Save
                    </Button>
                </Box>
            </Modal>
            <Modal open={openDeleteModal} onClose={handleCloseDeleteModal}>
                <Box sx={style}>
                    <h3>Are you sure you want to delete</h3>
                    
                    <Button variant="contained" color="primary" onClick={() => handleDeleteCountry(deleteRequirement)}>
                        Delete
                    </Button>
                </Box>
            </Modal>            
        </Paper>
    );
}
