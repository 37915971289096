import React from "react";
import UserTable1 from "../components/UserTable1";
import { useEffect } from "react";
import axios from "axios";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import API from "../API";
import { useNavigate } from "react-router-dom";
export default function Latest() {
  const navigate = useNavigate();
  const [data, setData] = React.useState("");
  const fetchData = async () => {
    try{
      const result = await API.get(
        `/ivisa/latest`
      );
      setData(result.data);
      console.log(result.data);
    } catch(error){
      if(error.response && error.response.status===401){
        navigate('/login');
      }
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  const rows = [
    data &&
      data.map((row) => ({
        name: row.name,
        passport_number: row.passport_number,
        reference_number: row.reference_number,
        visa_country: row.visa_country,
        visa_appointment_date: row.visa_appointment_date,
        visa_status: row.visa_status,
      })),
  ];
  const columns = [
    { id: "name", label: "Name", minWidth: 170 },
    { id: "passport_number", label: "Passport Number", minWidth: 100 },
    {
      id: "reference_number",
      label: "Reference Number",
      minWidth: 170,
      align: "right",
    },
    {
      id: "visa_country",
      label: "Visa Country",
      minWidth: 170,
      align: "right",
    },
    {
      id: "visa_appointment_date",
      label: "Visa Appointment Date",
      minWidth: 170,
      align: "right",
    },
    {
      id: "visa_status",
      label: "Visa Status",
      minWidth: 170,
      align: "right",
    },
  ];
  console.log(rows);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ minHeight:"50vh" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows[0].length > 0 &&
              rows[0]
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row._id}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                          
                    </TableRow>
                  );
                })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
