import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { Button, Box, Paper, Table, TableContainer, TableHead, TextField, TableRow, TableBody, TableCell, Tooltip, TablePagination, Grid, InputAdornment, IconButton } from '@mui/material';
import Modal from "@mui/material/Modal";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from '@mui/icons-material/Clear';
import AddBoxIcon from '@mui/icons-material/AddBox';
import API from "../../Visa/API";

// When Reading in future please note that reservation is synonymous to Contact Person, abd sales is synonymous to Sales Support

const initialCreateContactFormData = () => ({
    airline_code: "",
    airline_name: "",
    reservation: {
        name: "",
        email: [""],
        phone: [""]
    },
    sales: {
        name: "",
        email: [""],
        phone: [""]
    },
    group_desk: {
        name: "",
        email: [""],
        phone: [""]
    },
    notes: ""
});

export default function FlightContactDirectory() {
    const navigate = useNavigate();

    const [contacts, setContacts] = useState([]);
    const [contactSearchInput, setContactSearchInput] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [openCreateModal, setOpenCreateModal] = useState(false);
    const [createContactFormData, setCreateContactFormData] = useState(initialCreateContactFormData);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [deleteContactId, setDeleteContactId] = useState(null);
    const [saveOrEdit, setSaveOrEdit] = useState('Save');
    const [editContactId, setEditContactId] = useState(null);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        display: "flex",
        flexDirection: "column",
        maxHeight: "90vh",
        overflowY: "auto",
    };

    const handleCreateNewContact = () => {
        setOpenCreateModal(true);
        setSaveOrEdit('save');
        setCreateContactFormData(initialCreateContactFormData);
    }
           

    const handleOpenEditContact = (contact) => {
        setCreateContactFormData(contact);
        setOpenCreateModal(true);
        setEditContactId(contact._id);
        setSaveOrEdit('edit');
    }

    const handleOpenDeleteContactModal = (contactId) => {
        setDeleteContactId(contactId);
        setOpenDeleteModal(true);
    }

    const handleCloseDeleteModal = () => {
        setOpenDeleteModal(false);
        setDeleteContactId(null);
    } 

    const handleDeleteContact = async (contactId) => {
        try {
            const response = await API.post(`/flights/delete_contact/`, { id: contactId });
            fetchFlightsContacts();
            setOpenDeleteModal(false);
            setDeleteContactId(null);
            toast.success(response.data.message);
        } catch (error) {
            if (error.response && error.response.status === 401) {

            toast.error(error.response.data.error);
        }
        }
    }

    const ifFormValid = () => {
        if (!createContactFormData.airline_name) {
            toast.error('Name is required');
            return false;
        }
        if (!(createContactFormData.reservation.name || createContactFormData.sales.name || createContactFormData.group_desk.name)) {
            toast.error('Any Name is required');
            return false;
        }
        const isReservationEmailValid = createContactFormData.reservation.email.some(email => email.trim() !== '');
        const isSalesEmailValid = createContactFormData.sales.email.some(email => email.trim() !== '');
        const isGroupDeskEmailValid = createContactFormData.group_desk.email.some(email => email.trim() !== '');
        if (!(isReservationEmailValid || isSalesEmailValid || isGroupDeskEmailValid)) {
            toast.error('At least one email is required');
            return false;
        }
        // const isReservationPhoneValid = createContactFormData.reservation.phone.some(phone => phone.trim() !== '');
        // const isSalesPhoneValid = createContactFormData.sales.phone.some(phone => phone.trim() !== '');
        // const isGroupDeskPhoneValid = createContactFormData.group_desk.phone.some(phone => phone.trim() !== '');
        // if (!(isReservationPhoneValid || isSalesPhoneValid || isGroupDeskPhoneValid)) {
        //     toast.error('Any Phone is required');
        //     return false;
        // }
        return true;
    }

    const handleEditContact = async() => {
        if (!ifFormValid()) {
            return;
        }
        try {
            const response = await API.post('/flights/edit_contact', { id: editContactId, ...createContactFormData });
            fetchFlightsContacts();
            setOpenCreateModal(false);
            setCreateContactFormData(initialCreateContactFormData);
            toast.success(response.data.message);
        } catch (error) {
            if (error.response && error.response.status ) { 
               toast.error(error.response.data.error);
            }
        }
    }

    const handleSaveNewContact = async() => {
        if (!ifFormValid()) {
            return;
        }
        try {
            const response = await API.post('/flights/new_contact', createContactFormData);
            fetchFlightsContacts();
            setOpenCreateModal(false);
            setCreateContactFormData(initialCreateContactFormData);
            toast.success(response.data.message);
        } catch (error) {
            if (error.response && error.response.status ) {
                toast.error(error.response.data.error);
            }
        }
    }

    const fetchFlightsContacts = async () => {
        try {
            const response = await API.get('/flights/contact_directory');
            setContacts(response.data);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                console.error('Error fetching contacts:', error);}
        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };    

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };    

    const handleContactSearchInputChange = (event) => {
        setContactSearchInput(event.target.value);
        setPage(0);
    }

    const handleContactSearchInputClear = () => {
        setContactSearchInput('');
        setPage(0);
    }    

    const cleanPhoneNumber = (phoneNumber) => {
        return phoneNumber.replace(/\D/g, '');
    }

    const truncatedValue = (value) => {
        if (value && value.length > 50) {
            return value.substring(0, 50) + '...';
        }
        return value;
    }

    const handleAddEmailField = (group) => {
        const emails = createContactFormData[group].email;
        if (emails.some(email => email === '')) {
            toast.error('Please fill all existing email fields before adding a new one.');
            return;
        }
        setCreateContactFormData((prevData) => ({
            ...prevData,
            [group]: {
                ...prevData[group],
                email: [...prevData[group].email, '']
            }
        }));
    };

    const handleEmailChange = (group, index, event) => {
        const newEmails = createContactFormData[group].email.map((email, i) => i === index ? event.target.value : email);
        setCreateContactFormData({
            ...createContactFormData,
            [group]: {
                ...createContactFormData[group],
                email: newEmails
            }
        });
    };

    const handleDeleteEmailField = (group, index) => {
        const newEmails = createContactFormData[group].email.filter((email, i) => i !== index);
        setCreateContactFormData({
            ...createContactFormData,
            [group]: {
                ...createContactFormData[group],
                email: newEmails
            }
        });
    };

    const handleAddPhoneField = (group) => {
        const phones = createContactFormData[group].phone;
        if (phones.some(phone => phone === '')) {
            toast.error('Please fill all existing phone fields before adding a new one.');
            return;
        }
        setCreateContactFormData((prevData) => ({
            ...prevData,
            [group]: {
                ...prevData[group],
                phone: [...prevData[group].phone, '']
            }
        }));
    };

    const handlePhoneChange = (group, index, event) => {
        const newPhones = createContactFormData[group].phone.map((phone, i) => i === index ? event.target.value.replace(/[^+\d]/g, '') : phone);
        setCreateContactFormData({
            ...createContactFormData,
            [group]: {
                ...createContactFormData[group],
                phone: newPhones
            }
        });
    };

    const handleDeletePhoneField = (group, index) => {
        const newPhones = createContactFormData[group].phone.filter((phone, i) => i !== index);
        setCreateContactFormData({
            ...createContactFormData,
            [group]: {
                ...createContactFormData[group],
                phone: newPhones
            }
        });
    };

    const filteredContacts = contacts.filter((contact) => {
        const emailPhoneIncludesSearchInput = (emailPhoneArr, searchInput) => {
            return emailPhoneArr.some(item => item && item.toLowerCase().includes(searchInput.toLowerCase()));
        };
    
        return (contact.airline_code && contact.airline_code.toLowerCase().includes(contactSearchInput.toLowerCase())) || 
            (contact.airline_name && contact.airline_name.toLowerCase().includes(contactSearchInput.toLowerCase())) ||
            (contact.reservation.name && contact.reservation.name.toLowerCase().includes(contactSearchInput.toLowerCase())) ||
            (contact.reservation.email && emailPhoneIncludesSearchInput(contact.reservation.email, contactSearchInput)) ||
            (contact.reservation.phone && emailPhoneIncludesSearchInput(contact.reservation.phone, contactSearchInput)) ||
            (contact.sales.name && contact.sales.name.toLowerCase().includes(contactSearchInput.toLowerCase())) ||
            (contact.sales.email && emailPhoneIncludesSearchInput(contact.sales.email, contactSearchInput)) ||
            (contact.sales.phone && emailPhoneIncludesSearchInput(contact.sales.phone, contactSearchInput)) ||
            (contact.group_desk.name && contact.group_desk.name.toLowerCase().includes(contactSearchInput.toLowerCase())) ||
            (contact.group_desk.email && emailPhoneIncludesSearchInput(contact.group_desk.email, contactSearchInput)) ||
            (contact.group_desk.phone && emailPhoneIncludesSearchInput(contact.group_desk.phone, contactSearchInput)) ||
            (contact.notes && contact.notes.toLowerCase().includes(contactSearchInput.toLowerCase()));
    });

    useEffect(() => {
        fetchFlightsContacts();
    }, []);

    return (
        <Paper elevation={3} sx={{margin: '20px'}}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center',  padding: '20px' }}>
                <TextField
                    fullWidth
                    label="Search Contacts"
                    variant="outlined"
                    value={contactSearchInput}
                    onChange={handleContactSearchInputChange}
                    InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="clear text"
                              onClick={handleContactSearchInputClear}
                              edge="end"
                            >
                              <ClearIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}                    
                />
            </Box>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Airline Code</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Contact Person</TableCell>
                            <TableCell>Sales Support</TableCell>
                            <TableCell>Group Desk</TableCell>
                            <TableCell>Notes</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredContacts
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((contact) => (
                                <TableRow key={contact.id}>
                                    <TableCell>{contact.airline_code}</TableCell>
                                    <TableCell>{truncatedValue(contact.airline_name)}</TableCell>
                                    <TableCell>
                                        {truncatedValue(contact.reservation.name)}
                                        <br />
                                        {contact.reservation.email.map((email, index) => (
                                            <>
                                            <Tooltip key={index} title={`Email to ${email}`} >
                                                <a href={`mailto:${email}`}>
                                                    {email}
                                                </a>
                                            </Tooltip>
                                            <br />
                                            </>
                                        ))}
                                        {contact.reservation.phone.map((phone, index) => (
                                            phone.trim() !== '' && (
                                                <div key={index}>
                                                    <Tooltip title={`Call to ${cleanPhoneNumber(phone)}`} style={{marginRight: '10px'}}>
                                                        <a href={`tel:${cleanPhoneNumber(phone)}`} target='_blank' rel="noreferrer">
                                                            {cleanPhoneNumber(phone)}
                                                        </a>
                                                    </Tooltip>                                                        
                                                    <Tooltip title={`WhatsApp to ${cleanPhoneNumber(phone)}`}>
                                                        <a href={`https://wa.me/${cleanPhoneNumber(phone)}`} target='_blank' rel="noreferrer">
                                                            <img src={process.env.PUBLIC_URL + '/whatsapp.svg'} style={{ maxWidth: '15px' }} alt="WhatsApp" />
                                                        </a>
                                                    </Tooltip>
                                                    <br />
                                                </div>
                                            )
                                        ))}
                                    </TableCell>

                                    <TableCell>
                                            {truncatedValue(contact.sales.name)}
                                        <br />
                                        {contact.sales.email.map((email, index) => (
                                            <>
                                            <Tooltip key={index} title={`Email to ${email}`} >
                                                <a href={`mailto:${email}`}>
                                                    {email}
                                                </a>
                                            </Tooltip>
                                            <br />
                                            </>
                                        ))}
                                        {contact.sales.phone.map((phone, index) => (
                                            phone.trim() !== '' && (
                                                <div key={index}>
                                                    <Tooltip title={`Call to ${cleanPhoneNumber(phone)}`} style={{marginRight: '10px'}}>
                                                        <a href={`tel:${cleanPhoneNumber(phone)}`} target='_blank' rel="noreferrer">
                                                            {cleanPhoneNumber(phone)}
                                                        </a>
                                                    </Tooltip>                                                        
                                                    <Tooltip title={`WhatsApp to ${cleanPhoneNumber(phone)}`}>
                                                        <a href={`https://wa.me/${cleanPhoneNumber(phone)}`} target='_blank' rel="noreferrer">
                                                            <img src={process.env.PUBLIC_URL + '/whatsapp.svg'} style={{ maxWidth: '15px' }} alt="WhatsApp" />
                                                        </a>
                                                    </Tooltip>
                                                    <br />
                                                </div>
                                            )
                                        ))}
                                   </TableCell>
                                    <TableCell>
                                            {truncatedValue(contact.group_desk.name)}
                                        <br />
                                        {contact.group_desk.email.map((email, index) => (
                                            <>
                                            <Tooltip key={index} title={`Email to ${email}`}>
                                                <a href={`mailto:${email}`}>
                                                    {email}
                                                </a>
                                            </Tooltip>
                                            <br />
                                            </>
                                        ))}
                                        {contact.group_desk.phone.map((phone, index) => (
                                            phone.trim() !== '' && (
                                                <div key={index}>
                                                    <Tooltip title={`Call to ${cleanPhoneNumber(phone)}`} style={{marginRight: '10px'}}>
                                                        <a href={`tel:${cleanPhoneNumber(phone)}`} target='_blank' rel="noreferrer">
                                                            {cleanPhoneNumber(phone)}
                                                        </a>
                                                    </Tooltip>                                                        
                                                    <Tooltip title={`WhatsApp to ${cleanPhoneNumber(phone)}`}>
                                                        <a href={`https://wa.me/${cleanPhoneNumber(phone)}`} target='_blank' rel="noreferrer">
                                                            <img src={process.env.PUBLIC_URL + '/whatsapp.svg'} style={{ maxWidth: '15px' }} alt="WhatsApp" />
                                                        </a>
                                                    </Tooltip>
                                                    <br />
                                                </div>
                                            )
                                        ))}
                                    </TableCell>
                                    <TableCell>
                                        <Tooltip title={contact.notes}>
                                            <span>{truncatedValue(contact.notes)}</span>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell>
                                        <Tooltip
                                            title="Edit"
                                            sx={{color:"blue",cursor:"pointer"}} 
                                            onClick={() => handleOpenEditContact(contact)}
                                        >
                                            <EditIcon style={{ color: "blue" }} />
                                        </Tooltip>
                                        <Tooltip 
                                            title="Delete"
                                            sx={{color:"red",cursor:"pointer"}} 
                                            onClick={() => handleOpenDeleteContactModal(contact._id)}
                                        >
                                            <DeleteIcon style={{ color: "red" }} />
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10]}
                component="div"
                count={contacts.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <div style={{ position: "fixed", bottom: "10px", left: "10px" }}>
                <Button variant="contained" color="primary" onClick={() => handleCreateNewContact()}>
                    Add New Contact
                </Button>
            </div>            
            <Modal open={openCreateModal} onClose={() => setOpenCreateModal(false)}>
                <Box sx={style}>
                    <Grid container spacing={2}>
                        <Grid item xs={2}>
                            <TextField
                                label="Airline Code"
                                value={createContactFormData.airline_code}
                                onChange={(event) => setCreateContactFormData({ ...createContactFormData, airline_code: event.target.value })}
                                style={{ marginBottom: "10px" }}
                            />
                        </Grid>
                        <Grid item xs={10}>
                            <TextField
                                fullWidth
                                label="Name"
                                value={createContactFormData.airline_name}
                                onChange={(event) => setCreateContactFormData({ ...createContactFormData, airline_name: event.target.value })}
                                style={{ marginBottom: "10px" }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                    <Grid item xs={4}>
                            <label>Contact Person</label><br />                            
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                <TextField
                                    label="Name"
                                    value={createContactFormData.reservation.name}
                                    onChange={(event) => setCreateContactFormData({ ...createContactFormData, reservation: { ...createContactFormData.reservation, name: event.target.value } })}
                                    style={{ flex: 1 }}
                                />
                            </div>
                            {createContactFormData.reservation.email.map((email, index) => (
                                <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                    <TextField
                                        label={`Email ${index + 1}`}
                                        value={email}
                                        onChange={(event) => handleEmailChange('reservation', index, event)}
                                        style={{ flex: 1 }}
                                    />
                                    {index === 0 ? (
                                        <Tooltip
                                            title="Add More Contact Person Email"
                                            sx={{ color: "blue", cursor: "pointer", marginLeft: '10px' }}
                                            onClick={() => handleAddEmailField('reservation')}
                                        >
                                            <AddBoxIcon />
                                        </Tooltip>
                                    ) : (
                                        <Tooltip
                                            title="Delete"
                                            sx={{ color: "red", cursor: "pointer", marginLeft: '10px' }}
                                            onClick={() => handleDeleteEmailField('reservation', index)}
                                        >
                                            <DeleteIcon />
                                        </Tooltip>
                                    )}
                                </div>
                            ))}
                            {createContactFormData.reservation.phone.map((phone, index) => (
                                <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                    <TextField
                                        label={`Phone ${index + 1}`}
                                        value={phone}
                                        onChange={(event) => handlePhoneChange('reservation', index, event)}
                                        style={{ flex: 1 }}
                                    />
                                    {index === 0 ? (
                                        <Tooltip
                                            title="Add More Contact Person Phone"
                                            sx={{ color: "blue", cursor: "pointer", marginLeft: '10px' }}
                                            onClick={() => handleAddPhoneField('reservation')}
                                        >
                                            <AddBoxIcon />
                                        </Tooltip>
                                    ) : (
                                        <Tooltip
                                            title="Delete"
                                            sx={{ color: "red", cursor: "pointer", marginLeft: '10px' }}
                                            onClick={() => handleDeletePhoneField('reservation', index)}
                                        >
                                            <DeleteIcon />
                                        </Tooltip>
                                    )}
                                </div>
                            ))}
                        </Grid>                        
                        <Grid item xs={4}>
                            <label>Sales Support</label><br />
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                <TextField
                                    label="Name"
                                    value={createContactFormData.sales.name}
                                    onChange={(event) => setCreateContactFormData({ ...createContactFormData, sales: { ...createContactFormData.sales, name: event.target.value } })}
                                    style={{ flex: 1 }}
                                />
                            </div>
                            {createContactFormData.sales.email.map((email, index) => (
                                <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                    <TextField
                                        label={`Email ${index + 1}`}
                                        value={email}
                                        onChange={(event) => handleEmailChange('sales', index, event)}
                                        style={{ flex: 1 }}
                                    />
                                    {index === 0 ? (
                                        <Tooltip
                                            title="Add More Sales Email"
                                            sx={{ color: "blue", cursor: "pointer", marginLeft: '10px' }}
                                            onClick={() => handleAddEmailField('sales')}
                                        >
                                            <AddBoxIcon />
                                        </Tooltip>
                                    ) : (
                                        <Tooltip
                                            title="Delete"
                                            sx={{ color: "red", cursor: "pointer", marginLeft: '10px' }}
                                            onClick={() => handleDeleteEmailField('sales', index)}
                                        >
                                            <DeleteIcon />
                                        </Tooltip>
                                    )}
                                </div>
                            ))}
                            {createContactFormData.sales.phone.map((phone, index) => (
                                <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                    <TextField
                                        label={`Phone ${index + 1}`}
                                        value={phone}
                                        onChange={(event) => handlePhoneChange('sales', index, event)}
                                        style={{ flex: 1 }}
                                    />
                                    {index === 0 ? (
                                        <Tooltip
                                            title="Add More Sales Phone"
                                            sx={{ color: "blue", cursor: "pointer", marginLeft: '10px' }}
                                            onClick={() => handleAddPhoneField('sales')}
                                        >
                                            <AddBoxIcon />
                                        </Tooltip>
                                    ) : (
                                        <Tooltip
                                            title="Delete"
                                            sx={{ color: "red", cursor: "pointer", marginLeft: '10px' }}
                                            onClick={() => handleDeletePhoneField('sales', index)}
                                        >
                                            <DeleteIcon />
                                        </Tooltip>
                                    )}
                                </div>
                            ))}
                        </Grid>
                        <Grid item xs={4}>
                            <label>Group Desk</label>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                <TextField
                                    label="Name"
                                    value={createContactFormData.group_desk.name}
                                    onChange={(event) => setCreateContactFormData({ ...createContactFormData, group_desk: { ...createContactFormData.group_desk, name: event.target.value } })}
                                    style={{ flex: 1 }}
                                />
                            </div>
                            {createContactFormData.group_desk.email.map((email, index) => (
                                <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                    <TextField
                                        label={`Email ${index + 1}`}
                                        value={email}
                                        onChange={(event) => handleEmailChange('group_desk', index, event)}
                                        style={{ flex: 1 }}
                                    />
                                    {index === 0 ? (
                                        <Tooltip
                                            title="Add More Group Desk Email"
                                            sx={{ color: "blue", cursor: "pointer", marginLeft: '10px' }}
                                            onClick={() => handleAddEmailField('group_desk')}
                                        >
                                            <AddBoxIcon />
                                        </Tooltip>
                                    ) : (
                                        <Tooltip
                                            title="Delete"
                                            sx={{ color: "red", cursor: "pointer", marginLeft: '10px' }}
                                            onClick={() => handleDeleteEmailField('group_desk', index)}
                                        >
                                            <DeleteIcon />
                                        </Tooltip>
                                    )}
                                </div>
                            ))}
                            {createContactFormData.group_desk.phone.map((phone, index) => (
                                <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                    <TextField
                                        label={`Phone ${index + 1}`}
                                        value={phone}
                                        onChange={(event) => handlePhoneChange('group_desk', index, event)}
                                        style={{ flex: 1 }}
                                    />
                                    {index === 0 ? (
                                        <Tooltip
                                            title="Add More Group Desk Phone"
                                            sx={{ color: "blue", cursor: "pointer", marginLeft: '10px' }}
                                            onClick={() => handleAddPhoneField('group_desk')}
                                        >
                                            <AddBoxIcon />
                                        </Tooltip>
                                    ) : (
                                        <Tooltip
                                            title="Delete"
                                            sx={{ color: "red", cursor: "pointer", marginLeft: '10px' }}
                                            onClick={() => handleDeletePhoneField('group_desk', index)}
                                        >
                                            <DeleteIcon />
                                        </Tooltip>
                                    )}
                                </div>
                            ))    
                            }
                        </Grid>
                    </Grid>
                    <TextField
                        label="Notes"
                        value={createContactFormData.notes}
                        onChange={(event) => setCreateContactFormData({ ...createContactFormData, notes: event.target.value })}
                        style={{ marginBottom: "10px" }}
                        multiline={true}
                        rows={5}
                    />
                    <br />
                    <Grid container spacing={2}>
                        <Grid item xs={6} justifyContent={"end"} alignItems={"center"} display={"flex"}>
                            <Button variant="contained" color="secondary" onClick={() => setOpenCreateModal(false)}>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={6} justifyContent={"start"} alignItems={"center"} display={"flex"}>
                            { (saveOrEdit==='save')&&(
                            <Button variant="contained" color="primary" onClick={() => handleSaveNewContact()}>
                                Save
                            </Button>)}
                            { (saveOrEdit==='edit')&&(
                            <Button variant="contained" color="primary" onClick={() => handleEditContact()}>
                                Save
                            </Button>)
                            }                            
                        </Grid>                        
                    </Grid>
                </Box>
            </Modal>
            <Modal open={openDeleteModal} onClose={handleCloseDeleteModal}>
                <Box sx={style}>
                    <h3>Are you sure you want to delete</h3>
                    <br />
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" , margin: "10px", "gap": "10px" }}>
                        <Button variant="contained" color="secondary" onClick={() => handleCloseDeleteModal()}>
                            Cancel
                        </Button>
                        <Button variant="contained" color="primary" onClick={() => handleDeleteContact(deleteContactId)}>
                            Delete
                        </Button>
                    </div>
                </Box>
            </Modal>
        </Paper>
    );
}