import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    data: [],
    loading: false,
    error: null,
}

export const dataSlice = createSlice({
    name: 'data',
    initialState,
    reducers: {
        getDataStart: (state) => {
            state.loading = true;
            state.error = null;
        },
        getDataSuccess: (state, action) => {
            state.data = action.payload;
            state.loading = false;
            state.error = null;
        },
        getDataFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        getDataAscending: (state) => {
            state.data.sort((a, b) => {
                return a.passenger_name.localeCompare(b.passenger_name);
            });
        },
        getDataDescending: (state) => {
            state.data.sort((a, b) => {
                return b.passenger_name.localeCompare(a.passenger_name);
            });
        },
        // New action to sort data by departure_date in ascending order
        sortByDepartureDateAscending: (state) => {
            state.data.sort((a, b) => {
                return new Date(a.departure_date) - new Date(b.departure_date);
            });
        },

        // New action to sort data by departure_date in descending order
        sortByDepartureDateDescending: (state) => {
            state.data.sort((a, b) => {
                return new Date(b.departure_date) - new Date(a.departure_date);
            });
        }
        
    },
});

export const {
  getDataStart,
  getDataSuccess,
  getDataFailure,
  getDataAscending,
  getDataDescending,
  sortByDepartureDateAscending,
  sortByDepartureDateDescending,
} = dataSlice.actions;

export default dataSlice.reducer;
