import React, { useEffect } from "react";
import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";

const VisaCharts = () => {
  useEffect(() => {
    const sdk = new ChartsEmbedSDK({
      baseUrl: "https://charts.mongodb.com/charts-project-0-cboxu",
    });
    const dashboard = sdk.createDashboard({
      dashboardId: "4b380c04-ca10-4eba-be4a-3763398e7dff",
      height: "900px",
      width: "100%",
      theme: "dark",
    });
    dashboard.render(document.getElementById("dashboard"));
  }, []);

  return <div id="dashboard"></div>;
};

export default VisaCharts;
