import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import API from "../../Visa/API";
import { useLocation } from 'react-router-dom';

const FlightContactForm = ({ ip }) => {
  const location = useLocation();
  const currentPage = location.state?.currentPage || 1;
  const search = location.state?.search || "";
  const search1 = location.state?.search1 || "";
  const search8 = location.state?.search8 || "";
  const search4 = location.state?.search4 || "";
  const search5 = location.state?.search5 || "";
  const search6 = location.state?.search6 || "";
  const search7 = location.state?.search7 || "";
  const searchTicketType = location.state?.searchTicketType || "";
  const searchDate = location.state?.searchDate || "";
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [passenger_name, setPassengerName] = useState("");
  const [pnr, setPnr] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [hubspotEmail, setHubspotEmail] = useState("");
  const [hubspotNumber, setHubspotNumber] = useState("");
  const [hubspotLink, setHubspotLink] = useState("");
  const [hubspotName, setHubspotName] = useState("");
  const [nameMatch, setNameMatch] = useState(true);
  const [contacttype, setContacttype] = useState(false);
  const [emailError, setEmailError] = useState("");
  async function fetchData() {
    const response = await API.get(
      `/flights/edit/${id}?token=${localStorage.getItem(
        "authToken"
      )}`
    );
    setData(response.data);
    fetchHubspotData(response.data.hubspot_id);
  }

  async function fetchHubspotData(id) {
    try {
      const response = await API.get(
        `/flights/user/hubspot/${id}?token=${localStorage.getItem(
          "authToken"
        )}`
      );
      setHubspotEmail(response.data.properties.email);
      setHubspotNumber(response.data.properties.phone);
      setHubspotName(response.data.properties.firstname);
    } catch (err) {
      setHubspotEmail("");
      setHubspotNumber("");
      setHubspotName("");
      console.log(err);
    }
  }
  const convertToID = (link) => {
    if (link && String(link).includes("/")) {
      let id = link.split("/").pop();
      console.log(id);
      return id;
    } else {
      return link;
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isEmailValid(contactEmail) && contactEmail !== "") {
      setEmailError("Please enter a valid email address");
      return;
    }
    try {
      
      let updatedData = {
        contact_email: contactEmail,
        contact_number: contactNumber,
        hubspot_id: convertToID(hubspotLink),
        contact_type: contacttype ? "Hubspot" : "Non Hubspot",
        nameMatch: nameMatch,
      };
      await API.patch(
        `/flights/edit/contact/${id}?token=${localStorage.getItem(
          "authToken"
        )}`,
        updatedData
      );
      toast.success("Contact Details Updated Successfully");
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  const isEmailValid = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    if (data) {
      setPassengerName(data.passenger_name || "");
      setPnr(data.pnr || "");

      setContactEmail(data.contact_email || "");
      setContactNumber(data.contact_number || "");
      setHubspotLink(data.hubspot_id || "");
      
      setContacttype(data.contactType === "Hubspot" ? true : false);
    }
  }, [data]);
  return (
    <>
      <Button
        variant="contained"
        sx={{ margin: "5px" }}
        onClick={() =>
          navigate("/flights/tickets", {
            state: { 
              currentPage: currentPage,
              search: search,
              search1: search1,
              search8: search8,
              search4: search4,
              search5: search5,
              search6: search6,
              search7: search7,
              searchTicketType: searchTicketType,
              searchDate: searchDate
            },
          })
        }
      >
        <ArrowBackIcon /> &nbsp;Go Back
      </Button>      
      <Button
        variant="contained"
        sx={{ margin: "5px" }}
        onClick={() =>
          navigate(`/flights/edit/${data._id}`, {
            state: { 
              currentPage: currentPage,
              search: search,
              search1: search1,
              search8: search8,
              search4: search4,
              search5: search5,
              search6: search6,
              search7: search7,
              searchTicketType: searchTicketType,
              searchDate: searchDate
            }
          })
        }
      >
        <EditIcon /> &nbsp;Go To Segment Edit
      </Button>
      <Box
        component="section"
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          height: "80vh", // Set the height to 100% of the viewport height
          p: 2,
          border: "1px dashed grey",
          marginTop: "10px",
          width: "500px",
          margin: "auto",

        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} >
            <Typography variant="h6" gutterBottom component="div">
              Passenger Contact Details
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <label for="contact_email">Contact Email</label>
            <input
              id="contact_email"
              type="email"
              placeholder="Contact Email"
              value={contactEmail}
              onChange={(e) => {
                setContactEmail(e.target.value);
              }}
            />
            {emailError && <div style={{ color: "red" }}>{emailError}</div>}
          </Grid>
          <Grid item xs={12} sm={6} >
            <label for="contact_number">Contact Phone Number</label>
            <input
              id="contact_number"
              placeholder="Contact Phone Number"
              value={contactNumber}
              onChange={(e) => {
                setContactNumber(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} >
            <label for="hubspot_email">Hubspot Email</label>
            <input
              id="hubspot_email"
              type="email"
              placeholder="Hubspot Email"
              value={hubspotEmail}
              disabled
              style={{backgroundColor: "grey"}}
            />
          </Grid>
          <Grid item xs={12} sm={6} >
            <label for="contact_number">Hubspot Phone Number</label>
            <input
              id="contact_number"
              placeholder="Hubspot Phone Number"
              value={hubspotNumber}
              disabled
              style={{backgroundColor: "grey"}}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <label for="hubspot_name">Hubspot Name</label>
            <input
              id="hubspot_name"
              placeholder="Hubspot Name"
              value={hubspotName}
              disabled
              style={{backgroundColor: "grey"}}
            />
          </Grid>
          <Grid item xs={12}  >
            <label for="hubspot_id">Hubspot Link</label>
            <input
              id="hubspot_id"
              placeholder="Hubspot Link"
              value={hubspotLink}
              style={{ width: "400px" }}
              onChange={(e) => {
                setHubspotLink(e.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox color="secondary" name="saveIP" value="yes" checked />
              }
              label="Your IP Address and location will be noted for making changes to the ticket."
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  name="copyPNR_NAME"
                  value="yes"
                  checked
                />
              }
              label="This Hubspot Contact will be copied to all entries of this passenger with the same PNR."
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  name="copyPNR_NAME"
                  value={nameMatch}
                  checked={nameMatch}
                  onChange={(e) => {
                    setNameMatch(e.target.checked);
                  }}
                />
              }
              label="Copy to all entries of this Passenger with exact Name Match"
            />
          </Grid>

          {((contactEmail && contactEmail.length > 0) ||
            (hubspotEmail && hubspotEmail.length > 0) ||
            hubspotLink.length > 0) && (
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="secondary"
                    name="contactType"
                    value={contacttype}
                    checked={contacttype}
                    onChange={(e) => {
                      setContacttype(e.target.checked);
                    }}
                  />
                }
                label="Hubspot Contact Travelling"
              />
            </Grid>
          )}
        </Grid>
        <style>
          {`
          input{
            border: 1px solid #393f81;
            border-radius: 5px;
            padding: 5px;
          }
          input::placeholder{
            opacity: 0.5;
            }
          `}
        </style>
        <div className="fixed-bottom">
          <button
            type="submit"
            className={`btn btn-success col-2`}
            onClick={handleSubmit}
          >
            Save
          </button>
        </div>
      </Box>
    </>
  );
};

export default FlightContactForm;
