import { Typography } from "@mui/material";
import React from "react";
import { Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";

const CustomerNavbar = ({ customerName }) => {
  return (
    <>
      <Navbar bg="dark" expand="lg" variant="dark" style={{ display: "flex", justifyContent: "space-between" }}>
        <Navbar.Brand className="logo">
          <Link to={`https://marcopolo-holidays.com`}>
          <img
            src="https://marcopolo-holidays.com/wp-content/uploads/2021/08/Marcopolo-Grey-transparent-logo-200-px.png"
            width="120px"
            alt="Marcopolo Holidays"
          />
          </Link>
        </Navbar.Brand>
        <Typography style={{fontSize:"14px"}} color="white" className="customerName">{customerName}</Typography>
      </Navbar>
      <style>
        {`
          .me-auhref a {
            color: white;
            text-decoration: none;
          }
          .me-auhref a:hover {
            color: grey;
          }
          .logo {
            margin-left: 20px;  
          }
          @media (max-width: 600px) {
            .customerName {
              display: none;
            }
          }
        `}
      </style>
    </>
  );
};

export default CustomerNavbar;
